import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
// import faqData from "../../utils/pkfaqData.json";
import PlusIco from "../../assets/plusIco.svg";
import MinusIco from "../../assets/Minus.svg";
// Define the FaqData interface
interface FaqData {
  id: number;
  question: string;
  answer: string;
}

interface Props {
  faqData: FaqData[] | undefined; // The faqData prop must be an array of FaqData objects
}
const PackageAccordion: React.FC<Props> = ({ faqData }) => {
  const [activeKey, setActiveKey] = useState<string | null>(null);

  const handleToggle = (key: string) => {
    setActiveKey(activeKey === key ? null : key);
  };

  useEffect(() => {
    import("./PackageAccordian.css");
  }, []);

  return (
    <>
      <Accordion activeKey={activeKey}>
        {faqData?.map((faq, index) => (
          <Card key={index} className="faq-card">
            <Card.Header className="p-0 px-md-3">
              <Accordion.Button
                as="div"
                className="faq-toggle"
                onClick={() => handleToggle(index.toString())}
                aria-expanded={activeKey === index.toString()}
              >
                <span
                  className="faq-question"
                  dangerouslySetInnerHTML={{
                    __html: faq?.question ?? "",
                  }}
                ></span>
                <span className="faq-icon">
                  <img
                    src={activeKey === index.toString() ? MinusIco : PlusIco}
                    alt="Step icon"
                  />
                </span>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body
                dangerouslySetInnerHTML={{
                  __html: faq?.answer ?? "",
                }}
              ></Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
};

export default PackageAccordion;
