import { useNavigate } from "react-router-dom";
import PackageAccordion from "../../PackageAccordian/PackageAccordian";
import { FaqDataProps } from "../../../types/alhadeed";
// import "./faq.css"

interface FaqComponentProps {
  FaqData: FaqDataProps | undefined;
}

const Faq: React.FC<FaqComponentProps> = ({ FaqData }) => {
  const navigate = useNavigate();
  // const [activeKey, setActiveKey] = useState<string | null>(null);

  // const handleToggle = (key: string) => {
  //   setActiveKey(activeKey === key ? null : key);
  // };

  const handleNavigate = () => {
    navigate("/contactus");
  };

  return (
    <section className="faqWrap bg-2 ">
      <div className="container py-2 py-md-5">
        <div className="row">
          <div className="col-md-4 wow animate__fadeInLeft">
            <div className="hd-style-2 ">
              <h2
                dangerouslySetInnerHTML={{
                  __html: FaqData?.heading ?? " ",
                }}
              ></h2>
            </div>
            {FaqData?.line && (
              <p
                dangerouslySetInnerHTML={{
                  __html: FaqData?.line ?? " ",
                }}
              ></p>
            )}

            <button
              className="btn btn-primary flow-btn d-none d-md-block"
              onClick={() => handleNavigate()}
            >
              <span className="text-white text-decoration-none ff-semibold">
                Contact Us
              </span>
            </button>
          </div>

          <div className="col-md-8  p-0 p-md-3">
            <div
              className="scrollWrap wow animate__fadeIn"
              data-wow-duration="3s"
            >
              <PackageAccordion faqData={FaqData?.Faq} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
