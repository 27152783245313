import React, { Suspense, useEffect, useState } from "react";
import PreLoader from "../../components/preloader/PreLoader";
import AnnounceModal from "../../components/model/AnnounceModal";
import Enquiry from "../../components/enquiry/Enquiry";
import { AmerCenterData, SEOData } from "../../types/home";
import GoldenVisaSlider from "../../components/client/GoldenVisaSlider";
import Marquee from "../../components/marquee/Marquee";
import "../../css/slick-theme.css";
import "../../css/slick.css";
import "../../css/style.css";
import "../../css/responsive.css";
import MetaHead from "../../components/metaHead/MetaHead";

const apiUrl = import.meta.env.VITE_API_URL;

const BannerServices = React.lazy(
  () => import("../../components/bannerServices/BannerServices")
);
const WhoWeAre = React.lazy(() => import("../../components/whoweare/WhoWeAre"));
const Experts = React.lazy(() => import("../../components/expert/Experts"));
const TouristVisa = React.lazy(
  () => import("../../components/TouristVisa/TouristVisa")
);
const Services = React.lazy(() => import("../../components/services/Services"));
const Offers = React.lazy(() => import("../../components/Offers/Offers"));
const Clients = React.lazy(() => import("../../components/client/Clients"));

const Home = () => {
  const URL = `${apiUrl}/api/openRoute/home`;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mainServices, setMainServices] = useState([]);
  const [footBannerSlider, setfootBannerSlider] = useState([]);
  const [offers, setoffers] = useState([]);
  const [visitOffers, setvisitOffers] = useState([]);
  const [governmentPartners, setGovernmentPartners] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [data, setData] = useState<AmerCenterData>();
  const [metaData, setMetaData] = useState<SEOData>();

  const getDataFromJson = () => {
    return fetch("https://goodhand.b-cdn.net/Assets/Home/Home.json")
      .then((response) => response.json()) // Use json() to parse the JSON response
      .then((data) => {
        if (data) {
          setMetaData(data.seo);
          setData(data);
          return true; // Indicate success
        }
        return false; // Return false if data is not available
      })
      .catch((error) => {
        console.error("Error fetching JSON data:", error);
        return false; // Indicate failure
      });
  };

  const getDataFromApi = () => {
    return fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.HomeData) {
          setMainServices(data.HomeData.mainServices);
          setfootBannerSlider(data.HomeData.footBannerSlider);
          setoffers(data.HomeData.offers);
          setvisitOffers(data.HomeData.visitOffers);
          setGovernmentPartners(data.HomeData.MainLogo);
          setEmployee(data.HomeData.Employee);
          return true; // Indicate success
        }
        return false; // Return false if data is not available
      })
      .catch((error) => {
        console.error("Error fetching API data: home", error);
        return false; // Indicate failure
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!jsonDataSuccess || !apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <div>
          <MetaHead metaData={metaData} />
          <Suspense >
            <BannerServices mainServices={mainServices} data={data?.section1} />
            <Enquiry />
            <Offers
              offers={offers}
              section8={data?.section8}
              isPackage={true}
            />
            <WhoWeAre
              governmentPartners={governmentPartners}
              section2={data?.section2}
            />
            <Marquee news={data?.section3.heading} />
            <Experts employee={employee} />
            <Services section5={data?.section5} section6={data?.["section6"]} />
            <TouristVisa visitOffers={visitOffers} section4={data?.section4} />
            <Clients
              // footBannerSlider={footBannerSlider}
              section9={data?.section9}
            />
            <GoldenVisaSlider sliderData={footBannerSlider} />
            <AnnounceModal />
          </Suspense>
        </div>
      )}
    </>
  );
};

export default Home;
