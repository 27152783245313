import React, { useEffect, useState } from "react";

import { logoutFun } from "../../config/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [isLogin, setIsLogin] = useState(false);
  const [number, setNumber] = useState({ countryCode: "", phoneNumber: "" });

  useEffect(() => {
    import("./profile.css");
    const login_info = localStorage.getItem("Login_info");
    if (login_info) {
      setIsLogin(true);
    }

    const num = localStorage.getItem("phoneNumber");
    console.log("num",num)
    if (num) {
      const nm = JSON.parse(num);
      setNumber(nm[0]);
    }
    // const token = sessionStorage.getItem("tk");
  }, []);

  return (
    <>
      <div className="bg-2 min-vh-100 py-md-0">
        <div className="container ">
          <div className="row justify-content-center help-wrap">
            <div className="col-md-5 p-0 p-md-2">
              <div className="bg-white p-3 col mb-2">
                {isLogin ? (
                <div className="d-flex align-items-center gap-3">
                  {number.countryCode !== "" && (
                  <div>
                    {/* <h1 className="mb-0 text-black">Humayun Obaid</h1> */}
                    <span className="font-14">{number?.countryCode + number?.phoneNumber}</span>
                  </div>
                  )}
                  <div className="flex-grow-1 text-end">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.622 2.32c-.464 0-.91.184-1.238.512l-11.25 11.25a1.75 1.75 0 00-.512 1.238v4.19a1.75 1.75 0 001.75 1.75h4.5a1 1 0 00.707-.294l11.47-11.47a1.75 1.75 0 000-2.474l-4.19-4.19a1.75 1.75 0 00-1.237-.512zm-3 5.103l-8 8v3.836h3.836l8-8-3.836-3.836zm5.25 2.422l1.586-1.586-3.836-3.836-1.586 1.586 3.836 3.836z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                </div>
                ):(
                  <button className="btn btn-primary" onClick={() => navigate('/userlogout')}> Login </button>
                )}
              </div>

              <div className="bg-white p-3 col mb-3">
                {isLogin && (<>
                <div
                  className="d-flex align-items-center gap-3 border-bottom py-3"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.6,
                    cursor: "not-allowed",
                  }}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.024 8.332a4.225 4.225 0 116.046 0 6.647 6.647 0 012.732 2.595l.042.073h1.54c.344 0 .714.097 1.024.322.315.23.592.62.592 1.126v7.16c0 .506-.277.896-.592 1.126-.31.225-.68.322-1.023.322H14.46c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457v1h-2v-1c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H4.615c-.343 0-.713-.097-1.023-.322A1.395 1.395 0 013 19.608v-7.16c0-.506.277-.896.592-1.126.31-.225.68-.322 1.023-.322H6.25l.043-.074a6.647 6.647 0 012.732-2.594zm.797-2.953a2.225 2.225 0 112.24 2.226H12.033a2.225 2.225 0 01-2.21-2.226zM8.727 11h.811c.833 0 1.673.238 2.33.716.044.033.089.067.132.102.043-.035.088-.07.133-.102.656-.478 1.496-.716 2.329-.716h.904a4.643 4.643 0 00-3.302-1.395h-.035A4.647 4.647 0 008.727 11zM13 13.79v5.522a4.24 4.24 0 011.461-.256H19V13h-4.539c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457zm-2 0c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H5v6.056h4.538c.499 0 1 .085 1.462.256V13.79z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                  <div className="">
                    <p className="font-12 ff-normal mb-0 text-black">
                      My Plans
                    </p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3 border-bottom py-3"
                onClick={()=> navigate("/booking")}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.026 14.727a7 7 0 10-8.051 0 11.5 11.5 0 00-5.936 5.022l1.732 1A9.504 9.504 0 0112 16a9.504 9.504 0 018.229 4.75l1.732-1a11.504 11.504 0 00-5.935-5.023zM7 9a5 5 0 115 5 5 5 0 01-5-5z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p className="font-12 ff-normal mb-0 text-black">My Bookings</p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center gap-3 border-bottom py-3"
                  onClick={()=> navigate("/helpcenter")}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.024 8.332a4.225 4.225 0 116.046 0 6.647 6.647 0 012.732 2.595l.042.073h1.54c.344 0 .714.097 1.024.322.315.23.592.62.592 1.126v7.16c0 .506-.277.896-.592 1.126-.31.225-.68.322-1.023.322H14.46c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457v1h-2v-1c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H4.615c-.343 0-.713-.097-1.023-.322A1.395 1.395 0 013 19.608v-7.16c0-.506.277-.896.592-1.126.31-.225.68-.322 1.023-.322H6.25l.043-.074a6.647 6.647 0 012.732-2.594zm.797-2.953a2.225 2.225 0 112.24 2.226H12.033a2.225 2.225 0 01-2.21-2.226zM8.727 11h.811c.833 0 1.673.238 2.33.716.044.033.089.067.132.102.043-.035.088-.07.133-.102.656-.478 1.496-.716 2.329-.716h.904a4.643 4.643 0 00-3.302-1.395h-.035A4.647 4.647 0 008.727 11zM13 13.79v5.522a4.24 4.24 0 011.461-.256H19V13h-4.539c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457zm-2 0c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H5v6.056h4.538c.499 0 1 .085 1.462.256V13.79z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                  <div className="">
                    <p className="font-12 ff-normal mb-0 text-black">
                      Help Center
                    </p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center gap-3 py-3"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.6,
                    cursor: "not-allowed",
                  }}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 8a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                        fill="#0F0F0F"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3H4zM3 7a1 1 0 011-1h16a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V7z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p className="font-12 ff-normal mb-0 text-black">My Rating</p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center gap-3 py-3"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.6,
                    cursor: "not-allowed",
                  }}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 8a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                        fill="#0F0F0F"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3H4zM3 7a1 1 0 011-1h16a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V7z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p className="font-12 ff-normal mb-0 text-black">Manage Addressess</p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                </>)}
                <div
                  className="d-flex align-items-center gap-3 py-3"
                  onClick={()=> navigate("/about")}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 8a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                        fill="#0F0F0F"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3H4zM3 7a1 1 0 011-1h16a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V7z"
                        fill="#0F0F0F"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p className="font-12 ff-normal mb-0 text-black">About GoodHand</p>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="13px"
                      viewBox="-5.5 0 26 26"
                      version="1.1"
                    >
                      <title>chevron-right</title>
                      <desc>Created with Sketch Beta.</desc>
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-474.000000, -1196.000000)"
                          fill="#626161"
                        >
                          <path
                            d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                            id="chevron-right"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

              </div>
              {isLogin && ( 
              <div className="p-3 d-flex justify-content-center" >
                <button style={{
                  padding: "10px",
                  border: "none",
                  width: "90%"
                }}
                onClick={() => logoutFun(dispatch, navigate)}
                > Logout </button>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
