import { Swiper, SwiperSlide } from "swiper/react";

import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperNavButtons from "../../../../components/sliderButtons/SwiperNavButton";
import { ServiceInDubaiSlideData } from "../../../../types/alhadeed";

// Install Swiper modules

interface Props {
  // data: ServiceInDubaiSlideData[]
  data: ServiceInDubaiSlideData[];
}
const ServiceInDubaiSlider: React.FC<Props> = ({ data }) => {

  return (
    <section>
      <div className="container">
        <div className="hd-style-2 font-35 ff-bold">
          <p>
            <span>Authorised </span> <br /> Channel Partner
          </p>
        </div>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={10} // Space between slides
          slidesPerView={3} // Default slides per view (for larger screens)
          loop={true} // Enable looping
          autoplay={{
            delay: 2500, // Duration for each slide
            disableOnInteraction: false, // Continue autoplay after user interaction
          }}
          breakpoints={{
            320: { slidesPerView: 1 }, // 1 slide for small screens (mobile)
            680: { slidesPerView: 2 }, // 2 slides on tablets
            1024: { slidesPerView: 3 }, // 3 slides on desktop
          }}
          className="mySwiper"
        >
          {data?.map((department, index) => (
            <SwiperSlide key={index}>
              <div className="d-flex align-items-center gap-4 p-3 bg-white shadow-sm rounded departments">
                <div>
                  <img
                    src={department?.img}
                    alt={department.title}
                    className="d-block"
                  />
                </div>
                <div>
                  <p className="ff-semibold font-14 text-start mb-0 text-black">
                    {department.title}
                  </p>
                  <p className="font-12 description mb-0">
                    {department.description}
                  </p>
                  <a className="text-decoration-none" href={department.URL}>
                    <button className="btn btn-primary font-12 ff-semibold margin-minus bt--cart h-50">
                      Explore
                    </button>
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <SwiperNavButtons />
        </Swiper>
      </div>
    </section>
  );
};

export default ServiceInDubaiSlider;
