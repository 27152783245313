import React, { useEffect, useState } from "react";
import BackButton from "../../components/backButton/Backbutton";
import PreLoader from "../../components/preloader/PreLoader";
import axios from "axios";
import { logoutFun } from "../../config/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-main.svg";
import helpJson from "../../utils/help.json";
// import { helpData } from "../../types/help";
import CompnayInfo from "./components/CompnayInfo";
import HowToBook from "./components/HowToBook";
import WhyChooseUs from "./components/WhyChooseUs";
import ServicesOffered from "./components/ServicesOffered";
import UniqueFeatures from "./components/UniqueFeatures";
import Faq from "../../components/amirComponents/amirFaq/Faq";
// import PackageAccordion from "../../components/PackageAccordian/PackageAccordian";
import ContactUs from "./components/ContactUs";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";

const baseUrl = import.meta.env.VITE_API_URL;

interface OrderData {
  RecordId: number;
  userName: string;
  orderNumber: string;
  paymentstatus: number;
  ordertotal: number;
  createdon: string;
  modifiedon: string;
  orderStatus: string;
  InvoiceRef: string;
  paymentMode: string;
  NoofApplications: number;
}

const HelpSection: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [metaData, setMetaData] = useState<SEOData | null>(null);
  // const [jsonData, setJsonData] = useState<helpData[]>();

  const fetchOrderData = async (
    token: string | null,
    login_infoo_userName: string
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);

      const postData = {
        userName: login_infoo_userName,
      };

      const response = await axios.post(
        `${baseUrl}/api/user/orderShow`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      setOrderData(data.mainOrdersList.orders);
      // setCount(data.mainOrdersList.count);

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      // console.error('Error fetching order data:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // If token is expired or invalid
          if (error.response.status === 401 || error.response.status === 407) {
            console.error("Token expired. Logging out...");
            // logout(); // Call the logout function to handle session end
          }
        } else if (error.code === "ERR_NETWORK") {
          console.error("Network error:", error);
          logoutFun(dispatch, navigate);
        }
      } else {
        console.error("Error fetching order data:", error);
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    import("./helpSection.css");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    console.log("helpJson", helpJson);
    // setJsonData(helpJson);
    if (login_info) {
      const login_infoo = JSON.parse(login_info);
      fetchOrderData(token, login_infoo.userName);
    }

    const data = {
      "hidden":{
          "heading" : "",
          "description" : ""
      },
      "seo" : {
        "shortcut_icon": {
          "type": "image/x-icon",
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "apple_touch_icon": {
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "title": "Goodhand Get Help",
        "Keywords": "",
        "description": "Goodhand Get Help",
        "canonical": "https://www.goodhand.ae/helpcenter",
        "meta": [
          {
            "name": "description",
            "content": "Goodhand Get Help"
          },
          {
            "name": "url",
            "content": "https://www.goodhand.ae/helpcenter"
          },
          {
            "name": "og:title",
            "content": "Goodhand Get Help"
          },
          {
            "name": "og:type",
            "content": "website"
          },
          {
            "name": "og:url",
            "content": "https://www.goodhand.ae/helpcenter"
          },
          {
            "name": "og:image",
            "content": "https://goodhand.ideaintl.net/assets/logo-main-gFDCiCbr.svg"
          },
          {
            "name": "og:site_name",
            "content": "https://www.goodhand.ae"
          },
          {
            "name": "og:description",
            "content": "Goodhand Get Help"
          },
          {
            "name": "twitter:card",
            "content": "summary_large_image"
          },
          {
            "name": "twitter:title",
            "content": "Goodhand Get Help"
          },
          {
            "name": "twitter:description",
            "content": "Goodhand Get Help"
          },
          {
            "name": "twitter:image",
            "content": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          {
            "name": "twitter:site",
            "content": "@GoodHandUAE"
          }
        ],
        scripts: [],
        noscript: []
      }
    };
    setMetaData(data?.seo);
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}
          <div className="bg-2 min-vh-100 py-5 py-md-0 ">
            <div className="container">
              <div className="row justify-content-center help-wrap">
                <div className="col-md-5 p-0 p-md-2">
                  <div className="col-12">
                    <BackButton />
                  </div>
                  <p className="font-30 ff-semibold text-black px-3">
                    How can we help you?
                  </p>
                  <div>
                    <CompnayInfo compinfo={helpJson?.["companyInfo"]} />
                    <WhyChooseUs whychooseus={helpJson?.["whyChooseUs"]} />
                    <HowToBook howtobook={helpJson?.["howToBook"]} />
                    <ServicesOffered
                      servicesOffered={helpJson?.["servicesOffered"]}
                    />
                    <UniqueFeatures
                      uniqueFeature={helpJson?.["uniqueFeatures"]}
                    />
                    <Faq FaqData={helpJson?.["faqs"]} />
                    <ContactUs contactUs={helpJson?.["contactUs"]} />
                  
                  </div>
                  {orderData.length > 0 && (
                    <div className="bg-white p-3 col mb-1">
                      <p className="font-18 text-black ff-semibold mb-3">
                        Recent Orders
                      </p>
                      {orderData.map((order) => (
                        <div
                          key={order?.orderNumber}
                          className="d-flex align-items-center gap-3"
                          onClick={() =>
                            navigate(`/orderlist/${order.orderNumber}`)
                          }
                        >
                          <div>
                            <img
                              // src="https://goodhand.b-cdn.net/Assets/Web_Goodhand_imgs/Tawjeeh/taw-jeeh%20good%20hand%20website/Labour%20contract%20Renew%20Submission%20For%20Category-3-01.svg"
                              src={logo}
                              alt=""
                              className="img-fluid"
                              style={{ height: "80px", width: "90px" }}
                            />
                          </div>
                          <div>
                            <p className="font-14 ff-semibold mb-0 text-black">
                              {order.orderNumber}
                            </p>
                            <span className="font-11 ff-medium">
                              {/* {order.createdon} */}
                              {new Date(order.createdon).toLocaleString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </span>
                          </div>
                          <div className="flex-grow-1 text-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="13px"
                              height="13px"
                              viewBox="-5.5 0 26 26"
                              version="1.1"
                            >
                              <title>chevron-right</title>
                              <desc>Created with Sketch Beta.</desc>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Icon-Set-Filled"
                                  transform="translate(-474.000000, -1196.000000)"
                                  fill="#626161"
                                >
                                  <path
                                    d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                    id="chevron-right"
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="bg-white p-3 col mb-3">
                    <p className="font-18 text-black ff-semibold mb-3">
                      All topics
                    </p>

                    <div
                      className="d-flex align-items-center gap-3 border-bottom py-3"
                      onClick={() => navigate("/profile")}
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.026 14.727a7 7 0 10-8.051 0 11.5 11.5 0 00-5.936 5.022l1.732 1A9.504 9.504 0 0112 16a9.504 9.504 0 018.229 4.75l1.732-1a11.504 11.504 0 00-5.935-5.023zM7 9a5 5 0 115 5 5 5 0 01-5-5z"
                            fill="#000"
                          ></path>
                        </svg>
                      </div>
                      <div>
                        <p className="font-12 ff-normal mb-0 text-black">
                          Account
                        </p>
                      </div>
                      <div className="flex-grow-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="13px"
                          height="13px"
                          viewBox="-5.5 0 26 26"
                          version="1.1"
                        >
                          <title>chevron-right</title>
                          <desc>Created with Sketch Beta.</desc>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Icon-Set-Filled"
                              transform="translate(-474.000000, -1196.000000)"
                              fill="#626161"
                            >
                              <path
                                d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                id="chevron-right"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center gap-3 border-bottom py-3"
                      style={{
                        pointerEvents: "none",
                        opacity: 0.6,
                        cursor: "not-allowed",
                      }}
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.024 8.332a4.225 4.225 0 116.046 0 6.647 6.647 0 012.732 2.595l.042.073h1.54c.344 0 .714.097 1.024.322.315.23.592.62.592 1.126v7.16c0 .506-.277.896-.592 1.126-.31.225-.68.322-1.023.322H14.46c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457v1h-2v-1c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H4.615c-.343 0-.713-.097-1.023-.322A1.395 1.395 0 013 19.608v-7.16c0-.506.277-.896.592-1.126.31-.225.68-.322 1.023-.322H6.25l.043-.074a6.647 6.647 0 012.732-2.594zm.797-2.953a2.225 2.225 0 112.24 2.226H12.033a2.225 2.225 0 01-2.21-2.226zM8.727 11h.811c.833 0 1.673.238 2.33.716.044.033.089.067.132.102.043-.035.088-.07.133-.102.656-.478 1.496-.716 2.329-.716h.904a4.643 4.643 0 00-3.302-1.395h-.035A4.647 4.647 0 008.727 11zM13 13.79v5.522a4.24 4.24 0 011.461-.256H19V13h-4.539c-.473 0-.885.139-1.152.333-.262.19-.309.369-.309.457zm-2 0c0-.088-.047-.266-.31-.457-.266-.194-.678-.333-1.152-.333H5v6.056h4.538c.499 0 1 .085 1.462.256V13.79z"
                            fill="#0F0F0F"
                          ></path>
                        </svg>
                      </div>
                      <div className="">
                        <p className="font-12 ff-normal mb-0 text-black">
                          Getting started with GH
                        </p>
                      </div>
                      <div className="flex-grow-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="13px"
                          height="13px"
                          viewBox="-5.5 0 26 26"
                          version="1.1"
                        >
                          <title>chevron-right</title>
                          <desc>Created with Sketch Beta.</desc>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Icon-Set-Filled"
                              transform="translate(-474.000000, -1196.000000)"
                              fill="#626161"
                            >
                              <path
                                d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                id="chevron-right"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center gap-3 py-3"
                      style={{
                        pointerEvents: "none",
                        opacity: 0.6,
                        cursor: "not-allowed",
                      }}
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 8a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                            fill="#0F0F0F"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4 4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3H4zM3 7a1 1 0 011-1h16a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V7z"
                            fill="#0F0F0F"
                          ></path>
                        </svg>
                      </div>
                      <div>
                        <p className="font-12 ff-normal mb-0 text-black">
                          Payment
                        </p>
                      </div>
                      <div className="flex-grow-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="13px"
                          height="13px"
                          viewBox="-5.5 0 26 26"
                          version="1.1"
                        >
                          <title>chevron-right</title>
                          <desc>Created with Sketch Beta.</desc>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Icon-Set-Filled"
                              transform="translate(-474.000000, -1196.000000)"
                              fill="#626161"
                            >
                              <path
                                d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                id="chevron-right"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HelpSection;
