import Faq from "../../components/amirComponents/amirFaq/Faq";
import Clients from "../../components/client/Clients";
import { useSelector } from "react-redux";
import GetQuoteModel from "../../components/getquotemodel/GetQuoteModel";
import { useEffect, useState } from "react";
import PreLoader from "../../components/preloader/PreLoader";
import axios from "axios";
import HeroSection from "./components/HeroSection";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section5 from "./components/Section5";
// import ss from "./Legaljson.json"
import Section4 from "./components/Section4";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import MetaHead from "../../components/metaHead/MetaHead";
import { redirection } from "../../types/marriageSevices";
import ServiceInDubaiSlider from "../alAdheed/marriageServices/components/ServiceInDubaiSlider";
import { Allservice } from "../../types/typeofvisa";

const apiUrl = import.meta.env.VITE_API_URL;

const LegalTranslation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();
  const [dataFromApiLegalInsidepages, setDataFromApiLegalInsidepages] =
    useState();
  const [AllLegalServiceListApi, setAllLegalServiceListApi] = useState();
  const [redirection, setRedirection] = useState<redirection>();
  const [allService, setAllService] = useState<Allservice[]>([]);
  const URL = `${apiUrl}/api/openRoute/footerLinks?url=legal-translation-services-in-dubai`;

  const showGetQuoteModel = useSelector(
    (state: { modal: { getQuoteModel: boolean } }) => state.modal.getQuoteModel
  );

  const getDataFromJson = async () => {
    try {
      const response = await axios.get(
        "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/Legaljson.json"
      );
      // Access JSON data directly from response
      if (response.data) {
        setMetaData(response.data.seo);
        setData(response.data);
        setMetaData(response.data.seo);
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/openRoute/LegalInsidepages`
      );
      if (response.data) {
        setDataFromApiLegalInsidepages(response.data.Data);
        setRedirection(response.data.serviceList2);
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromAllLegalServiceListApi = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/openRoute/AllLegalServiceList`
      );
      if (response.data) {
        setAllLegalServiceListApi(response.data.Data);

        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromAllLegalServiceListApitest = async () => {
    try {
      const response = await axios.get(URL);
      if (response.data) {
        setAllService(response?.data.HomeData.Allservice);

        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    import("./legalTranslation.css");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();
        const AllLegalServiceList = await getDataFromAllLegalServiceListApi();
        const AllLegalServiceListtes =
          await getDataFromAllLegalServiceListApitest();

        // If neither call succeeds, show an error
        if (
          !jsonDataSuccess ||
          !apiDataSuccess ||
          !AllLegalServiceList ||
          !AllLegalServiceListtes
        ) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <MetaHead metaData={metaData} />
          <section className="pt-1 pt-md-5 ">
            <HeroSection heroSection={data?.["Hero Section"]} />
            <Section1 section={data?.["section"]} />
            <Section2 section1={data?.["section1"]} />
            <Section3
              section2={data?.["section2"]}
              apiData={dataFromApiLegalInsidepages}
            />
            <Section5
              section3={data?.["section3"]}
              apiData={AllLegalServiceListApi}
              redirection={redirection}
            />
            <section className="py-5">
              <ServiceInDubaiSlider data={allService} />
            </section>
            <Section4 section4={data?.["section4"]} />
            <Section6 section5={data?.["section5"]} />
            <section className="types-of-visa">
              <Faq FaqData={data?.["section6"]} />
            </section>
            <section>
              <Clients section9={data?.["section7"]} />
            </section>
            <Section7 data={data?.["section8"]} section={data?.["section"]} />
          </section>
          {showGetQuoteModel && <GetQuoteModel />}
        </>
      )}
    </>
  );
};

export default LegalTranslation;
