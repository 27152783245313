import ClientSlider from "./ClientSlider";
import { Section9 } from "../../types/home";

interface Props {
  section9?: Section9;
}

const Clients: React.FC<Props> = ({ section9 }) => {
  return (
    <>
      <section className="clients py-5">
        <div className="container">
          <div className="row">
            <div className={section9?.image ? "col-md-6" : ""}>
              <div className="hd-style-2 wow animate__fadeInLeft">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section9?.heading ?? "",
                  }}
                ></h2>
                {section9?.line !== "" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: section9?.line ?? "",
                    }}
                  ></p>
                )}
              </div>
            </div>
            {section9?.image !== "" && (
              <div className="col-md-6 wow animate__zoomIn">
                <img
                  loading="lazy"
                  src={section9?.image}
                  alt="no img"
                  className="img-fluid Client-bg"
                />
              </div>
            )}
            <div className="col-md-12 wow animate__zoomIn">
              <ClientSlider clientData={section9?.slider} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
