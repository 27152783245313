import { howtobook } from "../../../types/help";

interface Props {
  howtobook: howtobook
}

const HowToBook: React.FC<Props> = ({ howtobook }) => {
  return (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: howtobook?.heading ?? "",
        }}
      ></h1>

      {howtobook?.steps?.map((step) => (
        <ul key={step.id}>
          <li>{step.id}.&nbsp;&nbsp;&nbsp;<b>{step.heading}:</b>&nbsp;{step.step}</li>
        </ul>
      ))}
    </>
  );
};

export default HowToBook;
