import { useNavigate } from "react-router-dom";
import { SubserviceComponent } from "../../../../types/alhadeed";

interface Props {
  data?: SubserviceComponent;
}
const ServiceInDubai: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  // const serviceInDubai = {
  //   heading: `Al Adheed Services <br /><span> in UAE</span>`,
  //   servicesData: [
  //     {
  //       id: 1,
  //       title: "Marriage Services",
  //       description: [
  //         {
  //           id: 1,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //         {
  //           id: 2,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //       ],
  //       imgSrc:
  //         "https://goodhand.b-cdn.net/Assets/Web_Goodhand_imgs/Al%20Adheed/marriage-case.svg",
  //     },
  //     {
  //       id: 2,
  //       title: "Marriage Services",
  //       description: [
  //         {
  //           id: 1,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //         {
  //           id: 2,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //       ],
  //       imgSrc:
  //         "https://goodhand.b-cdn.net/Assets/Web_Goodhand_imgs/Al%20Adheed/personal-application.svg",
  //     },
  //     {
  //       id: 3,
  //       title: "Marriage Services",
  //       description: [
  //         {
  //           id: 1,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //         {
  //           id: 2,
  //           data: "Good Hand stands out as a trusted partner in managing personal legal matters. From sensitive inheritance claims to marital disputes, we provide professional, tailored services designed to meet your specific needs.",
  //         },
  //       ],
  //       imgSrc:
  //         "https://goodhand.b-cdn.net/Assets/Web_Goodhand_imgs/Al%20Adheed/case-regi-and-hearings.svg",
  //     },
  //   ],
  // };

  return (
    <>
      <section className="bg-2 py-5 py-md-5">
        <div className="container">
          <div className="row">
            <div className="hd-style-2 font-35 ff-bold">
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.heading ?? "",
                }}
              ></p>
            </div>

            {data?.data?.map((item) => (
              <>
                {item.id % 2 !== 0 && (
                  <div className="col-md-5 mb-5 d-none d-md-block ">
                    <img
                      src={item?.src}
                      alt=""
                      height={300}
                      className="d-md-block d-none mx-auto"
                    />
                  </div>
                )}
                <div className="col-md-6 my-md-auto my-3">
                  <div className="p-3 bg-white shadow-sm rounded">
                    <p className="font-18 ff-bold text-black">{item?.title}</p>
                    {item?.description?.map((desc) => (
                      <p className="font-12 text-black">{desc}</p>
                    ))}
                    <button
                      className="btn btn-primary font-14 ff-semibold margin-minus bt--cart h-75"
                      onClick={() => navigate(`/${item?.url}`)}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
                {item.id % 2 === 0 && (
                  <div className="col-md-5 mb-5 d-none d-md-block">
                    <img
                      src={item?.src}
                      alt=""
                      height={300}
                      className="d-md-block mx-auto d-none"
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceInDubai;
