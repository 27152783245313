import Faq from "../../../components/amirComponents/amirFaq/Faq";
import WhyChooseUs from "../../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";
import BannerCompnent from "../marriageServices/components/BannerCompnent";
import MarriageServiceComponent from "../marriageServices/components/MarriageServiceComponent";

import HappyStories from "../marriageServices/components/HappyStories";
import WhyChooseGoodHandMarriageServices from "../marriageServices/components/WhyChooseGoodHandMarriageServices";
import OneStopDestination from "../marriageServices/components/OneStopDestination";
import ComprehensiveService from "../marriageServices/components/ComprehensiveService";
import PreLoader from "../../../components/preloader/PreLoader";
import { useEffect, useState } from "react";
import axios from "axios";
import { SEOData } from "../../../types/home";
import MetaHead from "../../../components/metaHead/MetaHead";
import ServiceInDubaiSlider from "../marriageServices/components/ServiceInDubaiSlider";
const apiUrl = import.meta.env.VITE_API_URL;
const CaseService = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [personServic, serPersonServic] = useState();
  const [metaData, setMetaData] = useState<SEOData>();
  const [apidata, setApiData] = useState();
  const [allService, setAllService] = useState<[]>([]);

  const JSON_URL = `https://goodhand.b-cdn.net/Assets/Footer_pages/Ahdeed/all-case-services.json`;
  const URL = `${apiUrl}/api/openRoute/serviceAdheed?slug=all-case-services&mainPageSlug=dubai-courts-al-adheed`;

  const getDataFromJson = async () => {
    try {
      const response = await axios.get(JSON_URL); // Fetch data using Axios

      if (response) {
        serPersonServic(response.data); // Save data to state
        setMetaData(response.data?.seo); // Save metadata to state
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data with Axios:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);
      if (response) {
        setApiData(response.data);
        setAllService(response.data?.Allservice);
        return true;
      }

      return false;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error(
            "Error 400: Bad request, redirecting to NotFound page."
          );
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false;
    }
  };

  useEffect(() => {
    import("../aladheed.css");
    import("../marriageServices/marriageServices.css");

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!jsonDataSuccess && !apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}

          <BannerCompnent bannerImg={personServic?.["imageBanner"]?.["imageUrl"]} />

          <MarriageServiceComponent
            data={personServic?.["MarriageServiceComponent"]}
          />

          <ComprehensiveService
            data={personServic?.["comprehensiveService"]}
            apidata={apidata?.["mainCategory"]}
          />

          <HappyStories data={personServic?.["happyStories"]} />

          <WhyChooseGoodHandMarriageServices
            data={personServic?.["whyChooseGoodhand"]}
          />

          <OneStopDestination data={personServic?.["OneStopDestination"]} />

          <section className="faqWrap bg-2 py-5 types-of-visa">
            <Faq FaqData={personServic?.["Faqs"]} />
          </section>

          <WhyChooseUs
            section9={personServic?.["whyChooseUs1"]}
            section10={personServic?.["whyChooseUs2"]}
          />

          {/* <AuthorisedCustomerServiceComponent /> */}
          <section className="pb-5">
            <ServiceInDubaiSlider data={allService}  />
          </section>
        </>
      )}
    </>
  );
};

export default CaseService;
