interface Box {
  icon: string; // or number, based on your actual data
  heading: string;
  line: string;
}

interface Section3 {
  heading: string;
  line: string;
  box: Box[];
}

interface Props {
  section3?: Section3; // You can make section3 optional if necessary
}

const Eligibility: React.FC<Props> = ({ section3 }) => {
  return (
    <>
      <section className="bg-2 py-5">
        <div className="container pb-5 py-md-5">
          <div className="row">
            <div className="col-md-4">
              <div className="hd-style-2 golden wow animate__fadeInLeft">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section3?.heading ?? " ",
                  }}
                ></h2>
              </div>
            </div>

            <div className="col-md-5">
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html: section3?.line ?? " ",
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-4 above-minus">
          <div className="row justify-content-center">
            {section3?.box.map((boxItem, index) => (
              <div className="col-md-3 col-12 mb-4" key={index}>
                <div
                  className="card text-center h-100 shadow-sm rounded border border-0 wow animate__zoomIn"
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-body d-flex d-md-block  align-items-start gap-3">
                    <img
                      src={boxItem.icon} // Replace with your image URL
                      alt="Exceptional Talents Icon"
                      className="mb-3 img-fluid ico-pk"
                    />
                    <div className="text-start text-md-center mt-3 mt-md-0">
                      <h5
                        className="card-title"
                        dangerouslySetInnerHTML={{
                          __html: boxItem?.heading ?? " ",
                        }}
                      ></h5>
                      <p
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: boxItem?.line ?? " ",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Eligibility;
