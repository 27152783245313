import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import "./thankyou.css";
import { useLocation } from "react-router-dom";
import { useData } from "../../components/toast/Api";

interface OrderData {
  orderNumber: string;
  paymenton: string;
  ordertotal: number;
  paymentMode: string;
  paymentstatus: number;
  NoofApplications: number;
}

const Skeleton = () => (
  <section className="thank-you-wrapper">
    <div className="background-logo skeleton-background-logo"></div>
    <div className="thank-you-container">
      <header className="thank-you-header">
        <h1 className="thank-you-title skeleton-title"></h1>
        <p className="thank-you-subtitle skeleton-subtitle"></p>
      </header>
      <section className="thank-you-details">
        <h2 className="details-title skeleton-details-title"></h2>
        <div className="details-card">
          <ul className="details-list">
            <li className="details-item skeleton-details-item"></li>
            <li className="details-item skeleton-details-item"></li>
            <li className="details-item skeleton-details-item"></li>
            <li className="details-item skeleton-details-item"></li>
            <li className="details-item skeleton-details-item"></li>
            <li className="details-item skeleton-details-item"></li>
          </ul>
        </div>
      </section>
      <footer className="thank-you-footer">
        <p className="footer-text skeleton-footer-text"></p>
        <a href="/" className="footer-button skeleton-footer-button"></a>
        <p className="skeleton-redirecting"></p>
      </footer>
    </div>
  </section>
);

const Thankyou: React.FC = () => {
  const {
    showError,
  } = useData();
  const location = useLocation();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    import('./thankyou.css');
    const searchParams = new URLSearchParams(location.search);
    
    const ref = searchParams.get("ref");
    if (ref ) {
      if (ref) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${
                import.meta.env.VITE_API_URL
              }/api/payment/redirectUrl?ref=${ref}`
            );

            if (response.status === 400) {
              showError("Payment Decline");
              navigate("/payment");
            }
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setOrderData(data.result2);
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        };
        fetchData();
      }
    } else {
    const fetchData = async () => {
      const orderNumber = localStorage.getItem("orderNumber");
      if (orderNumber) {
        const orderNumber1 = JSON.parse(orderNumber);
        try {
          const response = await fetch(
            `${
              import.meta.env.VITE_API_URL
            }/api/payment/redirectUrlCash?orderNumber=${orderNumber1}`
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setOrderData(data.result);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    fetchData();
    }
  }, [location]);

  useEffect(() => {
    // Redirect after countdown completes
    if (seconds === 0) {
      navigate("/"); // Change '/' to the desired redirect path
    } else {
      const timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [seconds, navigate]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  // if (loading) return <p>Loading...</p>;
  if (loading) return <Skeleton />;
  return (
    <>
      <section className="thank-you-wrapper">
        <div className="background-logo"></div>
        <div className="thank-you-container">
          <header className="thank-you-header">
            <h1 className="thank-you-title fw-bold">Thank You!</h1>
            <p className="thank-you-subtitle">
              Your order has been successfully received.
            </p>
          </header>
          <section className="thank-you-details">
            <h2 className="details-title">Order Summary</h2>
            <div className="details-card">
              <ul className="details-list">
                <li className="details-item">
                  <strong>Order Number:</strong> {orderData?.orderNumber || ""}
                </li>
                <li className="details-item">
                  <strong>Date:</strong>{" "}
                  {orderData && orderData.paymenton
                    ? new Date(orderData.paymenton).toLocaleDateString()
                    : ""}
                </li>
                <li className="details-item">
                  <strong>Total:</strong> {orderData?.ordertotal || ""}
                </li>
                <li className="details-item">
                  <strong>Payment Method:</strong>{" "}
                  {orderData?.paymentMode || ""}
                </li>
                <li className="details-item">
                  <strong>Payment Status:</strong>{" "}
                  {orderData?.paymentstatus === 1 ? "Paid" : "Pending"}
                </li>
                <li className="details-item">
                  <strong>No. of Applications:</strong>{" "}
                  {orderData?.NoofApplications || ""}
                </li>
              </ul>
            </div>
          </section>
          <footer className="thank-you-footer">
            <p className="footer-text">
              We appreciate your business! Our team will begin processing your
              application and will reach out if any additional documents are
              required.
            </p>
            <a href="/" className="footer-button">
              Return to Homepage
            </a>
            <p>Redirecting in {seconds} seconds...</p>
          </footer>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
