import { UniqueFeature } from "../../../types/help"

interface Props {
  uniqueFeature: UniqueFeature
}

const UniqueFeatures: React.FC<Props> = ({uniqueFeature}) => {
  return (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: uniqueFeature?.heading ?? "",
        }}
      ></h1>

      {uniqueFeature?.features?.map((feature) => (
        <ul key={feature.id}>
          <li>{feature.id}.&nbsp;&nbsp;&nbsp;<b>{feature.heading}:</b>&nbsp;{feature.feature}</li>
        </ul>
      ))}
    </>
  )
}

export default UniqueFeatures
