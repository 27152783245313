import React, { Suspense, useEffect, useRef, useState } from "react";
import cartImg from "../../assets/cart.svg";
// import Model from "../../components/model/Model";
const Model = React.lazy(() => import("../../components/model/Model"));
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardItem from "../../components/cardItem/CardItem";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SideCard from "../../components/thirdsideCard/SideCard";
import alternateImg from "../../assets/alternateImg.jpeg";

import {
  openAddModelData,
  openPackageModel,
} from "../../store/slices/modalSlice";
// import { Helmet } from "react-helmet";
import PreLoader from "../../components/preloader/PreLoader";
import axios from "axios";
import { setFalse, setServiceNames, setTrue } from "../../store/slices/IdSlice";
import FloatingMenuButton from "../../components/floatingMenu/FloatingMenuButton";
import FloatingMenuSection from "../../components/FloatingMenuSection/FloatingMenuSection";
import SecondRightSideCard from "../../components/thirdsideCard/SecondRightSideCard";
import { AnimatedNumber } from "../../components/cardItem/AnimatedNumber";
import MetaHead from "../../components/metaHead/MetaHead";
import PackageDescriptionModel from "../../components/typeofvisaComponents/footerModel/PackageDescriptionModel";
import {
  CartItem,
  MainCategory,
  ProcessedCartItem,
  Service,
  TooltipLinkProps,
} from "../../types/service";

const baseUrl = import.meta.env.VITE_API_URL;

const Services: React.FC = () => {
  const { slug } = useParams();
  // const navigate = useNavigate();
  const [metaData, setMetaData] = useState();
  const [datas, setData] = useState<MainCategory[]>([]);

  // const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenValue, setHiddenValue] = useState({
    heading: "",
    description: "",
  });
  const [bannerImg, setImg] = useState<string>();
  const [isHidden, setIsHidden] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [cartItems, setCartItems] = useState<undefined | CartItem[]>();
  const [processedCart, setProcessedCart] = useState<ProcessedCartItem[]>([]);
  const [direction, setDirection] = useState({ id: 0, direction: "", key: 0 });

  useEffect(() => {
    localStorage.removeItem("count");
    const imgInsideBanner = datas[0]?.imgInsideBanner;
    setImg(imgInsideBanner);
  }, [datas]);

  const { outsideId, insideId, footerOutsideId } = useSelector(
    (state: {
      ID: { outsideId: string; insideId: string; footerOutsideId: string };
    }) => state.ID
  );

  const addModalOpen = useSelector(
    (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  );

  const packageModel = useSelector(
    (state: { modal: { packageModel: boolean } }) => state.modal.packageModel
  );

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleMinusQuantity = (
    priceRecordId: number,
    serviceid: number,
    numberOfOptions: number,
    name: string
  ) => {
    if (numberOfOptions > 1 && serviceid !== null) {
      sessionStorage.setItem("serviceid", JSON.stringify(serviceid));
      dispatch(openAddModelData());
    } else {
      setDirection({ id: priceRecordId, direction: "down", key: Date.now() });
      addDataToDataBase(priceRecordId, serviceid, name);
    }
  };

  const handlePlusQuantity = (
    priceRecordId: number,
    serviceid: number,
    numberOfOptions: number,
    name: string
  ) => {
    if (numberOfOptions > 1 && serviceid !== null) {
      sessionStorage.setItem("serviceid", JSON.stringify(serviceid));
      dispatch(openAddModelData());
    } else {
      setDirection({ id: priceRecordId, direction: "up", key: Date.now() });
      addDataToDataBase(priceRecordId, serviceid, name);
    }
  };

  const handleOpenAddModal = (
    service: Service,
    numberOfOptions: number,
    name: string,
    isPackage: boolean
  ) => {
    if (isPackage !== true) {
      if (name === "add") {
        if (numberOfOptions > 1) {
          sessionStorage.setItem(
            "serviceid",
            JSON.stringify(service?.Recordid ?? 0)
          );
          dispatch(openAddModelData());
        } else {
          addDataToDataBase(
            service.prices[0].PriceRecordId,
            service.Recordid ?? 0,
            "plus"
          );
        }
      } else {
        sessionStorage.setItem(
          "serviceid",
          JSON.stringify(service.Recordid ?? 0)
        );
        dispatch(openAddModelData());
      }
    } else {
      sessionStorage.setItem(
        "serviceid",
        JSON.stringify(service.Recordid ?? 0)
      );
      dispatch(openPackageModel());
    }
  };

  const extractSub1ServiceNames = (data: MainCategory[]): string[] => {
    return data.map((category) => category.sub1ServiceName);
  };

  const sub1ServiceNames = extractSub1ServiceNames(datas);

  useEffect(() => {
    const subServiceNames = extractSub1ServiceNames(datas);
    const ServiceNames = subServiceNames[0];
    if (ServiceNames) dispatch(setServiceNames({ ServiceNames }));
  }, []);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    import("./service.css");
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getDataFromJson = async (jsonUrl: string) => {
    try {
      const response = await fetch(jsonUrl);
      const data = await response.json();

      if (data) {
        setMetaData(data.seo);
        setHiddenValue(data.hidden);
      }
    } catch (error) {
      console.error("Error fetching JSON data:", error);
    }
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      setIsError(false);
      if (!baseUrl) {
        throw new Error("Base URL is not defined in the environment variables");
      }

      const URL = `${baseUrl}/api/openRoute/services?slug=${slug}`;

      fetch(URL)
        .then((response) => response.json())
        .then((data) => {
          console.log("service api response :: ", data.mainCategory);
          setData(data.mainCategory);
          getDataFromJson(data.mainCategory[0].jsonUrl);
          const subServiceNames = extractSub1ServiceNames(data.mainCategory);
          const ServiceNames = subServiceNames[0];
          if (ServiceNames) dispatch(setServiceNames({ ServiceNames }));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [slug]);

  const cartRef = useRef(null);
  const footerRef = useSelector(
    (state: { footer: { footerRef: Element | null } }) => state.footer.footerRef
  );
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    if (!footerRef) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0,
      }
    );

    observer.observe(footerRef);

    return () => {
      observer.unobserve(footerRef);
    };
  }, [footerRef]);

  useEffect(() => {
    const handleScroll = () => {
      const cartdown: HTMLElement | null =
        document.getElementById("sticky-cart");
      const scrollTop = window.scrollY;

      if (cartdown !== null) {
        if (scrollTop > 435) {
          cartdown.style.marginTop = "0px"; // Remove top margin as you scroll down
        } else {
          cartdown.style.marginTop = `${435 - scrollTop}px`; // Adjust top margin dynamically
        }
      }
    };

    const handleScrollB = () => {
      if (window.scrollY > 30) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScrollB);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScrollB);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleScrollToSectionn(insideId, "inside_");
  }, [datas]);

  useEffect(() => {
    if (insideId) {
      handleScrollToSectionn(insideId, "inside_");
    }
  }, [outsideId, insideId]);

  const handleScrollToSectionn = (insideId: string, ifCheck: string) => {
    if (ifCheck === "inside_" && insideId) {
      const section = document.getElementById("inside_" + insideId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    } else if (footerOutsideId) {
      const section = document.getElementById("outside_" + footerOutsideId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const handleScrollToSection = (id: string) => {
    const section = document.getElementById("outside_" + id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const isImage = (src: string | undefined) => {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(src ?? ""); // Regex to test for common image extensions
  };

  const mergeCartQuantities = (cartData: CartItem[]): ProcessedCartItem[] => {
    return cartData.reduce<ProcessedCartItem[]>((acc, item) => {
      const existingItem = acc.find((i) => i.serviceid === item.serviceid);
      if (existingItem) {
        existingItem.Quantity += item.Quantity;
      } else {
        acc.push({
          serviceid: item.serviceid,
          productName: item.productName,
          Quantity: item.Quantity,
        });
      }
      return acc;
    }, []);
  };

  const getData = async () => {
    const UUID = localStorage.getItem("uuid");
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/cartinfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        setCartItems(response.data.CartItems.Cart);
        const processedData = mergeCartQuantities(response.data.CartItems);
        setProcessedCart(processedData);
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  const toggle = useSelector(
    (store: { ID: { status: boolean } }) => store.ID.status
  );

  const [isFloatingSectionVisible, setIsFloatingSectionVisible] =
    useState(false);

  const toggleFloatingSection = () => {
    setIsFloatingSectionVisible(!isFloatingSectionVisible);
    if (document.body.classList.contains("bd-overlay")) {
      document.body.classList.remove("bd-overlay");
    } else {
      document.body.classList.add("bd-overlay");
    }
  };

  const setIsFloatingSectionVisibleMakeFalse = () => {
    setIsFloatingSectionVisible(false);
    document.body.classList.remove("bd-overlay");
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (toggle) {
      getData();
    }
    dispatch(setFalse());
    sessionStorage.removeItem("direction");
  }, [toggle]);

  if (isLoading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100dvh",
          }}
        >
          <PreLoader />
        </div>
      </>
    );
  }

  if (isError) {
    return <h1>Something Went Wrong</h1>;
  }

  const TooltipLink: React.FC<TooltipLinkProps> = ({ Id, children, title }) => (
    <OverlayTrigger
      overlay={
        <Tooltip id={Id} className="custom-tooltip">
          {title}
        </Tooltip>
      }
      placement="right"
    >
      <span>{children}</span>
    </OverlayTrigger>
  );

  return (
    <>
      <section className="inner-wrapper py-0 py-md-5">
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title> Service Page </title> */}
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        {/* <meta /> */}
        {/* </Helmet> */}
        <MetaHead metaData={metaData} />
        <div className="container ">
          <div className="row">
            <div className="services-list-banner d-none col-12">
              {/* <img src={banner} className="img-fluid d-block mx-auto" alt="" /> */}
              <div className="banner-caption-wrapper">
                <div className="banner-caption">
                  {/* Amer Services */}
                  <h2>{sub1ServiceNames} Services</h2>
                </div>
              </div>
            </div>

            {/* Conditional rendering of the div based on isVisible */}

            <div className="col-md-3 col-xl-3 col-12">
              <div
                className={`card border-0 pb-0 rounded sd-card ${
                  isSticky ? "position-fixed " : ""
                } sticky-offset z-index-0 mb-4 t-5 `}
                ref={cartRef}
              >
                <div hidden>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: hiddenValue?.heading ?? "",
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hiddenValue?.description ?? "",
                    }}
                  ></p>
                </div>
                <div className="hd-style-3 py-2">
                  {isHidden ? sub1ServiceNames : ""}
                  {/* {sub1ServiceNames} */}
                </div>

                {isMobile && <SecondRightSideCard isMobile={isMobile} />}

                <div className="catg-card">
                  <div className="d-flex align-items-center justify-content-start mb-3 gap-3">
                    <span className="ff-semibold mt-0 mb-0 mr-3 text-capitalize font-12 select-course-heading text-sec">
                      Select Your Services
                    </span>
                    <div className="line flex-grow-1"></div>
                  </div>

                  <div className="row sideCard-res">
                    {datas.map((category) => (
                      <>
                        {category.subCategory.map((item) => (
                          <div
                            className="col-md-4 col-3 px-1"
                            style={{ cursor: "pointer" }}
                          >
                            <TooltipLink
                              title={item.sub2ServiceName}
                              Id={item.sub2RecordId}
                            >
                              <div
                                className="cat-small-card my-3 my-md-3"
                                key={item.sub2RecordId}
                                onClick={() =>
                                  handleScrollToSection(item.sub2RecordId)
                                }
                              >
                                <div>
                                  <img
                                    src={item.SrcThumbnail}
                                    className="d-block mx-auto cat-icon"
                                    alt={item.sub2ImgAlt}
                                  />
                                </div>
                                <div className="text-center pt-2">
                                  <p className="checkout-ser-name text-center">
                                    {item.sub2ServiceName}
                                  </p>
                                </div>
                              </div>
                            </TooltipLink>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>

                  <FloatingMenuSection
                    isVisible={isFloatingSectionVisible}
                    datas={datas}
                    setIsFloatingSectionVisibleMakeFalse={
                      setIsFloatingSectionVisibleMakeFalse
                    }
                  />
                </div>
              </div>
            </div>
            <FloatingMenuButton
              onTouchStart={toggleFloatingSection}
              isFloatingSectionVisible={isFloatingSectionVisible}
            />

            <div className="col-md-9 col-xl-9 col-12">
              <div className="services-list-banner ">
                {/* <img
                  loading="lazy"
                  src={bannerImg}
                  className="img-fluid d-block mx-auto"
                  alt="bannerImg"
                /> */}
                {isImage(bannerImg) ? (
                  <img
                    loading="lazy"
                    src={bannerImg}
                    className="img-fluid d-block mx-auto"
                    alt="bannerImg"
                  />
                ) : (
                  <video
                    style={{
                      borderRadius: "16px",
                      height: "310px",
                      width: "100%",
                      objectFit: "cover",
                      marginTop: isFixed ? "-41px" : "0",
                    }}
                    key={bannerImg}
                    autoPlay
                    loop
                    muted
                    playsInline
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={bannerImg} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>

              <div className="row py-3">
                <div className="col-lg-7 col-md-12 col-12">
                  {datas.map((data) => (
                    <>
                      {data.subCategory.map((categories) => (
                        <div
                          className="serv-list-wrap"
                          key={"outside_" + categories.sub2RecordId}
                          // id={categories.sub2RecordId}
                          id={"outside_" + categories.sub2RecordId}
                        >
                          <div className="hd-style-3 py-2">
                            {categories.sub2ServiceName}
                            {/* {categories.sub2RecordId} */}
                          </div>
                          <div className="serv-link-text py-2 link-text font-14">
                            {categories.sub2Description}
                          </div>

                          <div className="services-list-banner-sub bg-2">
                            <img
                              loading="lazy"
                              src={categories.sub2ImgSrc}
                              className="img-fluid d-block mx-auto"
                              alt={
                                categories.sub2ImgAlt ||
                                categories.sub2ServiceName
                              }
                            />
                            {/* <div className="banner-sub-caption">
                              <p className="text-box blue-box">
                                {categories.sub2ServiceName}
                              </p>
                              <p className="text-box white-box">
                                Start from AED&nbsp;{categories.minimumPrice}
                              </p>
                            </div> */}
                          </div>
                          {/* one loop here */}
                          {categories.services.map((service) => (
                            <div className="list-card-main d-flex align-items-start align-items-md-center justify-content-between">
                              <div className="col-8">
                                <div id={"inside_" + service.Recordid}></div>
                                <div
                                  className="sub-hd1"
                                  id={service.Recordid.toString()}
                                >
                                  <p>
                                    {service.serviceName}
                                    {/* {service.Recordid} */}
                                  </p>
                                </div>

                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                      backgroundColor: "#6e42e5", // Adjust color to match the purple in your image
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="8"
                                      height="8"
                                      fill="white" // Set the star color to white to match the image
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3.612 15.443c-.396.198-.824-.149-.746-.592l.83-4.73-3.522-3.356c-.33-.316-.158-.888.283-.95l4.898-.696 2.189-4.41c.197-.39.73-.39.927 0l2.19 4.41 4.898.696c.441.062.613.634.283.95l-3.522 3.356.83 4.73c.078.443-.35.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                  </div>
                                  <span className="font-12">
                                    4.87 (1k reviews)
                                  </span>
                                </div>

                                <div className="d-flex align-items-center gap-2 flex-wrap">
                                  {service.prices.length > 1 ? (
                                    <p className="d-flex align-items-center gap-1">
                                      <span className="in-badge">
                                        Start from &nbsp;
                                        {service.prices[0].PriceCurrency}&nbsp;
                                        {Math.min(
                                          ...service.prices.map(
                                            (price) => price.PriceAmount
                                          )
                                        ).toLocaleString()}
                                      </span>
                                    </p>
                                  ) : (
                                    service.prices.map((price) => (
                                      <p
                                        className="d-flex align-items-center gap-1"
                                        key={price.PriceType}
                                      >
                                        <span className="in-badge ">
                                          {price.PriceAmount === 0
                                            ? `${price.PriceType}`
                                            : ` ${
                                                price.PriceCurrency
                                              } ${price.PriceAmount.toLocaleString()}`}
                                          &nbsp;
                                          {price.OldPriceAmount >
                                            price.PriceAmount &&
                                            price.OldPriceAmount >
                                              price.PriceAmount && (
                                              <s className="text-secondary ff-medium pe-1">
                                                {price.PriceAmount !== 0 &&
                                                  price.PriceCurrency}
                                                &nbsp;
                                                {Math.min(
                                                  ...service.prices.map(
                                                    (price) =>
                                                      price.OldPriceAmount
                                                  )
                                                ).toLocaleString()}
                                              </s>
                                            )}
                                        </span>
                                      </p>
                                    ))
                                  )}
                                </div>
                                {service.maxSaving >= 1 && (
                                  <div className="up-to">
                                    <span>
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 16 16"
                                        fill="#07794C"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                          fill="#07794C"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span className="font-12 ff-medium">
                                      <span className="ms-1">
                                        Up to AED {service.maxSaving}&nbsp;
                                      </span>
                                      Off
                                    </span>
                                  </div>
                                )}
                                <p className="mb-1"></p>

                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: service.outsideDescription ?? "",
                                  }}
                                />
                                <a
                                  className="btn-link ff-semibold font-14"
                                  data-bs-toggle="modal"
                                  onClick={() =>
                                    handleOpenAddModal(
                                      service,
                                      service.numberOfOptions,
                                      "ViewDetail",
                                      service.isPackage
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    marginLeft: "1.3rem",
                                    color: "rgb(110, 66, 229)",
                                  }}
                                  rel="nofollow"
                                >
                                  View Detail
                                </a>
                              </div>
                              <div>
                                <div className="image-container">
                                  <img
                                    loading="lazy"
                                    // src={eachServ}
                                    src={service.imageSrc ?? alternateImg}
                                    // alt="Service Image"
                                    alt={service.imgAlt ?? service.serviceName}
                                    className="img-fluid d-block mx-auto"
                                  />
                                  {/* <div
                                    className="btn-add position-absolute"
                                    onClick={() =>
                                      handleOpenAddModal(
                                        service,
                                        service.numberOfOptions
                                      )
                                    }
                                  >
                                    Add
                                  </div> */}
                                  {cartItems && cartItems.length > 0 ? (
                                    <>
                                      {cartItems?.find(
                                        (item) =>
                                          item?.serviceid ===
                                          service.prices[0]?.serviceid
                                      ) ? (
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                          <div className="d-flex align-items-center btn-add position-absolute gap-1 gap-md-2">
                                            <button
                                              className="bt-mi p-0"
                                              onClick={() =>
                                                handleMinusQuantity(
                                                  service.prices[0]
                                                    .PriceRecordId,
                                                  service?.ServiceId,
                                                  service.numberOfOptions,
                                                  "minus"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <span
                                              className={`counter-numbers ${
                                                service.prices[0]
                                                  .PriceRecordId ===
                                                direction.id
                                                  ? direction.direction
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                handleOpenAddModal(
                                                  service,
                                                  service.numberOfOptions,
                                                  "options",
                                                  service.isPackage
                                                )
                                              }
                                            >
                                              <AnimatedNumber
                                                value={
                                                  processedCart?.find(
                                                    (item) =>
                                                      item.serviceid ===
                                                      service.ServiceId
                                                  )?.Quantity || 1
                                                }
                                              />
                                            </span>
                                            <button
                                              className="bt-plu p-0"
                                              onClick={() =>
                                                handlePlusQuantity(
                                                  service.prices[0]
                                                    .PriceRecordId,
                                                  service?.ServiceId,
                                                  service.numberOfOptions,
                                                  "plus"
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="btn-add position-absolute"
                                          onClick={() =>
                                            handleOpenAddModal(
                                              service,
                                              service.numberOfOptions,
                                              "add",
                                              service.isPackage
                                            )
                                          }
                                        >
                                          Add
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div
                                      className="btn-add position-absolute"
                                      onClick={() =>
                                        handleOpenAddModal(
                                          service,
                                          service.numberOfOptions,
                                          "add",
                                          service.isPackage
                                        )
                                      }
                                    >
                                      Add
                                    </div>
                                  )}
                                </div>
                                {service.numberOfOptions > 1 && (
                                  <div
                                    onClick={() =>
                                      handleOpenAddModal(
                                        service,
                                        service.numberOfOptions,
                                        "options",
                                        service.isPackage
                                      )
                                    }
                                    className="options"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "10px",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span>
                                      {service.numberOfOptions} options
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          <hr className="border border-1 opacity-100" />
                        </div>
                      ))}
                    </>
                  ))}
                </div>

                <div className="col-lg-5 d-none d-lg-block ">
                  <div
                    ref={cartRef}
                    className={`cartdown  ${
                      isSticky ? "position-fixed " : ""
                    } sticky-offset`}
                    id="sticky-cart"
                    style={{
                      width: "391px",
                      marginTop: "343px",
                    }}
                  >
                    {cartItems && cartItems.length > 0 ? (
                      <>
                        <CardItem />
                      </>
                    ) : (
                      <>
                        <div className="card mb-3">
                          <div className="card-body add-cart-each">
                            <div className="d-flex fle justify-content-center align-items-center">
                              <div className=" mb-3">
                                <img
                                  loading="lazy"
                                  src={cartImg}
                                  alt="No img"
                                  className="d-block mx-auto my-3"
                                  style={{ height: "50px" }}
                                />
                                <p className="text-black font-12 ff-semibold text-center">
                                  Your Cart is Empty.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <SecondRightSideCard isMobile={isMobile} />
                    <SideCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense>
          {addModalOpen && <Model />}

          {packageModel && <PackageDescriptionModel />}
        </Suspense>
      </section>
    </>
  );
};

export default Services;
