import React, { useEffect } from "react";
import { MainCategory } from "../../types/home";
interface Props {
  setIsFloatingSectionVisibleMakeFalse: () => void;
  isVisible: boolean;
  datas?: MainCategory[];
}

const FloatingMenuSection: React.FC<Props> = ({
  isVisible,
  datas,
  setIsFloatingSectionVisibleMakeFalse,
}) => {
  const handleScrollToSection = (id: string) => {
    if (isVisible) {
      setIsFloatingSectionVisibleMakeFalse();
    }
    const section = document.getElementById("outside_" + id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    import("./FloatingMenuSection.css");
  }, []);
  return (
    <div
      className={`floating-menu-content ${isVisible ? "visible" : "hidden"}`}
    >
      <div className="row sideCard-res">
        {datas && datas.length > 0 ? (
          datas.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              {category.subCategory.map((item) => (
                <div
                  className="col-md-4 col-4 px-1"
                  key={item.sub2RecordId}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleScrollToSection(item.sub2RecordId)}
                >
                  <div className="cat-small-card my-3">
                    <div>
                      <img
                        loading="lazy"
                        src={item.SrcThumbnail}
                        className="d-block mx-auto cat-icon"
                        alt={item.sub2ImgAlt}
                      />
                    </div>
                    <div className="text-center pt-2">
                      <p className="checkout-ser-name text-center">
                        {item.sub2ServiceName}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default FloatingMenuSection;
