// import React from "react";

const ChatWithUs = () => {
  return (
    <div className="Saved-section bg-2 rounded p-3 d-flex  align-items-center justify-content-start gap-3  mb-3  d-md-none">
      <div className="d-flex justify-content-center ">
        <div
          className="d-flex align-items-center justify-content-center"
          role="img"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm13.434-3.723l-4.809 4.59-2.06-1.965-1.38 1.446 2.75 2.625a1 1 0 001.38 0l5.5-5.25-1.38-1.446z"
              fill="#0F0F0F"
            />
          </svg>
        </div>
      </div>

      {/* Text Section */}
      <div className="d-flex  align-items-center">
        <a
          href="https://web.whatsapp.com/send?phone=97142979998"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none"
        >
          <p className="mb-0 font-8 text-black ff-normal">
            Click here to chat with us on WhatsApp
          </p>
        </a>
      </div>

      {/* Secondary Icon Section */}
      <div
        className="d-flex align-items-center justify-content-end flex-grow-1"
        role="img"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#0F0F0F"
          viewBox="0 0 16 16"
        >
          <path
            fill="#0F0F0F"
            fillRule="evenodd"
            d="M9.94 8L5.47 3.53l1.06-1.06 5 5a.75.75 0 010 1.06l-5 5-1.06-1.06L9.94 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default ChatWithUs;
