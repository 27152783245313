import { Section2 } from "../../../types/department";

interface Props {
  section2: Section2 | undefined;
}

const WhoWeAre: React.FC<Props> = ({ section2 }) => {
  return (
    <>
      <section className="pk-about-sec py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 my-auto">
              <img
                src={section2?.image}
                alt="Immigration Services"
                className="img-fluid wow animate__zoomIn"
              />
            </div>

            <div className="col-md-7">
              <div className=" sm-hd mt-4 mt-md-4 wow animate__fadeInRight">
                <h1
                  className="hd-style-2"
                  dangerouslySetInnerHTML={{
                    __html: section2?.title ?? " ",
                  }}
                ></h1>
              </div>
              <div className="description">
                {section2?.content.map((des, index) => (
                  <p
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: des ?? " ",
                    }}
                  >
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
