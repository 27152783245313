
import { useEffect, useState } from "react";
import PreLoader from "../../components/preloader/PreLoader";
import WhyChooseUs from "../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";
import PackagesBanner from "../../components/typeofvisaComponents/typeofvisaBanner/PackagesBanner";
import PackagesList from "../../components/typeofvisaComponents/PackagesList/PackagesList";

import axios from "axios";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";
// import { GoldenVisaData } from "../../types/typeofvisa";
const apiUrl = import.meta.env.VITE_API_URL;

interface Section1 {
  BannerHeading: string;
  BannerSubText: string;
  BannerImg: string;
  SlugUrl: string;
  BannerImgAlt: string; // Ensure the link is added to the type
}

interface Section2 {
  heading: string;
  content: string[];
  image: string;
}

interface Box {
  icon: string;
  heading: string;
  line: string;
  link: string; // Ensure the link is added to the type
}

interface Section3 {
  heading: string;
  line: string;
  box: Box[];
}

interface Section4 {
  heading: string;
  content: string;
}

interface Section5 {
  heading: string;
  content: string;
  image: string;
}

interface GoldenVisaData {
  section1?: Section1;
  section2: Section2;
  section3: Section3;
  section4: Section4;
  section5: Section5;
}

const Packages = () => {
  const JSON_URL =
    "https://goodhand.b-cdn.net/Assets/Footer_pages/Package/packages-section.json";
  const URL = `${apiUrl}/api/openRoute/packages`;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<GoldenVisaData>();
  const [metaData, setMetaData] = useState<SEOData>();
  const [setapidata, setApiData] = useState();

  const getDataFromJson = () => {
    return fetch(JSON_URL)
      .then((response) => response.json()) // Use json() to parse the JSON response
      .then((data) => {
        if (data) {
          setData(data);
          setMetaData(data?.seo);
          return true; // Indicate success
        }
        return false; // Return false if data is not available
      })
      .catch((error) => {
        console.error("Error fetching JSON data:", error);
        return false; // Indicate failure
      });
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);
      if (response) {
        setApiData(response.data.packages);
        return true;
      }

      return false;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error(
            "Error 400: Bad request, redirecting to NotFound page."
          );
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false;
    }
  };

  useEffect(() => {
    import("./Packages.css");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!jsonDataSuccess && !apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        Something Went Wrong. Please try again later.
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}
          <div className="packages-banner">
            <PackagesBanner section1={setapidata} />
            <PackagesList section3={data?.section3} data={setapidata} />
            <WhyChooseUs section9={data?.section4} section10={data?.section5} />
          </div>
        </>
      )}
    </>
  );
};

export default Packages;
