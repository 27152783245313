import axios from "axios";
import { useEffect, useState } from "react";
import PreLoader from "../../../components/preloader/PreLoader";
import { openPriceModel } from "../../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import PriceModel from "./PriceModel";
import { LegalCategoryList, LegalServiceList, PriceData } from "../../../types/alhadeed";
const apiUrl = import.meta.env.VITE_API_URL;



const LegalForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [LegalCategoryList, setLegalCategoryList] =
    useState<LegalCategoryList[]>();
  const [LegalServiceList, setLegalServiceList] =
    useState<LegalServiceList[]>();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [slug, setSlug] = useState<string | undefined>();
  const [serviceid, setServiceId] = useState<string | undefined>();
  const [data, setData] = useState<PriceData>();

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");

  const openPriceModels = useSelector(
    (state: { modal: { priceModel: boolean } }) => state.modal.priceModel
  );

  const getDataFromApiSubSevice = async (selectedSer?: string) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/openRoute/LegalServiceList/?id=${selectedSer}`
      );
      if (response.data) {
        setLegalServiceList(response.data.Data);
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
    const result = LegalCategoryList?.find(
      (service) => service.sub2ServiceName === event.target.value
    );
    setSlug(result?.["sub2Slug"]);
    getDataFromApiSubSevice(result?.["sub2Slug"]);
  };

  const handleChangeSubSelectedService = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedService(event.target.value);
    const result = LegalServiceList?.find(
      (service) => service.serviceName === event.target.value
    );
    setServiceId(result?.serviceid);
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/openRoute/LegalCategoryList`
      );
      if (response.data) {
        setLegalCategoryList(response.data.Data);
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB max size

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size;

      if (fileSize > MAX_FILE_SIZE) {
        setError("File size exceeds the 5MB limit.");
        setFile(null);
      } else if (
        !fileType.includes("image") &&
        fileType !== "application/pdf"
      ) {
        setError("Only image or PDF files are allowed.");
        setFile(null);
      } else {
        setError("");
        setFile(selectedFile);
      }
    }
  };
  //test commit
  const submitData = async () => {
    // const localStorageData = localStorage.getItem("Login_info");
    // if (localStorageData) {
      if (!serviceid || !slug || !file) {
        setError("Please provide a valid service, category, and file.");
        return;
      }

      const formData = new FormData();
      formData.append("serviceid", serviceid);
      formData.append("sub2Slug", slug);
      formData.append("files", file);

      try {
        const response = await axios.post(
          `${apiUrl}/api/pdfCount/pagesCount`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.Data) {
          setData(response.data.Data);
          dispatch(openPriceModel())
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        setError("Failed to submit data. Please try again.");
      }
  
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }
  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="d-block py-2">
                <span className="ff-normal font-13">Select services</span>
                <select
                  className="form-control font-12 ff-semibold d-block custom-select bg-white"
                  value={selectedCategory}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a Category
                  </option>
                  {LegalCategoryList?.map((service) => (
                    <option
                      key={service.sub2ServiceName}
                      value={service.sub2ServiceName}
                    >
                      {service.sub2ServiceName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={`col-md-6 ${!selectedCategory ? "disabled" : ""}`}>
              <div className="d-block py-2">
                <span className="ff-normal font-13">Select services</span>
                <select
                  className="form-control font-12 ff-semibold d-block custom-select bg-white"
                  value={selectedService}
                  onChange={handleChangeSubSelectedService}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  {LegalServiceList?.map((service) => (
                    <option key={service.serviceid} value={service.serviceName}>
                      {service.serviceName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label>Upload File</label>
              <input
                type="file"
                className="form-control"
                onChange={handleFileChange}
              />
              {error && <p className="text-danger">{error}</p>}
            </div>

            <div className="text-center d-flex col-12">
              <button
                className="btn btn-primary font-14 ff-semibold mt-3 bt--cart"
                onClick={submitData}
                data-bs-toggle="modal"
                data-bs-target="#priceModal"
              >
                Check Price
              </button>
            </div>
          </div>

          {/* Modal */}
          {openPriceModels && <PriceModel data={data}/>}
          {/* <div
            className="modal "
            id="priceModal"
            aria-labelledby="priceModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body rounded-top">
                  {data ? (
                    <div className="price-details p-3">
                      <p className="mb-3 d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Price Type:</span>&nbsp;
                        {data?.PriceType}
                      </p>
                      <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Price Per Page:</span>&nbsp;
                        {data?.PriceForPdf}
                      </p>
                      <p className="d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Actual Price for PDF:</span>{" "}
                        {data?.oldPriceForPdf}
                      </p>
                      <hr className="my-3" />
                      <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Page Count:</span>&nbsp;
                        {data?.pageCount}
                      </p>
                      <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Price Amount:</span>&nbsp;
                        {data?.PriceAmount}
                      </p>
                      <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                        <span className="ff-medium">Actual Price Amount:</span>&nbsp;
                        {data?.OldPriceAmount}
                      </p>
                    </div>
                  ) : (
                    <p className="text-muted">No data available</p>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="price-section d-flex w-100 gap-2 align-items-center">
                    <p className="item-count p-2 mb-0 rounded ff-semibold">
                      Total :
                    </p>
                    <p className="current-price flex-grow-1 mb-0 ff-bold">
                      &nbsp;
                      <span>AED</span>&nbsp; {data?.PriceForPdf}
                      &nbsp;
                      <s className="ff-medium">
                        <span>AED</span> {data?.oldPriceForPdf}
                      </s>
                    </p>
                    <button className="done-button btn btn-primary mt-2 flex-grow-1 h-100">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default LegalForm;
