import { Videos } from "../../../../types/homeComponentTypes";

interface Props {
  data?: Videos;
}
const VideoSection: React.FC<Props> = ({ data }) => {
  return (
    <>
      <section className="stars">
        <div className="container">
          <div className="row justify-content-center video_sertion">
            <div className="col-12 col-md-12 col-lg-12 py-2 position-relative">
              <div className="video-container">
                <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  controlsList="nodownload nofullscreen noremoteplayback"
                  style={{ height: "540px" }}
                >
                  <source src={data?.videoUrl ?? data?.path} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoSection;
