import { useEffect, useState } from "react";
import SideCard from "../../components/thirdsideCard/SideCard";
import { useData } from "../../components/toast/Api";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { OrderDetails } from "../../types/cart";

const baseUrl = import.meta.env.VITE_API_URL;
const NewPayment = () => {
  const navigate = useNavigate();
  const { showError } = useData();
  const [code, setCode] = useState<string>("");
  const [apiData, setApiData] = useState<OrderDetails>();
  const [Online, setOnlinePayment] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [errorsconfirmation, setErrorsconfirmation] = useState(false);

  console.log(code);

  // const handleError = (error: string) => {
  //   toast.error(`Error: ${error || "Something went wrong!"}`);
  // };

  useEffect(() => {
    import("./payement.css");
    const fetchDataFromApi = async () => {
      const orderNumber = localStorage.getItem("orderNumber");
      if (orderNumber) {
        const orderNumber1 = JSON.parse(orderNumber);
        console.log("data", orderNumber1);
        try {
          const data = {
            orderNumber: orderNumber1,
          };

          const URL = `${baseUrl}/api/payment/paymentPage`;

          const response = await fetch(URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();

          setApiData(result);
        } catch (error) {
          showError(`Error: ${error || "Something went wrong!"}`);
        }
      }
    };
    fetchDataFromApi();
  }, []);

  const payPaymentByCash = async () => {
    const orderNumber = localStorage.getItem("orderNumber");

    if (orderNumber) {
      const orderNumber1 = JSON.parse(orderNumber);
      console.log("data", orderNumber1);

      try {
        const data = {
          orderNumber: orderNumber1,
        };

        const URL = `${baseUrl}/api/payment/orderPaymentcash`;

        const response = await axios.post(URL, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data) {
          navigate("/thankyou");
        }
      } catch (error) {
        showError(`Error: ${error || "Something went wrong!"}`);
      }
    }
  };

  const payOnlineByCard = async () => {
    const orderNumber = localStorage.getItem("orderNumber");
    if (orderNumber) {
      const order_Number = JSON.parse(orderNumber);
      try {
        const baseUrla = import.meta.env.VITE_APPLICATION_URL;
        const baseUrl = import.meta.env.VITE_API_URL;

        if (!baseUrla) {
          throw new Error(
            "baseUrla URL is not defined in the environment variables"
          );
        }

        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const postData = {
          redirectUrl: `${baseUrla}/thankyou`,
          cancelUrl: `${baseUrla}/CancelPayment`,
          orderNumber: order_Number,
        };

        const response = await fetch(`${baseUrl}/api/payment/orderPayment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        });
        const data = await response.json();
        const url = data.href;
        const params = new URLSearchParams(new URL(url).search);
        const codeParam = params.get("code");
        if (codeParam) {
          setCode(codeParam);
        }
        if (url) {
          window.location.href = url;
        }
        // setCartData(data.items);
      } catch (error) {
        showError("Error fetching order data");
        console.error("Error fetching order data:", error);
      }
    }
  };

  const handleSubmit = () => {
    if (isConfirmed === true) {
      if (Online === true) {
        payOnlineByCard();
      } else {
        payPaymentByCash();
      }
    } else {
      setErrorsconfirmation(true);
    }
  };

  const handleRadioChange = (val: string) => {
    if (val === "Online") {
      setOnlinePayment(true);
      console.log("cash");
    }

    if (val === "Cash") {
      setOnlinePayment(false);
    }
    console.log(val);
  };
  return (
    <>
      <section className="order-flow-main py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 bg-2 p-5 rounded">
              <div className="card shadow my-2 border-0">
                <div className="card-body p-4">
                  {/* Order and Application Information */}
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="hd-style-3">
                      <span className="text-muted">Order No:</span>
                      {/* <span>#854622</span> */}
                      <span>{apiData?.orderNumber}</span>
                    </div>
                    <p className="text-dark font-16 fw-bold mb-0">
                      No of Applications:{" "}
                      <span className="fw-normal">
                        {" "}
                        {apiData?.NoofApplications}
                      </span>
                    </p>
                  </div>

                  {/* Price Section */}
                  <div className="mb-4 mt-4 border-top pt-4">
                    <h4 className="fw-bold text-dark mb-3 font-18">
                      Order Summary
                    </h4>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <span className="text-muted">Order Price:</span>
                      <span className="fw-bold text-dark">
                        AED {apiData?.orderTotal.toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <span className="text-muted">Handling Fees:</span>
                      <span className="fw-bold text-dark">
                        AED
                        {Online
                          ? apiData?.handlingFeeOnline.toFixed(2)
                          : apiData?.handlingFeeCash.toFixed(2)}
                      </span>
                    </div>
                    <hr className="border-bottom-dashed" />
                    <div className="d-flex justify-content-between align-items-center py-2 fw-bold">
                      <span className="text-muted">Subtotal:</span>
                      <span className="text-dark">
                        AED
                        {Online
                          ? apiData?.totalAmountOnline.toFixed(2)
                          : apiData?.totalAmountcash.toFixed(2)}
                      </span>
                    </div>
                  </div>

                  {/* Payment Method Selection */}
                  <h4 className="fw-bold text-dark mb-4 font-18">
                    Choose Your Payment Method
                  </h4>

                  <div className="payment-option d-flex align-items-center p-3 mb-3 border rounded shadow-sm ">
                    <input
                      type="radio"
                      name="payment-method"
                      id="pay-card"
                      className="form-check-input me-3"
                      // defaultChecked
                      checked={Online === true}
                      onChange={() =>
                        handleRadioChange(
                          // val.RecordId,
                          "Online"
                        )
                      }
                    />
                    <label
                      htmlFor="pay-card"
                      className="fw-semibold text-dark mb-0 flex-grow-1"
                    >
                      Pay by Card
                    </label>
                    <i className="fas fa-credit-card text-primary fs-3"></i>
                  </div>

                  <div className="payment-option d-flex align-items-center p-3 mb-3 border rounded shadow-sm disabled">
                    <input
                      type="radio"
                      name="payment-method"
                      id="pay-cash"
                      defaultChecked
                      disabled
                      className="form-check-input me-3"
                      checked={Online === false}
                      onChange={() =>
                        handleRadioChange(
                          // val.RecordId,
                          "Cash"
                        )
                      }
                    />
                    <label
                      htmlFor="pay-cash"
                      className="fw-semibold text-dark mb-0 flex-grow-1"
                    >
                      Pay with Cash
                    </label>
                    <i className="fas fa-money-bill-alt text-primary fs-3"></i>
                  </div>

                  {/* Accepted Payment Methods */}
                  <div className="d-flex align-items-center justify-content-center mb-5">
                    <span className="text-muted fw-bold me-4">We accept:</span>
                    <div className="d-flex">
                      <i
                        className="fab fa-cc-visa mx-3"
                        style={{
                          fontSize: "36px",
                          color: "#1A1F71",
                        }}
                      ></i>
                      <i
                        className="fab fa-cc-mastercard mx-3"
                        style={{
                          fontSize: "36px",
                          color: "#EB001B",
                        }}
                      ></i>
                      <i
                        className="fab fa-cc-amex mx-3"
                        style={{
                          fontSize: "36px",
                          color: "#2E77BC",
                        }}
                      ></i>
                      <svg
                        className=""
                        width="90px"
                        height="100%"
                        viewBox="0 0 141 56"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M122.239 16.873L113.948 48.5272L113.929 48.5989H120.386L128.707 16.873H122.239Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M19.643 34.8883C18.6747 35.3651 17.6091 35.6112 16.5297 35.6072C14.2043 35.6072 12.8847 35.2358 12.7413 33.3563V33.2297C12.7413 33.1551 12.7328 33.089 12.7328 33.0018V27.528L12.7413 26.8837V23.0248H12.7328V21.3831L12.7413 20.7332V17.008L6.97342 17.7691C10.8744 17.0136 13.1126 13.9272 13.1126 10.8505V8.95557H6.63157V17.8183L6.26581 17.921V34.334C6.47964 38.9455 9.5183 41.6859 14.504 41.6859C16.2667 41.6859 18.2066 41.285 19.6922 40.6111L19.726 40.597V34.8391L19.643 34.8883Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M20.6657 15.6799L2.47736 18.4837V23.0951L20.6657 20.2914V15.6799Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M20.6657 22.427L2.47736 25.2307V29.6368L20.6657 26.8317V22.427Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M41.0782 24.5485C40.8208 19.425 37.6189 16.3892 32.4026 16.3892C29.4033 16.3892 26.9316 17.5469 25.2533 19.7401C23.575 21.9333 22.6971 25.1394 22.6971 29.0362C22.6971 32.933 23.5806 36.1489 25.2533 38.3336C26.9259 40.5184 29.4033 41.6846 32.4026 41.6846C37.6189 41.6846 40.8208 38.6333 41.0782 33.483V41.2021H47.5495V16.9294L41.0782 17.9282V24.5485ZM41.4159 29.0418C41.4159 33.5801 39.0328 36.5119 35.3456 36.5119C31.5472 36.5119 29.2753 33.7194 29.2753 29.0418C29.2711 24.3375 31.5402 21.5239 35.3414 21.5239C37.1899 21.5239 38.7345 22.2442 39.8079 23.606C40.856 24.9368 41.4116 26.8149 41.4116 29.0376L41.4159 29.0418Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M66.4581 16.3905C61.2389 16.3905 58.0356 19.4277 57.7824 24.5569V9.86157L51.3013 10.8618V41.2034H57.7824V33.4815C58.0356 38.6388 61.2389 41.693 66.4581 41.693C72.5649 41.693 76.2113 36.9647 76.2113 29.0445C76.2113 21.1243 72.5649 16.3905 66.4581 16.3905ZM63.5151 36.5075C59.8265 36.5075 57.4448 33.5758 57.4448 29.0375C57.4448 26.8148 57.999 24.9367 59.0485 23.6031C60.1219 22.2413 61.6665 21.521 63.5151 21.521C67.3134 21.521 69.5854 24.3346 69.5854 29.0375C69.5854 33.7151 67.3162 36.5075 63.5151 36.5075Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M93.8595 16.3905C88.6403 16.3905 85.4385 19.4277 85.1838 24.5569V9.86157L78.7028 10.8618V41.2034H85.1838V33.4815C85.4385 38.6388 88.6403 41.693 93.8595 41.693C99.9663 41.693 103.613 36.9647 103.613 29.0445C103.613 21.1243 99.9663 16.3905 93.8595 16.3905ZM90.9165 36.5075C87.2293 36.5075 84.8462 33.5758 84.8462 29.0375C84.8462 26.8148 85.4005 24.9367 86.4499 23.6031C87.5233 22.2413 89.068 21.521 90.9165 21.521C94.7148 21.521 96.9868 24.3346 96.9868 29.0375C96.9868 33.7151 94.7176 36.5075 90.9165 36.5075Z"
                          fill="#292929"
                        ></path>
                        <path
                          d="M103.628 16.873H110.548L116.17 41.1584H109.966L103.628 16.873Z"
                          fill="#292929"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="confirmation"
                      checked={isConfirmed}
                      onChange={(e) => setIsConfirmed(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="confirmation">
                      I have read and agree to the{" "}
                      <Link
                        to="/termsandconditions"
                        target="blank"
                        className="text-black ff-semibold font-14 "
                      >
                        Terms and Conditions.
                      </Link>
                    </label>
                    {errorsconfirmation && (
                      <p className="text-danger">
                        {" "}
                        Please agree to the terms and conditions before
                        proceeding.
                      </p>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-50 py-2 fs-5"
                      onClick={handleSubmit}
                      // disabled={!Online}
                    >
                      Submit Payment
                    </button>
                  </div>
                </div>

                <div className="custom-accent-bar mt-4"></div>
              </div>
            </div>

            <div className="col-lg-4 d-none  d-lg-block ">
              <div className="sticky-card cart ">
                <SideCard />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewPayment;
