interface Props {
  section?: {
    heading?: string;
    picUrl?: string;
    ph_Number?: string;
    ph_Number_text?: string;
    wh_Number?: string;
  };
  data?: {
    heading: string;
    line: string;
    image: string;
  };
}
const Section7: React.FC<Props> = ({ data, section }) => {
  return (
    <>
      <section className="legal-ft-banner pt-5 position-relative d-none d-md-block">
        {/* <img src={data?.image} alt={data?.heading} /> */}
        <img src={data?.image} alt={data?.heading} className="img-fluid" />
        <div className="whatsap-banner-footer">
          <div className="container text-center pt-5">
            <p
              className="font-30 ff-bold text-white"
              dangerouslySetInnerHTML={{
                __html: data?.heading ?? "",
              }}
            ></p>
            <p
              className="text-white"
              dangerouslySetInnerHTML={{
                __html: data?.line ?? "",
              }}
            ></p>

            <div className="text-center d-flex align-items-center justify-content-center col-12 pb-5">
              <a
                //   href="https://web.whatsapp.com/send?phone=+97142979998"
                href={`https://wa.me/${section?.wh_Number}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button className="btn btn-primary font-14 ff-semibold  bt--cart px-3 py-2 gap-2 h-100">
                  <i className="fab fa-whatsapp font-20"></i>
                  Whatsapp
                </button>
              </a>
              <div className="legal-ft-banner"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section7;
