import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Services from "./pages/service/Services";
import Blog from "./pages/blog/Blog";
import CheckOut from "./pages/checkout/CheckOut";
import TermsAndConditions from "./pages/termsandconditions/TermsAndConditions";
import PrivacyAndPolicy from "./pages/privacyandpolicy/PrivacyAndPolicy";
import AddressPage from "./pages/addressPage/AddressPage";
import OrderFlow from "./pages/orderFlow/OrderFlow";
import OrderList from "./pages/orderList/OrderList";
import Thankyou from "./pages/thankyou/Thankyou";
// import Profile from "./pages/profileUser/profileUser";
import Profile from "./pages/Profile/Profile";
import AdminNavbar from "./admin/adminComponents/navbar/Navbar";
import RightBar from "./admin/adminComponents/RightBar.tsx/RightBar";
import VisaServices from "./pages/typesofvisa/typesOfVisa/typesOfVisa.js";

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import SettingPage from "./pages/setting/SettingPage";
import AddRemoveUpdataAddress from "./pages/addressPage/AddRemoveUpdataAddress";
import Admin from "./admin/adminpages/Admin";
import AdminList from "./admin/adminpages/AdminList";
import "./App.css";
import NotFound from "./pages/notFound/NotFound";
import OrderDetail from "./pages/orderDetail/OrderDetail";
import ProtectedRoute from "./pages/ProtectedRoute/ProtectedRoute";
import Pre from "./pages/pre/Pre";

import Contact from "./pages/contact/Contact.js";
import Cart from "./pages/cart/Cart.js";
import Cart1 from "./pages/cart/Cart1.js";
import Packages from "./pages/Packages/Packages.js";
import HelpSection from "./pages/helpCenter/HelpSection.js";
import CancellationPolicy from "./pages/cancellationPolicy/CancellationPolicy.js";
import Logout from "./pages/Logout/Logout.tsx";
import Otp from "./pages/otp/Otp.tsx";
import FooterMobile from "./components/footer/FooterNav.tsx";
import ReviewPage from "./pages/ReviewPage/ReviewPage.js";
import LegalTranslation from "./pages/LegalTranslation/LegalTranslation.tsx";
import NewPayment from "./pages/payment/NewPayment.tsx";
import Payment from "./pages/payment/Payment.tsx";
import LegalTranslationDetail from "./pages/LegalTranslation/LegalTranslationDetail/LegalTranslationDetail.tsx";
import BlogDetails from "./pages/blog/blogDetails/BlogDetails.tsx";
import PaymentNavbar from "./components/navbar/navbarComponents/PaymentNavbar.tsx";
import AfterGetQuote from "./components/aftergetquotemodel/AfterGetQuote.tsx";
import Booking from "./pages/orderFlow/Booking.tsx";
import BookingDetail from "./components/bookingdetail/BookingDetail.tsx";
import MarriageServices from "./pages/alAdheed/marriageServices/MarriageServices.tsx";
import PesrsonalCases from "./pages/alAdheed/personalCases/PesrsonalCases.tsx";
import NewServices from "./pages/service/NewServices.tsx";
import Aladheed from "./pages/alAdheed/Aladheed.tsx";
import CaseService from "./pages/alAdheed/caseServices/CaseServices.tsx";
import Notaryservices from "./pages/notaryServices/Notaryservices.tsx";
//keys off
function App() {
  // useEffect(() => {
  //   // Handle keydown events for disabling shortcuts
  //   const handleKeyDown = (e: { keyCode: number; ctrlKey: boolean; key: string; metaKey: boolean; preventDefault: () => void; shiftKey?: boolean }) => {
  //     const isShortcutKey = (key: string, event: { ctrlKey: boolean; key: string; metaKey: boolean; shiftKey?: boolean }) =>
  //       (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === key;

  //     if (
  //       e.keyCode === 123 || // F12
  //       isShortcutKey("I", e) || // Ctrl + Shift + I or Cmd + Shift + I
  //       isShortcutKey("J", e) || // Ctrl + Shift + J or Cmd + Shift + J
  //       isShortcutKey("C", e) || // Ctrl + Shift + C or Cmd + Shift + C
  //       (e.ctrlKey && e.key === "u") || // Ctrl + U
  //       (e.metaKey && e.key === "u") // Cmd + U
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   // Handle right-click disabling
  //   const handleContextMenu = (e: MouseEvent) => {
  //     e.preventDefault();
  //   };

  //   // Add event listeners
  //   document.addEventListener("keydown", handleKeyDown);
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // Clean up event listeners on unmount
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  const Layout = () => {
    return (
      <div>
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const LayoutWithoutFooter = () => {
    return (
      <div>
        <Navbar />
        <Outlet />
      </div>
    );
  };

  const SimpleLayout = () => (
    <div className="simple-layout">
      <Outlet />
    </div>
  );

  const PaymentLayout = () => (
    <div className="simple-layout">
      <PaymentNavbar />
      <Outlet />
    </div>
  );

  const LayoutWithoutNavbar = () => {
    return (
      <div className="mobile-layout">
        <Outlet />
        <FooterMobile />
      </div>
    );
  };

  const FooterPagesLayout = () => {
    return (
      <div className="simple-layout">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const AdminLayout = () => (
    <div className="admin-layout">
      <div className="navbar">
        <AdminNavbar />
      </div>

      <div className="admin-body">
        <aside className="sidebar">
          <RightBar />
        </aside>
        <main className="admin-content">
          <Outlet />
        </main>
      </div>
    </div>
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About />, errorElement: <NotFound /> },

        { path: "/blog", element: <Blog /> },
        { path: "/blogDetails", element: <BlogDetails /> },

        {
          path: "notFound",
          element: <NotFound />,
        },

        {
          path: "/thank-you",
          element: <AfterGetQuote />,
        },

        { path: "/termsandconditions", element: <TermsAndConditions /> },
        { path: "/privacyandpolicy", element: <PrivacyAndPolicy /> },
        { path: "/cancellationPolicy", element: <CancellationPolicy /> },
        { path: "/contactus", element: <Contact /> },
        {
          path: "/legal-translation-services-in-dubai",
          element: <LegalTranslation />,
        },
        {
          path: "/LegalTranslation-details",
          element: <LegalTranslationDetail />,
        },
        {
          path: "dubai-courts-al-adheed/marriage-service",
          element: <MarriageServices />,
        },
        {
          path: "/notary-services-dubai",
          element: <Notaryservices />,
        },
        {
          path: "dubai-courts-al-adheed/personal-case",
          element: <PesrsonalCases />,
        },
        {
          path: "dubai-courts-al-adheed/all-case-services",
          element: <CaseService />,
        },
        {
          path: "/dubai-courts-al-adheed",
          element: <Aladheed />,
        },
        { path: "/reviews", element: <ReviewPage /> },

        {
          path: "visa-packages-dubai",
          element: <Packages />,
        },
        {
          path: "/orderlist/:slug",
          element: (
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          ),
        },
        { path: "/pre", element: <Pre /> },
      ],
    },
    {
      path: "/",
      element: <LayoutWithoutFooter />,
      children: [
        { path: "/cart1", element: <Cart /> },
        { path: "/cart", element: <Cart1 /> },
        {
          path: "/addresspage",
          element: (
            <ProtectedRoute>
              <AddressPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/checkout",
          element: (
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
        {
          path: "/addresspage",
          element: (
            <ProtectedRoute>
              <AddressPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/addRemoveUpdataAddress",
          element: (
            <ProtectedRoute>
              <AddRemoveUpdataAddress />
            </ProtectedRoute>
          ),
        },

        {
          path: "/orderlist/:slug",
          element: (
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/applicationdetail/:slug",
          element: (
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "/orderflow",
          element: (
            <ProtectedRoute>
              <OrderFlow />
              {/* <Booking/> */}
            </ProtectedRoute>
          ),
        },
        {
          path: "/setting",
          element: (
            <ProtectedRoute>
              <SettingPage />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: "/payment",
        //   element: (
        //     <ProtectedRoute>
        //       <Payment />
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: "/newpayment",
          element: (
            <ProtectedRoute>
              <NewPayment />
            </ProtectedRoute>
          ),
        },
        {
          path: "/orderlist/:slug",
          element: (
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/applicationdetail/:slug",
          element: (
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "/orderflow",
          element: (
            <ProtectedRoute>
              <OrderFlow />
            </ProtectedRoute>
          ),
        },
        {
          path: "/setting",
          element: (
            <ProtectedRoute>
              <SettingPage />
            </ProtectedRoute>
          ),
        },

        // {
        //   path: "/payment",
        //   element: (
        //     <ProtectedRoute>
        //       <Payment />
        //     </ProtectedRoute>
        //   ),
        // },
      ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        {
          path: "/admin",
          element: (
            <ProtectedRoute>
              <AdminList />
            </ProtectedRoute>
          ),
        },
        {
          path: "adminpanel",
          element: (
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin",
          element: (
            <ProtectedRoute>
              <AdminList />
            </ProtectedRoute>
          ),
        },
        {
          path: "adminpanel",
          element: (
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/thankyou",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute>
              <Thankyou />
            </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: "/helpcenter",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <HelpSection />,
        },
      ],
    },

    {
      path: "/profile",
      element: <LayoutWithoutNavbar />,
      children: [
        {
          path: "",
          element: <Profile />,
        },
      ],
    },

    {
      path: "/userlogout",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <Logout />,
        },
      ],
    },

    {
      path: "/otp",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <Otp />,
        },
      ],
    },

    {
      path: "/payment",
      element: <PaymentLayout />,
      // element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: "/booking",
      element: <PaymentLayout />,
      // element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute>
              {/* <OrderFlow /> */}
              <Booking />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/bookingdetail/:slug",
      element: <PaymentLayout />,
      // element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: (
            // <ProtectedRoute>
            <>
              <BookingDetail />
            </>
            // {/* <OrderFlow /> */}
            // </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: "/CancelPayment",
      element: <Navigate to="/payment" replace />,
    },

    {
      path: "/",
      // element: <MobileLayout />,
      element: <Layout />,
      children: [{ path: "/services/:slug", element: <Services /> }],
    },
    {
      path: "/",
      // element: <MobileLayout />,
      element: <Layout />,
      children: [{ path: "/newservices/:slug", element: <NewServices /> }],
    },
    {
      path: "/",
      element: <FooterPagesLayout />,
      children: [],
    },
    {
      path: "/",
      element: <Layout />,
      children: [{ path: ":visaType", element: <VisaServices /> }],
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/:Category/:Detail", element: <LegalTranslationDetail /> },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
