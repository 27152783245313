import React from "react";
import { Section3 } from "../../types/notary";
import Slider from "./Slider";

interface Props {
    section3?: Section3; 
}

const WhoWeAre: React.FC<Props> = ({ section3 }) => {

    const getSection3Content = section3?.content.map((item, index) => {
        // Split the text into number/metric and description
        const [number, description] = item.split(/\s*\+\s*/); // Split by '+'
      
        return (
          <div className="col-md-4" key={index}>
            <p className="font-20 ff-bold text-black mb-0">{number}+</p>
            <p className="font-14">{description}</p>
          </div>
        );
    });

    return(
        <section className="py-5 my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src="https://goodhand.b-cdn.net/Assets/Footer_pages/Notaryservices/testi-abt.png"
                alt=""
                className="d-block img-fluid mx-auto notary-counter rounded"
              />
              <p className="pt-3 font-14"
                dangerouslySetInnerHTML={{
                  __html: section3?.description ?? "",
                }}>
              </p>
            </div>
            <div className="col-md-2">
              <img
                src="https://goodhand.b-cdn.net/Assets/Footer_pages/Notaryservices/arrow4.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-5">
              <div className="hd-style-2 pb-3 wow animate__fadeInRight animated">
                <div className="font-35 ff-bold "
                  dangerouslySetInnerHTML={{
                    __html: section3?.heading ?? "",
                  }}>
                </div>
              </div>
              <div className="border rounded p-4 mt-5">
                <div className="row">
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <div className="cut-log-main">
                      <img
                        src="https://goodhand.b-cdn.net/Assets/Footer_pages/Notaryservices/cutLogo.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  { getSection3Content }
                </div>
              </div>
              <div className="row pt-5">
                <Slider data={section3?.slider} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default WhoWeAre;