import Faq from "../../../components/amirComponents/amirFaq/Faq";
import WhyChooseUs from "../../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";

import BannerCompnent from "./components/BannerCompnent";
import CivilMarriage from "./components/CivilMarriage";
import HappyStories from "./components/HappyStories";
import MarriageServiceComponent from "./components/MarriageServiceComponent";
import MuslimMarriage from "./components/MuslimMarriage";
import WhyChooseGoodHandMarriageServices from "./components/WhyChooseGoodHandMarriageServices";

import { useEffect, useState } from "react";
import PreLoader from "../../../components/preloader/PreLoader";
import axios from "axios";
import { SEOData } from "../../../types/home";
import MetaHead from "../../../components/metaHead/MetaHead";
import {
  Marriage,
  MarriageService,
  WhyChooseGoodhand,
  HappyStorie,
} from "../../../types/marriageSevices";
import { Section10, Section9 } from "../../../types/department";
import { FaqDataProps } from "../../../types/alhadeed";
import ServiceInDubaiSlider from "./components/ServiceInDubaiSlider";
const apiUrl = import.meta.env.VITE_API_URL;

interface MarriageServicesData {
  imageBanner: {
    imageUrl: string;
  };
  MarriageServiceComponent: MarriageService;
  Faqs: FaqDataProps;
  CivilMarriage: Marriage;
  MuslimMarriage: Marriage;
  whyChooseGoodhand: WhyChooseGoodhand;
  happyStories: HappyStorie;
  whyChooseUs1: Section9;
  whyChooseUs2: Section10;
}

const MarriageServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [marrieageSer, serPersonServic] = useState<MarriageServicesData>();
  const [metaData, setMetaData] = useState<SEOData>();
  const [allService, setAllService] = useState<[]>([]);
  const [apidata, setApiData] = useState<any>([]);
  
  const URL = `${apiUrl}/api/openRoute/serviceAdheed?slug=marriage-service&mainPageSlug=dubai-courts-al-adheed`;

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);
      if (response) {
        setApiData(response.data);
        setAllService(response.data?.Allservice);
        return true;
      }

      return false;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error(
            "Error 400: Bad request, redirecting to NotFound page."
          );
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false;
    }
  };

  useEffect(() => {
    import("../aladheed.css");
    import("./marriageServices.css");

    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loading
        const responseData = await getDataFromApi();
        const response = await axios.get(
          "https://goodhand.b-cdn.net/Assets/Footer_pages/Ahdeed/marrieageService.json"
        );

        if (response.data && responseData) {
          serPersonServic(response.data); // Save data to state/'
          setMetaData(response.data?.seo);
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };


    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }
  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}
          <BannerCompnent bannerImg={marrieageSer?.imageBanner.imageUrl} />

          <MarriageServiceComponent
            data={marrieageSer?.["MarriageServiceComponent"]}
          />

          <CivilMarriage data={marrieageSer?.["CivilMarriage"]} serviceData={apidata.mainCategory}/>

          <MuslimMarriage data={marrieageSer?.["MuslimMarriage"]} serviceData={apidata.mainCategory}/>

          <WhyChooseGoodHandMarriageServices
            data={marrieageSer?.["whyChooseGoodhand"]}
          />

          <HappyStories data={marrieageSer?.["happyStories"]} />

          <section className="faqWrap bg-2 py-5 types-of-visa">
            <Faq FaqData={marrieageSer?.["Faqs"]} />
          </section>

          <WhyChooseUs
            section9={marrieageSer?.["whyChooseUs1"]}
            section10={marrieageSer?.["whyChooseUs2"]}
          />
          <section className="pb-5">
            <ServiceInDubaiSlider data={allService}  />
          </section>
        </>
      )}
    </>
  );
};

export default MarriageServices;
