import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperNavButtons from "../../sliderButtons/SwiperNavButton";
import SwiperNavButtons from "../../../../components/sliderButtons/SwiperNavButton";

import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { HappyStorie } from "../../../../types/marriageSevices";

interface Props {
  data?: HappyStorie;
}

const HappyStoriesSlider: React.FC<Props> = ({ data }) => {
    return (
      <>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={10} // Space between slides
          slidesPerView={3} // Default slides per view
          loop={true} // Enable looping
          autoplay={{
            delay: 2500, // Duration for each slide
            disableOnInteraction: false, // Continue autoplay after user interaction
          }}
          breakpoints={{
            320: {
              slidesPerView: 1, // Show 1 slide on small screens
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1, // Show 1 slide on slightly larger screens
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2, // Show 2 slides on medium screens
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 3, // Show 3 slides on large screens
              spaceBetween: 20,
            },
          }}
        >
          {data?.testimonials.map((testimonial) => (
            <>
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial">
                <div className="testimonial-content">
                  <div className="testimonial-icon">
                    <i className={`fa ${testimonial.icon}`}></i>
                  </div>
                  <p className="description">{testimonial.description}</p>
                </div>
                <h3 className="font-20 ff-semibold text-black">
                  {testimonial.name}
                </h3>
              </div>
            </SwiperSlide>
          </>
          ))}
          <SwiperNavButtons />
        </Swiper>
      </>
    );
  };
  
  export default HappyStoriesSlider;
  