// import catMain from "../../../assets/Legal/cat.png";

import { useNavigate } from "react-router-dom";
import { Step } from "../../../types/homeComponentTypes";

interface Props {
  apiData?: Step[];
  section2?: {
    heading: string;
  };
}

const Section3: React.FC<Props> = ({ section2, apiData }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="bg-2 py-3 py-md-5">
        <div className="container py-3 py-md-5">
          <div className="row">
            <div className="col-md-4">
              <div className="hd-style-2 golden wow animate__fadeInLeft">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section2?.heading ?? "",
                  }}
                ></h2>
              </div>
            </div>

            <div className="col-md-7 justify-content-end align-items-center"></div>
          </div>
        </div>
      </section>

      <section className="mt-5 mt-md-5">
        <div className="container mt-4 above-minus">
          <div className="row justify-content-center">
            {/* {section2?.Step?.map((item, index) => ( */}
            {apiData?.map((item, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div
                  className="card  text-start shadow-sm rounded border border-0 wow animate__zoomIn"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/${item.link}`)}
                >
                  <div className="card-body  ff d-flex d-md-block gap-3 align-items-center">
                    <img
                      src={item.img}
                      alt={item.imgAlt}
                      className="mb-0 mb-md-4 img-fluid allpk"
                    />
                    <div className="d-flex d-md-block flex-column text-start text-md-center flex-grow-1 ">
                      <span className="bg-2 text-white ff-semibold d-none font-12 px-2 py-1 mb-3 tag-main rounded d-flex align-items-center justify-content-center d-block d-md-none">
                        PRICE DROP
                      </span>
                      <h3 className="font-17 ff-semibold text-start">
                        {item.heading}
                      </h3>
                      <h4
                        className="card-title card-text-title-legal  ff-semibold text-start font-14"
                        dangerouslySetInnerHTML={{
                          __html: item.subHeading ?? "",
                        }}
                      >
                        {/* {item.Sub_heading} */}
                      </h4>
                      <p className="card-text card-text-legal mb-0 mb-md-3 font-14 text-black">
                        {item.paragraph}
                      </p>
                    </div>

                    <div className="d-flex justify-content-end justify-content-md-center align-items-center">
                      <a
                        // href="javascript:void(0)"

                        className="text-decoration-none d-block d-md-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="13px"
                          height="13px"
                          viewBox="-5.5 0 26 26"
                          version="1.1"
                        >
                          <title>chevron-right</title>
                          <desc>Created with Sketch Beta.</desc>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Icon-Set-Filled"
                              transform="translate(-474.000000, -1196.000000)"
                              fill="#626161"
                            >
                              <path
                                d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                id="chevron-right"
                              />
                            </g>
                          </g>
                        </svg>
                      </a>
                      <div
                        className="text-center d-md-flex col-12 d-none"
                        onClick={() => navigate(`/${item.link}`)}
                        // onClick={() => navigate(`/payment`)}
                      >
                        <button className="btn btn-primary font-14 ff-semibold  bt--cart px-3 py-2 gap-2 h-100">
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
