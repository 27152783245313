import {configureStore} from "@reduxjs/toolkit"
import cartSlice from "./slices/cartSlice"
import footerReducer from "./slices/footerSlice"
import separatCartReducer from './slices/separateCartDataSlice'
import authSlice from "./slices/authSlice"
import billingAddressSlice from "./slices/billingAddressSlice"
import shippingAddressSlice from "./slices/shippingAddressSlice"
import modalReducer from "./slices/modalSlice";
import IdSlice from "./slices/IdSlice"
const store = configureStore({
    reducer: {
        cart: cartSlice,
        footer: footerReducer,
        separate: separatCartReducer,
        signin: authSlice,
        billing: billingAddressSlice,
        shipping: shippingAddressSlice,
        modal: modalReducer,
        ID : IdSlice
    }
})


export default store