import { Marriage, ServiceData } from "../../../../types/marriageSevices";
import { useDispatch } from "react-redux";
import { getID, setTrue } from "../../../../store/slices/IdSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseUrl = import.meta.env.VITE_API_URL;
interface Props {
  data?: Marriage;
  serviceData?: ServiceData[];
}

const MuslimMarriage: React.FC<Props> = ({ data, serviceData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spacificData = serviceData?.find(
    (item) => item.serviceName?.toLowerCase() === "muslims marriages"
  );

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    // alert("test")
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
        const insideId = ServiceId;
        const outsideId = 42;
        dispatch(getID({ insideId, outsideId }));
        navigate(`/services/al-adheed`);
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const hanleBookNow = () => {
    // const insideId = spacificData?.ServiceId;
    // const outsideId = 42;
    addDataToDataBase(
      spacificData?.PriceId ?? 109,
      spacificData?.ServiceId ?? 167,
      "plus"
    );
    // dispatch(getID({ insideId, outsideId }));
    // navigate(`/services/al-adheed`);
  };
  return (
    <>
      <section className=" py-5 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5  mt-2 mt-md-4 wow animate__fadeInRight ">
              <div>
                <img
                  src="https://goodhand.b-cdn.net/Assets/Footer_pages/Ahdeed/MarriageServices/muslim-ab.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                  width={350}
                />
              </div>
              <div className="row justify-content-center margin-minus-50">
                <div className="col-7">
                  <div className="list-card-main d-flex align-items-start align-items-md-center justify-content-between mb-0 pb-5">
                    <div className="col-12">
                      <div id="inside_166"></div>
                      <div className="sub-hd1" id="166">
                        <p
                          className="text-center mb-2"
                          dangerouslySetInnerHTML={{
                            __html: data?.heading ?? "",
                          }}
                        ></p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <div
                          style={{
                            width: "14px",
                            height: "14px",
                            backgroundColor: "rgb(110, 66, 229)",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "5px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            fill="white"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.396.198-.824-.149-.746-.592l.83-4.73-3.522-3.356c-.33-.316-.158-.888.283-.95l4.898-.696 2.189-4.41c.197-.39.73-.39.927 0l2.19 4.41 4.898.696c.441.062.613.634.283.95l-3.522 3.356.83 4.73c.078.443-.35.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </div>
                        <span className="font-12">
                          {spacificData?.serviceReviews}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
                        <p className="d-flex align-items-center justify-content-center gap-1 mb-2">
                          <span className="in-badge">
                            AED {spacificData?.PriceAmount} &nbsp;
                            {spacificData?.maxSaving &&
                              spacificData?.maxSaving >= 1 && (
                                <s className="text-secondary ff-medium pe-1">
                                  AED&nbsp;{spacificData?.OldPriceAmount}
                                </s>
                              )}
                          </span>
                        </p>
                      </div>
                      {spacificData?.maxSaving &&
                        spacificData?.maxSaving >= 1 && (
                          <div className="up-to text-center">
                            <span>
                              <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 16 16"
                                fill="#07794C"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                  fill="#07794C"
                                ></path>
                              </svg>
                            </span>
                            <span className="font-12 ff-medium">
                              <span className="ms-1">
                                Up to AED {spacificData?.maxSaving}&nbsp;
                              </span>
                              Off
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="text-center d-flex align-items-center margin-minus-25 justify-content-center col-12 pb-4 pb-md-0 ">
                    <button
                      className="btn btn-primary   font-14 ff-semibold margin-minus bt--cart"
                      onClick={hanleBookNow}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7  my-auto wow animate__zoomIn">
              <div className="hd-style-2 ">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.heading ?? "",
                  }}
                ></h2>
              </div>
              <p
                className="font-14"
                dangerouslySetInnerHTML={{
                  __html: data?.paragraph ?? "",
                }}
              ></p>
              <div>
                <p className="ff-semibold font-18 text-black">Key Feature</p>
                <ul>
                  {data?.features.map((feature) => (
                    <li
                      key={feature.id}
                      className="d-flex align-items-center gap-2 mb-2 mb-md-3 font-14"
                    >
                      <div>
                        <img
                          src={feature.icon}
                          alt="Checked Icon"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div>{feature.text}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-12">
              <p
                className="ff-semibold font-18 text-black mb-5 mt-5"
                dangerouslySetInnerHTML={{
                  __html: data?.eligibilityCriteriaHeading ?? "",
                }}
              >
                {/* Eligibility Criteria */}
              </p>

              <div className="row justify-content-center">
                {data?.eligibilityCriteria.map((criteria) => (
                  <div key={criteria.id} className="col-md-4 mb-5">
                    <div className="elg-card p-3 bg-2 rounded shadow-sm">
                      <div className="d-flex align-items-center gap-2">
                        <div className="margin-minus-50">
                          <img
                            src={criteria.icon}
                            alt={`${criteria.title} Icon`}
                            width={55}
                            height={55}
                          />
                        </div>
                        <div className="font-16 ff-semibold">
                          {criteria.title}
                        </div>
                      </div>
                      <p
                        className="font-14 mb-0 text-black"
                        style={{ height: "45px" }}
                      >
                        {criteria.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MuslimMarriage;
