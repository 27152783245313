import { ContactU } from "../../../types/help"

interface Props {
  contactUs: ContactU
}

const ContactUs: React.FC<Props> = ({contactUs}) => {
  return (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: contactUs?.heading ?? "",
        }}
      ></p>
    </>
  )
}

export default ContactUs
