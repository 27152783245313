import { createSlice } from "@reduxjs/toolkit";
// import Login from "../../components/login/Login";

const initialState = {
    status: false,
    userData: {}
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            // const { productId, quantity, productName, productPrice, priceType } = action.payload;
            state.status = true;
            state.userData = action.payload

        },
        logout: (state) => {
            state.status = false;
            state.userData = {}
        },

    }

})

export const {login, logout} = authSlice.actions

export default authSlice.reducer
