import { SetStateAction, useState, ChangeEvent } from "react";

const ReviewPage = () => {
  const [reviews, setReviews] = useState([
    { id: 1, name: "Alice Johnson", rating: 4, comment: "Great service!" },
    { id: 2, name: "Bob Smith", rating: 5, comment: "Excellent experience!" },
    {
      id: 3,
      name: "Jane Doe",
      rating: 3,
      comment: "Good, but could be better.",
    },
    { id: 4, name: "Mark Lee", rating: 5, comment: "Amazing!" },
    { id: 5, name: "Susan Green", rating: 4, comment: "Very helpful." },
    { id: 6, name: "Tom Brown", rating: 3, comment: "Decent overall." },
    { id: 7, name: "Emma White", rating: 5, comment: "Highly recommend!" },
    { id: 8, name: "Chris Black", rating: 2, comment: "Needs improvement." },
  ]);

  const [newReview, setNewReview] = useState({
    name: "",
    rating: "",
    comment: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 10; // Number of reviews per page

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleAddReview = () => {
    if (newReview.name && newReview.rating && newReview.comment) {
      const newReviewEntry = {
        id: reviews.length + 1,
        name: newReview.name,
        rating: parseInt(newReview.rating, 10),
        comment: newReview.comment,
      };
      setReviews([...reviews, newReviewEntry]);
      setNewReview({ name: "", rating: "", comment: "" });
      // Automatically move to the last page to show the newly added review
      setCurrentPage(Math.ceil((reviews.length + 1) / reviewsPerPage));
    } else {
      alert("Please fill all fields to add a review.");
    }
  };

  // Pagination logic
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const handlePageChange = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container py-2 py-md-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <h1 className="text-start mb-4 font-16">Recently Added Reviews</h1>
          <div className="row">
            <div className="col-md-7">
              <div className="mb-2 mb-md-5 border">
                {currentReviews.map((review) => (
                  <div
                    key={review.id}
                    className={`card mb-3${
                      review === currentReviews[currentReviews.length - 1]
                        ? " border-0"
                        : " border-0 border-bottom rounded-0"
                    }`}
                  >
                    <div className="card-body ">
                      <h5 className="card-title d-flex align-items-center justify-content-between mb-0">
                        <div className="ff-semibold font-16">{review.name}</div>
                        <div className="ff-medium font-12">
                          10 November 2024
                        </div>
                      </h5>
                      <span className="ms-auto font-10">
                        {[...Array(5)].map((_, i) => (
                          <i
                            key={i}
                            className={`fa-star ${
                              i < review.rating ? "fas" : "far"
                            } text-warning`}
                          ></i>
                        ))}
                      </span>
                      <p className="card-text font-14">{review.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
              <nav>
                <ul className="pagination justify-content-center">
                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="col-md-4">
              <div className="card border-opacity-10">
                <div className="card-body">
                  <h4 className="card-title mb-3 font-14 ff-semibold">
                    Add Your Review
                  </h4>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label font-14">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control font-14"
                      id="name"
                      name="name"
                      value={newReview.name}
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="rating" className="form-label font-14">
                      Rating (1 to 5)
                    </label>
                    <select
                      className="form-select font-14"
                      id="rating"
                      name="rating"
                      value={newReview.rating}
                      onChange={handleInputChange}
                    >
                      <option value="">Select a rating</option>
                      {[1, 2, 3, 4, 5].map((rating) => (
                        <option key={rating} value={rating}>
                          {rating}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="comment" className="form-label font-14">
                      Your Review
                    </label>
                    <textarea
                      className="form-control font-14"
                      id="comment"
                      name="comment"
                      value={newReview.comment}
                      onChange={handleInputChange}
                      rows={3}
                      placeholder="Write your review here..."
                    ></textarea>
                  </div>
                  <button
                    className="btn btn-primary font-12"
                    onClick={handleAddReview}
                  >
                    Submit Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;
