// import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState } from "react";
import axios from "axios";
import { useData } from "../toast/Api";


interface Props {
  mode: string;
  show: boolean;
  onClose: () => void;
  // handleAddAddressClick: () => void
}

const baseUrl = import.meta.env.VITE_API_URL;

const AddAddress: React.FC<Props> = ({ mode, show, onClose }) => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    // showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [error, setShowError] = useState<boolean>(false);
  // const [localStorageData, setLocalStorageData ] = useState({})
  const [recordId, setRecordId] = useState("");
  const [userName, setUserName] = useState("");
  const [addressType, setAddressType] = useState("");

  const [addresNickname, setAddresNickname] = useState("");
  const [streetName, setStreetName] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [floor, setfloor] = useState("");
  const [flat, setFlat] = useState("");
  const [landmark, setLandmark] = useState("");
  const [isPrimary, setIsPrimary] = useState(true);

  useEffect(() => {
    import("./addaddressmodel.css");
    if (mode === "Edit") {
      const editData = localStorage.getItem("filteredData");


      if (editData) {
        try {
          const parsedData = JSON.parse(editData);
          const {
            RecordId,
            addresNickname,
            address_type,
            building,
            landmark,
            streetName,
            floor,
            flatNo,
            userName,
          } = parsedData;
          setAddresNickname(addresNickname);
          setStreetName(streetName);
          setbuildingName(building);
          setfloor(floor);
          setFlat(flatNo);
          setLandmark(landmark);
          setIsPrimary(true);
          setUserName(userName);
          setAddressType(address_type);
          setRecordId(RecordId);


          localStorage.removeItem("filteredData");
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.log('No data found in localStorage for key "filteredData".');
      }
    }
    
  }, []);
  // const dispatch = useDispatch();

  const emptyField = () => {
    setAddresNickname("");
    setStreetName("");
    setbuildingName("");
    setfloor("");
    setFlat("");
    setLandmark("");
    setIsPrimary(false);
    setUserName("");
    setAddressType("");
    setRecordId("");
  };

  const handleSubmit = async () => {
    const localStorageData = sessionStorage.getItem("userName");
    const token = sessionStorage.getItem("tk");

    const login_info = localStorage.getItem("Login_info");

    console.log("handleSubmit ::: data", {
      mode,
      localStorageData,
      userName,
      addresNickname,
      streetName,
      buildingName,
      floor,
      flat,
      landmark,
      isPrimary,
    });

    if (login_info) {
      const login_infoo = JSON.parse(login_info);

      if (mode === "Billing") {
        if (
          addresNickname != "" &&
          streetName != "" &&
          buildingName != "" &&
          floor != "" &&
          flat != "" &&
          landmark != ""
        ) {
          try {
            const data = {
              // userName: localStorageData ?? login_infoo.userName,
              address_type: "billing",
              addresNickname: addresNickname,
              streetName: streetName,
              building: buildingName,
              floor: floor,
              flatNo: flat,
              landmark: landmark,
              primaryAddress: isPrimary,
            };

            // const data = {
            //   userFullName: "jhjh",
            //   userEmail: "jkjk@gmail.com",
            //   userPass: "A7s8hkhkh",
            //   userConfirmPass: "A7s8hkhkh",
            //   UserCountryCode: "+971",
            //   userNumber: "551314124",
            // };
            if (!baseUrl) {
              throw new Error(
                "Base URL is not defined in the environment variables"
              );
            }

            const response = await axios.post(
              `${baseUrl}/api/user/userAddressAdd`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            console.log("addAddress ::::  ", response);
            sessionStorage.setItem(
              "billing",
              JSON.stringify(Math.floor(Math.random() * 1000) + 1)
            );
            showSuccess(" Billing Address Saved");
            // toast(" Billing Address Saved ",{
            //   icon: '👏',
            //   style: {
            //     borderRadius: '10px',
            //     background: '#333',
            //     color: '#fff',
            //   },
            // })
            
            // navigate("/addRemoveUpdataAddress")
            onClose();
          } catch (error) {
            console.log("error in billing", error);
          }
        } else {
          setShowError(true);
          showError("pllz fill all the fields")
        }
      } else if (mode === "Edit") {
        if (
          addresNickname != "" &&
          streetName != "" &&
          buildingName != "" &&
          floor != "" &&
          flat != "" &&
          landmark != ""
        ) {
          try {
            const data = {
              id: recordId,
              userName: localStorageData ?? login_infoo.userName,
              address_type: addressType,
              addresNickname: addresNickname,
              streetName: streetName,
              building: buildingName,
              floor: floor,
              flatNo: flat,
              landmark: landmark,
              primaryAddress: isPrimary,
            };

            if (!baseUrl) {
              throw new Error(
                "Base URL is not defined in the environment variables"
              );
            }

            const response = await axios.put(
              `${baseUrl}/api/user/userAddressUpdate?id=${recordId}`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  // Cookie:
                  // "connect.sid=s%3A4ECOzDPvCtulxKnSSJN3mCr22bNtF4_g.1ER0Pq7c%2BCS7SAJrlEOz5y6ATmbW%2F9eIz%2FwgmFesCrw",
                  // "Cache-Control": "no-cache", // You can add this if necessary
                },
              }
            );

            console.log("addAddress ::::  ", response);
            // if (addressType === "billing" && localStorageData) {
            //   dispatch(
            //     updateBillingAddress({
            //       userName: localStorageData,
            //       id: recordId,
            //       address_type: addressType,
            //       addresNickname: addresNickname,
            //       streetName: streetName,
            //       buildingName: buildingName,
            //       floor: floor,
            //       flat: flat,
            //       Landmark: landmark,
            //       isPrimary: isPrimary,
            //     })
            //   );
            // }
            // if (addressType === "pickup" && localStorageData) {
            //   dispatch(
            //     updateShippingAddress({
            //       userName: localStorageData,
            //       id: recordId,
            //       address_type: addressType,
            //       addresNickname: addresNickname,
            //       streetName: streetName,
            //       buildingName: buildingName,
            //       floor: floor,
            //       flat: flat,
            //       Landmark: landmark,
            //       isPrimary: isPrimary,
            //     })
            //   );
            // }
            emptyField();
            sessionStorage.setItem(
              "billing",
              JSON.stringify(Math.floor(Math.random() * 1000) + 1)
            );
            showSuccess(" Address Successfully Updated");
            // navigate("/addRemoveUpdataAddress");
            onClose();
          } catch (error) {
            console.log("error", error);
          }
        } else {
          setShowError(true);
          showError("pllz fill all the fields")
        }
      } else if (mode === "Shipping") {
        if (
          addresNickname != "" &&
          streetName != "" &&
          buildingName != "" &&
          floor != "" &&
          flat != "" &&
          landmark != ""
        ) {
          try {
            const data = {
              userName: localStorageData ?? login_infoo.userName,
              address_type: "pickup",
              addresNickname: addresNickname,
              streetName: streetName,
              building: buildingName,
              floor: floor,
              flatNo: flat,
              landmark: landmark,
              primaryAddress: isPrimary,
            };

            if (!baseUrl) {
              throw new Error(
                "Base URL is not defined in the environment variables"
              );
            }

            const response = await axios.post(
              `${baseUrl}/api/user/userAddressAdd`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  // Cookie:
                  // "connect.sid=s%3A4ECOzDPvCtulxKnSSJN3mCr22bNtF4_g.1ER0Pq7c%2BCS7SAJrlEOz5y6ATmbW%2F9eIz%2FwgmFesCrw",
                  // "Cache-Control": "no-cache", // You can add this if necessary
                },
              }
            );

            console.log("addAddress ::::  ", response);
            sessionStorage.setItem(
              "billing",
              JSON.stringify(Math.floor(Math.random() * 1000) + 1)
            );
            showSuccess(" Shipping Address Saved ");
            onClose();
          } catch (error) {
            console.log("error in ", error);
          }
        } else {
          setShowError(true);
          showError("pllz fill all the fields")
        }
      }
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrimary(e.target.checked);
  };

  return (
    <>
      <Offcanvas show={show} onHide={onClose} placement="end">
        {/* <ToastContainer delay={3000} /> */}
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hd-style-2 text-center ">
                  <h2 className="text-uppercase">
                    {mode} <span> Address </span>
                  </h2>
                  <p>To access your account</p>
                </div>

                <form className="form-main">
                  <div className="row">
                    <div className="mb-3 col-md-12 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Addres Nickname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder="e.g Work, Home.. etc"
                        value={addresNickname}
                        onChange={(e) => setAddresNickname(e.target.value)}
                      />
                      {/* <div className="invalid-feedback"> `{addresNickname === ''} ? `Error message</div> */}
                      {error && (
                        <div className="invalid-feedback">
                          Error message: Address nickname is required.
                        </div>
                      )}
                    </div>

                    <div className="mb-3 col-md-12 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Street Name *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder=" State"
                        value={streetName}
                        onChange={(e) => setStreetName(e.target.value)}
                      />
                      <div className="invalid-feedback">Error message</div>
                    </div>
                    <div className="mb-3 col-md-12 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Building *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder=" State"
                        value={buildingName}
                        onChange={(e) => setbuildingName(e.target.value)}
                      />
                      <div className="invalid-feedback">Error message</div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Floor *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder=" State"
                        value={floor}
                        onChange={(e) => setfloor(e.target.value)}
                      />
                      <div className="invalid-feedback">Error message</div>
                    </div>
                    <div className="mb-3 col-md-6 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Flat No
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder=" State"
                        value={flat}
                        onChange={(e) => setFlat(e.target.value)}
                      />
                      <div className="invalid-feedback">Error message</div>
                    </div>

                    <div className="mb-3 col-md-12 col-12">
                      <label htmlFor="emailPhone" className="form-label">
                        Landmark
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        placeholder=" State"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                      />
                      <div className="invalid-feedback">Error message</div>
                    </div>
                    <div className="mb-3 col-md-12 col-12">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDisabled"
                          checked={isPrimary}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDisabled"
                        >
                          Set as primary
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Header className="offcanvas-footer py-2">
          <div className="container">
            <div className="text-center mt-3">
              <button
                onClick={handleSubmit}
                className="btn btn-primary w-100 flow-btn"
                data-bs-dismiss="offcanvas"
              >
                Submit
              </button>
            </div>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default AddAddress;
