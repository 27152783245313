// import { useState } from "react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTrue } from "../../store/slices/IdSlice";
import PreLoader from "../../assets/ajax-loader.gif";
import { AnimatedNumber } from "../cardItem/AnimatedNumber";
import { closeAddModelData } from "../../store/slices/modalSlice";
const baseUrl = import.meta.env.VITE_API_URL;

interface Price {
  PriceRecordId: number;
  PriceServiceName: string;
  PriceType: string;
  PriceAmount: number;
  PriceCurrency: string;
  Description: string | null;
  serviceName: string;
  serviceid: number;
  OldPriceAmount: number;
}

interface PriceDetails {
  Quantity: number;
  // TotalQuantity: number;
  PriceRecordId: number;
  PriceServiceName: string;
  PriceType: string;
  PriceAmount: number;
  PriceCurrency: string;
  Description: string | null;
  serviceName: string;
  serviceid: number;
  OldPriceAmount: number;
}
// interface ModelProps {
//   show: boolean;
//   // onClose: () => void;
// }

const Model = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [priceData, setPriceData] = useState<Price[]>([]);
  const [imggg, setImg] = useState<string | undefined>(undefined);
  const [serviceName, setServiceName] = useState<string>();
  const [htmlContent, setHtmlContent] = useState<string | null>("");
  const [quantityData, setQuantityData] = useState<PriceDetails[]>();

  const addModalOpen = useSelector(
    (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  );

  const handleCloseAddModal = () => {
    dispatch(setTrue());
    dispatch(closeAddModelData());
  };
  
  const [total, setTotal] = useState({
    totalPrice: 0,
    totalQuantity: 0,
    oldPrice: 0,
  });

  function mapPricesWithQuantities(
    servicePrices: Price[],
    quantity: PriceDetails[]
  ) {
    return servicePrices.map((price) => {
      // Find the corresponding quantity for the given PriceRecordId
      const correspondingQuantity = quantity.find(
        (q) => q.PriceRecordId === price.PriceRecordId
      );

      // Return the price object along with the corresponding quantity
      return {
        ...price,
        Quantity: correspondingQuantity ? correspondingQuantity.Quantity : 0, // Default to 0 if no quantity is found
      };
    });
  }

  const getData = async (serviceid: string) => {
    const uuid = localStorage.getItem("uuid");
    // console.log("response data : junaid  cartitem 1");
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await axios.get(
        `${baseUrl}/api/openRoute/servicesDescrption?ServiceId=${serviceid}&uuid=${uuid}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        console.log("response data : servicesDescrption", response.data);
        // console.log(
        //   "response data : servicesDescrption",
        //   response.data.servicesDescrption.quantity
        // );
        setHtmlContent(
          response.data.servicesDescrption.serviceDetails.insideDescription
        );
        setPriceData(response.data.servicesDescrption.servicePrices);
        setImg(
          response.data.servicesDescrption.serviceDetails?.imageSrc ?? undefined
        );
        setServiceName(
          response.data.servicesDescrption.serviceDetails.serviceName
        );

        const pricesWithQuantities = mapPricesWithQuantities(
          response.data.servicesDescrption.servicePrices,
          response.data.servicesDescrption.quantity
        );

        console.log("pricesWithQuantities",pricesWithQuantities)

        setQuantityData(pricesWithQuantities);
        calculateTotal(pricesWithQuantities);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.log("Error on CartItems", error);
    }
  };

  useEffect(() => {
    const serviceid = sessionStorage.getItem("serviceid");
    if (serviceid) {
      getData(serviceid);
    }
  }, []);

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    // console.log("imppo",PriceRecordId,ServiceId,)
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        // dispatch(setTrue());
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleAddToCart = (id: number) => {
    quantityData?.forEach((item) => {
      if (item.PriceRecordId === id) {
        if (item.Quantity === 0) {
          item.Quantity = item.Quantity + 1;
        }
      }
    });
    calculateTotal(quantityData);

    priceData.forEach((item) => {
      if (item.PriceRecordId === id) {
        addDataToDataBase(item.PriceRecordId, item.serviceid, "plus");
      }
    });
  };

  const calculateTotal = (quantityDataa?: PriceDetails[]) => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let oldPrice = 0;

    quantityDataa?.forEach((item) => {
      totalQuantity += item.Quantity; // Add quantity
      totalPrice += item.PriceAmount * item.Quantity; // Add total price for this item
      oldPrice += item.OldPriceAmount * item.Quantity;
    });
    setTotal({ totalPrice, totalQuantity, oldPrice });
  };

  const handlePlusQuantity = (id: number) => {
    quantityData?.forEach((item) => {
      if (item.PriceRecordId === id) {
        item.Quantity = item.Quantity + 1;
      }
    });
    calculateTotal(quantityData);
    priceData.forEach((item) => {
      if (item.PriceRecordId === id) {
        addDataToDataBase(item.PriceRecordId, item.serviceid, "plus");
      }
    });
  };

  const handleMinusQuantity = (id: number) => {
    quantityData?.forEach((item) => {
      if (item.PriceRecordId === id) {
        if (item.Quantity !== 0)
          // console.log("handlePlusQuantity :: minus");
          item.Quantity = item.Quantity - 1;
      }
    });
    calculateTotal(quantityData);
    priceData.forEach((item) => {
      if (item.PriceRecordId === id) {
        addDataToDataBase(item.PriceRecordId, item.serviceid, "minus");
      }
    });
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }



  return (
    <>
      <Modal show={addModalOpen} onHide={handleCloseAddModal} className="" scrollable>
        <Modal.Header closeButton>
          {/* <Modal.Title>Amer Services</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="modal-dialog-scrollable">
          {isLoading ? (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  flexDirection: "column",
                  height: "50px",
                  padding: "50px 2px",
                }}
              >
                <img src={PreLoader} />
                <h1>Loading ... </h1>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="image-container d-flex align-items-center justify-content-start gap-3 mb-4 list-card-main shadow-none p-0">
                    <img
                      src={imggg}
                      // alt={addModelData.imgAlt ?? serviceName}
                      alt={serviceName}
                      className="img-fluid d-block "
                    />
                    <div className="sub-hd1 ">
                      <p>{serviceName}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {quantityData &&
                  quantityData?.map((data) => (
                    <div className="col-md-6" key={data.PriceRecordId}>
                      <div className="list-card-main d-flex align-items-center justify-content-between position-relative">
                        <div className="col-12">
                          {/* <div className="sub-hd1">
                    <p>{data.serviceName}</p>
                  </div> */}
                          {/* <div className="d-flex align-items-center gap-2"> */}
                          <span className="in-badge position-absolute top-0 badge2">
                            {data.PriceType}
                          </span>
                          {/* </div> */}
                          <div className="d-flex align-items-center gap-2 flex-wrap justify-content-between ">
                            <p className="d-flex align-items-center gap-2 text-black mb-0 py-2 fs-prize">
                              AED {data.PriceAmount.toLocaleString()}
                              {data.OldPriceAmount > data.PriceAmount && (
                                <s className="ff-medium">
                                  AED {data.OldPriceAmount}
                                </s>
                              )}
                            </p>

                            {data.Quantity > 0 ? (
                              <div className=" d-flex align-items-center flex-column justify-content-center add-cart-btn-main">
                                <div className="d-flex align-items-center justify-content-center gap-1 flex-wrap  flex-md-nowrap">
                                  <button
                                    className="bt-mi px-0 "
                                    id="decrement"
                                    onClick={() =>
                                      handleMinusQuantity(data.PriceRecordId)
                                    }
                                  >
                                    -
                                  </button>
                                  <span className="mx-0.5">
                                    <div className="w-1 text-center">
                                      <AnimatedNumber value={data.Quantity} />
                                    </div>
                                  </span>
                                  <button
                                    className="bt-plu px-0 "
                                    id="increment"
                                    onClick={() =>
                                      handlePlusQuantity(data.PriceRecordId)
                                    }
                                  >
                                    +
                                  </button>
                                </div>

                                {/* <div className="loading-line-container">
                                  <div className="loading-line"></div>
                                </div> */}
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="btn-add position-relative"
                                onClick={() =>
                                  handleAddToCart(data.PriceRecordId)
                                }
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlContent ?? " ",
                  }}
                />
                <p>
                  All services are subject to change as per government
                  notification
                </p>
              </div>
            </>
          )}
        </Modal.Body>
        {/* asjdh */}
        {total.totalQuantity > 0 && (
          <Modal.Footer>
            <div
              className={
                total.totalQuantity > 0
                  ? "price-section d-flex w-100 gap-2 align-items-center "
                  : "d-flex justify-content-end"
              }
            >
              <>
                <p className="item-count bg-2 p-2 mb-0 rounded ff-semibold">
                  {total.totalQuantity ? total.totalQuantity : ""}
                </p>
                {/* {data.OldPriceAmount > data.PriceAmount && ( */}
                <p className="current-price flex-grow-1 mb-0 ff-bold">
                  &nbsp;
                  <span>{total.totalQuantity ? "AED" : ""}</span>&nbsp;
                  {total.totalPrice ? total.totalPrice.toFixed(2) : ""}
                  &nbsp;
                  <s className="ff-medium">
                    <span>
                      {total.oldPrice > total.totalPrice ? "AED" : ""}
                    </span>{" "}
                    {total.oldPrice > total.totalPrice
                      ? total.oldPrice.toFixed(2)
                      : ""}
                  </s>
                </p>
                {/* )} */}
              </>
              <button
                // className="total.totalQuantity > 0 ? "": done-button btn btn-primary mt-2 float-right flex-grow-1"
                className={`${total.totalQuantity} > 0 ? "done-button btn btn-primary mt-2 flex-grow-1" : "float-right"`}
                onClick={handleCloseAddModal}
              >
                Done
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Model;
