import Faq from "../../components/amirComponents/amirFaq/Faq";
import WhyChooseUs from "../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";
import Experts from "../../components/expert/Experts";
import ChannelPartaner from "../Footerpages/department/components/ChannelPartaner";
import BannerCompnent from "./marriageServices/components/BannerCompnent";
import MarriageServiceComponent from "./marriageServices/components/MarriageServiceComponent";
import ServiceInDubai from "./marriageServices/components/ServiceInDubai";
import VideoSec from "../../components/amirComponents/amirVideoSec/VideoSec";
import OneStopDestination from "./marriageServices/components/OneStopDestination";
import WhyChooseGoodHandMarriageServices from "./marriageServices/components/WhyChooseGoodHandMarriageServices";
import ServiceInDubaiSlider from "./marriageServices/components/ServiceInDubaiSlider";
import axios from "axios";
import { useEffect, useState } from "react";
import PreLoader from "../../components/preloader/PreLoader";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";
import {
  MarriageService,
  WhyChooseGoodhand,
} from "../../types/marriageSevices";
import { FaqDataProps, SubserviceComponent } from "../../types/alhadeed";
import { Section10, Section3, Section9 } from "../../types/department";

const apiUrl = import.meta.env.VITE_API_URL;

interface VideoSection {
  video: string; // URL to the video file
}
interface PersonalServiceData {
  imageBanner: {
    imageUrl: string;
  };
  Authorized?: Section3;
  FAQ: FaqDataProps;
  OneStopDestination?: {
    heading: string;
    subHeading: string;
    line: string;
    imageUrl: string;
    imageAlt: string;
  };
  ServiceComponent?: MarriageService;
  Whychoose1: Section9;
  Whychoose2: Section10;
  section9: Section9;
  seo?: SEOData;
  subserviceComponent: SubserviceComponent;
  videoSection?: VideoSection;
  whyChooseGoodhand?: WhyChooseGoodhand;
}

const Aladheed = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiData, setApiData] = useState();
  const [allService, setAllService] = useState<[]>([]);
  const [metaData, setMetaData] = useState<SEOData>();
  const [AlAhdeedData, setAlAhdeedData] = useState<PersonalServiceData>();

  const JSON_URL = `https://goodhand.b-cdn.net/Assets/Footer_pages/Ahdeed/Alhadeed.json`;
  const URL = `${apiUrl}/api/openRoute/footerLinks?url=dubai-courts-al-adheed`;

  const getDataFromJson = async () => {
    try {
      const response = await axios.get(JSON_URL); // Fetch data using Axios

      if (response) {
        // console.log("response.data.response.data", response.data);
        setMetaData(response?.data.seo);
        setAlAhdeedData(response.data); // Save metadata to state
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data with Axios:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);
      if (response) {
        setApiData(response.data.HomeData.employeeData);
        setAllService(response.data.HomeData?.Allservice);
        return true;
      }

      return false;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error(
            "Error 400: Bad request, redirecting to NotFound page."
          );
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false;
    }
  };

  useEffect(() => {
    import("./marriageServices/marriageServices.css");
    import("./aladheed.css");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!jsonDataSuccess && !apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}

          <BannerCompnent bannerImg={AlAhdeedData?.imageBanner.imageUrl} />

          <MarriageServiceComponent data={AlAhdeedData?.["ServiceComponent"]} />

          <ServiceInDubai data={AlAhdeedData?.["subserviceComponent"]} />

          <div className="bg-white">
            <ChannelPartaner section3={AlAhdeedData?.["Authorized"]} />
          </div>

          <Experts employee={apiData} />

          <VideoSec videoPath={AlAhdeedData?.videoSection?.video} />
          <section className="py-5">
            <ServiceInDubaiSlider data={allService} />
          </section>

          <OneStopDestination data={AlAhdeedData?.["OneStopDestination"]} />

          <WhyChooseGoodHandMarriageServices
            data={AlAhdeedData?.["whyChooseGoodhand"]}
          />

          <section className="faqWrap bg-2 types-of-visa">
            <Faq FaqData={AlAhdeedData?.["FAQ"]} />
          </section>

          <WhyChooseUs
            section9={AlAhdeedData?.["Whychoose1"]}
            section10={AlAhdeedData?.["Whychoose2"]}
          />
        </>
      )}
    </>
  );
};

export default Aladheed;
