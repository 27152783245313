import { useState, useEffect } from "react";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";

const CancellationPolicy = () => {
  const [metaData, setMetaData] = useState<SEOData | null>(null);
  useEffect(() => {
    const data = {
      "hidden":{
          "heading" : "",
          "description" : ""
      },
      "seo" : {
        "shortcut_icon": {
          "type": "image/x-icon",
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "apple_touch_icon": {
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "title": "Goodhand Cancellation & Refund Policy",
        "Keywords": "",
        "description": "Goodhand Cancellation & Refund Policy",
        "canonical": "https://www.goodhand.ae/CancellationPolicy",
        "meta": [
          {
            "name": "description",
            "content": "Goodhand Cancellation & Refund Policy"
          },
          {
            "name": "url",
            "content": "https://www.goodhand.ae/CancellationPolicy"
          },
          {
            "name": "og:title",
            "content": "Goodhand Cancellation & Refund Policy"
          },
          {
            "name": "og:type",
            "content": "website"
          },
          {
            "name": "og:url",
            "content": "https://www.goodhand.ae/CancellationPolicy"
          },
          {
            "name": "og:image",
            "content": "https://goodhand.ideaintl.net/assets/logo-main-gFDCiCbr.svg"
          },
          {
            "name": "og:site_name",
            "content": "https://www.goodhand.ae"
          },
          {
            "name": "og:description",
            "content": "Goodhand Cancellation & Refund Policy"
          },
          {
            "name": "twitter:card",
            "content": "summary_large_image"
          },
          {
            "name": "twitter:title",
            "content": "Goodhand Cancellation & Refund Policy"
          },
          {
            "name": "twitter:description",
            "content": "Goodhand Cancellation & Refund Policy"
          },
          {
            "name": "twitter:image",
            "content": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          {
            "name": "twitter:site",
            "content": "@GoodHandUAE"
          }
        ],
        scripts: [],
        noscript: []
      }
    };
    setMetaData(data?.seo);
  }, []);
  return (
    <>
      {metaData && <MetaHead metaData={metaData} />}
      {/* <section className="bg-2 py-5 terms-main border-bottom border-3">
        <div className="container py-5 px-5 bg-white rounded shadow-sm">
          <div className="terms-header p-3 text-white mb-3 rounded">
            <div className="hd-style-2 text-center">
              <h2>Refund Policy</h2>
              <p className="text-white">
                At Good Hand, we strive to provide exceptional services tailored
                to meet your needs. Our Refund Policy is designed to ensure
                clarity and fairness for all our clients. Please review the
                following guidelines:
              </p>
            </div>
          </div>
          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16 mb-3">
              Eligibility for Refunds:
            </h5>
            <p className="mb-3">
              • Refunds are available for services that have not been rendered
              or are unsatisfactory due to our fault. <br />• Requests must be
              made within 30 days of the service date.
            </p>
            <h5 className="ff-semibold font-16 mb-3">
              Non-Refundable Services:
            </h5>
            <p className="mb-3">
              • Services are non-refundable once initiated.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Request Process:</h5>
            <p className="mb-3">
              • To initiate a refund, please contact our customer service at
              <span className="ff-semibold"> (contact details)</span> with your{" "}
              <span className="ff-semibold">(name/ID No/OrderNumber)</span> and
              a brief explanation of your request. <br />• Refund requests must
              be submitted via email or through our website form.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Review Period:</h5>
            <p className="mb-3">
              • We will review your request within{" "}
              <span className="ff-semibold"> 7 business days </span>. If
              approved, refunds will be processed within{" "}
              <span className="ff-semibold">30 business </span>
              days using the original payment method.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Partial Refunds:</h5>
            <p className="mb-3">
              • In cases where services were partially rendered, a partial
              refund may be issued at our discretion.
            </p>
            <h5 className="ff-semibold font-16 mb-3">
              Changes and Cancellations:
            </h5>
            <p className="mb-3">
              • If you wish to cancel a service before it is rendered, please do
              so at least <span className="ff-semibold"> 24 hours </span> in
              advance for a full refund.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Dispute Resolution:</h5>
            <p className="mb-3">
              • If you have any concerns about our services that may not warrant
              a refund, we encourage you to reach out, and we will work to
              resolve the issue amicably.
            </p>
            <p className="mb-3">
              We appreciate your understanding and support. Your satisfaction is
              our priority. For any questions regarding our Refund Policy,
              please contact us at{" "}
              <span className="ff-semibold">(contact details)</span>. Thank you
              for choosing Good Hand!
            </p>
          </div>
        </div>
      </section> */}
      <section className="bg-2 py-5 terms-main border-bottom border-3">
        <div className="container py-5 px-5 bg-white rounded shadow-sm">
          <div
            className="terms-header p-3 text-white mb-3 rounded"
            style={{ background: "rgb(110, 66, 229)" }}
          >
            <div className="hd-style-2 text-center">
              <h2>Cancellation & Refund Policy</h2>
              <p className="text-white">
                At Good Hand, we strive to provide exceptional services tailored
                to meet your needs. Our Cancellation & Refund Policy is designed
                to ensure clarity and fairness for all our clients. Please
                review the following guidelines:
              </p>
            </div>
          </div>
          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16 mb-3">Cancellation Policy</h5>
            <p className="mb-3">
              Customers may cancel their request only during the review of their
              request with Good Hand, while showing status as <b>“Under Review”</b>.
              The cancellation request will not be entertained once the request
              is processed for submission <b>(accepted by Good Hand)</b>. Furthermore,
              if Good Hand processes the Customer’s request and submits it to
              the Respective Government Department, the Department/Authorities
              may accept, approve or reject the request besides may ask for more
              documents or clarifications. In all these cases, the Customer will
              have no right to ask for cancellation of request or refund of any
              amount. Good Hand reserves the right to assess a penalty for
              Customers that repeatedly cancel 3 or more times within the
              twenty-four (24) hour Cancellation Policy.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Refund Policy</h5>
            <h5 className="ff-semibold font-16 mb-3">
              Refund and Review Period:
            </h5>
            <p className="mb-3">
              The total refund amount will be calculated after the deduction of
              bank charges and service charges. We will review your request for
              a refund and if approved, you will receive the refund within 14
              business working days.
            </p>
            <h5 className="ff-semibold font-16 mb-3">Request Process:</h5>
            <p className="mb-3">
              To initiate a refund, the refund requests must be submitted via
              email or through our website form.
            </p>
            <p className="mb-3">
              For further inquiries, please contact our customer service at
              (insert details) with your{" "}
              <b>
                Order Number, Application Number and registered phone number on
                the website.
              </b>
            </p>

            <h5 className="ff-semibold font-16 mb-3">
              Right to Refuse, Suspend, or Terminate Service
            </h5>
            <p className="mb-3">
              We reserve the right to refuse, terminate, or suspend service to
              any Customer at our sole discretion and without prior notice.
              Generally, we will take such actions if we find that a Customer is
              non-compliant or ineligible for the requested service, provides
              inappropriate or obscene content, infringes on the rights of
              privacy or publicity, or violates the civil rights of others.
              Additionally, we may suspend, refuse, or terminate service for any
              other reason we deem suitable.
            </p>

            <h5 className="ff-semibold font-16 mb-3">Queries</h5>
            <p className="mb-3">
              If you have any concerns about our services that may not warrant a
              refund, we encourage you to reach out and we will work to resolve
              the issue amicably. We appreciate your understanding and support.
              Your satisfaction is our priority. Thank you for choosing Good
              Hand!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancellationPolicy;
