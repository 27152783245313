import { useState, useEffect } from "react";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";

const PrivacyAndPolicy = () => {
    const [metaData, setMetaData] = useState<SEOData | null>(null);
    useEffect(() => {
      const data = {
        "hidden":{
            "heading" : "",
            "description" : ""
        },
        "seo" : {
          "shortcut_icon": {
            "type": "image/x-icon",
            "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          "apple_touch_icon": {
            "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          "title": "Goodhand Privacy Policy",
          "Keywords": "",
          "description": "Goodhand Privacy Policy",
          "canonical": "https://www.goodhand.ae/privacyandpolicy",
          "meta": [
            {
              "name": "description",
              "content": "Goodhand Privacy Policy"
            },
            {
              "name": "url",
              "content": "https://www.goodhand.ae/privacyandpolicy"
            },
            {
              "name": "og:title",
              "content": "Goodhand Privacy Policy"
            },
            {
              "name": "og:type",
              "content": "website"
            },
            {
              "name": "og:url",
              "content": "https://www.goodhand.ae/privacyandpolicy"
            },
            {
              "name": "og:image",
              "content": "https://goodhand.ideaintl.net/assets/logo-main-gFDCiCbr.svg"
            },
            {
              "name": "og:site_name",
              "content": "https://www.goodhand.ae"
            },
            {
              "name": "og:description",
              "content": "Goodhand Privacy Policy"
            },
            {
              "name": "twitter:card",
              "content": "summary_large_image"
            },
            {
              "name": "twitter:title",
              "content": "Goodhand Privacy Policy"
            },
            {
              "name": "twitter:description",
              "content": "Goodhand Privacy Policy"
            },
            {
              "name": "twitter:image",
              "content": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
            },
            {
              "name": "twitter:site",
              "content": "@GoodHandUAE"
            }
          ],
          scripts: [],
          noscript: []
        }
      };
      setMetaData(data?.seo);
    }, []);

  return (
    <>
      {metaData && <MetaHead metaData={metaData} />}
      <section className="bg-2 py-5 terms-main border-bottom border-3">
        <div className="container py-5 px-5 bg-white rounded shadow-sm">
          <div
            className="terms-header p-3 text-white mb-3 rounded"
            style={{ background: "rgb(110, 66, 229)" }}
          >
            <div className="hd-style-2 text-center">
              <h2>Privacy Policy</h2>
            </div>
          </div>

          <div className="border-bottom  pt-2">
            <p className="mb-3">
              This Website collects some Personal Data from its Users.
            </p>
            <h5 className="ff-semibold font-16 mb-3">
              Owner and Data Controller
            </h5>
            <p className="mb-3">
              Good Hand, Ground Floor, Saraya Plaza, Al Muraqabat, Deira. PO
              Box: 81748, Dubai.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">1. Overview</h5>
            <p className="mb-3">
              The Privacy Policy outlined below shall be considered an essential
              component of our Terms and Conditions.
            </p>
            <p className="mb-3">
              At Good Hand, we are dedicated to protecting your privacy and
              ensuring a positive experience when you use our services. This
              Privacy Policy entails the type of data/ information collected,
              how we utilize them and your rights regarding your
              data/information.
            </p>
            <p className="mb-3">
              This Privacy Policy applies only to our online services and is
              applicable to all those who are registered users of our website
              with regard to the data/information that they shared and/ or
              collected through <b>goodhand.ae</b>. This policy, however, is not
              applicable to any data/information collected via channels other
              than this website.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">2. Consent</h5>
            <p className="mb-3">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its Terms.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">3. Data We Collect</h5>
            <p className="mb-3">
              We collect various types of data/information to provide and
              improve our services to you. The personal information that you are
              asked to provide will be made clear to you at the point we ask you
              to provide your personal information. If you contact us directly,
              we may receive additional information and you may send us any
              other information you may choose to provide. When you register for
              an account, we may ask for your contact information (eg: name,
              company name, address, email address, and telephone number etc.).
            </p>
            <p className="mb-3">
              Unless specified otherwise, all data requested by this website is
              mandatory and failure to provide this data may make it impossible
              for this website to provide its services. In cases where this
              website specifically states that some data is not mandatory, users
              are at their liberty to communicate this data without consequences
              to the availability or the functioning of the service.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">
              3.1 Methods of Processing the Data
            </h5>
            <p className="mb-3">
              We take appropriate security measures to prevent unauthorized
              access, disclosure, modification, or unauthorized destruction of
              the data. The data processing is carried out using computers
              and/or IT enabled tools, following organizational procedures and
              modes strictly related to the purposes indicated.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">3.2 Place</h5>
            <p className="mb-3">
              The data is processed at our operating offices and in any other
              place/s where the parties involved in the processing are located.
            </p>
          </div>

          <div className="border-bottom  pt-2">
            <h5 className="ff-semibold font-16 mb-3">
              4. Purpose of Data Processing
            </h5>
            <p className="mb-3">
              The data concerning to the User is collected for allowing Good
              Hand to provide its Services, comply with its legal obligations,
              protect its rights and interests (or those of its Users or Third
              Parties).
            </p>
          </div>
          <div className="border-bottom  pt-2">
            <h2 className="ff-semibold font-16 mb-3 pt-2">
              5. Further Information for Users
            </h2>
            <div className="container mt-4">
              <h6 className="font-weight-bold font-14 mt-3">
                5.1 Legal basis of processing
              </h6>
              <p className="font-14">
                Good Hand may process personal data relating to Users if one or
                more of the following apply:
              </p>
              <ul className="list-unstyled pl-4 font-14">
                <li>
                  Users have given their consent for one or more specific
                  purposes.
                </li>
                <li>
                  Provision of data is necessary for the performance of an
                  agreement with the User and/or for any pre-contractual
                  obligations thereof or the providing of a service/s.
                </li>
                <li>
                  Processing is necessary for compliance with a legal obligation
                  to which Good Hand is subject.
                </li>
              </ul>

              <h6 className="font-weight-bold font-14 mt-3 pt-3">
                5.2 Federal Law No. 45 of 2021 - UAE Personal Data Protection
                Law
              </h6>
              <p className="font-14">
                The <b>Federal Law No. 45 of 2021</b>, known as the{" "}
                <b>UAE Personal Data Protection Law</b> give individuals control
                over their personal data and outline the responsibilities of
                data handlers. UAE's Data Protection Law lay down rights which
                include the right to request for corrections of inaccurate
                personal data and to restrict or stop the processing of his
                personal data.
              </p>

              <h6 className="font-weight-bold font-14 mt-3">
                5.3 How to exercise these Rights
              </h6>
              <p className="font-14">
                Any requests to exercise user rights can be directed to Good
                Hand through the contact details provided in this website. Such
                requests are free of charge and will be answered by Good Hand as
                early as possible.
              </p>
            </div>
          </div>

          <div className="border-bottom  pt-2">
            <h2 className="font-weight-bold font-16 ">
              6. Additional information about Data collection and processing
            </h2>
            <div className="container mt-4">
              <h6 className="font-weight-bold font-14 mt-3">6.1 Legal action</h6>
              <p className="font-14">
                The User's personal data may be used for legal purposes by Good
                Hand in Court or in the stages leading to possible legal action
                arising from improper use of this website or related services.
                The user declares to be aware that Good Hand may be required to
                reveal personal data upon request of public authorities.
              </p>

              <h6 className="font-weight-bold font-14 mt-3">
                6.2 Additional information about User's Personal Data
              </h6>
              <p className="font-14">
                In addition to the information contained in this Privacy Policy,
                this website may provide the User with additional and contextual
                information concerning particular Services or the collection and
                processing of personal data upon request.
              </p>
            </div>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16 mb-3">
              7. Information not contained in this Policy
            </h5>
            <p className="mb-3">
              More details concerning the collection or processing of personal
              data may be requested from the owner at any time.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16 mb-3">
              8. Changes in Privacy Policy
            </h5>
            <p className="mb-3">
              We reserve the right to update this Privacy Policy from time to
              time. We encourage and advise you to review this Privacy Policy
              periodically.
            </p>
          </div>

          <div className="border-bottom pt-4 pb-4 contact-us">
            <h5 className="ff-semibold font-16 contact-title">9. Contact Us</h5>
            <p className="contact-content mb-3">
              If you have any questions/queries about this Privacy Policy or our
              practices, please contact us at:
            </p>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-2 contact-item">
                <i className="fas fa-phone me-2 contact-icon"></i>
                <span className="contact-info">+971 42 97 9997</span>
              </div>
              <div className="d-flex align-items-center contact-item mb-2">
                <i className="fab fa-whatsapp me-2 contact-icon"></i>
                <span className="contact-info">+971 42 97 9998</span>
              </div>
              <div className="d-flex align-items-center  contact-item">
                <i className="fas fa-envelope me-2 contact-icon"></i>
                <span className="contact-info">
                  <a href="mailto:info@goodhand.ae">info@goodhand.ae</a>
                </span>
              </div>
            </div>
          </div>
          <p>Last updated: 18th of November 2024 </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyAndPolicy;
