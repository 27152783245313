import { MarriageService } from "../../../../types/marriageSevices";

interface Props {
  data?: MarriageService;
}
const MarriageServiceComponent: React.FC<Props> = ({ data }) => {
  return (
    <>
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7 my-auto wow animate__zoomIn">
              <div className="hd-style-2 ">
                <h1 className="font-35 ff-bold"
                  dangerouslySetInnerHTML={{
                    __html: data?.heading ?? "",
                  }}
                ></h1>
              </div>

              <img
                src={data?.imageSrc}
                alt={data?.imageAlt}
                className="img-fluid  d-none d-md-block border border-0 mx-auto"
                height={400}
                width={400}
              />
            </div>

            <div className="col-md-5 my-auto mt-2 mt-md-4 wow animate__fadeInRight mrg-abt-card">
              <div className="p-md-4 p-3 bg-white shadow-sm m-md-3  mb-md-0 rounded-top">
                <p
                  className="font-21 ff-bold text-black"
                  dangerouslySetInnerHTML={{
                    __html: data?.pheading ?? "",
                  }}
                />
                <p
                  className="font-14"
                  dangerouslySetInnerHTML={{
                    __html: data?.paragraph ?? "",
                  }}
                />
              </div>
              <div className="p-4 bg-main m-md-3 m-0 mt-md-0 shadow-sm rounded-bottom">
                <img
                  src={data?.imageSrc1}
                  alt={data?.imageAlt1}
                  className="img-fluid  text-primary  d-none d-md-block mx-auto"
                  height={75}
                  width={75}
                />
                <p
                  className="font-16 ff-bold text-white text-center"
                  dangerouslySetInnerHTML={{
                    __html: data?.subHeading ?? "",
                  }}
                ></p>
                <div className="d-flex align-items-center gap-3 justify-content-center text-primary py-3 py-md-0">
                  <div className="text-center d-flex">
                    <a
                      href="tel:+97142979997"
                      // href="tel:{data?.tel}"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <button
                        type="button"
                        className="position-relative bg-transparent rounded text-white border font-14 py-2 bt--cart px-3 h-100"
                        style={{ width: "fit-content" }}
                      >
                        {data?.telNumber}
                      </button>
                    </a>
                  </div>
                  <div className="text-center d-flex">
                    <a
                      // href="https://web.whatsapp.com/send?phone=${data?.whatsAppNumber ?? 97142979998}"
                      href="https://web.whatsapp.com/send?phone=97142979998"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <button className="btn btn-primary bg-white text-primary font-14 ff-semibold bt--cart px-3 py-2 gap-2 h-100">
                        <i className="fab fa-whatsapp font-20"></i>Whatsapp
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarriageServiceComponent;
