import { useState, useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddAnotherAddress,
  openAddressFromMap,
} from "../../store/slices/modalSlice";
import { MapPin } from "lucide-react";
import AddressFromMap from "../selectAddressFromMapModel/AddressFromMap";
import GoogleMapReact from "google-map-react";
import { prediction, selectedPlace, suggestion } from "../../types/booking";
// Define types for the places returned from Autocomplete
const libraries: "places"[] = ["places"];



const googleMapsApiKey = import.meta.env.VITE_Google_Maps_Api_Key;

const AddAnotherAddressModel = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);
  const [center, setCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [zoom, setZoom] = useState(12);
  const [suggestions, setSuggestions] = useState<suggestion[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<selectedPlace | null>(
    null
  );
  const [error, setError] = useState(null);
  const [predictions, setPredictions] = useState<prediction[]>();
  const showAddAddress = useSelector(
    (state: { modal: { addAnotherAddress: boolean } }) =>
      state.modal.addAnotherAddress
  );

  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: "AIzaSyBhxRitKE353P3AcgffuOJ6TjRgSfYAs1M",
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });

  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    autocompleteServiceRef.current = new maps.places.AutocompleteService();
    mapRef.current = map;
    mapsRef.current = maps;

    const initialMarker = new maps.Marker({
      position: center,
      map,
      draggable: true,
      icon: {
        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
        size: new maps.Size(50, 50),
        scaledSize: new maps.Size(50, 50),
        anchor: new maps.Point(25, 50),
      },
    });

    // setMarker(initialMarker);
    console.log(initialMarker);

    // Update marker position on drag end
    // initialMarker.addListener("dragend", (event) => {
    //   const newLat = event.latLng.lat();
    //   const newLng = event.latLng.lng();
    //   reverseGeocode(newLat, newLng, maps);
    // });

    // Update marker position on map click
    // map.addListener("click", (event) => {
    //   const lat = event.latLng.lat();
    //   const lng = event.latLng.lng();
    //   reverseGeocode(lat, lng, maps);
    // });
  };

  const cleanAddress = (address: string | undefined) => {
    const parts = address?.split(" - ");
    return parts
      ?.filter((part) => !/^[A-Za-z0-9+]{2,}$/.test(part))
      .join(" - ");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    autocompleteServiceRef.current =
      new google.maps.places.AutocompleteService();
    if (query && autocompleteServiceRef.current) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: query },
        (predictions, status) => {
          console.log("suggestions :: handleInputChange", predictions);
          if (status === "OK" && predictions) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (placeId: string) => {
    if (!mapRef.current || !mapsRef.current) return;
    const placesService = new mapsRef.current.places.PlacesService(
      mapRef.current
    );
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === "OK" && place?.geometry?.location) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setCenter(newCenter);
        setSelectedPlace({
          name: place?.name,
          address: cleanAddress(place.formatted_address),
          coordinates: {
            lat: newCenter.lat,
            lng: newCenter.lng,
          },
        });

        // setSuggestions([]);

        const theSelectedPlace = {
          name: place.name,
          address: cleanAddress(place.formatted_address),
          coordinates: newCenter,
        };

        const theSuggestions = {
          name: place.name,
          address: cleanAddress(place.formatted_address),
          coordinates: newCenter,
          place_id: placeId,
        };

        const suggestion = sessionStorage.getItem("suggestions");
        if (suggestion) {
          const newSuggestion = JSON.parse(suggestion);

          const isPresent = newSuggestion.some(
            (item: prediction) => item.place_id === theSuggestions.place_id
          );
          if (!isPresent) {
            newSuggestion.unshift(theSuggestions);
            // console.log("suggestions if", newSuggestion);

            if (newSuggestion.length > 8) {
              newSuggestion.pop(); // Remove the oldest suggestion
            }

            sessionStorage.setItem(
              "suggestions",
              JSON.stringify(newSuggestion)
            );
          }
        } else {
          sessionStorage.setItem(
            "suggestions",
            JSON.stringify([theSuggestions])
          );
        }

        sessionStorage.setItem(
          "selectedPlace",
          JSON.stringify(theSelectedPlace)
        );

        dispatch(closeAddAnotherAddress());
        dispatch(openAddressFromMap());
      }
    });
  };

  const useCurrentLocation = () => {
    console.log("useCurrentLocation :: geolocation");

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          setError(null); // Clear previous errors

          // Perform reverse geocoding to get the city name and address
          // const geocoder = new mapsRef.current.Geocoder();

          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
          fetch(url)
            .then((res) => res.json())
            .then((data) => {
              // setAdd(data.address)

              const newCenter = {
                lat: latitude,
                lng: longitude,
              };

              setSelectedPlace({
                name: data.address.residential ?? data.town,
                address: data.display_name,
                coordinates: {
                  lat: newCenter.lat,
                  lng: newCenter.lng,
                },
              });

              const theSelectedPlace = {
                name: data.address.residential ?? data.town,
                // address: cleanAddress(data.formatted_address),
                address: data.display_name,
                coordinates: newCenter,
              };

              sessionStorage.setItem(
                "selectedPlace",
                JSON.stringify(theSelectedPlace)
              );

              dispatch(closeAddAnotherAddress());
              dispatch(openAddressFromMap());
            });
        },
        (error) => {
          console.error("Error fetching location:", error);
          // setError("Unable to fetch location. Please check your permissions.");
        }
      );
    } else {
      // setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    const suggestion = sessionStorage.getItem("suggestions");
    if (suggestion) {
      const suggestionParse = JSON.parse(suggestion);
      setPredictions(suggestionParse);
    }
  }, []);

  if (loadError) {
    return <div className="text-red-500">Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-screen">
        <MapPin className="w-8 h-8 text-blue-500 animate-bounce" />
      </div>
    );
  }

  const closeAddAddress = () => {
    sessionStorage.removeItem("mode");
    localStorage.removeItem("filteredData");
    dispatch(closeAddAnotherAddress());
  };

  return (
    <>
      {isLoaded ? (
        <Modal
          show={showAddAddress}
          onHide={closeAddAddress}
          centered
          className="mb-fix slide-from-bottom"
        >
          <Modal.Header closeButton />
          <Modal.Body className="rounded">
            <div className="">
              <div className="d-flex align-items-center border col-12 rounded ">
                <div className="search-icon bg-transparent ms-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="#545454"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.875 2a8.875 8.875 0 105.528 15.818l3.89 3.89 1.414-1.415-3.89-3.889A8.875 8.875 0 0010.875 2zM4 10.875a6.875 6.875 0 1113.75 0 6.875 6.875 0 01-13.75 0z"
                      fill="#545454"
                    ></path>
                  </svg>
                </div>
                <input
                  ref={inputRef}
                  placeholder="Search for a place"
                  className="form-control font-13 border-0 ps-1"
                  onChange={handleInputChange}
                />
              </div>

              {selectedPlace && <AddressFromMap />}

              <>
                <div
                  className="d-flex flex-wrap align-items-center gap-3 py-3"
                  onClick={useCurrentLocation}
                >
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="#6E42E5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.063 3.504V5h1.874V3.504a6.567 6.567 0 015.559 5.559H15v1.874h1.496a6.567 6.567 0 01-5.558 5.559V15H9.062v1.496a6.567 6.567 0 01-5.558-5.558H5V9.062H3.504a6.567 6.567 0 015.559-5.558zm0-1.89a8.44 8.44 0 00-7.449 7.449H0v1.874h1.614a8.44 8.44 0 007.449 7.449V20h1.874v-1.614a8.44 8.44 0 007.449-7.448H20V9.062h-1.614a8.44 8.44 0 00-7.448-7.448V0H9.062v1.614zM10 8.438a1.563 1.563 0 100 3.125 1.563 1.563 0 000-3.126zM6.562 10a3.437 3.437 0 116.875 0 3.437 3.437 0 01-6.874 0z"
                        fill="#6E42E5"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p className="mb-0 text-new ff-semibold font-13 cursor-pointer">
                      Use current location
                    </p>
                  </div>
                  {error && (
                    <p style={{ color: "red", marginBottom: "10px" }}>
                      {error}
                    </p>
                  )}
                </div>
              </>

              {suggestions.length > 0 ? (
                <>
                  {suggestions &&
                    suggestions?.map((item) => (
                      <div
                        className="location-container d-flex align-items-center gap-4 address-main border-bottom py-3"
                        style={{ cursor: "pointer" }}
                        key={item?.place_id}
                        onClick={() => handleSuggestionClick(item?.place_id)}
                      >
                        <div className="icon-wrapper  bg-light rounded ">
                          <div
                            aria-disabled="true"
                            role="img"
                            className="icon-center"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="#545454"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.426 22.569L12 21.75l.573.82a1 1 0 01-1.147-.001z"
                                fill="#545454"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 5.75a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                                fill="#545454"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.426 22.569L12 21.75c.573.82.575.818.575.818l.002-.001.006-.004.02-.015.07-.05.257-.192a25.395 25.395 0 003.575-3.368c1.932-2.223 3.995-5.453 3.995-9.188a8.5 8.5 0 10-17 0c0 3.735 2.063 6.965 3.995 9.187a25.4 25.4 0 003.575 3.369 14.361 14.361 0 00.327.242l.02.015.006.004.003.002zM7.404 5.154A6.5 6.5 0 0118.5 9.75c0 3.015-1.687 5.785-3.505 7.875A23.403 23.403 0 0112 20.495a23.4 23.4 0 01-2.995-2.869C7.187 15.534 5.5 12.764 5.5 9.75a6.5 6.5 0 011.904-4.596z"
                                fill="#545454"
                              ></path>
                            </svg>
                          </div>
                        </div>

                        <div className="d-flex flex-column align-items-start ">
                          <p className="ff-semibold mb-0 location-title font-12 text-black">
                            {item?.description}
                          </p>
                          {/* <p className="text-black location-subtitle font-12">
                            {item?.address}
                          </p> */}
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {predictions && predictions?.length > 0 ? (
                    <>
                      {predictions?.map((prediction) => (
                        <div className="location-container d-flex align-items-center gap-4 address-main border-bottom py-3"
                          key={prediction?.place_id}
                        >
                          <div className="icon-wrapper bg-light rounded p-3">
                            <div
                              aria-disabled="true"
                              role="img"
                              className="icon-center"
                            >
                              {/* Font Awesome Icon */}
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="#545454"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.195 3.178A9.25 9.25 0 115.46 18.79l1.414-1.415a7.25 7.25 0 100-10.253L5.247 8.75H8v2H3a1 1 0 01-1-1v-5h2v2.419l1.46-1.46a9.25 9.25 0 014.735-2.531z"
                                  fill="#545454"
                                ></path>
                                <path
                                  d="M11 12.5V7h2v4.908l3.584 1.965-.962 1.754-4.103-2.25A1 1 0 0111 12.5z"
                                  fill="#545454"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <div
                            className="d-flex flex-column align-items-start "
                            onClick={() =>
                              handleSuggestionClick(prediction.place_id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <p className="ff-semibold mb-0 location-title font-12 text-black">
                              {prediction?.address}
                            </p>
                            {/* <p className="text-black location-subtitle font-12">
                          // {item?.address}
                        // </p> */}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="error-location text-center">
                        <svg
                          width="128"
                          height="96"
                          viewBox="0 0 72 72"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 20h20v44H10V20z" fill="#FFD47F"></path>
                          <path
                            d="M22 8h20L30 20H10L22 8z"
                            fill="#9E4D10"
                          ></path>
                          <path
                            d="M30 20L42 8l12 12v44H30V20z"
                            fill="#FFE0A3"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 28h-4v10h4V28zm0 14h-4v10h4V42zm4-14h4v10h-4V28zm4 14h-4v10h4V42z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M54.411 28.006a13.998 13.998 0 00-8.222 2.39 14.047 14.047 0 00-5.302 6.738 14.081 14.081 0 00-.405 8.57c.76 2.83 2.384 5.35 4.644 7.21a30.672 30.672 0 016.19 6.622 23.79 23.79 0 012.182 4.141.543.543 0 00.79.235.542.542 0 00.2-.235 23.156 23.156 0 012.244-4.079 29.861 29.861 0 016.082-6.576 13.954 13.954 0 003.873-4.951 13.985 13.985 0 001.311-6.154 14.625 14.625 0 00-4.145-9.554 14.563 14.563 0 00-9.442-4.357z"
                            fill="#DC2804"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M54 40.586l-4.293-4.293-1.414 1.414L52.586 42l-4.294 4.294 1.415 1.414L54 43.414l4.293 4.293 1.414-1.414L55.414 42l4.293-4.293-1.414-1.414L54 40.586z"
                            fill="#fff"
                          ></path>
                        </svg>

                        <p className="my-3 font-16 ff-semibold">
                          Location could not be fetched
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}

              <GoogleMapReact
                bootstrapURLKeys={{
                  key: googleMapsApiKey,
                  libraries: ["places"],
                }}
                // disabled
                defaultCenter={center}
                defaultZoom={zoom}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
                onChange={({ center, zoom }) => {
                  setCenter(center);
                  setZoom(zoom);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default AddAnotherAddressModel;
