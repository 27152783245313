import "./notaryservices.css";
import Faq from "../../components/amirComponents/amirFaq/Faq";
import WhyChooseUs from "../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";
import axios from "axios";
import { useEffect, useState } from "react";
import { SEOData } from "../../types/home";
import { HomeData, Allservice } from "../../types/typeofvisa";
import PreLoader from "../../components/preloader/PreLoader";
import MetaHead from "../../components/metaHead/MetaHead";
import ServiceInDubaiSlider from "../alAdheed/marriageServices/components/ServiceInDubaiSlider";
import VideoSec from "../../components/amirComponents/amirVideoSec/VideoSec";
import About from "../../components/notaryComponents/About";
import AllSubService from "../../components/notaryComponents/AllSubService";
import Expertise from "../../components/notaryComponents/Expertise";
import WhoWeAre from "../../components/notaryComponents/WhoWeAre";
import Transformation from "../../components/notaryComponents/Transformation";
import { NotaryData } from "../../types/notary";

const apiUrl = import.meta.env.VITE_API_URL;

const Notaryservices = () => {
  const URL = `${apiUrl}/api/openRoute/footerLinks?url=notary-services-dubai`;
  
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [metaData, setMetaData] = useState<SEOData>();
  const [data, setData] = useState<NotaryData>();
  const [departmentData, setDepartmentData] = useState<HomeData>();
  const [allService, setAllService] = useState<Allservice[]>([]);
  
  const getDataFromJson = async (jsonUrl: string) => {
    try {
      const response = await fetch(jsonUrl);
      const data = await response.json();

      if (data) {
        setMetaData(data.seo);
        setData(data);
        return true; // Indicate success
      }

      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data:", error);
      return false; // Indicate failure
    }
  };
  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);

      if (response && response.data) {
        setDepartmentData(response?.data.HomeData);
        setAllService(response?.data.HomeData.Allservice);
        const jsonSuccess = await getDataFromJson(
          response.data.HomeData.JSON_URl
        );
        return jsonSuccess;
      }

      return false; // Return false if data is not available
    } catch (error) {
      return false; // Indicate overall failure if both API calls fail
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch data from the main API
        const apiDataSuccess = await getDataFromApi();

        if (!apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);  

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
    {isLoading ? (
      <>
        <PreLoader />
      </>
    ) : (
      <>
      {metaData && <MetaHead metaData={metaData} />}
      <About section2={data?.section2} />
      {data?.section1 && departmentData?.serviceSubData &&  (
        <AllSubService section1={data?.section1} serviceSubData={departmentData} />

      )}
      {data?.section5 &&  (
        <Expertise section5={data?.section5} />
      )}
      {data?.section3 &&  (
        <WhoWeAre section3={data?.section3} />
      )}
      
      {data?.section6.video && (
        <VideoSec videoPath={data?.section6.video} />
      )}

      {data?.section4 && (
        <Transformation section4={data?.section4} />
      )}

      <section className="faqWrap bg-2  types-of-visa">
        <Faq FaqData={data?.section8} />
      </section>
      <WhyChooseUs section9={data?.section9} section10={data?.section10} />

      {allService && allService.length > 0 && (
        <ServiceInDubaiSlider data={allService} />
      )}
    </>
    )};
  </>
  );
};

export default Notaryservices;