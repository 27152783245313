
// import { Counter } from './Counter';

// const ButtonWithLineAnimation = () => {
//   return (  
//     <Counter />
//   );
// }

// export default ButtonWithLineAnimation

import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCartData } from "../../store/slices/separateCartDataSlice";
import SideCard from "../../components/thirdsideCard/SideCard";
import PdfIco from "../../assets/pdf-ico.png";

// import loader from "../../assets/logo animation.gif";
import { useData } from "../../components/toast/Api";
import PreLoader from "../../components/preloader/PreLoader";
import BackButton from "../../components/backButton/Backbutton";
import axios from "axios";
import { logoutFun } from "../../config/auth";

interface SavedData {
  RecordId: number;
  isDataSave: boolean;
}

// type UserDataType = {
//   refreshToken: string;
//   token: string;
//   userEmail: string;
//   userFullName: string;
//   userName: string;
//   userNumber: string;
// };
interface UploadedFile {
  id: number;
  // productName: string;
  file: File;
  previewUrl?: string;
}
interface CartItem {
  productId: number;
  quantity: number;
  productName: string;
  productPrice: number;
  priceType: string;
}

interface OrderDetails {
  RecordId: number;
  applicationNumber: string;
  createdon: string;
  formDescription: string;
  modifiedon: string | null;
  orderNumber: string;
  serivceid: number;
  serviceStatus: number;
  serviceAmount: number;
  serviceName: string;
  serviceType: string;
  userName: string;
}

interface Dataab {
  InvoiceRef: string | null;
  NoofApplications: number;
  RecordId: number;
  billingAddress: number;
  createdon: string;
  modifiedon: string | null;
  orderNumber: string;
  orderStatus: number;
  ordertotal: number;
  paymentMode: string | null;
  paymenton: string | null;
  paymentstatus: number;
  pickupAddress: number;
  userName: string;
}

const Pre: React.FC = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();
  const baseUrl = import.meta.env.VITE_API_URL;
  const navigate = useNavigate();

  // const [localUserName, setlocalUserName] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [date, setDate] = useState("");
  const [dataab, setDataab] = useState<Dataab>();
  const [applicationNo, setApplicationNo] = useState<number>();
  const [num, setNum] = useState<SavedData[]>([]);
  const [cartData, setCartData] = useState<OrderDetails[]>([]);

  const [activeAccordion, setActiveAccordion] = useState(0);

  const [fileCount, setFileCount] = useState<{ [key: number]: number }>({});
  const [totalPrice, setTotalPrice] = useState(0);

  // const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [condition, setCondition] = useState(false);
  const [packageName, setPackageName] = useState();
  const [dataRecordId, setDataRecordId] = useState<number>(202024);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const dispatch = useDispatch();
  const cartItems = useSelector(
    (store: { cart: { items: CartItem[] } }) => store.cart.items
  );

  const [applicantDetails, setApplicantDetails] = useState(
    [] as {
      applicantName: string;
      applicantPassportNumber: string;
      sponsorName: string;
      sponsorPassportNumber: string;
    }[]
  );

  const [errors, setErrors] = useState<{
    applicantName?: string;
    applicantPassportNumber?: string;
    sponsorName?: string;
    sponsorPassportNumber?: string;
    files?: string;
  }>({});

  const validateForm = (RecordId: number) => {
    const newErrors: {
      applicantName?: string;
      applicantPassportNumber?: string;
      sponsorName?: string;
      sponsorPassportNumber?: string;
      files?: string;
    } = {};

    // Regex for applicantName: Only allows letters and spaces
    const nameRegex = /^[A-Za-z\s]+$/;

    // Regex for applicantPassportNumber: Allows numbers and alphanumeric characters
    const passportNumberRegex = /^[A-Za-z0-9]+$/;
    console.log("checkout ::: 1");
    // Check if applicantName is provided and matches regex
    // if (applicantDetails[RecordId]?.applicantName === "") {
    //   console.log("checkout ::: 2");
    //   showError("Applicant name is required");
    // }
    // else if (applicantDetails[RecordId]?.applicantPassportNumber !== "") {
    //   showError("Applicant name is required");
    // } else if (applicantDetails[RecordId]?.sponsorName !== "") {
    //   showError("Applicant name is required");
    // } else if (applicantDetails[RecordId]?.sponsorPassportNumber !== "") {
    //   showError("Applicant name is required");
    // }

    const applicant = applicantDetails[RecordId];
    if (!applicant) {
      // newErrors.applicantName = "Applicant details not found";
      // setErrors(newErrors);
      return false;
    }

    if (
      !applicantDetails[RecordId]?.applicantName ||
      applicantDetails[RecordId].applicantName.trim() === ""
    ) {
      newErrors.applicantName = "Applicant name is required";
    } else if (!nameRegex.test(applicantDetails[RecordId].applicantName)) {
      newErrors.applicantName =
        "Applicant name must contain only letters and spaces";
    }

    // Check if applicantPassportNumber is provided and matches regex
    if (
      !applicantDetails[RecordId].applicantPassportNumber ||
      applicantDetails[RecordId].applicantPassportNumber.trim() === ""
    ) {
      newErrors.applicantPassportNumber =
        "Applicant passport number is required";
    } else if (
      !passportNumberRegex.test(
        applicantDetails[RecordId].applicantPassportNumber
      )
    ) {
      newErrors.applicantPassportNumber =
        "Applicant passport number must be alphanumeric";
    }

    // Check if sponsorName is provided
    if (
      !applicantDetails[RecordId].sponsorName ||
      applicantDetails[RecordId].sponsorName.trim() === ""
    ) {
      newErrors.sponsorName = "Sponsor name is required";
    } else if (!nameRegex.test(applicantDetails[RecordId].sponsorName)) {
      newErrors.sponsorName =
        "Applicant name must contain only letters and spaces";
    }

    // Check if sponsorPassportNumber is provided and matches regex
    if (
      !applicantDetails[RecordId].sponsorPassportNumber ||
      applicantDetails[RecordId].sponsorPassportNumber.trim() === ""
      // ||
      // applicantDetails[RecordId]?.sponsorPassportNumber !== ""
    ) {
      newErrors.sponsorPassportNumber = "Sponsor passport number is required";
    } else if (
      !passportNumberRegex.test(
        applicantDetails[RecordId].sponsorPassportNumber
      )
    ) {
      newErrors.sponsorPassportNumber =
        "Sponsor passport number must be alphanumeric";
    }

    const filteredNames = uploadedFiles.filter((item) => item.id === RecordId);

    // // Check if at least one file is uploaded
    if (!filteredNames) {
      newErrors.files = "At least one file must be uploaded";
    }

    // Set errors and return validation result
    setErrors(newErrors);

    if (filteredNames.length === 0) {
      return false;
    }

    if (!applicantDetails[RecordId]) {
      return false;
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    setApplicantDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [field]: value,
      };
      return updatedDetails;
    });
  };

  function extractDate(dateTimeString: string) {
    // Split the string by the 'T' to separate date and time
    const [datePart] = dateTimeString.split("T");

    // Return only the date part
    return datePart;
  }

  useEffect(() => {
    if (cartData.length > 0 && dataab) {
      const extractedDate = extractDate(dataab.createdon);
      setDate(extractedDate);
      setOrderNumber(dataab.orderNumber);
      setApplicationNo(dataab.NoofApplications);
      setTotalPrice(dataab.ordertotal);
    }
  }, [dataab]);

  useEffect(() => {
    const getUserNameFromSession = sessionStorage.getItem("userName");
    if (getUserNameFromSession) {
      // setlocalUserName(getUserNameFromSession);
    }
  }, []);

  const fetchOrderData = async () => {
    const userNameSS = sessionStorage.getItem("userName");
    const token = sessionStorage.getItem("tk");
    const getCount = localStorage.getItem("count");
    const login_info = localStorage.getItem("Login_info");

    if (getCount) {
      setNum(JSON.parse(getCount));
    }
    if (login_info) {
      const login_infoo = JSON.parse(login_info);
      try {
        setIsLoading(true);
        setIsError(false);

        const postData = {
          userName: login_infoo.userName || userNameSS,
        };

        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const response = await axios.post(
          `${baseUrl}/api/user/formList`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data;

        if (data.isPackage === true) {
          setDataRecordId(202020);
        }
        // setCondition(data.isPackage);
        setCondition(false);
        setPackageName(data.packageName);
        setCartData(data.items);
        // data?.formDescription 
        console.log("data.items.formDescription ",data.items[0].formDescription)
        setDataab(data.order);

        if (data) {
          setIsLoading(false);
        }

        const initialDetails = data.items.map(() => ({
          applicantName: "",
          applicantPassportNumber: "",
          sponsorName: "",
          sponsorPassportNumber: "",
        }));
        setApplicantDetails(initialDetails);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            // If token is expired or invalid
            if (
              error.response.status === 401 ||
              error.response.status === 407
            ) {
              console.error("Token expired. Logging out...");
              // logout(); // Call the logout function to handle session end
            }
          } else if (error.code === "ERR_NETWORK") {
            console.error("Network error:", error);
            logoutFun(dispatch, navigate);
          }
        } else {
          console.error("Error fetching order data:", error);
          setIsError(true);
        }
      }
    } else {
      showError("Something went wrong");
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const handleFiles = (
    files: FileList,
    productId: number
    // productName: string
    // priceType: string
  ) => {
    const validFiles: UploadedFile[] = [];

    Array.from(files).forEach((file) => {
      if (
        file.type === "application/pdf" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        const fileObject: UploadedFile = {
          // id: `${file.name}-${Date.now()}`, // Unique ID for each file
          id: productId,
          // productName: productName,
          file: file,
          previewUrl: file.type.startsWith("image/")
            ? URL.createObjectURL(file)
            : undefined, // Generate preview URL if image
        };
        validFiles.push(fileObject);
        setFileCount((prev) => ({
          ...prev,
          [productId]: (prev[productId] || 0) + 1,
        }));
      } else {
        showError(
          `${file.name} is not a valid file type. Only .pdf, .png and .jpg files are allowed.`
        );
      }
    });
    // setUploadStatus(true)
    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDeleteFile = (fileToDelete: UploadedFile) => {
    // Remove the file from the uploaded files list
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.file !== fileToDelete.file)
    );
  };

  useEffect(() => {
    function separateProducts(products: CartItem[]) {
      products.forEach((product) => {
        for (let i = 1; i <= product.quantity; i++) {
          dispatch(
            addToCartData({
              productId: `${product.productId}_${i}`,
              quantity: 1,
              productName: product.productName,
              productPrice: product.productPrice,
              priceType: product.priceType,
            })
          );
        }
      });
    }

    separateProducts(cartItems);
  }, []);

  const handleSubmit = async () => {
    localStorage.removeItem("count");
    localStorage.removeItem("cartDataLocal");
    showSuccess("Your document has been successfully submitted!");
    showInfo("Our team will review it and respond shortly.");
    navigate("/payment");
  };

  // const emptyAllFields = () => {
  // console.log("emptyAllFields");
  // setApplicantName("");
  // setApplicantPassportNumber("");
  // setSponsorName("");
  // setSponsorPassportNumber("");
  // };

  const handleNext = (index: number) => {
    setActiveAccordion(index + 1);
  };

  const handlePrevious = (index: number) => {
    setActiveAccordion(index - 1); // Update to next accordion item
  };
  const addUniqueData = (RecordId: number) => {
    // Step 1: Check if the RecordId is already in num
    const isAlreadyInNum = num.some((item) => item.RecordId === RecordId);

    // Step 2: If not, add the new data to num
    if (!isAlreadyInNum) {
      const newData = { RecordId: RecordId, isDataSave: true };
      // setNum((prevNum) => [...prevNum, newData]);
      setNum((prevNum) => {
        const updatedNum = [...prevNum, newData];

        // Save updatedNum to local storage
        localStorage.setItem("count", JSON.stringify(updatedNum));

        return updatedNum;
      });
    }
  };

  const handleUploadData = async (
    index: number,
    RecordId: number,
    // userName: string,
    orderNumber: string,
    appNumber: string
  ) => {
    if (validateForm(RecordId)) {
      const details = applicantDetails[RecordId];
      const token = sessionStorage.getItem("tk");
      const filteredNames = uploadedFiles.filter(
        (item) => item.id === RecordId
      );

      const formData = new FormData();
      formData.append("orderNumber", orderNumber);
      formData.append("applicantName", details.applicantName);
      formData.append("applicantPassport", details.applicantPassportNumber);
      formData.append("sponsorName", details.sponsorName);
      formData.append("sponsorPassport", details.sponsorPassportNumber);
      formData.append("applicationNumber", appNumber);

      if (filteredNames.length > 0 && filteredNames) {
        filteredNames.forEach((item) => {
          formData.append(`files`, item.file); // Append each file
        });
      }

      const login_info = localStorage.getItem("Login_info");

      if (login_info) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await fetch(`${baseUrl}/api/user/applicationFill`, {
            method: "POST",
            headers: {
              //   "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: formData, // Use FormData object as the body
            // credentials: "include",
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }

          const responseData = await response.json();

          // emptyAllFields();
          // addUniqueData(RecordId);
          // const lastIndex = cartData.length - 1;
          if (responseData) {
            addUniqueData(RecordId);
            const lastIndex = cartData.length - 1;

            if (lastIndex === index) {
              handleSubmit();
            } else {
              handleNext(index);
            }
          }
          // handleNextClick(RecordId)
        } catch (error) {
          console.error("Response ::: Error sending form data:", error);
        }
      }
    } else {
      showInfo("fill all fields");
    }
  };

  const handleSelect = (index: number) => {
    setActiveAccordion(index);
  };

  const handleFooterData = async (RecordId: number) => {
    if (validateForm(RecordId)) {
      const details = applicantDetails[RecordId];
      const token = sessionStorage.getItem("tk");
      const filteredNames = uploadedFiles.filter(
        (item) => item.id === RecordId
      );

      if (filteredNames.length === 0) {
        console.warn("No files found for this RecordId");
      }

      if (!baseUrl) {
        console.error("Base URL is not defined in the environment variables");
        return;
      }

      for (let i = 0; i < cartData.length; i++) {
        const data = cartData[i];
        const formData = new FormData();
        formData.append("orderNumber", data.orderNumber);
        formData.append("applicantName", details.applicantName);
        formData.append("applicantPassport", details.applicantPassportNumber);
        formData.append("sponsorName", details.sponsorName);
        formData.append("sponsorPassport", details.sponsorPassportNumber);
        formData.append("applicationNumber", data.applicationNumber);

        filteredNames.forEach((item) => {
          formData.append("files", item.file); // Append each file
        });

        try {
          const response = await fetch(`${baseUrl}/api/user/applicationFill`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }

          const responseData = await response.json();

          // Call handleSubmit() if this is the last item in cartData
          if (responseData) {
            if (i === cartData.length - 1) {
              handleSubmit();
            }
          }
        } catch (error) {
          console.error("Error sending form data:", error);
        }
      }
    } else {
      showInfo("fill all fields");
    }
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <BackButton />
          {!condition ? (
            <section className="checkoutWrap py-2 py-md-5 ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 bg-2 p-3">
                    <Accordion
                      activeKey={activeAccordion.toString()}
                      // defaultActiveKey="10"
                      className="accordion acco-style-2"
                      id="add-details-aco"
                    >
                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div className="hd-style-3">
                          Order No: <span>{orderNumber}</span>
                        </div>
                        <div>
                          <p className="text-black font-16 fw-bold mb-0">
                            Date:{" "}
                            <span>
                              {new Date(date).toLocaleString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div>
                          <p className="text-black font-16 fw-bold ">
                            No of Application: <span>{applicationNo}</span>
                          </p>
                        </div>

                        <div>
                          <p className="text-black font-16 fw-bold ">
                            Order Total:
                            <span> {totalPrice?.toLocaleString()} AED</span>
                          </p>
                        </div>
                      </div>
                      <hr className="border border-1 opacity-100 my-1" />
                      {cartData.map((data, index) => (
                        <Accordion.Item
                          className="accordion-item my-3 border-0"
                          // eventKey={"1" + data.RecordId}
                          eventKey={index.toString()}
                          // eventKey="0"
                          // onClick={() => setActiveAccordion(index)}
                          key={data.RecordId}
                        >
                          <Accordion.Header
                            onClick={() => handleSelect(index)}
                            // onClick={() => toggleAccordion(data.RecordId)}
                            // id={`heading${index}`}
                            // data-bs-target={`#${"1" + data.RecordId}`}
                            // data-bs-toggle="collapse"
                            // aria-expanded="true"
                            // aria-expanded={activeAccordion === data.RecordId}
                            // aria-controls={"1" + data.RecordId}
                            style={{
                              fontWeight: "bolder",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div className="ff-bold font-16 me-4">
                              {index + 1}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <div style={{ display: "flex", gap: "5px" }}>
                                {data.serviceName}
                                {/* {"1" + data.RecordId}x */}
                                {data?.serviceType && (
                                  <span className="span">
                                    {/* ({data?.serviceType}) */}
                                  </span>
                                )}
                              </div>
                              {!condition && (
                                <>
                                  <div>
                                    <p className="font-14  text-dark float-start text-primary mb-1">
                                      Application Amount:
                                      <span className="mx-2 fw-bold">
                                        {data?.serviceAmount?.toLocaleString()}{" "}
                                        AED
                                      </span>
                                    </p>
                                    <p className="font-14  text-dark float-end text-primary mb-1">
                                      Application Number:
                                      <span className="mx-2 fw-bold">
                                        {data.applicationNumber}
                                      </span>
                                    </p>
                                  </div>

                                  <div>
                                    <p className="font-14  text-dark float-start text-primary mb-1">
                                      Applicant Name:
                                      <span className="mx-2 fw-bold">
                                        {
                                          applicantDetails[data.RecordId]
                                            ?.applicantName
                                        }
                                      </span>
                                    </p>
                                    <p className="font-14  text-dark float-end text-primary ">
                                      Passport Number:
                                      <span className="mx-2 fw-bold">
                                        {
                                          applicantDetails[data.RecordId]
                                            ?.applicantPassportNumber
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* {!condition && (
                              <div
                                className="dlt-ico text-center"
                                style={{ marginLeft: "5px" }}
                                onClick={() =>
                                  handleDelete(data.applicationNumber)
                                }
                              >
                                <i className="fas fa-trash"></i>
                              </div>
                            )} */}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-body">
                              <div className="row">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data?.formDescription ?? " ",
                                  }}
                                />
                              </div>

                              <form className="form-main bg-2 p-4 border-radius-10">
                                <div className="row">
                                  <div className="sub-hd1 col-12 text-decoration-underline">
                                    <p className="font-21">
                                      Applicant’s Details
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      {/* <input
                                  type="text"
                                  className="form-control"
                                  id="emailPhone"
                                  placeholder="Applicant name"
                                  value={applicantName}
                                  onChange={(e) =>
                                    setApplicantName(e.target.value)
                                  }
                                /> */}
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`applicantName-${data.RecordId}`}
                                        placeholder="Applicant name"
                                        value={
                                          applicantDetails[data.RecordId]
                                            ?.applicantName || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            data.RecordId,
                                            "applicantName",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {errors.applicantName && (
                                        <p className="text-danger">
                                          {errors.applicantName}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="emailPhone"
                                        className="form-label"
                                      >
                                        Passport No
                                      </label>
                                      {/* <input
                                  type="text"
                                  className="form-control"
                                  id="emailPhone"
                                  placeholder="Applicant Passport No"
                                  value={applicantPassportNumber}
                                  onChange={(e) =>
                                    setApplicantPassportNumber(e.target.value)
                                  }
                                /> */}
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`applicantPassport-${data.RecordId}`}
                                        placeholder="Applicant Passport No"
                                        value={
                                          applicantDetails[data.RecordId]
                                            ?.applicantPassportNumber || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            data.RecordId,
                                            "applicantPassportNumber",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {errors.applicantPassportNumber && (
                                        <p className="text-danger">
                                          {errors.applicantPassportNumber}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="sub-hd1 col-12 text-decoration-underline">
                                    <p>Sponsor's Details</p>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="emailPhone"
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      {/* <input
                                  type="text"
                                  className="form-control"
                                  id="emailPhone"
                                  placeholder="Sponsor name"
                                  value={sponsorName}
                                  onChange={(e) =>
                                    setSponsorName(e.target.value)
                                  }
                                /> */}
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`sponsorName-${data.RecordId}`}
                                        placeholder="Sponsor name"
                                        value={
                                          applicantDetails[data.RecordId]
                                            ?.sponsorName || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            data.RecordId,
                                            "sponsorName",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {errors.sponsorName && (
                                        <p className="text-danger">
                                          {errors.sponsorName}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="emailPhone"
                                        className="form-label"
                                      >
                                        Passport No
                                      </label>
                                      {/* <input
                                  type="text"
                                  className="form-control"
                                  id="emailPhone"
                                  placeholder="Sponsor's Passport No"
                                  value={sponsorPassportNumber}
                                  onChange={(e) =>
                                    setSponsorPassportNumber(e.target.value)
                                  }
                                /> */}
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`sponsorPassport-${data.RecordId}`}
                                        placeholder="Sponsor's Passport No"
                                        value={
                                          applicantDetails[data.RecordId]
                                            ?.sponsorPassportNumber || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            data.RecordId,
                                            "sponsorPassportNumber",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {errors.sponsorPassportNumber && (
                                        <p className="text-danger">
                                          {errors.sponsorPassportNumber}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="upload-button-container">
                                      <label
                                        htmlFor="emailPhone"
                                        className="form-label"
                                      >
                                        Please upload all the necessary
                                        documents.
                                      </label>
                                      <div
                                        id="drop-zone"
                                        className="drop-zone"
                                        onClick={() =>
                                          document
                                            .getElementById(
                                              `fileInput-${data.RecordId}`
                                            )
                                            ?.click()
                                        }
                                        onDrop={(e) => {
                                          e.preventDefault();
                                          handleFiles(
                                            e.dataTransfer.files,
                                            data.RecordId
                                            // data.serviceName
                                            // data.servicetype
                                          );
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                        style={{
                                          border: "2px dashed #385986",
                                          padding: "20px",
                                          textAlign: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div
                                          className="d-flex align-items-center"
                                          style={{ textAlign: "justify" }}
                                        >
                                          <svg
                                            width="34"
                                            height="33"
                                            viewBox="0 0 34 33"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <path
                                              d="M15.625 22V10.7938L12.05 14.3688L10.125 12.375L17 5.5L23.875 12.375L21.95 14.3688L18.375 10.7938V22H15.625ZM8.75 27.5C7.99375 27.5 7.34635 27.2307 6.80781 26.6922C6.26927 26.1536 6 25.5063 6 24.75V20.625H8.75V24.75H25.25V20.625H28V24.75C28 25.5063 27.7307 26.1536 27.1922 26.6922C26.6536 27.2307 26.0063 27.5 25.25 27.5H8.75Z"
                                              fill="#385986"
                                            />
                                          </svg>

                                          <p className="mx-2">
                                            Drag and drop files here or click to
                                            upload
                                          </p>
                                        </div>
                                        <input
                                          id={`fileInput-${data.RecordId}`}
                                          type="file"
                                          multiple
                                          style={{ display: "none" }} // Hide the default file input
                                          onChange={(e) => {
                                            if (e.target.files) {
                                              handleFiles(
                                                e.target.files,
                                                data.RecordId
                                                // data.serviceName
                                                // data.servicetype
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                      {fileCount[data.RecordId] && (
                                        <div id="upload-status">
                                          {fileCount[data.RecordId]} File Upload
                                        </div>
                                      )}
                                      {errors.files && (
                                        <p className="text-danger">
                                          {errors.files}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div className="col-12">
                                <div className="file-preview-container mt-4">
                                  {uploadedFiles?.map(
                                    (file, index) =>
                                      // Check if file.id matches data.RecordId
                                      file.id === data.RecordId && (
                                        <div
                                          key={index}
                                          className="file-preview"
                                        >
                                          <button
                                            className="close-button"
                                            onClick={() =>
                                              handleDeleteFile(file)
                                            }
                                          >
                                            &times; {/* Close icon */}
                                          </button>
                                          {file.file.type ===
                                          "application/pdf" ? (
                                            <>
                                              <div className="file-icon font-25 text-primary">
                                                <img
                                                  src={PdfIco}
                                                  className="img-fluid"
                                                  alt="PDF Icon"
                                                />
                                              </div>
                                              <span className="file-name">
                                                {file.file.name}
                                              </span>
                                            </>
                                          ) : (
                                            <div className="image-preview">
                                              {file?.file && (
                                                <img
                                                  src={file?.previewUrl}
                                                  alt={file?.file.name}
                                                  className="img-fluid"
                                                />
                                              )}
                                              <p className="file-name">
                                                {file.file.name}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="tracking-btn-container">
                                  {index !== 0 && (
                                    <button
                                      className="tracking-btn"
                                      onClick={() => handlePrevious(index)}
                                    >
                                      <i className="fas fa-chevron-left"></i>
                                      Previous
                                    </button>
                                  )}
                                  {index === cartData.length - 1 ? (
                                    <button
                                      className="tracking-btn"
                                      onClick={() =>
                                        handleUploadData(
                                          index,
                                          data.RecordId,
                                          // data.userName,
                                          data.orderNumber,
                                          data.applicationNumber
                                        )
                                      }
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <button
                                      className="tracking-btn"
                                      onClick={() =>
                                        handleUploadData(
                                          index,
                                          data.RecordId,
                                          // data.userName,
                                          data.orderNumber,
                                          data.applicationNumber
                                        )
                                      }
                                    >
                                      Next
                                      <i className="fas fa-chevron-right"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                  <div className="col-lg-4 d-none d-lg-block ">
                    <div className="sticky-card cart">
                      <SideCard />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="checkoutWrap py-2 py-md-5 ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 bg-2 p-3">
                    <Accordion
                      // activeKey={activeAccordion.toString()}
                      defaultActiveKey="1"
                      className="accordion acco-style-2"
                      id="add-details-aco"
                    >
                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div className="hd-style-3">
                          Order No: <span>{orderNumber}</span>
                        </div>
                        <div>
                          <p className="text-black font-16 fw-bold mb-0">
                            Date:{" "}
                            <span>
                              {new Date(date).toLocaleString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div>
                          {!condition && (
                            <p className="text-black font-16 fw-bold ">
                              No of Application: <span>{applicationNo}</span>
                            </p>
                          )}
                        </div>
                        <div>
                          <p className="text-black font-16 fw-bold ">
                            Order Total:
                            <span> {totalPrice?.toLocaleString()} AED</span>
                          </p>
                        </div>
                      </div>
                      <hr className="border border-1 opacity-100 my-1" />
                      {/* {cartData.map((data, index) => ( */}
                      <Accordion.Item
                        className="accordion-item my-3 border-0"
                        eventKey="1"
                        // eventKey={index.toString()}
                        // eventKey="0"
                        // onClick={() => setActiveAccordion(index)}
                        // key={data.RecordId}
                      >
                        <Accordion.Header
                          // onClick={() => handleSelect(index)}
                          // onClick={() => toggleAccordion(data.RecordId)}
                          // id={`heading${index}`}
                          // data-bs-target={`#${"1" + data.RecordId}`}
                          // data-bs-toggle="collapse"
                          // aria-expanded="true"
                          // aria-expanded={activeAccordion === data.RecordId}
                          // aria-controls={"1" + data.RecordId}
                          style={{
                            fontWeight: "bolder",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {/* <div className="ff-bold font-16 me-4">
                              {index + 1}
                            </div> */}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div style={{ display: "flex", gap: "5px" }}>
                              {packageName}
                            </div>
                            {!condition && (
                              <>
                                <div>
                                  <p className="font-14  text-dark float-start text-primary mb-1">
                                    Application Amount:
                                    <span className="mx-2 fw-bold">
                                      {/* {data?.serviceAmount?.toLocaleString()}{" "} */}
                                      AED
                                    </span>
                                  </p>
                                  <p className="font-14  text-dark float-end text-primary mb-1">
                                    Application Number:
                                    <span className="mx-2 fw-bold">
                                      {/* {data.applicationNumber} */}
                                    </span>
                                  </p>
                                </div>

                                <div>
                                  <p className="font-14  text-dark float-start text-primary mb-1">
                                    Applicant Name:
                                    <span className="mx-2 fw-bold">
                                      {
                                        // applicantDetails[data.RecordId]
                                        // ?.applicantName
                                      }
                                    </span>
                                  </p>
                                  <p className="font-14  text-dark float-end text-primary ">
                                    Passport Number:
                                    <span className="mx-2 fw-bold">
                                      {/* {
                                          applicantDetails[data.RecordId]
                                            ?.applicantPassportNumber
                                        } */}
                                    </span>
                                  </p>
                                </div>
                              </>
                            )}
                          </div>

                          {!condition && (
                            <div
                              className="dlt-ico text-center"
                              style={{ marginLeft: "5px" }}
                              // onClick={() =>
                              // handleDelete(data.applicationNumber)
                              // }
                            >
                              <i className="fas fa-trash"></i>
                            </div>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="accordion-body modal-content">
                            {cartData.map((data, index) => (
                              <div className="row" key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data?.formDescription ?? " ",
                                  }}
                                />
                              </div>
                            ))}

                            <form className="form-main bg-2 p-4 border-radius-10">
                              <div className="row">
                                <div className="sub-hd1 col-12 text-decoration-underline">
                                  <p className="font-21">Applicant’s Details</p>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="name"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Applicant name"
                                      id={`applicantName-${dataRecordId}`}
                                      value={
                                        applicantDetails[dataRecordId]
                                          ?.applicantName || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          dataRecordId,
                                          "applicantName",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.applicantName && (
                                      <p className="text-danger">
                                        {errors.applicantName}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="emailPhone"
                                      className="form-label"
                                    >
                                      Passport No
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Applicant Passport No"
                                      id={`applicantPassport-${dataRecordId}`}
                                      value={
                                        applicantDetails[dataRecordId]
                                          ?.applicantPassportNumber || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          dataRecordId,
                                          "applicantPassportNumber",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.applicantPassportNumber && (
                                      <p className="text-danger">
                                        {errors.applicantPassportNumber}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="sub-hd1 col-12 text-decoration-underline">
                                  <p>Sponsor's Details</p>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="emailPhone"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Sponsor name"
                                      id={`sponsorName-${dataRecordId}`}
                                      value={
                                        applicantDetails[dataRecordId]
                                          ?.sponsorName || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          dataRecordId,
                                          "sponsorName",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.sponsorName && (
                                      <p className="text-danger">
                                        {errors.sponsorName}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="emailPhone"
                                      className="form-label"
                                    >
                                      Passport No
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Sponsor's Passport No"
                                      id={`sponsorPassport-${dataRecordId}`}
                                      value={
                                        applicantDetails[dataRecordId]
                                          ?.sponsorPassportNumber || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          dataRecordId,
                                          "sponsorPassportNumber",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.sponsorPassportNumber && (
                                      <p className="text-danger">
                                        {errors.sponsorPassportNumber}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="upload-button-container">
                                    <label
                                      htmlFor="emailPhone"
                                      className="form-label"
                                    >
                                      Please upload all the necessary documents.
                                    </label>
                                    <div
                                      id="drop-zone"
                                      className="drop-zone"
                                      onClick={() =>
                                        document
                                          .getElementById(
                                            `fileInput-${dataRecordId}`
                                          )
                                          ?.click()
                                      }
                                      onDrop={(e) => {
                                        e.preventDefault();
                                        handleFiles(
                                          e.dataTransfer.files,
                                          dataRecordId
                                          // data.serviceName
                                        );
                                      }}
                                      onDragOver={(e) => e.preventDefault()}
                                      style={{
                                        border: "2px dashed #385986",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{ textAlign: "justify" }}
                                      >
                                        <svg
                                          width="34"
                                          height="33"
                                          viewBox="0 0 34 33"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <path
                                            d="M15.625 22V10.7938L12.05 14.3688L10.125 12.375L17 5.5L23.875 12.375L21.95 14.3688L18.375 10.7938V22H15.625ZM8.75 27.5C7.99375 27.5 7.34635 27.2307 6.80781 26.6922C6.26927 26.1536 6 25.5063 6 24.75V20.625H8.75V24.75H25.25V20.625H28V24.75C28 25.5063 27.7307 26.1536 27.1922 26.6922C26.6536 27.2307 26.0063 27.5 25.25 27.5H8.75Z"
                                            fill="#385986"
                                          />
                                        </svg>

                                        <p className="mx-2">
                                          Drag and drop files here or click to
                                          upload
                                        </p>
                                      </div>
                                      <input
                                        id={`fileInput-${dataRecordId}`}
                                        type="file"
                                        multiple
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                          if (e.target.files) {
                                            handleFiles(
                                              e.target.files,
                                              dataRecordId
                                              // data.serviceName
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    {fileCount[dataRecordId] && (
                                      <div id="upload-status">
                                        {fileCount[dataRecordId]} File Upload
                                      </div>
                                    )}
                                    {errors.files && (
                                      <p className="text-danger">
                                        {errors.files}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>

                            <div className="col-12">
                              <div className="file-preview-container mt-4">
                                {uploadedFiles?.map(
                                  (file, index) =>
                                    file.id === dataRecordId && (
                                      <div key={index} className="file-preview">
                                        <button
                                          className="close-button"
                                          onClick={() => handleDeleteFile(file)}
                                        >
                                          &times;
                                        </button>
                                        {file.file.type ===
                                        "application/pdf" ? (
                                          <>
                                            <div className="file-icon font-25 text-primary">
                                              <img
                                                src={PdfIco}
                                                className="img-fluid"
                                                alt="PDF Icon"
                                              />
                                            </div>
                                            <span className="file-name">
                                              {file.file.name}
                                            </span>
                                          </>
                                        ) : (
                                          <div className="image-preview">
                                            {file?.file && (
                                              <img
                                                src={file?.previewUrl}
                                                alt={file?.file.name}
                                                className="img-fluid"
                                              />
                                            )}
                                            <p className="file-name">
                                              {file.file.name}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="tracking-btn-container">
                                <button
                                  className="tracking-btn"
                                  onClick={() => handleFooterData(dataRecordId)}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* ))} */}
                    </Accordion>
                  </div>
                  <div className="col-lg-4 d-none d-lg-block ">
                    <div className="sticky-card cart">
                      {/* <div className="card mb-3 add-cart-main ">
                  <h5>cart</h5>
                  <div className="card mb-3 add-cart-each">
                    {cartItems.map((item) => (
                      <div className="card-body ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p className="checkout-ser-name">
                              {item.productName}
                            </p>
                          </div>
                          <div className="d-flex align-items-center add-cart-btn-main">
                          <button className="bt-mi">-</button>
                          <span className="mx-2">1</span>
                          <button className="bt-plu">+</button>
                        </div>
                          <div>
                            <span className="font-weight-bold">
                              AED {item.productPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}

                      {/* <div className=" mb-3">
                  <a
                    className="add-car-bt d-flex justify-content-between align-items-center"
                    href=""
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <span className="font-weight-bold">Total:</span>
                    <span className="">AED {totalPrice}</span>
                  </a>
                </div> */}

                      <SideCard />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Pre;

