import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    outsideId: "",
    insideId: "",
    footerOutsideId: "",
    cartValue: 0,
    cartValuePrice: 0,
    status: false,
    ServiceName: "Summary",
    discount: 0,
    oldValueOfCartItems: 0,
}

const IdSlice = createSlice({
    name: "ID",
    initialState,
    reducers: {
        getID: (state, action) => {
            const { insideId, outsideId } = action.payload;
            state.outsideId = outsideId;
            state.insideId = insideId;
        },
        getInsideID: (state, action) =>{
            const { insideId } = action.payload;
            // state.outsideId = outsideId;
            state.insideId = insideId;
        },
        getOutsideID: (state, action) => {
            const { footerOutsideId } = action.payload;
            state.footerOutsideId = footerOutsideId;
        },
        setTrue: (state) => {
            state.status = true;
        },
        setFalse: (state) => {
            state.status = false;
        },
        setCartValue: (state, action) => {
            const { ItemInCart } = action.payload;
            state.cartValue = ItemInCart;
        },
        setcartValuePrice: (state, action) => {
            const { totalAmountOfCartItem } = action.payload;
            state.cartValuePrice = totalAmountOfCartItem;
        },
        setServiceNames: (state, action) => {
            const { ServiceNames } = action.payload;
            state.ServiceName = ServiceNames;
        },
        setDiscount: (state, action) => {
            const { discount } = action.payload;
            state.discount = discount;
        },
        setOldValueOfCartItems: (state, action) => {
            const { oldTotalAmount } = action.payload;
            state.oldValueOfCartItems = oldTotalAmount;
        },

        }

    })

export const { getID,getInsideID, getOutsideID, setTrue, setFalse, setCartValue,setcartValuePrice,setServiceNames, setDiscount, setOldValueOfCartItems } = IdSlice.actions

export default IdSlice.reducer
