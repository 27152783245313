import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButtons from "../sliderButtons/SwiperNavButton";

import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Props {
    data?: string[]; 
  }

const Slider: React.FC<Props> = ({data}) => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={2}
        loop={true}
        autoplay={{ // Set autoplay configuration
            delay: 2500, // Duration for each slide (in milliseconds)
            disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 80,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 200,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
      >
        {data?.map((data, index) => (
          <SwiperSlide key={index}>
            <img loading="lazy" src={data} alt="img" className="img-fluid rounded"/>
          </SwiperSlide>
        ))}
        <SwiperNavButtons />
      </Swiper>
    </>
  );
};

export default Slider;
