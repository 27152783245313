interface steps{
  id: number;
  title: string;
  icon: string;
  description: string;
}

interface Props {
  section5?: {
    heading: string;
    line: string;
    steps: steps[];
  };
}

const Section6: React.FC<Props>  = ({ section5 }) => {

  return (
    <>
      <section className="bg-2 py-3 py-md-5">
        <div className="container py-3 py-md-5">
          <div className="row">
            <div className="col-md-4">
              <div className="hd-style-2 golden wow animate__fadeInLeft">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section5?.heading ?? "",
                  }}
                >
                  {/* why choose <br /> <span>Goodhand</span> */}
                </h2>
              </div>
            </div>

            <div className="col-md-7 justify-content-end align-items-center"></div>
          </div>
        </div>
      </section>

      <section className="mt-5 my-md-5">
        <div className="container mt-4 above-minus">
          <div className="carousel-container">
            <div className="row">
              {section5?.steps?.map((Benefits) => (
                <div
                  key={Benefits.id}
                  className="col-12 col-sm-4 col-md-3 col-lg-2 mb-4"
                >
                  <div className="card flex-row flex-md-column align-items-center gap-md-0 gap-4  py-3 px-3 text-center shadow-sm rounded border border-0 legal-serv-card">
                    <img
                      src={Benefits.icon}
                      alt={Benefits.title}
                      className="img-fluid cat-svg-each mb-0 mb-md-3"
                    />
                    <div className="card-body p-0">
                      <h5 className="card-title font-12 ff-semibold text-start text-md-center">
                        {Benefits.title}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section6;
