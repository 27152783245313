import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButtons from "../sliderButtons/SwiperNavButton";

interface Props {
  clientData?: [];
}

const ClientSlider: React.FC<Props> = ({ clientData }) => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={20}
        slidesPerView={6}
        loop={true}
        autoplay={{
          // Set autoplay configuration
          delay: 2500, // Duration for each slide (in milliseconds)
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
        }}
      >
        {clientData?.map((data, index) => (
          <SwiperSlide key={index} className="item">
            <img
              loading="lazy"
              src={data}
              className="img-fluid"
              alt={`img${index}`}
            />
          </SwiperSlide>
        ))}
        <SwiperNavButtons />
      </Swiper>
    </>
  );
};

export default ClientSlider;
