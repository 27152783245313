const Main = () => {
  return (
    <div className="documents-section">
      <h3>
        Service Name <span className="badge">Invoiced</span>
      </h3>
      <div className="document-grid">
        {[1, 2, 3, 4].map((doc) => (
          <div className="document-card" key={doc}>
            <img src="path/to/document-image.jpg" alt={`Document ${doc}`} />
            <p>Document{doc}.jpg</p>
            <p>Thu Feb 23</p>
          </div>
        ))}
      </div>
      <div className="upload-section">
        <input type="file" id="file-upload" />
        <button>Upload</button>
      </div>
    </div>
  );
};

export default Main;
