import { useEffect, useState } from "react";
import PreLoader from "../../components/preloader/PreLoader";
import { MainService } from "../../types/homeComponentTypes";
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;

const NotFound = () => {
  const URL = `${apiUrl}/api/openRoute/home`;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mainServices, setMainServices] = useState<MainService[]>([]);

 const getDataFromApi = () => {
    return fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.HomeData) {
          setMainServices(data.HomeData.mainServices);
          return true; // Indicate success
        }
        return false; // Return false if data is not available
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
        return false; // Indicate failure
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        // const jsonDataSuccess = await getDataFromJson();
        const apiDataSuccess = await getDataFromApi();

        // If neither call succeeds, show an error
        if (!apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNewPage = (slug: string) => {
    if (slug === "packages") {
      navigate("/visa-packages-dubai");
    }
    //  else if (slug === "legal-translation") {
    //   navigate("/legal-translation-services-in-dubai");
    // }
     else {
      navigate(`/services/${slug}`);
    }
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <div>
          <section className="py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p className="font-25 text-black ff-semibold">
                    
                      We're sorry, we couldn't find
                <br /> what you were looking for!
                    
              </p>
                  <img
                      src="https://goodhand.b-cdn.net/Assets/404bg-DxUPqR88-01.webp"
                      alt="not found"
                      className="img-fluid"
                    />
                    </div>

                <div className="col-md-6 d-flex flex-column my-auto">
                  <div className="hd-style-1 wow animate__fadeInLeft d-none d-md-block"></div>
                  <div
                    className="banner-services mt-auto border"
                    style={{ height: "auto" }}
                  >
                    <div className="sub-hd1 wow fadeInUp d-none d-md-block">
                      <p>What you are looking for</p>
                    </div>
                    <div className="row">
                      {mainServices.map((item) => (
                        <div
                          className="col-md-4 col-lg-4 col-4"
                          key={item.sub2RecordId + item.sub1Slug}
                          onClick={() => handleNewPage(item.sub1Slug)}
                        >
                          <div
                            className="banner-sr-card wow animate__zoomIn"
                            data-wow-duration="1s"
                            data-wow-delay="0s"
                          >
                            <img
                              loading="lazy"
                              src={item.sub1ImageSrc}
                              alt={`Service ${
                                item.sub1ImgAlt + item.sub2RecordId
                              }`}
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <p>{item.sub1ServiceName}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default NotFound;
