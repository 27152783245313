
import map from "../../assets/inner-page/map.svg";
import clock from "../../assets/inner-page/clock.svg";
import Phone from "../../assets/inner-page/phone.svg";


const SideCard = () => {
  return (
    <>
      <div className="card mb-3" style={{ bottom: "0" }}>
        <div className="card-body">
          <h5 className="card-title font-16 ff-semibold">
            Goodhand Government Transactions LLC
          </h5>
          <div className="rating d-flex flex-column align-items-start">
            <div className="d-flex align-items-center gap-2">
              <span className="rating-value font-weight-bold">4.8</span>
              <span className="stars mx-2">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half-alt"></i>
              </span>
            </div>
            <span className="reviews text-muted font-weight-bold">
              Trusted By over 3.2k Satisfied Costomers
            </span>
          </div>
          <div className="info-item mb-2 font-12">
            <img
              src={clock}
              alt="images/inner-page/clock.svg"
              width="16"
              height="16"
            />
            <span>Open until 08:00 PM</span>
          </div>
          <div className="info-item mb-2 font-12">
            <img src={map} alt="Map Icon" width="16" height="16" />
            <span>M-01 Saraya Plaza, Al Rigga Road, Al Rigga - Dubai</span>
          </div>
          <div
            style={{
              display: " flex",
              alignItems: " center",
              gap: "15px",
              marginTop: "1em",
            }}
          >
            {/* <img src={phone} alt="Phone Icon" width="16" height="16" /> */}
            <img
              src={Phone}
              alt="images/inner-page/phone.svg"
              width="16"
              height="16"
            />
            {/* <FontAwesomeIcon icon="fa-thin fa-phone-volume" /> */}
            <span>
              <a
                href="tel:+97142979997"
                target="_blank"
                className="FooterHref font-12"
                style={{
                  // display: " flex",
                  // gap: "15px",
                  textDecoration: "none",
                }}
              >
                +971 42 97 9997
              </a>
            </span>
          </div>

          <div
            className="font-12 mb-3"
            style={{
              display: " flex",
              alignItems: " self-start",
              gap: "15px",
            }}
          >
            <i className="fab fa-whatsapp font-16"></i>
            <a
              href="https://web.whatsapp.com/send?phone=97142979998"
              target="_blank"
              style={{
                display: " flex",
                gap: "15px",
                textDecoration: "none",
              }}
            >
              <span>+971 42 97 9998</span>
            </a>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57732.37369806494!2d55.28618482577051!3d25.2613883075519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ccee39fee9f%3A0x3850270ea3d15e5e!2sAmer%20Center%20-%20Good%20Hand%20Government%20Transactions%20LLC!5e0!3m2!1sen!2sae!4v1726757774492!5m2!1sen!2sae"
            width="100%"
            height="150"
            style={{ border: "0", borderRadius: "5px" }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default SideCard;
