import { useDispatch } from "react-redux";
import LegalTranslationCarousel from "../../../components/LegalTranslation/LegalTranslationCarousel";
import { CarouselItem } from "../../../types/homeComponentTypes";
import { getID } from "../../../store/slices/IdSlice";
import { useNavigate } from "react-router-dom";
import { redirection, section3 } from "../../../types/marriageSevices";

interface Props {
  redirection?: redirection;
  section3?: section3;
  apiData?: CarouselItem[];
}

const Section5: React.FC<Props> = ({ section3, apiData, redirection }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGetAQuote = (id?: number, url?: string) => {
    const insideId = 0;
    const outsideId = id;
    // addDataToDataBase(109,167,"plus")
    dispatch(getID({ insideId, outsideId }));
    navigate(`/services/${url}`);
    // dispatch(openGetQuoteModel());
  };
  return (
    <>
      <section className="bg-2 py-5">
        <div className="container mb-3">
          <div className="d-md-flex mb-2 mb-md-0 align-items-center justify-content-between mb-4">
            <h3
              className="col-16 col-md-5 font-18 ff-semibold text-black"
              dangerouslySetInnerHTML={{
                __html: section3?.heading ?? "Legal Sevices",
              }}
            ></h3>

            <div className="text-center d-md-flex ">
              <button
                className="btn btn-primary   font-14 ff-semibold  mt-0 mt-md-3  bt--cart"
                onClick={() =>
                  handleGetAQuote(
                    redirection?.sub2RecordId,
                    redirection?.sub2SlugServiceSub1
                  )
                }
              >
                Explore All Services
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <LegalTranslationCarousel items={apiData} />
        </div>
      </section>
    </>
  );
};

export default Section5;
