interface Props {
  section?: {
    heading?: string;
    picUrl?: string;
    ph_Number?: string;
    ph_Number_text?: string;
    wh_Number?: string;
  };
}

const Section1: React.FC<Props> = ({ section }) => {
  return (
    <>
      <div className="contact-wrapper-legal bg-2">
        <div className="container">
          <div className="row py-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center gap-0 gap-md-3 text-primary">
                <div>
                  <img
                    src={section?.picUrl}
                    alt=""
                    className="img-fluid border text-primary proleg d-none d-md-block"
                  />
                </div>
                <div>
                  <p
                    className="font-16 ff-semibold text-primary py-3 py-md-0 mb-0"
                    dangerouslySetInnerHTML={{
                      __html: section?.heading ?? "",
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 my-auto">
              <div className="d-flex align-items-center gap-3 justify-content-start justify-content-md-end text-primary py-3 py-md-0">
                <div className="text-center d-flex">
                  <a
                    // href="https://web.whatsapp.com/send?phone=+97142979998"
                    href={`https://wa.me/${section?.wh_Number}`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btn btn-primary font-14 ff-semibold  bt--cart px-3 py-2 gap-2 h-100">
                      <i className="fab fa-whatsapp font-20"></i>
                      Whatsapp
                    </button>
                  </a>
                </div>
                <div className="text-center d-flex">
                  <a
                    href={`tel:${section?.ph_Number}`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      type="button"
                      className="position-relative font-14 text-primary   py-2 px-3 bt--sec h-100"
                      style={{ width: "fit-content" }}
                    >
                      {section?.ph_Number_text ?? " "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
