import { useEffect, useState } from "react";
import { logoutFun } from "../../config/auth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../components/preloader/PreLoader";
import { OrderData } from "../../types/booking";

const baseUrl = import.meta.env.VITE_API_URL;

const Booking = () => {
  // const orders = [
  //   {
  //     orderId: "ORD-GH-0000015",
  //     orderStatus: "Open",
  //     applicationsCount: 2,
  //     total: "AED 1528",
  //     date: "December 28, 2024",
  //   },
  //   {
  //     orderId: "ORD-GH-0000016",
  //     orderStatus: "Processing",
  //     applicationsCount: 3,
  //     total: "AED 1728",
  //     date: "December 29, 2024",
  //   },
  //   {
  //     orderId: "ORD-GH-0000017",
  //     orderStatus: "Completed",
  //     applicationsCount: 1,
  //     total: "AED 528",
  //     date: "December 30, 2024",
  //   },
  //   {
  //     orderId: "ORD-GH-0000018",
  //     orderStatus: "Open",
  //     applicationsCount: 4,
  //     total: "AED 2528",
  //     date: "January 1, 2025",
  //   },
  //   {
  //     orderId: "ORD-GH-0000019",
  //     orderStatus: "Cancelled",
  //     applicationsCount: 2,
  //     total: "AED 0",
  //     date: "January 2, 2025",
  //   },
  //   {
  //     orderId: "ORD-GH-0000020",
  //     orderStatus: "Open",
  //     applicationsCount: 3,
  //     total: "AED 1128",
  //     date: "January 3, 2025",
  //   },
  // ];

  const [showAll, setShowAll] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term
  const [count, setCount] = useState<number>();
  const [completedOrders, setCompletedOrders] = useState<OrderData[]>();

  const ordersToDisplay = showAll ? orderData : orderData?.slice(0, 3);

  const fetchOrderData = async (
    token: string | null,
    login_infoo_userName: string
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);

      const postData = {
        userName: login_infoo_userName,
      };

      const response = await axios.post(
        `${baseUrl}/api/user/orderShow`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data) {
        console.log("booking data : ", data.mainOrdersList.orders);
        const filteredOpenOrders = data.mainOrdersList.orders.filter(
          (order: OrderData) => order.orderStatus === "open"
        );
        const filteredCompletedOrders = data.mainOrdersList.orders?.filter(
          (order: OrderData) =>
            order.orderStatus === "Completed" ||
            order.orderStatus === "Cancelled"
        );

        setOrderData(filteredOpenOrders);
        setCompletedOrders(filteredCompletedOrders);
        setCount(data.mainOrdersList.count);
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      // console.error('Error fetching order data:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // If token is expired or invalid
          if (error.response.status === 401 || error.response.status === 407) {
            console.error("Token expired. Logging out...");
            // logout(); // Call the logout function to handle session end
          }
        } else if (error.code === "ERR_NETWORK") {
          console.error("Network error:", error);
          logoutFun(dispatch, navigate);
        }
      } else {
        console.error("Error fetching order data:", error);
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    import("./orderflow.css");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    if (login_info) {
      const login_infoo = JSON.parse(login_info);
      fetchOrderData(token, login_infoo.userName);
    }
  }, []);

  const fetchData = async () => {
    if (searchTerm.trim() !== "") {
      const token = sessionStorage.getItem("tk");
      if (token) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.get(`${baseUrl}/api/user/searchOrder`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              orderNumber: searchTerm,
            },
          });

          setOrderData(response.data.order.orders);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response) {
              // If token is expired or invalid
              if (
                error.response.status === 401 ||
                error.response.status === 407
              ) {
                console.error("Token expired. Logging out...");
                // logout(); // Call the logout function to handle session end
              }
            } else if (error.code === "ERR_NETWORK") {
              console.error("Network error:", error);
              logoutFun(dispatch, navigate);
            }
          } else {
            console.error("Error fetching order data:", error);
            setIsError(true);
          }
        }
      } else {
        console.log("No Token found");
      }
    } else {
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      if (login_info && token) {
        const login_infoo = JSON.parse(login_info);
        fetchOrderData(token, login_infoo.userName);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <div className="section bg-2 mt-5" style={{ minHeight: "100vh" }}>
            <div className="container">
              <div className="row d-flex flex-column align-items-center justify-content-center pt-3  ">
                <div
                  className="col-md-5 bg-white"
                  style={{ minHeight: "100vh" }}
                >
                  <div className="bg-white">
                  <div className="d-flex align-items-center justify-content-between flex-wrap ">
                    <div className="font-16 ff-semibold text-primary">
                      No. of Orders :
                      <span className="text-black ms-1"> {count}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="ff-semibold font-16 me-3 text-primary">
                        Search:
                      </span>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control custom-search-input"
                          placeholder="Enter Order Number"
                          value={searchTerm} // Bind input to searchTerm state
                          onChange={handleSearch}
                        />
                        <i className="fas fa-search custom-search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="payment-card p-3 ff-medium">
                    <div className="payment-summary-header">
                      <p className="font-18 text-black ff-bold mb-3">
                        Active Bookings
                      </p>

                      <div>
                        {ordersToDisplay.map((order, index) => (
                          <div
                            className="d-flex align-items-center gap-4 border-bottom py-3 cursor-pointer"
                            key={index}
                          >
                            <div className="flex-grow-1">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <p className="font-14 ff-bold text-black mb-0">
                                  Order ID: {order.orderNumber}
                                </p>
                                <p className="bg-primary text-white font-10 mb-0 px-3 rounded-pill">
                                  {order.orderStatus}
                                </p>
                              </div>
                              <div
                                className="d-flex justify-content-between align-items-center mb-1"
                                
                              >
                                <p className="font-12 text-muted mb-0">
                                  No. of Applications:
                                </p>
                                <p className="font-12 text-black mb-0">
                                  {order.NoofApplications}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-1">
                                <p className="font-12 text-muted mb-0">
                                  Total:
                                </p>
                                <p className="font-12 text-black mb-0">
                                  {order.ordertotal}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="font-12 text-muted mb-0">Date:</p>
                                <p className="font-12 text-black mb-0">
                                  {new Date(order.createdon).toLocaleString(
                                    "en-US",
                                    {
                                      day: "numeric",
                                      month: "long",
                                      year: "numeric",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="text-end"
                            onClick={() =>
                              navigate(
                                `/bookingdetail/${order.orderNumber}`
                              )
                            }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="12px"
                                height="12px"
                                viewBox="-5.5 0 26 26"
                                version="1.1"
                              >
                                <title>chevron-right</title>
                                <desc>Created with Sketch Beta.</desc>
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="Icon-Set-Filled"
                                    transform="translate(-474.000000, -1196.000000)"
                                    fill="#626161"
                                  >
                                    <path
                                      d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                      id="chevron-right"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        ))}

                        {orderData?.length > 3 && (
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowAll(!showAll)}
                            >
                              {showAll ? "Show Less" : "Show More"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="payment-card p-3 ff-medium">
                    <div className="payment-summary-header">
                      <p className="font-18 text-black ff-bold mb-3">
                        Order History
                      </p>
                      {completedOrders && completedOrders?.length > 0 ? (
                        <>
                          {completedOrders?.map((order) => (
                            <div
                              key={order.orderNumber}
                              className="d-flex align-items-start gap-4 border-bottom py-3"
                            >
                              <div className="d-flex flex-column w-100">
                                <p className="font-12 ff-bold mb-0 text-black mb-2">
                                  {order.orderNumber}
                                </p>
                                <div className="font-12 ff-medium mb-0 text-black d-flex align-items-center justify-content-between">
                                  <span>{order.orderStatus}</span>
                                  <span>
                                    •{" "}
                                    {new Date(order.createdon).toLocaleString(
                                      "en-US",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                  <span className="ms-auto ff">
                                    {order.ordertotal}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-shrink-0">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="13px"
                                  height="13px"
                                  viewBox="-5.5 0 26 26"
                                  version="1.1"
                                >
                                  <g
                                    id="Page-1"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Icon-Set-Filled"
                                      transform="translate(-474.000000, -1196.000000)"
                                      fill="#626161"
                                    >
                                      <path
                                        d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                        id="chevron-right"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <span> No Data in Order History</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Booking;
