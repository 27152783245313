import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectAddressModel from "../../components/selectAddressModel/SelectAddressModel";
import {
  openNewLogin,
  openSelectAddressModel,
} from "../../store/slices/modalSlice";
import AddAnotherAddressModel from "../../components/addAnotherAddress/AddAnotherAddress";
import AddDetailModel from "../../components/AddDetail/AddDetailModel";
import LoginModel from "../../components/LoginModel/LoginModel";
import { Link, useNavigate } from "react-router-dom";
import AddressFromMap from "../../components/selectAddressFromMapModel/AddressFromMap";
import Coupons from "../../components/coupons/Coupons";
// import { setFalse, setTrue } from "../../store/slices/IdSlice";
// import { useData } from "../../components/toast/Api";
import cartImg from "../../assets/cart.svg";
import axios from "axios";
import PreLoader from "../../components/preloader/PreLoader";
import CardItem from "../../components/cardItem/CardItem";
import { AddressRecord } from "../../types/cart";
import { CartItem, UserDataType } from "../../types/navbarTypes";

const baseUrl = import.meta.env.VITE_API_URL;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [addDetail, setAddDetail] = useState<string | null>();
  const [tooken, settoken] = useState<string | null>();
  const [address, setAddress] = useState<AddressRecord | null>();
  const [number, setNumber] = useState({ countryCode: "", phoneNumber: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cartItems, setData] = useState<CartItem[]>();
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [oldTotalAmount, setOldTotalAmount] = useState<number>(0);
  // const [discount, setDiscount] = useState<number>(0);

  const openSelectAddress = () => {
    dispatch(openSelectAddressModel());
  };

  const discount = useSelector(
    (store: { ID: { discount: number } }) => store.ID.discount
  );

  const totalPrice = useSelector(
    (store: { ID: { cartValuePrice: number } }) => store.ID.cartValuePrice
  );

  const oldTotalAmount = useSelector(
    (store: { ID: { oldValueOfCartItems: number } }) =>
      store.ID.oldValueOfCartItems
  );

  const showSelectAddress = useSelector(
    (state: { modal: { selectAddressModel: boolean } }) =>
      state.modal.selectAddressModel
  );

  const showAddAddress = useSelector(
    (state: { modal: { addAnotherAddress: boolean } }) =>
      state.modal.addAnotherAddress
  );

  const showDetailModel = useSelector(
    (state: { modal: { addDetailModel: boolean } }) =>
      state.modal.addDetailModel
  );

  const showLoginModel = useSelector(
    (state: { modal: { login: boolean } }) => state.modal.login
  );

  const showAddAddressFromMap = useSelector(
    (state: { modal: { addressFromMap: boolean } }) =>
      state.modal.addressFromMap
  );

  const handleRadioChange = (val: string) => {
    if (val === "addDetail") {
      setAddDetail("false");
      console.log("cart ::: cash");
    }

    if (val === "skillfornow") {
      setAddDetail("true");
    }
    console.log("cart :: val", val);
  };

  const handleData = () => {
    const localStorageData = localStorage.getItem("Login_info");
    if (localStorageData) {
      const billingRecordID = sessionStorage.getItem("billingRecordID");
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      const uuid = localStorage.getItem("uuid");

      if (login_info) {
        if (uuid) {
          console.log("cart servicepage :::: UUIDD 22", uuid);

          const fetchOrderData = async () => {
            try {
              const postData = {
                uuid,
              };

              if (!baseUrl) {
                throw new Error(
                  "Base URL is not defined in the environment variables"
                );
              }

              const URL = `${baseUrl}/api/user/createOrder`;

              const response = await fetch(URL, {
                // Replace with your API endpoint
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(postData),
              }); // Replace with your API endpoint
              const data = await response.json();
              localStorage.setItem(
                "orderNumber",
                JSON.stringify(data.orderNumber)
              );

              if (data.orderNumber) {
                const postData = {
                  billingRecordId: billingRecordID,
                  pickupAddressRecordId: "0",
                  orderNumber: data.orderNumber,
                };

                const URL = `${baseUrl}/api/user/userAddressSelection`;

                const response = await fetch(URL, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify(postData),
                });

                if (response) {
                  // Check if the response is successful
                  if (addDetail === "true") {
                    navigate("/payment");
                  } else {
                    navigate("/checkout");
                  }
                }
              }
            } catch (error) {
              console.error("cart :: Error fetching order data:", error);
            }
          };

          fetchOrderData();
        }
      }
    }
  };

  // const toggle = useSelector(
  //   (store: { ID: { status: boolean } }) => store.ID.status
  // );

  const login = useSelector(
    (state: { signin: { userData: UserDataType } }) => state.signin.userData
  );

  const getData = async () => {
    const UUID = localStorage.getItem("uuid");

    try {
      setIsLoading(true);
      setIsError(false);

      const response = await axios.get(
        `${baseUrl}/api/openRoute/cartinfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.CartItems.Cart) {
        console.log(
          "cart ::: response data : cartitem",
          response.data.CartItems
        );
        setData(response.data.CartItems);

        // Calculate total amount and total items in cart using reduce
        // const { totalAmount, totalItems, oldTotalAmount } =
        //   response.data.CartItems.reduce(
        //     (
        //       acc: {
        //         totalAmount: number;
        //         totalItems: number;
        //         oldTotalAmount: number;
        //       },
        //       item: CartItem
        //     ) => {
        //       acc.totalAmount += item.TotalAmount;
        //       acc.totalItems += item.Quantity;
        //       if (
        //         item.oldTotalAmount == null ||
        //         item.oldTotalAmount == undefined ||
        //         item.oldTotalAmount === 0 ||
        //         item.oldTotalAmount < item.TotalAmount
        //       ) {
        //         acc.oldTotalAmount += item.TotalAmount;
        //       } else {
        //         acc.oldTotalAmount += item.oldTotalAmount;
        //       }
        //       return acc;
        //     },
        //     { totalAmount: 0, totalItems: 0, oldTotalAmount: 0 }
        //   );

        // console.log("cart ::: Total Amount: ", totalAmount);
        // console.log("cart ::: Total Items in Cart: ", totalItems);
        // dispatch(setcartValuePrice({ totalAmount }))

        // setTotalPrice(totalAmount);
        // setOldTotalAmount(oldTotalAmount);
        // const discount = oldTotalAmount - totalAmount;
        // setDiscount(discount);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error("Error fetching CartItems", error);
    }
  };

  const address1 = sessionStorage.getItem("address");

  useEffect(() => {
    if (address1) {
      const addre = JSON.parse(address1);
      setAddress(addre);
    } else {
      setAddress(null);
    }
  }, [address1]);

  useEffect(() => {
    const token = sessionStorage.getItem("tk");
    // const address = sessionStorage.getItem("address");
    settoken(token);
    const num = localStorage.getItem("phoneNumber");
    if (num) {
      const nm = JSON.parse(num);
      setNumber(nm);
    }
    console.log("junaid");
    getData();
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("tk");
    settoken(token);
    const num = localStorage.getItem("phoneNumber");
    if (num) {
      const nm = JSON.parse(num);
      setNumber(nm[0]);
    }
  }, [login]);

  useEffect(() => {
    if (totalPrice <= 0) {
      getData();
    }
  }, [totalPrice]);

  // useEffect(() => {
  //   if (toggle === true) {
  //     getData();
  //   }
  //   dispatch(setFalse());
  // }, [toggle]);

  // const handleDelete = async (id: number) => {
  //   try {
  //     if (!baseUrl) {
  //       throw new Error("Base URL is not defined in the environment variables");
  //     }

  //     const response = await fetch(
  //       `${baseUrl}/api/openRoute/deleteCartItem?recordid=${id}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response) {
  //       dispatch(setTrue());
  //       console.log("AddressPage :: delete", response);

  //       showSuccess(" Data Successfully Delete ");
  //     }
  //   } catch (error) {
  //     console.log("handleDelete error :: ", error);
  //   }
  // };

  const handleLogin = () => {
    dispatch(openNewLogin());
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {cartItems && cartItems.length > 0 ? (
            <section className="cartPage-wrap py-0 py-md-5 my-4 my-md-5">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-5  mb-3 mb-md-0">
                    {discount > 0 && (
                      <div className="Saved-section p-3 rounded text-center bg-2 mb-3">
                        <span className="font-12 ff-semibold text-dark">
                          You're saving total AED {discount.toFixed(2)} on this
                          order!
                        </span>
                      </div>
                    )}

                    {tooken !== null ? (
                      <div className="address-card ">
                        <div className="d-flex align-items-center gap-3 border-bottom p-3">
                          <div className="cart-map-ico">
                            <i className="fa-solid fa-message"></i>
                          </div>

                          <div className="flex-grow-1">
                            <p className="mb-0 font-14 ff-semibold text-black">
                              Send booking details to
                            </p>
                            <p className="mb-0 font-14 ">
                              {number.countryCode + number.phoneNumber}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-3  p-3 flex-wrap border-bottom">
                          <div className="cart-map-ico">
                            <i className="fas fa-map-marker-alt"></i>
                          </div>

                          <div className="flex-grow-1">
                            <div>
                              <p className="mb-0 font-14 ff-semibold text-black">
                                Adress
                              </p>
                              {address && (
                                <p className="font-12">
                                  {/* Home - axis6, yty, ديرة - دبي - United Arab */}
                                  {address?.streetName}
                                </p>
                              )}
                            </div>
                          </div>
                          {address !== null && (
                            <div className="d-flex justify-content-start align-items-center">
                              <a
                                href="javascript:void(0)"
                                className="text-decoration-none"
                              >
                                <button
                                  type="button"
                                  className="btn-add position-relative my-2 px-3"
                                  onClick={openSelectAddress}
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  Edit
                                </button>
                              </a>
                            </div>
                          )}
                          {address === null && (
                            <div className="text-center d-flex col-12 ">
                              <button
                                onClick={openSelectAddress}
                                className="btn btn-primary  w-100 font-14 ff-semibold py-3 mx-3 bt--cart"
                              >
                                Select Address
                              </button>
                            </div>
                          )}
                        </div>

                        {/* Main Modal select address model */}
                        {showSelectAddress && <SelectAddressModel />}

                        {/* Nested Modal when no address then open new model */}
                        {showAddAddress && <AddAnotherAddressModel />}

                        <div
                          className={`d-flex align-items-center gap-3 border-bottom p-3 flex-wrap ${
                            address === null ? "disabled" : ""
                          }`}
                        >
                          <div className="cart-map-ico">
                            <i className="fas fa-user-alt ml-2"></i>
                          </div>

                          <div className="flex-grow-1">
                            <p
                              className={`mb-0 font-14 ff-semibold ${
                                !address ? "" : "text-black"
                              }`}
                            >
                              Details
                            </p>
                            <div className="text-center d-flex col-12 flex-grow-1 py-2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="skipRadio"
                                  name="detailsOption" // Grouped name for mutual exclusivity
                                  checked={addDetail === "true"}
                                  onChange={() =>
                                    handleRadioChange("skillfornow")
                                  }
                                  disabled={address === null}
                                />
                                <label
                                  className="form-check-label font-12"
                                  htmlFor="skipRadio"
                                >
                                  Skip for Now
                                </label>
                              </div>
                              <div className="form-check mx-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="addDetailsRadio"
                                  name="detailsOption" // Grouped name for mutual exclusivity
                                  checked={addDetail === "false"}
                                  onChange={() =>
                                    handleRadioChange("addDetail")
                                  }
                                  disabled={address === null}
                                />
                                <label
                                  className="form-check-label font-12"
                                  htmlFor="addDetailsRadio"
                                >
                                  Add Details
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-3  p-3 flex-wrap">
                          <div className="cart-map-ico">
                            <i className="fas fa-money-bill-wave ml-2"></i>
                          </div>

                          <div className="flex-grow-1">
                            <p
                              className={`mb-0 font-14 ff-semibold ${
                                !addDetail ? "disabled" : "text-black"
                              }`}
                            >
                              Payment Method
                            </p>
                          </div>
                          <div className="text-center d-flex col-12 flex-grow-1">
                            <button
                              className="btn btn-primary  ff-semibold w-100 font-14 py-3 mx-3 bt--cart"
                              disabled={!addDetail}
                              onClick={handleData}
                            >
                              Pay AED {totalPrice.toFixed(2)}
                            </button>
                          </div>
                        </div>

                        {addDetail && (
                          <div className="By_Proceding Saved-section p-2 rounded-bottom text-center bg-2">
                            <span className="font-12 ff-medium text-dark">
                              By Proceding, you agree to our &nbsp;
                              <Link
                                to="/termsandconditions"
                                target="blank"
                                className="text-black ff-semibold font-12 "
                              >
                                T&C
                              </Link>
                              , &nbsp;
                              <Link
                                to="/privacyandpolicy"
                                target="blank"
                                className="text-black ff-semibold font-12 "
                              >
                                Privacy
                              </Link>
                              &nbsp;and&nbsp;
                              <Link
                                to="/CancellationPolicy"
                                target="blank"
                                className="text-black ff-semibold font-12 "
                              >
                                Cancellation Policy
                              </Link>
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="cart-content p-4">
                        <h2 className="font-14 ff-semibold"> Account </h2>
                        <p className="font-12 text-black">
                          To book the service, please login or signup
                        </p>
                        <button
                          className="btn btn-primary  w-100 font-14 ff-semibold py-3 me-3 bt--cart"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </div>
                    )}
                  </div>

                  {/* <CartRightSide /> */}
                  <div className="col-md-5 ">
                    <div className="cart-content p-4 mb-3">
                      {/* <div className="cart-items">
                        {cartItems?.map((item, index) => (
                          <div
                            key={index}
                            className="cart-item d-flex justify-content-between align-items-md-center align-items-start pb-4"
                          >
                            <div className="item-info flex-grow-1">
                              <h5 className="item-name font-13">
                                {item.serviceName}
                              </h5>
                              <p className="item-quantity mb-0 font-13 ">
                                Quantity: {item.Quantity}
                              </p>
                            </div>
                            <div className="item-price font-13 ff-semibold">
                              <p className="mb-0">
                                AED{" "}
                                {Number(item.Amount)
                                  .toFixed(2)
                                  .toLocaleString()}
                                {item.OldPriceAmount &&
                                  item.OldPriceAmount > item.Amount && (
                                    <span className="mx-1 text-secondary">
                                      <s className="ff-medium">
                                        {" "}
                                        {item.OldPriceAmount.toFixed(2)}{" "}
                                      </s>
                                    </span>
                                  )}
                              </p>
                            </div>
                            <button
                              className="btn btn-link text-danger delete-btn"
                              onClick={() => handleDelete(item?.recordid)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        ))}
                      </div> */}
                      <CardItem />
                      <hr />
                      <div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="confirmation"
                            checked={isConfirmed}
                            onChange={(e) => setIsConfirmed(e.target.checked)}
                          />
                          <label
                            className="form-check-label font-12"
                            htmlFor="confirmation"
                          >
                            Get order updates on WhatsApp
                          </label>
                        </div>
                      </div>
                    </div>

                    <Coupons />
                    {showDetailModel && <AddDetailModel />}
                    {showLoginModel && <LoginModel />}
                    {showAddAddressFromMap && <AddressFromMap />}

                    <div className="payment-summary-container p-4">
                      <div className="payment-summary-header">
                        <p className="payment-summary-title">Payment summary</p>
                      </div>
                      {oldTotalAmount > totalPrice && (
                        <div className="payment-summary-item">
                          <div className="d-flex justify-content-between">
                            <p className="payment-summary-label">Actual Price</p>
                            <p className="payment-summary-value discount">
                              <s className="ff-medium">
                                AED {oldTotalAmount.toFixed(2)}
                              </s>
                            </p>
                          </div>
                        </div>
                      )}

                      {totalPrice > 0 && (
                        <div className="payment-summary-item">
                          <div className="d-flex justify-content-between">
                            <p className="payment-summary-label">Item total </p>
                            <p className="payment-summary-value">
                              AED {totalPrice.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )}

                      {discount > 0 && (
                        <div className="payment-summary-item">
                          <div className="d-flex justify-content-between">
                            <p className="payment-summary-label">Discount</p>
                            <p className="payment-summary-value discount">
                              AED {discount.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )}

                      {totalPrice > 0 && (
                        <div className="payment-summary-item total">
                          <div className="d-flex justify-content-between">
                            <p className="payment-summary-label">
                              <b>Grand total</b>
                            </p>
                            <p className="payment-summary-value">
                              <b> AED {totalPrice.toFixed(2)}</b>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="container d-flex justify-content-center flex-column py-5">
              <img
                loading="lazy"
                src={cartImg}
                alt="No img"
                className="d-block mx-auto my-3"
                style={{ height: "50px" }}
              />
              <p className="text-black font-16 ff-semibold text-center">
                Hey, it feels so empty here.
              </p>
              <p className="text-black font-11 ff-semibold text-center">
                Lets add some services
              </p>
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/services/immigration"
                  className="text-decoration-none"
                >
                  <button
                    type="button"
                    className="btn-add cart-empty position-relative my-2 px-3"
                    style={{ width: "fit-content" }}
                  >
                    Explore services
                  </button>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Cart;
