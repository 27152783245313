// import React from "react";
import loader from "../../../assets/Good-Hand-02.png";

const Navbar = () => {
  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <div>
            {/* <a className="navbar-brand">  </a> */}
            <img src={loader} height={50} />
          </div>
          <div className="right d-flex align-items-center gap-3">
            <div>a</div>
            <div>b</div>
            <div>
              <div className="dropdown">
                <a
                  className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="https://github.com/mdo.png"
                    alt="mdo"
                    width="24"
                    height="24"
                    className="rounded-circle"
                  />
                </a>
                <ul className="dropdown-menu text-small shadow">
                  <li>
                    <a className="dropdown-item">New project...</a>
                  </li>
                  <li>
                    <a className="dropdown-item">Settings</a>
                  </li>
                  <li>
                    <a className="dropdown-item">Profile</a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item">Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
