import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeAddDetailModel } from "../../store/slices/modalSlice";

const AddDetailModel = () => {
  const dispatch = useDispatch();

  const showDetailModel = useSelector(
    (state: { modal: { addDetailModel: boolean } }) =>
      state.modal.addDetailModel
  );

  const closeAddAddress = () => {
    dispatch(closeAddDetailModel());
  };

  return (
    <>
      <Modal show={showDetailModel} onHide={closeAddAddress}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div>
              <div className="section">
                <div className="main-heading">
                  Required Documents for Security Deposit
                </div>
                <ul className="sub-text">
                  <li>Entry Permit Application</li>
                  <li>Sponsor ID Copy</li>
                  <li>Applicant Passport</li>
                </ul>
              </div>
            </div>
          </div>

          <form className="form-main bg-2 p-4 border-radius-10">
            <div className="row">
              <div className="sub-hd1 col-12 text-decoration-underline">
                <p className="font-21">Applicant’s Details</p>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="applicantName-1103" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="applicantName-1103"
                    placeholder="Applicant name"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="applicantPassport-1103"
                    className="form-label"
                  >
                    Passport No
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="applicantPassport-1103"
                    placeholder="Applicant Passport No"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="sub-hd1 col-12 text-decoration-underline">
                <p>Sponsor's Details</p>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="sponsorName-1103" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sponsorName-1103"
                    placeholder="Sponsor name"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="sponsorPassport-1103" className="form-label">
                    Passport No
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sponsorPassport-1103"
                    placeholder="Sponsor's Passport No"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="upload-button-container">
                  <label htmlFor="fileInput-1103" className="form-label">
                    Please upload all the necessary documents.
                  </label>
                  <div
                    id="drop-zone"
                    className="drop-zone"
                    style={{
                      border: "2px dashed rgb(56, 89, 134)",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ textAlign: "justify" }}
                    >
                      <svg
                        width="34"
                        height="33"
                        viewBox="0 0 34 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                      >
                        <path
                          d="M15.625 22V10.7938L12.05 14.3688L10.125 12.375L17 5.5L23.875 12.375L21.95 14.3688L18.375 10.7938V22H15.625ZM8.75 27.5C7.99375 27.5 7.34635 27.2307 6.80781 26.6922C6.26927 26.1536 6 25.5063 6 24.75V20.625H8.75V24.75H25.25V20.625H28V24.75C28 25.5063 27.7307 26.1536 27.1922 26.6922C26.6536 27.2307 26.0063 27.5 25.25 27.5H8.75Z"
                          fill="#385986"
                        ></path>
                      </svg>
                      <p className="mx-2">
                        Drag and drop files here or click to upload
                      </p>
                    </div>
                    <input
                      id="fileInput-1103"
                      type="file"
                      multiple
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn btn-primary w-100"
            onClick={closeAddAddress}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDetailModel;
