import { useDispatch } from "react-redux";
import { closeUploadFileModel } from "../../store/slices/modalSlice";

const UploadFileModel = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeUploadFileModel());
  };
  return (
    <div
      className="modal show d-block border"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header border-0">
            {/* <h5 className="modal-title">Choose an Option</h5> */}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
                onClick={handleCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Upload Options */}
            <div className="upload-option mb-2">
              <i className="fas fa-folder"></i>
              <span>Upload from Files</span>
            </div>
            <div className="upload-option mb-2">
              <i className="fas fa-camera"></i>
              <span>Take a Picture</span>
            </div>
            <div className="upload-option">
              <i className="fas fa-file-alt"></i>
              <span>Upload from Documents</span>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn btn-primary w-100"
                onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFileModel;
