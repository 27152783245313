// import AboutImage from "../../../assets/spouse-visa/walk.jpeg";
// import { spouseVisaData } from "../../../datas/SpouseVisaDubai";

interface Section2 {
  heading: string;
  content: string[];
  image: string;
}

interface Props {
  // section2: Section2 | undefined;
  section2?: Section2; // You can make section3 optional if necessary
}

const WhoWeAre: React.FC<Props> = ({ section2 }) => {
  return (
    <>
      <section className="pk-about-sec py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-auto wow animate__zoomIn">
              <img
                src={section2?.image}
                // src="https://goodhand.b-cdn.net/Assets/Footer_pages/SpouseVisa/walk-CQuFUhhm.jpeg"
                alt="Immigration Services"
                className="img-fluid pk-ab-image d-none d-md-block"
              />
            </div>

            <div className="col-md-7 my-auto mt-2 mt-md-4 wow animate__fadeInRight">
              <div className="hd-style-2 ">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section2?.heading ?? "",
                  }}
                ></h2>
              </div>
              <div className="description">
                {section2?.content.map((desc) => (
                  <p key={desc}>{desc}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
