import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { openNewLogin } from "../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { logoutFun } from "../../config/auth";

export default function MobileNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Function to check login status from localStorage
    const checkLoginStatus = () => {
      const loginData = localStorage.getItem("Login_info");
      if (loginData) {
        setIsLoggedIn(true);
      }
    };

    // Set up event listener for storage changes
    window.addEventListener("storage", checkLoginStatus);

    // Initial check when component mounts
    checkLoginStatus();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, [isLoggedIn]);

  // Toggle dropdown visibility
  // function toggleDropdown() {
  //   setIsDropdownVisible(!isDropdownVisible);
  // }

  return (
    <>
      <nav className="navbar navbar-footer navbar-light mob-nav-mia fixed-bottom bottom-nav d-block d-md-none">
        <div className="container-fluid">
          <div className="nav-item active">
            <NavLink className="nav-link" to="/">
              <img
                src="https://goodhand.b-cdn.net/Good-Hand-02-vdjwclCG.png"
                className="img-fluid"
                alt=""
                style={{ height: "25px" }}
              />

              <span className="font-9 ff-medium">GH</span>
            </NavLink>
          </div>
          <div
            className={`nav-item`}
            style={{
              pointerEvents: !isLoggedIn ? "none" : "auto",
              opacity: !isLoggedIn ? 0.6 : 1,
              cursor: !isLoggedIn ? "not-allowed" : "default",
            }}
          >
            {/* <NavLink to="/orderflow" className="nav-link"> */}
            <NavLink to="/booking" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#B0B0B0"
                  fillRule="evenodd"
                  d="M8.25 1.5a.75.75 0 00-.75.75V3H5.25a1.5 1.5 0 00-1.5 1.5v15.75a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V4.5a1.5 1.5 0 00-1.5-1.5H16.5v-.75a.75.75 0 00-.75-.75h-7.5zM16.5 6a.75.75 0 01-.75.75h-7.5A.75.75 0 017.5 6V4.5H5.25v15.75h13.5V4.5H16.5V6zM9 5.25V3h6v2.25H9zm6 10.5H9v-1.5h6v1.5zm-6-4.5h6v-1.5H9v1.5z"
                  clipRule="evenodd"
                ></path>
              </svg>

              <span className="font-9 ff-medium">Booking</span>
            </NavLink>
          </div>

          {/* <div className="nav-item">
            <NavLink to="/cart" className="nav-link">
              <div className="badge-wrapper">
                <div className="badge-link">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0208 18.6532L14.9739 6.58752C14.902 6.00627 14.3989 5.56876 13.8082 5.56876H11.9395V3.42186C11.9395 1.53121 10.4176 0 8.51445 0C6.62697 0 5.09254 1.53439 5.09254 3.42186V5.56876H3.22378C2.63315 5.56876 2.13316 6.00627 2.06129 6.57501L0.0113149 18.6594C-0.0355398 18.9938 0.0644691 19.3344 0.286328 19.5906C0.511359 19.85 0.839479 20 1.18319 20H15.8488C16.1926 20 16.5207 19.85 16.7457 19.5906C16.9707 19.3344 17.0707 18.9938 17.0208 18.6532ZM5.71761 3.42186C5.71761 1.88122 6.97073 0.624974 8.51449 0.624974C10.0676 0.624974 11.3145 1.87184 11.3145 3.42186V5.56876H5.71761V3.42186ZM16.2739 19.1813C16.1676 19.3032 16.0145 19.3751 15.8489 19.3751H1.18319C1.02069 19.3751 0.864447 19.3032 0.758185 19.1813C0.651923 19.0594 0.608195 18.9063 0.626955 18.7563L2.67697 6.66877C2.71132 6.40001 2.95199 6.19378 3.22387 6.19378H5.09263V7.95941C4.40825 8.10627 3.89575 8.7094 3.89575 9.43443C3.89575 10.2657 4.57387 10.9438 5.40825 10.9438C6.23949 10.9438 6.91449 10.2657 6.91449 9.43443C6.91449 8.70945 6.40199 8.10319 5.71761 7.95941V6.19378H11.3145V7.95941C10.6301 8.10627 10.1176 8.7094 10.1176 9.43443C10.1176 10.2657 10.7958 10.9438 11.6301 10.9438C12.4614 10.9438 13.1364 10.2657 13.1364 9.43443C13.1364 8.70945 12.6239 8.10319 11.9395 7.95941V6.19378H13.8082C14.0801 6.19378 14.3207 6.40001 14.3551 6.68127L16.4052 18.7469C16.427 18.9063 16.3801 19.0594 16.2739 19.1813Z"
                      fill="black"
                    />
                  </svg>

                  <span className="badge-count">{cartValue}</span>
                </div>
                <span>Cart</span>
              </div>
            </NavLink>
          </div> */}

          <div className="nav-item">
            <NavLink
              to="/helpcenter"
              // className="nav-link"
              className="text-decoration-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#B0B0B0"
                  d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
                ></path>
              </svg>
              <span className="font-9 ff-medium">Help </span>
            </NavLink>

            {/* Dropdown menu */}
            {isDropdownVisible && (
              <div
                className="dropdown-menu show"
                style={{ position: "absolute", bottom: "68px", right: "10px" }}
              >
                {/* <NavLink className="dropdown-item" to="/profile">
                  View Profile
                </NavLink>
                <NavLink className="dropdown-item" to="/addRemoveUpdataAddress">
                  Add Address
                </NavLink> */}
                {/* <NavLink className="dropdown-item " to="/orderflow"> */}
                <NavLink className="dropdown-item " to="/booking">
                  <span className="font-9 ff-medium">Order</span>
                </NavLink>
                {/* <NavLink className="dropdown-item" to="/setting">
                  Settings
                </NavLink> */}
                <NavLink
                  className="dropdown-item"
                  to="/"
                  onClick={() => {
                    logoutFun(dispatch, navigate);
                    setIsDropdownVisible(false);
                    setIsLoggedIn(false);
                  }}
                >
                  <span className="font-9 ff-medium"> Logout</span>
                </NavLink>
              </div>
            )}
          </div>
          <div className="nav-item">
            <NavLink
              to=""
              // className="nav-link"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
              // onClick={toggleDropdown}
              onClick={(e) => {
                const loginData = localStorage.getItem("Login_info");
                if (!loginData) {
                  e.preventDefault(); // Prevent default navigation
                  // navigate('/userlogout')
                  navigate("/profile");
                  // dispatch(openNewLogin()); // Dispatch action to open login
                  setIsLoggedIn(true);
                } else {
                  e.preventDefault(); // Prevent NavLink's default navigation
                  // toggleDropdown();
                  navigate("/profile");
                }
              }}
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.026 14.727a7 7 0 10-8.051 0 11.5 11.5 0 00-5.936 5.022l1.732 1A9.504 9.504 0 0112 16a9.504 9.504 0 018.229 4.75l1.732-1a11.504 11.504 0 00-5.935-5.023zM7 9a5 5 0 115 5 5 5 0 01-5-5z"
                  fill="#B0B0B0"
                ></path>
              </svg>
              {isLoggedIn ? (
                <span> {isLoggedIn} Account </span>
              ) : (
                <span>Login </span>
              )}
              {/* {localStorage.getItem("Login_info") ? <span>Settings</span> : <span>Login</span>} */}
              {/* <span>{name === t}</span> */}
            </NavLink>

            {/* Dropdown menu */}
            {isDropdownVisible && (
              <div
                className="dropdown-menu show"
                style={{ position: "absolute", bottom: "68px", right: "10px" }}
              >
                {/* <NavLink className="dropdown-item" to="/profile">
                  View Profile
                </NavLink>
                <NavLink className="dropdown-item" to="/addRemoveUpdataAddress">
                  Add Address
                </NavLink> */}
                {/* <NavLink className="dropdown-item" to="/orderflow"> */}
                <NavLink className="dropdown-item" to="/booking">
                  Order
                </NavLink>
                {/* <NavLink className="dropdown-item" to="/setting">
                  Settings
                </NavLink> */}
                <NavLink
                  className="dropdown-item"
                  to="/"
                  onClick={() => {
                    logoutFun(dispatch, navigate);
                    setIsDropdownVisible(false);
                    setIsLoggedIn(false);
                  }}
                >
                  Logout
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
