import { useDispatch } from "react-redux";
import { ProfessionalLanguages } from "../../../../types/homeComponentTypes";
import { openLanguadesModel } from "../../../../store/slices/modalSlice";
interface Props {
  data?: ProfessionalLanguages;
}

const ProfessionalLanguagesTranslation: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const handleOpenModel = () => {
    dispatch(openLanguadesModel());
  };
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row flex-row-reverse flex-md-row">
            {/* Left Column */}
            <div className="col-md-6">
              <span
                className="font-14 ff-semibold pb-3 text-uppercase"
                dangerouslySetInnerHTML={{
                  __html: data?.heading ?? "",
                }}
              ></span>
              <div className="hd-style-2 golden wow animate__fadeIn">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.subheading ?? "",
                  }}
                ></h2>
              </div>

              <p
                className="font-14 "
                dangerouslySetInnerHTML={{
                  __html: data?.description ?? "",
                }}
              ></p>

              <div className="text-center d-md-flex d-none">
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={handleOpenModel}
                >
                  Explore all Languages
                </button>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="row">
                {data?.languages?.map((item) => (
                  <div className="col-12 col-md-6 mb-4 mb-md-5" key={item.id}>
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <img
                          src={item.image}
                          alt={`${item.heading} flag`}
                          className="flagStyle "
                        />
                      </div>
                      <div className="flex-grow-1">
                        <p
                          className="mb-1 font-14 ff-semibold text-black"
                          dangerouslySetInnerHTML={{
                            __html: item.heading ?? "",
                          }}
                        >
                        </p>
                        <p
                          className="mb-0 font-12"
                          dangerouslySetInnerHTML={{
                            __html: item.description ?? "",
                          }}
                        >
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="text-center d-md-flex d-block d-md-none">
                  <button className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart">
                    Explore all Languages
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfessionalLanguagesTranslation;
