import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../../../assets/ajax-loader.gif";
import {
  closePackageModel,
  // openNewLogin,
} from "../../../store/slices/modalSlice";
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
// import { logoutFun } from "../../../config/auth";
import { packageDetail, PackageModelInterface } from "../../../types/packageModel";
import { setTrue } from "../../../store/slices/IdSlice";
const baseUrl = import.meta.env.VITE_API_URL;

interface check {
  PriceRecordId: number;
  serviceName: string;
  check: boolean;
}

interface Service {
  PriceRecordId: number;
  PriceType: string;
  PriceAmount: number;
  PriceCurrency: string;
  serviceName: string;
  sub2ServiceName: string;
  sub2ImgSrc: string;
  insideDescription: string;
}

// Interface for the main package data
interface PackageData {
  RecordId: number;
  Name: string;
  options: string;
  outSide: string;
  insidePoints: string;
  slugURL: string;
  imgUrl: string;
  imgAlt: string;
  separatedDescriptions: string[];
  total: number;
  services: Service[];
}

interface Props {
  data?: PackageData[];
}

const PackageDescriptionModel: React.FC<Props> = ({ data }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [packageid, setID] = useState<string | number>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [packageData, setPackageData] = useState<PackageModelInterface>();
  const [selectedServices, setSelectedServices] = useState<check[]>();
  const [packageDetails, setPackageDetails] = useState<packageDetail>();
  
  console.log(packageid)

  const handleCheckboxChange = (id: number) => {
    setSelectedServices((prev) =>
      prev?.map(
        (service) =>
          service.PriceRecordId === id
            ? { ...service, check: !service.check } // Toggle the 'check' value
            : service // Keep the service unchanged
      )
    );
  };

  const isOpenFooterModel = useSelector(
    (state: { modal: { packageModel: boolean } }) => state.modal.packageModel
  );

  function transformData(data: Service[]) {
    return data.map((item) => ({
      PriceRecordId: item.PriceRecordId,
      serviceName: item.serviceName,
      check: true,
    }));
  }

  const getData = async (serviceid: string) => {
    const uuid = localStorage.getItem("uuid");
    // console.log("response data : junaid  cartitem 1");
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await axios.get(
        `${baseUrl}/api/openRoute/servicesPackageDescrption?ServiceId=${serviceid}&uuid=${uuid}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        // console.log(
        //   "response data : servicesPackagesDescrption",
        //   response.data.services
        // );
        setPackageData(response.data);
        setPackageDetails(response.data.packageDetails);
        const result = transformData(response.data.services);
        // console.log("response data : servicesPackagesDescrption",result);
        setSelectedServices(result);
        // console.log(
        //   "response data : servicesDescrption",
        //   response.data.servicesDescrption.quantity
        // );
        // setHtmlContent(
        //   response.data.servicesDescrption.serviceDetails.insideDescription
        // );
        // setPriceData(response.data.servicesDescrption.servicePrices);
        // setImg(
        //   response.data.servicesDescrption.serviceDetails?.imageSrc ?? undefined
        // );
        // setServiceName(
        //   response.data.servicesDescrption.serviceDetails.serviceName
        // );

        // const pricesWithQuantities = mapPricesWithQuantities(
        //   response.data.servicesDescrption.servicePrices,
        //   response.data.servicesDescrption.quantity
        // );

        // setQuantityData(pricesWithQuantities);
        // calculateTotal(pricesWithQuantities);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.log("Error on CartItems", error);
    }
  };

  useEffect(() => {
    const serviceid = sessionStorage.getItem("serviceid");
    if (serviceid) {
      getData(serviceid);
    }
  }, []);

  const handleClose = () => {
    dispatch(closePackageModel());
  };

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    // console.log("imppo",PriceRecordId,ServiceId,op)
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
        handleClose();
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleBookNow = () => {
    console.log(
      "packageData..",
      packageDetails
    );

    addDataToDataBase(packageDetails?.PriceId ?? 0, packageDetails?.serviceid ?? 0, "plus");
  };

  useEffect(() => {
    if (data) {
      setID(data[0].RecordId.toString());
    }
  }, []);

  // useEffect(() => {
  //   const initialData = packageData?.services?.map((service) => ({
  //     PriceRecordId: service.PriceRecordId,
  //     status: true,
  //   }));
  //   setFormData(initialData);
  // }, [packageData]);

  // const handleCheckboxChange = (priceRecordId: number) => {
  //   console.log("priceRecordId",priceRecordId)
  //   // setFormData((prevState) => {
  //   //   // Check if the record already exists in the state
  //   //   const existingRecord = prevState.find(
  //   //     (item) => item.PriceRecordId === priceRecordId
  //   //   );

  //   //   if (existingRecord) {
  //   //     // If it exists, toggle its status
  //   //     return prevState.map((item) =>
  //   //       item.PriceRecordId === priceRecordId
  //   //         ? { ...item, status: !item.status }
  //   //         : item
  //   //     );
  //   //   } else {
  //   //     // If it doesn't exist, add it with status true
  //   //     return [...prevState, { PriceRecordId: priceRecordId, status: true }];
  //   //   }
  //   // });
  // };

  // if (formData) {
  //   console.log("FooterModel footer ", formData);
  // }

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }
  return (
    <>
      <Modal show={isOpenFooterModel} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  flexDirection: "column",
                  height: "50px",
                  padding: "50px 2px",
                }}
              >
                <img src={PreLoader} />
                <h1>Loading ... </h1>
              </div>
            </>
          ) : (
            <>
              <div>
                {/* {packageData?.map((item) => ( */}
                <>
                  <div
                    className="row"
                    key={packageData?.packageDetails?.imageSrc}
                  >
                    <div className="col-12">
                      <div className="image-container d-flex align-items-center justify-content-start gap-3 mb-4 list-card-main shadow-none p-0">
                        <img
                          src={packageData?.packageDetails?.imageSrc}
                          alt={packageData?.packageDetails?.imgAlt}
                          className="img-fluid d-block"
                        />
                        <div className="sub-hd1">
                          <p>
                            {packageData?.packageDetails?.serviceName ??
                              "no name"}
                          </p>

                          <p>
                            Total Price:{" "}
                            <span className="text-primary">
                              AED {packageData?.totalPrice.toFixed()}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-container">
                    <p className="ff-bold font-18 text-black mb-0 text-primary ">
                      Services Included
                    </p>
                    {packageData?.services?.map((service, index) => (
                      <div
                        key={index}
                        className="service-card-wrapper mb-5 position-relative"
                      >
                        <span className="in-badge position-absolute top-0 badge2 ms-3">
                           {service.sub2ServiceName}
                        </span>

                        <div className="section mt-4 p-3 border rounded">
                          <div className="d-flex justify-content-start py-1 gap-2 align-items-start">
                              <div className="form-check ps-0">
                              <input type="checkbox" checked={true} readOnly />
                                <input
                                  disabled 
                                  className="d-none"
                                  type="checkbox"
                                  value={service.PriceRecordId}
                                  checked={
                                    selectedServices?.find(
                                      (selected) =>
                                        selected.PriceRecordId ===
                                          service.PriceRecordId &&
                                        selected.check === true
                                    ) !== undefined
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(service.PriceRecordId)
                                  }
                                />
                              </div>
                              <div className="service-name">
                              <p className="ff-semibold text-dark mb-1">
                                {service.serviceName}
                              </p>
                            </div>
                          </div>
                          {/* <div className="d-flex justify-content-between align-items-center border-bottom">
                          <div className="form-check">
                                <input
                                  type="checkbox"
                                  value={service.PriceRecordId}
                                  checked={
                                    selectedServices?.find(
                                      (selected) =>
                                        selected.PriceRecordId ===
                                          service.PriceRecordId &&
                                        selected.check === true
                                    ) !== undefined
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(service.PriceRecordId)
                                  }
                                />
                              </div>
                            <div className="service-name">
                              <p className="ff-semibold text-dark mb-1">
                                {service.serviceName}
                              </p>
                            </div>
                            <div className="service-price col-4 text-end">
                              <p className="text-primary ff-semibold mb-0 font-14">
                                AED {service.PriceAmount}
                              </p>
                            </div>
                          </div> */}
                          <div
                            style={{ marginTop: "5px" }}
                            dangerouslySetInnerHTML={{
                              __html: service?.insideDescription ?? " ",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
                {/* ))} */}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleBookNow}>Book Now</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PackageDescriptionModel;
