//
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the types for the cart item and initial state
interface CartItem {
  userName: string,
  id: string;
  address_type: string,
  addresNickname: string;
  streetName: string;
  buildingName: string;
  floor: string;
  flat: string;
  Landmark: string;
  isPrimary: boolean;
}

interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: [],
};

const shippingAddressSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    addShippingAddress: (
      state,
      action: PayloadAction<{
        userName: string,
        id: string;
        address_type: string,
        addresNickname: string;
        streetName: string;
        buildingName: string;
        floor: string;
        flat: string;
        Landmark: string;
        isPrimary: boolean;
      }>
    ) => {
      const { userName, id, address_type, addresNickname, streetName, buildingName, floor, flat, Landmark, isPrimary } = action.payload;
      if (isPrimary) {
        state.items = state.items.map((item) =>
          item.isPrimary ? { ...item, isPrimary: false } : item
        );
      }
      state.items.push({
        userName, id, address_type, addresNickname, streetName, buildingName, floor, flat, Landmark, isPrimary
      });
    },
    toggleShippingPrimary: (
      state,
      action: PayloadAction<{ id: string; isPrimary: boolean }>
    ) => {
      const { id, isPrimary } = action.payload;

      state.items = state.items.map((item) =>
        item.id === id ? { ...item, isPrimary: isPrimary } : { ...item, isPrimary: false }
      );
    },
    deleteShippingAddres : (state, action: PayloadAction<{ id: string }>) =>{
      const { id } = action.payload;
      state.items = state.items.filter(
          (item) => item.id !== id
        );
    },
    updateShippingAddress: (
      state,
      action: PayloadAction<{
        userName: string;
        id: string;
        address_type?: string;
        addresNickname?: string;
        streetName?: string;
        buildingName?: string;
        floor?: string;
        flat?: string;
        Landmark?: string;
        isPrimary?: boolean;
      }>
    ) => {
      const {userName, id, address_type, addresNickname, streetName, buildingName, floor, flat, Landmark, isPrimary } = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            userName: userName ?? item.userName,
            address_type: address_type ?? item.address_type,
            addresNickname: addresNickname ?? item.addresNickname,
            streetName: streetName ?? item.streetName,
            buildingName: buildingName ?? item.buildingName,
            floor: floor ?? item.floor,
            flat: flat ?? item.flat,
            Landmark: Landmark ?? item.Landmark,
            isPrimary: isPrimary !== undefined ? isPrimary : item.isPrimary,
          };
        }
        return item;
      });
    },
  },
  },
);

export const { addShippingAddress, toggleShippingPrimary, deleteShippingAddres, updateShippingAddress } = shippingAddressSlice.actions;

export default shippingAddressSlice.reducer;
