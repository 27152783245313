// import { spouseVisaData } from "../../../datas/SpouseVisaDubai";
// import TickIco from "../../../assets/spouse-visa/tickIcon-blue.svg";
// import IconCart from "../../../assets/Shopping cart.svg";
// import FooterModel from "../footerModel/FooterModel";
import { useDispatch } from "react-redux";
// import { openFooterModel } from "../../../store/slices/modalSlice";
import { useState } from "react";
import { getID, setTrue } from "../../../store/slices/IdSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PackageData } from "../../../types/typeofvisa";
// import { PackageData } from "../../../types/typeofvisa";

const baseUrl = import.meta.env.VITE_API_URL;
interface Props {
  data?: PackageData[];
  section4?: {
    heading: string;
  };
}

const RequiredDocuments: React.FC<Props> = ({ data, section4 }) => {
  // const [modelData, setModelData] = useState<ResponseItem[]>();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleReadMore = (index: number) => {
    document.body.classList.toggle("overlay-add", expandedIndex !== index);
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  // const isOpenFooterModel = useSelector(
  //   (state: { modal: { footerModel: boolean } }) => state.modal.footerModel
  // );

  // const hanldeFooterModel = () => {
  //   dispatch(openFooterModel());
  // };
  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string,
    url: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    // console.log("imppo",PriceRecordId,ServiceId,op)
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
        const insideId = ServiceId;
        const outsideId = PriceRecordId; 
        // here we only required inside in and two function in the cart for that 
        // getID 
        // getInsideID({ insideId}));
        dispatch(getID({ insideId, outsideId }));
        navigate(`/${url}`);
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleModelData = (priceId: number, serviceId: number, URL: string) => {
    addDataToDataBase(priceId, serviceId, "plus", URL);
    // hanldeFooterModel();
    // const filteredData = data?.filter((item) => item.RecordId === id);
    // setModelData(filteredData);
  };
  return (
    <>
      <section id="pkg">
        <div className="container mt-4">
          <div className="row">
            <div className="container">
              <div className="hd-style-2  py-4 wow animate__fadeInLeft">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section4?.heading ?? " ",
                  }}
                ></h2>
              </div>
              {/* {isOpenFooterModel && modelData && (
                <FooterModel data={modelData} />
              )} */}
              <div className="row justify-content-center">
                {data?.map((service, index) => (
                  <div
                    className="col-md-6 col-sm-6 mb-3 my-md-4"
                    key={service.RecordId}
                  >
                    <div className="experience-item">
                      <div className="image-container">
                        <img
                          src={service.imgUrl}
                          alt={service.imgAlt || "Experience Icon"}
                          className="experience-image allpk wow animate__zoomIn"
                        />
                        <div
                          className="custom-badge wow animate__fadeIn d-none d-md-block"
                          data-wow-duration="3s"
                        >
                          {service.Name}
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <p className="font-14 text-black ff-bold d-block d-md-none">
                          {" "}
                          {service.Name}{" "}
                        </p>
                        <div className="package-modal position-relative">
                          <div
                            className={` gl-close  d-md-none ${
                              expandedIndex === index ? "show" : "hide"
                            }`}
                          >
                            <span
                              className="text-decoration-none d-md-none"
                              onClick={() => toggleReadMore(index)}
                            >
                              <i className="fa fa-close"></i>
                            </span>
                          </div>
                          <div
                            className={`experience-text ${
                              expandedIndex === index ? "expanded" : ""
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: service?.outSide ?? " ",
                            }}
                          ></div>
                        </div>
                        <a
                          className="text-decoration-none d-block d-md-none flex-grow-1 ff-semibold"
                          onClick={() => toggleReadMore(index)}
                        >
                          {expandedIndex === index ? "Read Less" : "Read More"}
                        </a>
                      </div>

                      <div className="d-flex justify-content-between align-items-center ">
                        <a
                          className="btn-link text-decoration-none d-none d-md-block cursor-pointer"
                          onClick={() => toggleReadMore(index)}
                        >
                          {expandedIndex === index ? "Read Less" : "Read More"}
                        </a>
                        <a
                          className="btn-link position-relative text-decoration-none onImage d-flex align-items-center gap-2 justify-content-end h-100 p-2 cursor-pointer "
                          onClick={() =>
                            handleModelData(
                              service.priceId,
                              service.serviceid,
                              service.URL
                            )
                          }
                        >
                          <span className="ff-semibold">Add</span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequiredDocuments;
