import React, { useState, useEffect } from "react";
import axios from "axios";
import { useData } from "../../components/toast/Api";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";

const apiUrl = import.meta.env.VITE_API_URL;

const Contact = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    // showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();

  const url = `${apiUrl}/api/openRoute/contactus`;

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState<{
    name?: string;
    number?: string;
    email?: string;
    message?: string;
  }>({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [metaData, setMetaData] = useState<SEOData | null>(null);

  useEffect(() => {
    const data = {
      "hidden":{
          "heading" : "",
          "description" : ""
      },
      "seo" : {
        "shortcut_icon": {
          "type": "image/x-icon",
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "apple_touch_icon": {
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "title": "Goodhand Contact Us",
        "Keywords": "",
        "description": "Goodhand Contact Us",
        "canonical": "https://www.goodhand.ae/contactus",
        "meta": [
          {
            "name": "description",
            "content": "Goodhand Contact Us"
          },
          {
            "name": "url",
            "content": "https://www.goodhand.ae/contactus"
          },
          {
            "name": "og:title",
            "content": "Goodhand Contact Us"
          },
          {
            "name": "og:type",
            "content": "website"
          },
          {
            "name": "og:url",
            "content": "https://www.goodhand.ae/contactus"
          },
          {
            "name": "og:image",
            "content": "https://goodhand.ideaintl.net/assets/logo-main-gFDCiCbr.svg"
          },
          {
            "name": "og:site_name",
            "content": "https://www.goodhand.ae"
          },
          {
            "name": "og:description",
            "content": "Goodhand Contact Us"
          },
          {
            "name": "twitter:card",
            "content": "summary_large_image"
          },
          {
            "name": "twitter:title",
            "content": "Goodhand Contact Us"
          },
          {
            "name": "twitter:description",
            "content": "Goodhand Contact Us"
          },
          {
            "name": "twitter:image",
            "content": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          {
            "name": "twitter:site",
            "content": "@GoodHandUAE"
          }
        ],
        scripts: [],
        noscript: []
      }
    };
    setMetaData(data?.seo);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const validate = () => {
    const newErrors: {
      name?: string;
      number?: string;
      email?: string;
      message?: string;
    } = {};
    const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for valid email
    const numberRegex = /^\d{1,11}$/; // Regular expression to validate up to 11 digits
    const nameRegex = /^[A-Za-z\s]+$/; // Regular expression to validate only English alphabets and spaces
    const messageRegex = /^[^0-9]*$/; // Ensures message is not just digits

    // Validate name (must contain only English alphabets)
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!nameRegex.test(formData.name)) {
      newErrors.name = "Name can only contain English alphabets";
    }

    // Validate number (must be numeric and up to 11 digits)
    if (!formData.number) {
      newErrors.number = "Number is required";
    } else if (!numberRegex.test(formData.number)) {
      newErrors.number = "Number must be a valid 11-digit number";
    }

    // Validate email (must be a valid email)
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // Validate message (must be a string and not only digits)
    if (!formData.message) {
      newErrors.message = "Message is required";
    } else if (!messageRegex.test(formData.message)) {
      newErrors.message = "Message cannot contain only digits";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const clearAllField = () => {
    setFormData({
      name: "",
      number: "",
      email: "",
      message: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    if (validate()) {
      try {
        const response = await axios.post(url, formData);
        if (response) {
          clearAllField();
          showSuccess("Message sent successfully!");
        }
      } catch (error) {
        showError(
          "An error occurred while sending the message. Please try again."
        );
      } finally {
        setLoading(false);
      }
    } else {
      showError("Please Check all fields");
    }
  };

  return (
    <>
      {metaData && <MetaHead metaData={metaData} />}
      <div
        className="contact-section container"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <div className="hd-style-2">
          <h2>
            Contact
            <span> Us </span>
          </h2>
          <p>Any question or remarks? Just write us a message!</p>
        </div>
        <div className="row">
          {/* Contact Information Section */}

          {/* Contact Form Section */}
          <div className="col-md-6 contact-form-section bg-2 py-2 px-4 rounded">
            <form className="form-main py-3" onSubmit={handleSubmit}>
              <div className="form-group py-3">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div className="alert alert-danger mt-3">{errors.name}</div>
                )}
              </div>
              <div className="form-group py-3">
                <label className="form-label" htmlFor="number">
                  Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="number"
                  placeholder="Enter your number"
                  value={formData.number}
                  onChange={handleChange}
                />
                {errors.number && (
                  <div className="alert alert-danger mt-3">{errors.number}</div>
                )}
              </div>
              <div className="form-group py-3">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="alert alert-danger mt-3">{errors.email}</div>
                )}
              </div>
              <div className="form-group py-3">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Your message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <div className="alert alert-danger mt-3">{errors.message}</div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Submit"}
                </button>
              </div>
            </form>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            {success && <div className="alert alert-success mt-3">{success}</div>}
          </div>

          <div className="col-md-6 info-section shadow-none px-4">
            <h5 className="card-title font-16 ff-semibold pb-4">
              Goodhand Government Transactions LLC Dubai
            </h5>
            <div>
              <p>
                <i className="fas fa-clock me-2"></i> Open until 08:00 PM
              </p>
              <p>
                <i className="fas fa-map-marker-alt me-2"></i> M-01 Saraya Plaza,
                Al Rigga Road, Al Rigga - Dubai
              </p>
              <p>
                <i className="fas fa-phone me-2"></i>
                <a
                  href="tel:+97142979997"
                  target="_blank"
                  className="FooterHref text-decoration-none"
                >
                  +971 42 97 9997
                </a>
              </p>
              <p>
                <i className="fab fa-whatsapp me-2"></i>
                <a
                  href="https://web.whatsapp.com/send?phone=97142979998"
                  target="_blank"
                  className="text-decoration-none"
                >
                  <span>+971 42 97 9998</span>
                </a>
              </p>
            </div>

            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115464.62026250697!2d55.327352!3d25.261522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdadcbbb60f%3A0x7825f27cc5151615!2zQWwgQWRoZWVkIC0gR29vZCBIYW5kIEZpcnN0IEdvdmVybm1lbnQgU2VydmljZXMtINin2YTYudi22YrYryDigJMg2KzZiNivINmH2KfZhtivINmB2YrYsdiz2Kog2YTZhNiu2K_Zhdin2Kog2KfZhNit2YPZiNmF2YrYqQ!5e0!3m2!1sen!2sae!4v1723896548309!5m2!1sen!2sae"
                width="100%"
                height="410"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
