import { useEffect, useState } from "react";
import AddAddress from "../../components/addAddress/AddAddress";
import SideCard from "../../components/thirdsideCard/SideCard";
import { Accordion } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import {
// addBillingAddress,
// deleteBillingAddres,
// toggleBillingPrimary,
// updateBillingAddress,
// } from "../../store/slices/billingAddressSlice";
// import {
// deleteShippingAddres,
// toggleShippingPrimary,
// updateShippingAddress,
// } from "../../store/slices/shippingAddressSlice";
import mapImg from "../../assets/red.png";
import { useNavigate } from "react-router-dom";
import { useData } from "../../components/toast/Api";
// import Form from "react-bootstrap/Form";
// import { ToastContainer } from "react-toast";

// interface items {
//   userName: string;
//   RecordId: string;
//   address_type: string;
//   addresNickname: string;
//   streetName: string;
//   building: string;
//   floor: string;
//   flatNo: string;
//   landmark: string;
//   primaryAddress: boolean;
// }
interface Address {
  RecordId: number;
  userName: string;
  session: string | null;
  address_type: string | null;
  address_detail: string | null;
  address: string | null;
  status: string | null;
  createdon: string | null;
  modifiedon: string;
  addresNickname: string;
  streetName: string;
  building: string;
  floor: string;
  flatNo: string;
  landmark: string;
  primaryAddress: number;
}

// interface RootState {
//   billing: {
//     items: Address[];
//   };
//   shipping: {
//     items: Address[];
//   };
// }
const baseUrl = import.meta.env.VITE_API_URL;

const AddressPage: React.FC = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [code, setCode] = useState<string>("");

  const [mode, setMode] = useState("Billing");
  const [billingData, setBillingData] = useState<Address[]>([]);
  const [shippingData, setShippingData] = useState<Address[]>([]);
  // const [hasBillingAddress, setHasBillingAddress] = useState(true);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  // const [hasShippingAddress, setHasShippingAddress] = useState(true);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [selectedBillingAddress, setBillingSelectedAddress] = useState(0);
  const [selectedShippingAddress, setShippingSelectedAddress] = useState(0);
  const [checked, setChecked] = useState<number | null>(null);

  // const billinglocal = useSelector(
  //   (store: { billing: { items: items[] } }) => store.billing.items
  // );
  // console.log("billinglocal", billinglocal);

  const handleCloseBillingModal = () => {
    setIsBillingModalOpen(false);
  };

  const handleCloseShippingModal = () => {
    setIsShippingModalOpen(false);
  };

  const handleOpenModal = (val: string) => {
    // console.log("AddressPage :: val ", val);
    // console.log("AddressPage :: hasBillingAddress ", isBillingModalOpen);
    // console.log("AddressPage :: IsShippingModalOpen ", isShippingModalOpen);
    if (val == "Billing") {
      setMode("Billing");
      // console.log("Billing");
      setIsBillingModalOpen(true);
    } else if (val === "Shipping") {
      setMode("Shipping");
      setIsShippingModalOpen(true);
    }
  };

  const handleOpenEditModal = (val: string, id: number) => {
    const combinedArray = billingData.concat(shippingData);

    const filteredData = combinedArray.filter((item) => item.RecordId === id);

    // console.log ("AddressPage :: filter data ",filteredData)

    if (filteredData.length > 0) {
      localStorage.setItem("filteredData", JSON.stringify(filteredData[0])); // Assuming you want to store the first match
    }

    if (val === "Edit") {
      setMode("Edit");
      setIsBillingModalOpen(true);
    }
    fetchOrderData();
  };

  useEffect(() => {
    fetchOrderData();
  }, [sessionStorage.getItem("billing")]);
  //

  const fetchOrderData = async () => {
    const localStorageData = sessionStorage.getItem("userName");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    if (login_info) {
      const login_infoo = JSON.parse(login_info);
      try {
        const postData = {
          userName: localStorageData ?? login_infoo.userName,
        };

        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const response = await fetch(`${baseUrl}/api/user/userAddressShow`, {
          // Replace with your API endpoint
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // Add any additional headers here, like authorization if needed
          },
          body: JSON.stringify(postData),
        });
        const data = await response.json();

        setBillingData(data.mainAddressList.billingAddress);
        setShippingData(data.mainAddressList.pickupAddress);

        const billingPrimaryAddressRecordId =
          data.mainAddressList.billingAddress.find(
            (item: Address) => item.primaryAddress === 1
          )?.RecordId;
        const shippingPrimaryAddressRecordId =
          data.mainAddressList.pickupAddress.find(
            (item: Address) => item.primaryAddress === 1
          )?.RecordId;

        setBillingSelectedAddress(billingPrimaryAddressRecordId);
        setShippingSelectedAddress(shippingPrimaryAddressRecordId);
      } catch (error) {
        sessionStorage.removeItem("billing");
        console.error("Error fetching order data:", error);
      }
    }
  };

  useEffect(() => {
    import('./addresspage.css');
    const localStorageData = sessionStorage.getItem("userName");
    if (localStorageData) {
      // setUserName(localStorageData);
    }
    // console.log("check",localStorageData)

    fetchOrderData();
  }, []);

  const handleDelete = async (id: string, val: string) => {
    console.log("AddressPage :: id", id, val);
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    if (login_info) {
      try {
        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const response = await fetch(
          `${baseUrl}/api/user/userAddressDelete/?id=${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("AddressPage :: delete", response);

        if (val === "Billing") {
          setBillingData((prev) =>
            prev.filter((add) => add.RecordId.toString() !== id)
          );
        }

        if (val === "Shipping") {
          setShippingData((prev) =>
            prev.filter((add) => add.RecordId.toString() !== id)
          );
        }

        showSuccess(" Address Successfully Delete ");
        fetchOrderData();
      } catch (error) {
        console.log("handleDelete error :: ", error);
        // setIsLoading(false);
        // setIsError(true);
      }
    }
  };

  const handleRadioChange = (id: number, val: string) => {
    console.log("Addresspage ::: selectedBillingAddress", id);
    if (val === "Billing") {
      setBillingSelectedAddress(id);
    }

    if (val === "Shipping") {
      setShippingSelectedAddress(id);
    }
    console.log(val);
  };

  const handleSubmit = () => {
    const orderNum = localStorage.getItem("orderNumber");
    const token = sessionStorage.getItem("tk");
    checked !== null
      ? console.log("handleCheckBox :: checked", checked)
      : console.log(
          "handleCheckBox :: selectedBillingAddress",
          selectedBillingAddress
        );

    const fetchOrderData = async () => {
      // const localStorageData = sessionStorage.getItem("userName");

      // if (checked === true) {
      //   console.log("selectedShippingAddress", selectedShippingAddress);
      // } else {
      //   console.log("selectedBillingAddress", selectedBillingAddress);
      // }
      if (
        selectedShippingAddress && checked !== null
          ? selectedShippingAddress
          : selectedBillingAddress
      ) {
        if (orderNum) {
          const orderNumParse = JSON.parse(orderNum);
          const login_info = localStorage.getItem("Login_info");
          console.log("orderNumParse", orderNumParse);

          if (login_info) {
            const login_infoo = JSON.parse(login_info);
            console.log("login_infoo", login_infoo);

            try {
              if (!baseUrl) {
                throw new Error(
                  "Base URL is not defined in the environment variables"
                );
              }

              const postData = {
                billingRecordId:
                  checked !== null ? checked : selectedBillingAddress,
                pickupAddressRecordId: selectedShippingAddress,
                // userName: localStorageData ?? login_infoo.userName ,
                orderNumber: orderNumParse,
              };
              //   orderNumber: "ORD-GH-0000001",
              // };

              const response = await fetch(
                `${baseUrl}/api/user/userAddressSelection`,
                {
                  // Replace with your API endpoint
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    // "authorization" : token
                    // Add any additional headers here, like authorization if needed
                  },
                  body: JSON.stringify(postData),
                }
              ); // Replace with your API endpoint
              const data = await response.json();
              showSuccess(data.message);

              if (data) {
                navigate("/payment");
              }

              // Use URLSearchParams to extract the 'code' parameter from the URL

              // setCartData(data.items);
            } catch (error) {
              showError("Error fetching data from api");
              console.error("Error fetching order data:", error);
            }
          }
        } else {
          showError("there are no Order number.");
        }
      } else {
        showInfo("Please Select Address");
      }
    };

    fetchOrderData();
  };

  const handleCheckBox = (recordId: number) => {
    console.log("handleCheckBox", recordId);

    if (checked === recordId) {
      setChecked(null);
    } else {
      setChecked(recordId);
    }
  };
  return (
    <>
      <section className="checkoutWrap py-2 py-md-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-8 bg-2 p-3">
              {/* Shipping Address Section */}
              <Accordion
                className="accordion acco-style-2"
                id="shipping-address-aco"
                defaultActiveKey="0"
              >
                <Accordion.Item
                  className="accordion-item my-3 border-0"
                  eventKey="0"
                >
                  <Accordion.Header>
                    <span
                      className="accordion-header border-0"
                      id="shipping-address-aco"
                    >
                      {/* <button
                        className="accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#shippingCollapse"
                        aria-expanded="true"
                        aria-controls="shippingCollapse"
                      > */}
                      Shipping Address
                      {/* </button> */}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <div className="row">
                      <div className="section-detail-text">
                        {shippingData.length === 0 ? (
                          <div
                            id="empty-shipping-address"
                            className="text-center mt-3"
                          >
                            {/* Add SVG and Button */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="80px"
                              height="80px"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
                                stroke="#d6d6d6"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <ellipse
                                cx="15"
                                cy="10.5"
                                rx="1"
                                ry="1.5"
                                fill="#d6d6d6"
                              />
                              <ellipse
                                cx="9"
                                cy="10.5"
                                rx="1"
                                ry="1.5"
                                fill="#d6d6d6"
                              />
                              <path
                                d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                                stroke="#d6d6d6"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                            <div className="font-18 text-center font-bold mb-3">
                              You don't have an address
                              <br />
                            </div>
                            <button
                              className="btn btn-primary w-25 flow-btn-2"
                              onClick={() => handleOpenModal("Shipping")}
                            >
                              Add Shipping Address
                            </button>
                          </div>
                        ) : (
                          <div id="has-address" className=" mt-3">
                            <div className="row">
                              <div className="col-12 d-flex align-items-center justify-content-end">
                                <div
                                  className=" mb-3"
                                  style={{ cursor: "pointer" }}
                                >
                                  <a
                                    className="add-car-bt p-1 px-3 d-flex justify-content-between align-items-center"
                                    // href=""
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBilling"
                                    aria-controls="offcanvasBilling"
                                    onClick={() => handleOpenModal("Shipping")}
                                  >
                                    <span className="font-weight-bold">
                                      Add New
                                    </span>
                                    <span className="mx-1">+</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {shippingData.map((val) => (
                              <div key={val.RecordId}>
                                <div
                                  className={`row my-3 p-2 rounded ${
                                    selectedShippingAddress === val.RecordId
                                      ? "bg-2 address-active"
                                      : ""
                                  }`}
                                >
                                  <div className="mb-3 col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        // id={`exampleRadios${index}`}
                                        value={`option${val.RecordId}`}
                                        checked={
                                          selectedShippingAddress ===
                                          val.RecordId
                                        }
                                        onChange={() =>
                                          handleRadioChange(
                                            val.RecordId,
                                            "Shipping"
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`exampleRadios${val.RecordId}`}
                                      >
                                        Choose this address
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col d-flex align-items-center">
                                    <img
                                      src={mapImg}
                                      alt="Location Image"
                                      className="img-fluid"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  </div>

                                  <div className="col">
                                    {val?.primaryAddress === 1 && (
                                      <span className="badge-address font-12 d-block mb-2">
                                        Primary
                                      </span>
                                    )}
                                    <span>
                                      <strong>{val.addresNickname}</strong>
                                      <br />
                                      {val.building}
                                      <br />
                                      {val.streetName}
                                      <br />
                                      {val.floor}
                                    </span>
                                  </div>

                                  <div className="col-6 d-flex flex-column  align-items-end justify-content-between">
                                    <div className="col-6 d-flex align-items-center justify-content-end">
                                      <button
                                        className="btn btn-primary me-2"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasBilling"
                                        aria-controls="offcanvasBilling"
                                        onClick={() =>
                                          handleOpenEditModal(
                                            "Edit",
                                            val.RecordId
                                          )
                                        }
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          handleDelete(
                                            val.RecordId.toString(),
                                            "shipping"
                                          )
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>

                                    <div>
                                      <div className="form-check form-switch">
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexSwitchCheckDisabled"
                                        >
                                          Use for Billing
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          // type="radio"
                                          role="switch"
                                          id={`flexSwitchCheck${val.RecordId}`}
                                          // checked={checked}
                                          // onChange={() => setChecked(val.RecordId)}
                                          value={`option${val.RecordId}`}
                                          checked={checked === val.RecordId}
                                          onChange={() =>
                                            handleCheckBox(val.RecordId)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr className="border border-1 opacity-100" />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* <Form>
                <Form.Check // prettier-ignore
                  type="checkbox"
                  // id={`default-`}
                  checked={checked}
                  label={`use Shipping address for billing`}
                  onChange={(e) => setChecked(e.currentTarget.checked)}
                />
              </Form> */}

              {/* Billing Address Section */}
              {!checked && (
                <Accordion
                  className="accordion acco-style-2"
                  id="billing-address-aco"
                  // defaultActiveKey="0"
                >
                  <Accordion.Item
                    className="accordion-item my-3 border-0"
                    eventKey="1"
                  >
                    <Accordion.Header>
                      <span
                        className="accordion-header border-0"
                        id="billing-address-aco"
                      >
                        Billing Address
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      <div className="row">
                        <div className="section-detail-text">
                          {billingData.length === 0 ? (
                            <div
                              id="empty-billing-address"
                              className="text-center mt-3"
                            >
                              {/* Add SVG and Button */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80px"
                                height="80px"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
                                  stroke="#d6d6d6"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                                <ellipse
                                  cx="15"
                                  cy="10.5"
                                  rx="1"
                                  ry="1.5"
                                  fill="#d6d6d6"
                                />
                                <ellipse
                                  cx="9"
                                  cy="10.5"
                                  rx="1"
                                  ry="1.5"
                                  fill="#d6d6d6"
                                />
                                <path
                                  d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                                  stroke="#d6d6d6"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <div className="font-18 text-center font-bold mb-3">
                                You don't have an address
                                <br />
                              </div>
                              <button
                                // className="btn btn-primary w-25 flow-btn-2"
                                className="btn btn-primary w-25 flow-btn-2"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasBilling"
                                // aria-controls="offcanvasBilling"
                                onClick={() => handleOpenModal("Billing")}
                              >
                                Add Billing Address
                              </button>
                            </div>
                          ) : (
                            // <div id="has-billing-address" className="mt-3">
                            //
                            //   <button
                            //     className="btn btn-primary me-2"
                            //     onClick={handleOpenBillingModal}
                            //   >
                            //     Edit Billing Address
                            //   </button>
                            // </div>
                            // Billing Address Content
                            <div id="has-address" className=" mt-3">
                              <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-end">
                                  <div
                                    className=" mb-3"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      className="add-car-bt p-1 px-3 d-flex justify-content-between align-items-center"
                                      // href=""
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasBilling"
                                      aria-controls="offcanvasBilling"
                                      onClick={() => handleOpenModal("Billing")}
                                    >
                                      <span className="font-weight-bold">
                                        Add New
                                      </span>
                                      <span className="mx-1">+</span>
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {billingData?.map((val) => (
                                <div key={val.RecordId}>
                                  <div
                                    className={`row my-3 p-2 rounded ${
                                      selectedBillingAddress === val.RecordId
                                        ? "bg-2 address-active"
                                        : ""
                                    }`}
                                  >
                                    <div className="mb-3 col-md-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios1"
                                          // id={`exampleRadios${index}`}
                                          value={`option${val.RecordId}`}
                                          checked={
                                            selectedBillingAddress ===
                                            val.RecordId
                                          }
                                          onChange={() =>
                                            handleRadioChange(
                                              val.RecordId,
                                              "Billing"
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`exampleRadios${val.RecordId}`}
                                        >
                                          Choose this address
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col d-flex align-items-center">
                                      <img
                                        src={mapImg}
                                        alt="Location Image"
                                        className="img-fluid"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    </div>

                                    <div className="col">
                                      {val?.primaryAddress === 1 && (
                                        <span className="badge-address font-12 d-block mb-2">
                                          Primary
                                        </span>
                                      )}
                                      <span>
                                        <strong>{val.addresNickname}</strong>
                                        <br />
                                        {val.building}
                                        <br />
                                        {val.streetName}
                                        <br />
                                        {val.floor}
                                      </span>
                                    </div>

                                    <div className="col-6 d-flex align-items-center justify-content-end">
                                      <button
                                        className="btn btn-primary me-2"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasBilling"
                                        aria-controls="offcanvasBilling"
                                        onClick={() =>
                                          handleOpenEditModal(
                                            "Edit",
                                            val.RecordId
                                          )
                                        }
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          handleDelete(
                                            val.RecordId.toString(),
                                            "billing"
                                          )
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                  <hr className="border border-1 opacity-100" />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary w-25 flow-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>

            {isBillingModalOpen && (
              <AddAddress
                mode={mode}
                show={isBillingModalOpen}
                onClose={handleCloseBillingModal}
                // handleAddAddressClick={handleAddBillingAddressClick}
              />
            )}

            {isShippingModalOpen && (
              <AddAddress
                mode={mode}
                show={isShippingModalOpen}
                onClose={handleCloseShippingModal}
                // handleAddAddressClick={handleAddShippingAddressClick}
              />
            )}

            <div className="col-lg-4 d-none d-lg-block">
              <div className="sticky-card cart">
                <SideCard />
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer delay={3000} position="bottom-left"/> */}
      </section>
    </>
  );
};

export default AddressPage;
