import React from "react";
import { Section1, ServiceSubData } from "../../types/notary";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getID } from "../../store/slices/IdSlice";

interface Props {
    section1?: Section1; // Allow section1 to be optional
    serviceSubData: ServiceSubData;
}
const AllSubService: React.FC<Props> = ({ section1, serviceSubData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleGetAQuote = (id?: number, url?: string) => {
        const insideId = 0;
        const outsideId = id;
        // addDataToDataBase(109,167,"plus")
        dispatch(getID({ insideId, outsideId }));
        navigate(`/services/${url}`);
        // dispatch(openGetQuoteModel());
    };
    return (
        <section className="bg-2 py-5">
        <div className="container">
          <div className="d-md-flex mb-2 mb-md-3 align-items-center justify-content-between mb-4">
            <div className="hd-style-2 ">
              <h2 className="font-35 ff-bold mb-0" 
                dangerouslySetInnerHTML={{
                  __html: section1?.title ?? "",
                }}>
              </h2>
            </div>
            <div className="text-center d-md-flex ">
              <button onClick={() => navigate("/services/notary-services")} className="btn btn-primary   font-14 ff-semibold  mt-0 mt-md-3  bt--cart">
                View All Services
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            {serviceSubData.serviceSubData.map((service, index) => (
              <div className="col-md-2 col-6 mb-5" key={index}>
                <div className="notary-card bg-white shadow-sm rounded p-3 text-center">
                  <img
                    src={service.SrcThumbnail}
                    alt={service.sub2ImgTitle}
                    className="img-fluid d-block mx-auto"
                  />
                  <h4
                    className="font-14 mb-0 py-2 text-black ff-semibold"
                    style={{ minHeight: "50px" }}
                  >
                    {service.sub2ServiceName}
                  </h4>
                  <p className="font-12"></p>
                  {/* <p className="font-12 d-none">{service.totalServices}</p> */}
                </div>
                <div className="text-center d-flex align-items-center margin-minus-20 justify-content-center col-12 ">
                  <button onClick={() =>
                      handleGetAQuote(
                        service.sub2RecordId,
                        service.sub2SlugServiceSub1
                      )
                    }  className="btn btn-primary   font-12 ff-semibold margin-minus bt--cart h-50">
                    Book Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
};

export default AllSubService;