import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  closePriceModel,
} from "../../../store/slices/modalSlice";
import { PriceData } from "../../../types/alhadeed";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getID, setTrue } from "../../../store/slices/IdSlice";

const baseUrl = import.meta.env.VITE_API_URL;

interface Props {
  data?: PriceData;
}

const PriceModel: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const openPriceModel = useSelector(
    (state: { modal: { priceModel: boolean } }) => state.modal.priceModel
  );

  const closedPriceModel = () => {
    dispatch(closePriceModel());
  };

  const addDataToDataBase = async (
    PriceRecordId?: number,
    ServiceId?: number,
    op?: string,
  ) => {
    const UUID = localStorage.getItem("uuid");
    // alert("test")
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());

      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleToCart = (PriceRecordId?: number, ServiceId?: number, redirectURL?: string) => {
    // const localStorageData = localStorage.getItem("Login_info");
    // if (localStorageData) {
      addDataToDataBase(PriceRecordId,ServiceId,"plus")
      const insideId = ServiceId
      const outsideId = 0
      dispatch(getID({ insideId, outsideId }));
      dispatch(closePriceModel());
      navigate(`/${redirectURL}`)
      
    // } else {
    //   dispatch(openNewLogin());
    // }
  };
  return (
    <>
      <Modal
        show={openPriceModel}
        onHide={closedPriceModel}
        className=""
        scrollable
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-dialog-scrollable">
          <div className="modal-body rounded-top">
            {data ? (
              <div className="price-details p-3">
                <p className="mb-3 d-flex align-items-center justify-content-between font-14 ">
                  <span className="ff-medium">Price Type :</span>&nbsp;
                  <b>{data?.PriceType}</b>
                </p>
                <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                  <span className="ff-medium">Price Type :</span>&nbsp;
                  
                  <b><span>AED</span>&nbsp;{data?.pageCount}</b>
                </p>
             
                
                <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                  <span className="ff-medium">Actual Price Amount :</span>&nbsp;
                  <s><span>AED</span>&nbsp;{data?.OldPriceAmount}</s>
                </p>
                <p className="mb-2 d-flex align-items-center justify-content-between font-14">
                  <span className="ff-medium">Price Amount :</span>&nbsp;
                  <b><span>AED</span>&nbsp;{data?.PriceAmount}</b>
                </p>
                <hr className="my-3" />
                <p className="d-flex align-items-center justify-content-between font-14">
                  <span className="ff-medium">Actual Price :</span>{" "}
                  <s><span>AED</span>&nbsp;{data?.oldPriceForPdf}</s>
                </p>
                <p className="d-flex align-items-center justify-content-between font-14">
                  <span className="ff-medium">Final Price :</span>{" "}
                 <b><span>AED</span>&nbsp;{data?.PriceForPdf}</b>
                </p>
              </div>
            ) : (
              <p className="text-muted">No data available</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="modal-footer"> */}
          <div className="price-section d-flex w-100 gap-2 align-items-center">
            <p className="item-count p-2 mb-0 rounded ff-semibold">Total :</p>
            <p className="current-price flex-grow-1 mb-0 ff-bold">
              &nbsp;
              <span>AED</span>&nbsp;{data?.PriceForPdf}
              &nbsp;
              <s className="ff-medium">
                <span>AED</span> {data?.oldPriceForPdf}
              </s>
            </p>
            <button
              className="done-button btn btn-primary mt-2 flex-grow-1 h-100"
              onClick={() => handleToCart(data?.priceid, data?.serviceid, data?.redirect)}
            >
              Add to Cart
            </button>
          </div>
          {/* </div> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PriceModel;
