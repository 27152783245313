import { useSelector } from "react-redux";
import { useEffect } from "react";

interface FloatingMenuButtonProps {
  onTouchStart?: () => void;
  isFloatingSectionVisible: boolean; // Boolean to control icon display
}

const FloatingMenuButton: React.FC<FloatingMenuButtonProps> = ({
  onTouchStart,
  isFloatingSectionVisible,
}) => {
  const { discount } = useSelector(
    (state: {
      ID: {
        cartValue: string;
        cartValuePrice: number;
        insideId: string;
        footerOutsideId: string;
        discount: number;
      };
    }) => state.ID
  );

  useEffect(() => {
    import('./FloatingMenuButton.css');
  },[])

  return (
    <button
      className="floating-menu-button d-flex d-md-none gap-2"
      onTouchStart={onTouchStart}
      style={{ marginBottom: discount > 0 ? "80px" : "0px" }}
    >
      <i
        className={`fas ${isFloatingSectionVisible ? "fa-times" : "fa-bars"}`}
      ></i>
      {isFloatingSectionVisible ? "" : " Menu"}
    </button>
  );
};

export default FloatingMenuButton;
