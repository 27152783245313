import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { CartItems } from "../../types/form";
// import { CartItems } from "../../types/cart";

// Define the types for the cart item and initial state
interface CartItem {
  productId: number;
  quantity: number;
  productName: string;
  productPrice: number;
  priceType: string;
}

interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: []
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<{ productId: number; quantity: number; productName: string; productPrice: number; 
      priceType: string, ServiceId: number
    }>) => {
      const { productId, quantity, productName, productPrice, priceType } = action.payload;
      const indexProductId = state.items.findIndex(item => item.productId === productId);
      if (indexProductId >= 0) {
        state.items[indexProductId].quantity += quantity;
      } else {
        state.items.push({ productId, quantity, productName, productPrice, priceType });
      }
    },
    changeQuantity: (state, action: PayloadAction<{ productId: number; quantity: number }>) => {
      const { productId, quantity } = action.payload;
      const indexProductId = state.items.findIndex(item => item.productId === productId);
      if (indexProductId >= 0) {
        if (quantity > 0) {
          state.items[indexProductId].quantity = quantity;
        }
        else {
          state.items = state.items.filter(item => item.productId !== productId);
          if(state.items.length === 0 ){
            localStorage.removeItem("cartDataLocal")
          } 
        }
      }
    },
    // arrayAddToCart: (state, action) => {
    //   console.log("array in cart ", action)
    //   console.log(state)
    // }'
    clearCart(state) {
      state.items = []; // Clear the cart items
    },
  }
});

export const { addToCart, changeQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
