// import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOutsideID } from "../../../store/slices/IdSlice";
import { BannerService } from "../../../types/home";
import { Section1 } from "../../../types/department";
// import banneramer from '../../../assets/amer/banner-amer.png'



interface Props {
  BannerService: BannerService[];
  data?: Section1
}

const BannerServices: React.FC<Props> = ({ BannerService, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const [cards, setCards] = useState<Service[]>(services);
  //   useEffect(() => {
  //     fetch("url")
  //       .then((response) => response.json())
  //       .then((data) => setCards(data))
  //       .catch((error) => console.error("Error fetching data:", error));
  //   }, []);

  //   useEffect(() => {
  //     const wow = new WOW();
  //     wow.init();
  //   }, []);

  console.log("BannerServices ::: ", data, BannerService);

  const handleSuggestionClick = (footerOutsideId: string, slug: string) => {
    dispatch(getOutsideID({ footerOutsideId }));
    navigate(`/services/${slug}`);
  };

  return (
    <section className="banner-wrap py-1 py-md-5 amer-banner">
      <div className="container">
        <div className="row">
          <div className="hd-style-1">
            <h2 className="wow animate__fadeInLeft">{data?.title}</h2>
          </div>
          <div className="col-md-6 d-flex flex-column">
            <div className="banner-services">
              <div className="sub-hd1 wow animate__zoomIn">
                <p>What you are looking for</p>
              </div>
              <div className="row scoll-ser">
                {BannerService.map((card) => (
                  <div
                    className="col-md-4 col-lg-4 col-6"
                    key={card.sub2RecordId}
                  >
                    <div
                      className="banner-sr-card "
                      onClick={() =>
                        handleSuggestionClick(
                          card.sub2RecordId.toString(),
                          card.sub2SlugServiceSub1
                        )
                      }
                    >
                      <img
                        src={card.SrcThumbnail}
                        className="img-fluid"
                        alt={card.sub2ImgAlt}
                      />
                    </div>

                    <p>{card.sub2ServiceName}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <div
              className="main-banner d-none d-md-block w-100 h-100 wow animate__zoomIn"
              data-wow-duration="2s"
            >
              <img
                // loading="lazy"
                src={data?.image}
                alt="banner img"
                className="img-fluid banner-main-img-one"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerServices;
