import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useData } from "../../components/toast/Api";
import { useNavigate } from "react-router-dom";
import { OtpInput } from "../../components/otp/OtpInput";
// import BackButton from "../../components/backButton/Backbutton";

const baseUrl = import.meta.env.VITE_API_URL;

// interface CountryOption {
//   value: string;
//   label: string;
// }

const Otp: React.FC = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    // showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpCode, setOtp] = useState<string>("");
  const [num, setNumber] = useState<string>("");
  const [phNum, setPhoneNumber] = useState({
    countryCode: "",
    phoneNumber: "",
  });

  console.log("otp :: num", phNum);
  console.log("otp :: otp", otpCode);

  useEffect(() => {
    import("./opt.css");

    // Retrieve OTP from local storage
    // const storedOtp = localStorage.getItem("Signup_Otp");
    const number = localStorage.getItem("Signup_num");
    // const num1 = JSON.parse(number)

    if (number) {
      // setOtp(storedOtp);
      setNumber(number);
    }
  }, []);

  const handleVerifyOtp = async () => {
    const numbe = num.replace(/^"|"$/g, "");
    console.log(numbe);
    console.log("numbe", otpCode);
    const fullPhoneNumber = phNum.countryCode + phNum.phoneNumber;
    try {
      const postData = {
        userNumberWithCode: fullPhoneNumber,
        otp: otpCode,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/verifyOtp`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers here, like authorization if needed
          },
        }
      );

      const data = response.data;
      console.log("otp :: data", data);

      if (data.message === "Sign-in successfully") {
        console.log("otp :: data if", data);
        localStorage.removeItem("Signup_Otp");
        localStorage.removeItem("Signup_num");
        localStorage.setItem("Login_info", JSON.stringify(data.data));
        localStorage.setItem("Login_info_status", JSON.stringify(true));
        sessionStorage.setItem("tk", data.data.token); // 15 min expiry
        localStorage.setItem("rt", JSON.stringify(data.data.refreshToken));
        navigate("/");
      } else {
        showError(data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    const numb = localStorage.getItem("phoneNumber");
    if (numb) {
      const numb1 = JSON.parse(numb);
      console.log("numb1", numb1);
      setPhoneNumber(numb1[0]);
      // This effect runs every time the component mounts or otp changes
      const otpArray = otpCode?.split("");
      console.log("otp :: otp ::: useEffect ::: split", otpCode);
      otpArray?.forEach((digit, index) => {
        const inputField = document.getElementById(
          `otp${index + 1}`
        ) as HTMLInputElement;
        if (inputField) {
          inputField.value = digit;
        }
      });
    }
  }, [otpCode]);

  const handleEdit = () => {
    navigate("/userlogout");
  };

  const handleResend = async () => {
    const response = await axios.post(
      `${baseUrl}/api/openRoute/loginWithNumber`,
      {
        userNumber: phNum.phoneNumber,
        UserCountryCode: phNum.countryCode,
      }
    );

    if (response.status === 200) {
      showSuccess("Resend Code on you Phone Number");
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <div className="bg-2 min-vh-100 py-md-0">
        <div className="container">
          <div className="row justify-content-center help-wrap">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
                borderBottom: "1px solid gray",
                marginBottom: "5px",
              }}
            >
              <div onClick={goBack}>
                {/* <div className="col-12"> */}
                {/* <BackButton />  */}
                <i className="fas fa-arrow-left"></i>
                {/* </div> */}
              </div>
              <div>
                <span className="ff-semibold text-black">
                  Login to continue
                </span>
              </div>
              <div>
                <h3 className="ff-semibold text-black"></h3>
              </div>
            </div>

            <div className="mb-4">
              <a
                href="javascript:void(0)"
                onClick={handleEdit}
                className="text-black ff-medium font-16"
              >
                Edit Number
              </a>
            </div>

            <div className="container">
              <div className="row px-2">
                <div className="col-12 p-0">
                  <p className="font-18 ff-semibold text-black mb-0 pb-0">
                    Verification Code
                  </p>
                  <p className="font-14 ff-medium text-black pb-3">
                    We have sent you a 4 digit code on{" "}
                    {phNum.countryCode + phNum.phoneNumber}
                  </p>

                  <div className="d-flex gap-2 mb-3 w-50">
                    <OtpInput value={otpCode} onChange={setOtp} length={4} />
                  </div>
                  <div className="text-left pb-4">
                    <small className="text-muted">
                      Didn't receive the OTP?{" "}
                      <a
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={handleResend}
                      >
                        Resend
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Button
                className="btn btn-primary w-100 ff-semibold"
                onClick={handleVerifyOtp}
                // disabled={phoneNumber.length < 9 || !isConfirmed}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
