import { useState } from "react";
import { useData } from "../../components/toast/Api";
import BackButton from "../../components/backButton/Backbutton";
// import { useNavigate } from "react-router-dom";

const baseUrl = import.meta.env.VITE_API_URL;
const SettingPage = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    // showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();

  // const navigate = useNavigate()
  // const [token, setToken] = useState();
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();

  const [errors, setErrors] = useState<{
    currentPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
    // confirmation?: string;
  }>({});

  const validateForm = () => {
    const newErrors: {
      currentPassword?: string;
      newPassword?: string;
      confirmNewPassword?: string;
    } = {};

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!currentPassword) {
      newErrors.currentPassword = "Current password is required";
    }

    if (!newPassword) {
      newErrors.newPassword = "New Password is required";
    } else if (!passwordRegex.test(newPassword)) {
      newErrors.newPassword =
        "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    if (!confirmNewPassword) {
      newErrors.confirmNewPassword = "Confirm Password is required";
    } else if (newPassword !== confirmNewPassword) {
      newErrors.confirmNewPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearAllFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    //     setIsLoading(true); // Start loading state
    if (validateForm()) {
      const token = sessionStorage.getItem("tk");
      if (token) {
        try {
          const postData = {
            currentPassword,
            newPassword,
            confirmNewPassword,
          };

          const response = await fetch(`${baseUrl}/api/user/changePassword`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData),
          });

          // Check if the response is not OK (status is not in the 200-299 range)
          // if (!response.ok) {
          //   throw new Error(`HTTP error! Status: ${response.status}`);
          // }

          // Parse the response and handle JSON parsing errors
          const res = await response.json();
          if (res.message === "Password changed successfully") {
            showSuccess(res.message);
            // navigate("/setting")
          }
          clearAllFields();
        } catch (error) {
          console.log("Error : ", error);
        }
      }
    } else {
      showError("Fill All Fields");
    }
  };

  return (
    <>
      <BackButton />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-9">
            <h5 className="font-21 my-2 fw-bold">Change Your Password</h5>
            <div className="form-section p-5 bg-2 border-radius-10">
              <form className="form-main">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <label htmlFor="currentPassword" className="form-label">
                      Current Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="currentPassword"
                      name="currentPassword"
                      placeholder="Enter your password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  {errors.currentPassword && (
                    <p className="text-danger">{errors.currentPassword}</p>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter your password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  {errors.newPassword && (
                    <p className="text-danger">{errors.newPassword}</p>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <label htmlFor="confirmNewPassword" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      placeholder="Enter your password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                  {errors.confirmNewPassword && (
                    <p className="text-danger">{errors.confirmNewPassword}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onSubmit}
                >
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingPage;
