// modalSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
  isLoginOpen: boolean;
  isSignUpOpen: boolean;
  isOtpOpen: boolean;
  isSuccessful: boolean;
  isForgetPassword: boolean;
  isResetPassword: boolean;
  isResetPasswordOtp: boolean;
  addModelData: boolean;
  footerModel: boolean;
  selectAddressModel: boolean;
  addAnotherAddress: boolean;
  addDetailModel: boolean;
  verifyOtp: boolean;
  login: boolean;
  addressFromMap: boolean;
  uploadFileModel: boolean;
  getQuoteModel: boolean;
  languadesModel: boolean;
  packageModel: boolean;
  priceModel: boolean;
}

const initialState: ModalState = {
  isLoginOpen: false,
  isSignUpOpen: false,
  isOtpOpen: false,
  isSuccessful: false,
  isForgetPassword: false,
  isResetPassword: false,
  isResetPasswordOtp: false,
  addModelData: false,
  footerModel: false,
  selectAddressModel: false,
  addAnotherAddress: false,
  addDetailModel: false,
  verifyOtp: false,
  login: false,
  addressFromMap: false,
  uploadFileModel: false,
  getQuoteModel: false,
  languadesModel: false,
  packageModel: false,
  priceModel: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openLogin(state) {
      state.isLoginOpen = true;
    },
    closeLogin(state) {
      state.isLoginOpen = false;
    },
    openSignUp(state) {
      state.isSignUpOpen = true;
    },
    closeSignUp(state) {
      state.isSignUpOpen = false;
    },
    openOtp(state) {
      state.isOtpOpen = true;
    },
    closeOtp(state) {
      state.isOtpOpen = false;
    },
    openSuccessful(state) {
      state.isSuccessful = true;
    },
    closeSuccessful(state) {
      state.isSuccessful = false;
    },
    openForgetPassword(state) {
      state.isForgetPassword = true;
    },
    closeForgetPassword(state) {
      state.isForgetPassword = false;
    },
    openResetPassword(state) {
      state.isResetPassword = true;
    },
    closeResetPassword(state) {
      state.isResetPassword = false;
    },
    openResetPasswordOtp(state) {
      state.isResetPasswordOtp = true;
    },
    closeResetPasswordOtp(state) {
      state.isResetPasswordOtp = false;
    },
    openAddModelData(state) {
      state.addModelData = true 
    },
    closeAddModelData(state) {
      state.addModelData = false 
    },
    openFooterModel(state) {
      state.footerModel = true 
    },
    closeFooterModel(state) {
      state.footerModel = false 
    },
    openSelectAddressModel(state) {
      state.selectAddressModel = true 
    },
    closeSelectAddressModel(state) {
      state.selectAddressModel = false 
    },
    openAddAnotherAddress(state) {
      state.addAnotherAddress = true 
    },
    closeAddAnotherAddress(state) {
      state.addAnotherAddress = false 
    },
    openAddDetailModel(state) {
      state.addDetailModel = true 
    },
    closeAddDetailModel(state) {
      state.addDetailModel = false 
    },
    openVerifyOtp(state) {
      state.verifyOtp = true 
    },
    closeVerifyOtp(state) {
      state.verifyOtp = false 
    },
    openNewLogin(state) {
      state.login = true 
    },
    closeNewLogin(state) {
      state.login = false 
    },
    openAddressFromMap(state) {
      state.addressFromMap = true 
    },
    closeAddressFromMap(state) {
      state.addressFromMap = false 
    },
    openUploadFileModel(state) {
      state.uploadFileModel = true 
    },
    closeUploadFileModel(state) {
      state.uploadFileModel = false 
    },
    openGetQuoteModel(state) {
      state.getQuoteModel = true 
    },
    closeGetQuoteModel(state) {
      state.getQuoteModel = false 
    },
    openLanguadesModel(state) {
      state.languadesModel = true 
    },
    closeLanguadesModel(state) {
      state.languadesModel = false 
    },
    openPackageModel(state) {
      state.packageModel = true 
    },
    closePackageModel(state) {
      state.packageModel = false 
    },
    openPriceModel(state) {
      state.priceModel = true 
    },
    closePriceModel(state) {
      state.priceModel = false 
    },
  },
});

export const {
  openLogin,
  closeLogin,
  openSignUp,
  closeSignUp,
  openOtp,
  closeOtp,
  openSuccessful,
  closeSuccessful,
  openForgetPassword,
  closeForgetPassword,
  openResetPassword,
  closeResetPassword,
  openResetPasswordOtp,
  closeResetPasswordOtp,
  openAddModelData,
  closeAddModelData,
  openFooterModel,
  closeFooterModel,
  openSelectAddressModel,
  closeSelectAddressModel,
  openAddAnotherAddress,
  closeAddAnotherAddress,
  openAddDetailModel,
  closeAddDetailModel,
  openVerifyOtp,
  closeVerifyOtp,
  openNewLogin,
  closeNewLogin,
  openAddressFromMap,
  closeAddressFromMap,
  openUploadFileModel,
  closeUploadFileModel,
  openGetQuoteModel,
  closeGetQuoteModel,
  openLanguadesModel,
  closeLanguadesModel,
  openPackageModel,
  closePackageModel,
  openPriceModel,
  closePriceModel,
} = modalSlice.actions;

export default modalSlice.reducer;
