import React, { useEffect, useState } from "react";

interface Props {
  value: number;
}

export const AnimatedNumber: React.FC<Props> = ({ value }) => {
  const [animationClass, setAnimationClass] = useState("");
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    import("./AnimatedNumber.css");
    if (value !== displayValue) {
      // setIsAnimating(true);
      if (value > displayValue) {
        setAnimationClass("slide-down-out");
        setTimeout(() => {
          setDisplayValue(value);
          setAnimationClass("slide-down-in");
        }, 200);
      } else {
        setAnimationClass("slide-up-out");
        setTimeout(() => {
          setDisplayValue(value);
          setAnimationClass("slide-up-in");
        }, 200);
      }
    }
  }, [value, displayValue]);

  return (
    <div className="number-container">
      <span className={`animated-number ${animationClass}`}>
        {displayValue}
      </span>
    </div>
  );
};
