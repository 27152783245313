import { useEffect } from "react";

const Enquiry = () => {

  useEffect(() =>{
    import('./enquiry.css');
  },[])
  
  return (
    <div className="container-fluid p-0 d-block d-md-none">
      <div className="d-flex align-items-center justify-content-between enquiry-sec px-3">
        <div>
          <p className="font-16 ff-semibold text-black mb-0">
            Issues with Your bookings?
          </p>
          <p className="font-14 ff-medium text-black mb-2">
            We are here to support you
          </p>
          <a className="ff-semibold text-black font-14" href="#get-help">
            Get Help
          </a>
        </div>
        <div>
          <svg
            width="60"
            height="60"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M464 128.051H448V253.851C448 307.951 404.4 351.951 350.8 351.951H128V399.951C128 426.451 149.5 447.951 176 447.951H410.4L486 508.451C488.9 510.751 492.5 511.951 496 511.951C498.3 511.951 500.7 511.451 502.9 510.351C508.4 507.651 512 502.051 512 495.951V176.051C512 149.551 490.5 128.051 464 128.051Z"
              fill="#A2B6FB"
            />
            <path
              d="M352 0.0507812H64C28.7 0.0507812 0 28.7508 0 64.0508V384.051C0 390.251 3.6 395.851 9.2 398.551C11.3 399.551 13.7 400.051 16 400.051C19.7 400.051 23.3 398.851 26.2 396.351L117.8 320.051H352C387.3 320.051 416 291.351 416 256.051V64.0508C416 28.7508 387.3 0.0507812 352 0.0507812Z"
              fill="#235AF4"
            />
            <path
              d="M304 96.0508H112C103.2 96.0508 96 103.251 96 112.051C96 120.851 103.2 128.051 112 128.051H304C312.8 128.051 320 120.851 320 112.051C320 103.251 312.8 96.0508 304 96.0508Z"
              fill="#FAFAFA"
            />
            <path
              d="M240 160.051H112C103.2 160.051 96 167.251 96 176.051C96 184.851 103.2 192.051 112 192.051H240C248.8 192.051 256 184.851 256 176.051C256 167.251 248.8 160.051 240 160.051Z"
              fill="#FAFAFA"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
