import { useNavigate } from "react-router-dom";

const RightBar = () => {
  const navigate = useNavigate();
  return (
    <>
      <ul className="nav nav-flush flex-column mb-auto text-center">
        <li className="nav-item">
          <a
            //
            className="nav-link active py-3 rounded-0"
            aria-current="page"
            title="Home"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            onClick={() => navigate("/admin")}
          >
            <i className="fa fa-home text-white" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a
            //
            className="nav-link py-3 rounded-0"
            title="Dashboard"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            onClick={() => navigate("/admin/adminpanel")}
          >
            <i className="fa fa-home text-white" aria-hidden="true"></i>
          </a>
        </li>
        {/* <li>
          <a
            
            className="nav-link py-3 rounded-0"
            title="Orders"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
          >
            <svg
              className="bi pe-none"
              width="24"
              height="24"
              role="img"
              aria-label="Orders"
            >
            </svg>
          </a>
        </li>
        <li>
          <a
            
            className="nav-link py-3  rounded-0"
            title="Products"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
          >
            <svg
              className="bi pe-none"
              width="24"
              height="24"
              role="img"
              aria-label="Products"
            >
            </svg>
          </a>
        </li>
        <li>
          <a
            
            className="nav-link py-3 rounded-0"
            title="Customers"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
          >
            <svg
              className="bi pe-none"
              width="24"
              height="24"
              role="img"
              aria-label="Customers"
            >
            </svg>
          </a>
        </li> */}
      </ul>
    </>
  );
};

export default RightBar;
