import { Navigate } from 'react-router-dom';
import { logout } from '../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../store/slices/cartSlice';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch()
  const loginData = !!localStorage.getItem('Login_info');  // or use authentication state from Redux/store
  

  if (!loginData) {
    dispatch(logout());
    dispatch(clearCart());
    localStorage.clear();
    sessionStorage.clear()
    // Redirect to login page if not logged in
    return <Navigate to="/" replace />;
  }

  // If logged in, render the child components (protected routes)
  return children;
};

export default ProtectedRoute;