import { EmployeeData } from "../../types/typeofvisa";
import SwiperSlider from "./SwiperSlider";
// import { Employee } from "../../types/homeComponentTypes";

type Props = {
  employee?: EmployeeData[];
};

const Experts: React.FC<Props> = ({ employee}) => {
  console.log("employee",employee)
  return (
    <>
      <section className="expert-wrap">
        <div className="container">
          <div className="row ">
            <div className="hd-style-3 py-2">
              Our Employees <br />
              {/* Partners */}
            </div>
            <div className="col-12 wow animate__zoomIn" data-wow-duration="2s">
              <SwiperSlider employee={employee} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experts;
