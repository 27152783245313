import { HappyStorie } from "../../../../types/marriageSevices";
import HappyStoriesSlider from "./HappyStoriesSlider";

interface Props {
  data?: HappyStorie;
}

const HappyStories: React.FC<Props> = ({ data }) => {
  return (
    <>
      <section className="py-0 py-md-5">
        <div className="container">
          <div className="row">
            <div className="hd-style-2 golden wow animate__fadeInLeft">
              <h2
                className="mb-5"
                dangerouslySetInnerHTML={{
                  __html: data?.heading ?? "",
                }}
              ></h2>
            </div>
            <HappyStoriesSlider data={data} />
          </div>
        </div>
      </section>
    </>
  );
};

export default HappyStories;
