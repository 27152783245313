import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { setFalse, setTrue } from "../../store/slices/IdSlice";
import { openAddModelData } from "../../store/slices/modalSlice";
import { AnimatedNumber } from "./AnimatedNumber";
import { useNavigate } from "react-router-dom";
// import Model from "../model/Model";
import { CartItem } from "../../types/cart";

const baseUrl = import.meta.env.VITE_API_URL;

const CardItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<CartItem[]>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItemInCart, setTotalItemInCart] = useState<number>();
  const [oldTotalAmount, setOldTotalAmount] = useState<number>();
  const [currentLocation, setCurrentLocation] = useState<string>("");
  // const [direction, setDirection] = useState({ id: 0, direction: "", key: 0 });
  // const [loadingId, setLoadingId] = useState<number | null>(null);
  // const [togglee, setIsLoading] = useState(false);
  // const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const toggle = useSelector(
    (store: { ID: { status: boolean } }) => store.ID.status
  );

  // const addModalOpen = useSelector(
  //   (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  // );

  const getData = async () => {
    const UUID = localStorage.getItem("uuid");
    // console.log("test", UUID);
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/cartinfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        // console.log("response.data.CartItems.cart,", response.data);
        setData(response.data.CartItems.Cart);
        // calData(response.data.CartItems)
        // let total = 0;
        // response.data.CartItems.Cart.map((item: CartItem) => {
        //   total = item.TotalAmount + total;
        // });
        // let ItemInCart = 0;
        // response.data.CartItems.Cart.map((item: CartItem) => {
        //   ItemInCart = item.Quantity + ItemInCart;
        // });
        // let oldTotalAmount = 0;
        // response.data.CartItems.Cart.map((item: CartItem) => {
        //   if (
        //     item.oldTotalAmount == null ||
        //     item.oldTotalAmount == undefined ||
        //     item.oldTotalAmount === 0 ||
        //     item.oldTotalAmount < item.TotalAmount
        //   ) {
        //     oldTotalAmount = item.TotalAmount + oldTotalAmount;
        //   } else {
        //     oldTotalAmount = item.oldTotalAmount + oldTotalAmount;
        //   }
        // });

        let total = 0;
        let ItemInCart = 0;
        let oldTotalAmount = 0;

        response.data.CartItems.Cart.forEach((item: CartItem) => {
          total += item.TotalAmount;
          ItemInCart += item.Quantity;

          if (
            item.oldTotalAmount == null ||
            item.oldTotalAmount === undefined ||
            item.oldTotalAmount === 0 ||
            item.oldTotalAmount < item.TotalAmount
          ) {
            oldTotalAmount += item.TotalAmount;
          } else {
            oldTotalAmount += item.oldTotalAmount;
          }
        });

        setTotalItemInCart(ItemInCart);
        setTotalPrice(total);
        setOldTotalAmount(oldTotalAmount);
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  useEffect(() => {
    import("./cardItem.css");
    const loca = location.pathname;
    if (loca) {
      setCurrentLocation(loca);
    }
    getData();
  }, []);

  useEffect(() => {
    if (toggle) {
      getData();
    }
    dispatch(setFalse());
  }, [toggle]);

  const handleMinusQuantity = async (
    serviceid: number,
    priceRecordId: number
  ) => {
    sessionStorage.setItem("serviceid", JSON.stringify(serviceid));

    data?.forEach((item) => {
      if (item.PriceRecordId === priceRecordId) {
        addDataToDataBase(item.PriceRecordId, item.serviceid, "minus");
      }
    });
    // sessionStorage.setItem("direction",JSON.stringify({ id: priceRecordId, direction: "down", key: Date.now() }))
    // setDirection({ id: priceRecordId, direction: "down", key: Date.now() });
  };

  const handlePlusQuantity = (serviceid: number, priceRecordId: number) => {
    sessionStorage.setItem("serviceid", JSON.stringify(serviceid));

    data?.forEach((item) => {
      if (item.PriceRecordId === priceRecordId) {
        addDataToDataBase(item.PriceRecordId, item.serviceid, "plus");
      }
    });
    // setDirection({ id: priceRecordId, direction: "up", key: Date.now() });
  };

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const openModel = (serviceid: number) => {
    sessionStorage.setItem("serviceid", JSON.stringify(serviceid));
    dispatch(openAddModelData());
  };

  const handleFunction = () => {
    navigate("/cart");
  };

  return (
    <>
      {currentLocation === "/cart1" ? (
        <>
          <div
            className="card mb-3 add-cart-each border-bottom-0 p-2"
            style={{
              maxHeight: "175px",
              overflowY: "auto",
            }}
          >
            {data?.map((cartItem) => (
              <div className="card-body1 " key={cartItem.productId}>
                <div className="d-flex justify-content-between align-items-center gap-1 mb-3">
                  <div className="product-name">
                    <p className="checkout-ser-name font-14 mb-0">
                      {cartItem.serviceName}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center justify-content-center add-cart-btn-main"
                    style={{
                      // width: "30%",
                      height: "5%",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {/* <div> */}
                      <button
                        className="bt-mi"
                        onClick={() =>
                          handleMinusQuantity(
                            cartItem.serviceid,
                            cartItem.PriceRecordId
                          )
                        }
                      >
                        -
                      </button>
                      <span
                        className="mx-0.5"
                        onClick={() => openModel(cartItem.serviceid)}
                      >
                        <div className="w-8">
                          <AnimatedNumber
                            value={cartItem.Quantity}
                            // setIsAnimating={setIsAnimating}
                          />
                        </div>
                      </span>
                      <button
                        className="bt-plu"
                        onClick={() =>
                          handlePlusQuantity(
                            cartItem.serviceid,
                            cartItem.PriceRecordId
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    {/* {cartItem.PriceRecordId === direction.id && (
                    <LoadingLine isAnimating={isAnimating} />
                  )} */}
                  </div>

                  <div style={{ width: "30%" }}>
                    <span className="main-price pt-1 ff-semibold text-end d-block">
                      AED {cartItem.TotalAmount.toFixed(2).toLocaleString()}
                    </span>
                    {cartItem.oldTotalAmount > cartItem.TotalAmount && (
                      <span className="main-price text-end d-block text-secondary">
                        <s className="ff-medium">
                          AED {cartItem.oldTotalAmount.toFixed(2)}
                        </s>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="card mb-3 add-cart-main ">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className=" "
            >
              <div className="font-21 text-black ff-semibold px-2 ">Cart</div>
              <div className="hd-style-3 px-2 d-none">
                {totalItemInCart} <span className="font-12"> Services </span>
              </div>
            </div>

            <div
              className="card mb-3 add-cart-each border-bottom-0 p-2"
              style={{
                maxHeight: "175px",
                overflowY: "auto",
              }}
            >
              {data?.map((cartItem) => (
                <div className="card-body1 " key={cartItem.serviceName}>
                  <div className="d-flex justify-content-between align-items-center gap-1 mb-3">
                    <div className="product-name">
                      <p className="checkout-ser-name font-14 mb-0">
                        {cartItem.serviceName}
                      </p>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center justify-content-center add-cart-btn-main"
                      style={{
                        // width: "30%",
                        height: "5%",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <div> */}
                        <button
                          className="bt-mi"
                          onClick={() =>
                            handleMinusQuantity(
                              cartItem.serviceid,
                              cartItem.PriceRecordId
                            )
                          }
                        >
                          -
                        </button>
                        <span
                          className="mx-0.5"
                          onClick={() => openModel(cartItem.serviceid)}
                        >
                          <div className="w-8">
                            <AnimatedNumber
                              value={cartItem.Quantity}
                              // setIsAnimating={setIsAnimating}
                            />
                          </div>
                        </span>
                        <button
                          className="bt-plu"
                          onClick={() =>
                            handlePlusQuantity(
                              cartItem.serviceid,
                              cartItem.PriceRecordId
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      {/* {cartItem.PriceRecordId === direction.id && (
                    <LoadingLine isAnimating={isAnimating} />
                  )} */}
                    </div>

                    <div style={{ width: "30%" }}>
                      <span className="main-price pt-1 ff-semibold text-end d-block">
                        AED {cartItem.TotalAmount.toFixed(2).toLocaleString()}
                      </span>
                      {cartItem.oldTotalAmount > cartItem.TotalAmount && (
                        <span className="main-price text-end d-block text-secondary">
                          <s className="ff-medium">
                            AED {cartItem.oldTotalAmount.toFixed(2)}
                          </s>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {oldTotalAmount && oldTotalAmount > totalPrice && (
              <div
                className="mb-2"
                style={{
                  height: "34px",
                  position: "relative",
                  width: "106%",
                  margin: "0 auto",
                  left: "0",
                  display: "block",
                  marginLeft: "-10px",
                }}
              >
                <div
                  className="py-2 d-flex justify-content-center align-items-center"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgb(7, 121, 76)",
                    border: "none",
                    color: "white",
                    position: "absolute",
                    width: "100%",
                    margin: "0 auto",
                    left: "0",
                    right: "0",
                    top: "0",
                  }}
                >
                  <span className=" font-12 ff-semibold text-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                        fill="white"
                      ></path>
                    </svg>
                    <span className="ms-1">
                      Congratulations! AED&nbsp;
                      {Number(oldTotalAmount - totalPrice).toFixed(2)}
                      &nbsp;saved so far!
                    </span>
                  </span>
                </div>
              </div>
            )}

            <div className="mb-2">
              <a
                className="btn btn-primary flow-btn py-3 d-flex justify-content-between align-items-center mt-2 mx-3"
                onClick={handleFunction}
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgba(110, 66, 229, 1)",
                  border: "none",
                }}
              >
                <span className="font-weight-bold font-14">
                  <span className="ff-semibold">
                    AED {Number(totalPrice).toFixed(2).toLocaleString()} &nbsp;
                  </span>
                  {oldTotalAmount && oldTotalAmount > totalPrice && (
                    <>
                      {totalPrice && (
                        <s>
                          AED{" "}
                          {Number(oldTotalAmount).toFixed(2).toLocaleString()}
                        </s>
                      )}
                    </>
                  )}
                </span>
                <span className="font-14 ff-semibold">View Cart</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardItem;
