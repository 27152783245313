import { useState, useCallback } from "react";
import { toast } from "react-toastify";

export const useData = () => {
  const [loading, setLoading] = useState(false);

  const getSomeData = useCallback(() => {
    setLoading(true);
    fetch("https://jsonplaceholder.ir/posts")
      .then((res) => res.json())
      .then(() => {
        setLoading(false);
        toast.success("Yeay! New data is here.");
      })
      .catch(() => {
        toast.error("Oops! Something went wrong.");
      });
  }, []);

  const showMorning = () => toast("Morning! Have a good day.");

  const showSuccess = (msg: string) => {
    toast.dismiss(); // Dismiss any existing toasts
    toast.success(msg);
  };

  const showError = (msg: string) => {
    toast.dismiss(); // Dismiss any existing toasts
    toast.error(msg);
  };

  const showInfo = (msg: string) => {
    toast.dismiss(); // Dismiss any existing toasts
    toast.info(msg);
  };

  const showWarn = () => toast.warn("This may be dangerous!");

  const showCustom = () =>
    toast("Hey! This is a custom toast.", {
      // backgroundColor: "#323131",
      // color: "#ffffff"
    });

  return {
    loading,
    getSomeData,
    showMorning,
    showSuccess,
    showError,
    showInfo,
    showWarn,
    showCustom,
  };
};
