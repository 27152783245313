import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { setFalse, setTrue } from "../../store/slices/IdSlice";
// import { openAddModelData } from "../../store/slices/modalSlice";
// import { AnimatedNumber } from "./AnimatedNumber";
// import { useNavigate } from "react-router-dom";
import { DynamicForm } from "../Formfield/DynamicForm";
import { CartItems, Category, FormDataa } from "../../types/form";
import Model from "../model/Model";
import "bootstrap";
// import {
//   // arrayForPayment,
//   TotalQuantity,
// } from "../../types/cart";
import { CartItem } from "../../types/navbarTypes";
import { Accordion } from "react-bootstrap";
import { openAddModelData } from "../../store/slices/modalSlice";
import { TotalQuantity } from "../../types/cart";

const baseUrl = import.meta.env.VITE_API_URL;

interface Props {
  handleCheckAllData: (check: boolean) => void;
  AlltotalAmount: number;
  AlloldTotalAmount: number;
  discount: number;
}

interface formNoData {
  formNod: string;
  check: boolean;
}

const AddDocument: React.FC<Props> = ({ handleCheckAllData }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [cartData, setCartData] = useState<CartItems[]>();
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalItemInCart, setTotalItemInCart] = useState<number>();
  // const [oldTotalAmount, setOldTotalAmount] = useState<number>();
  const [currentLocation, setCurrentLocation] = useState<string>("");
  const [formInfoData, setFormInfoData] = useState<Category[]>();
  const [UUiD, setUUiD] = useState<string>();
  // const [arrayForPayment, setArrayForPayment] = useState<arrayForPayment[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<TotalQuantity[]>([]);
  const [formNos, setFormNos] = useState<formNoData[]>([]);
  const [activeAccordion, setActiveAccordion] = useState<string>("");

  // console.log("arrayForPayment", arrayForPayment, cartData, formInfoData,totalQuantity);

  const toggle = useSelector(
    (store: { ID: { status: boolean } }) => store.ID.status
  );

  const addModalOpen = useSelector(
    (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  );

  const getFormInfo = async () => {
    const UUID = localStorage.getItem("uuid");
    if (UUID) {
      setUUiD(UUID);
    }
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/formInfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        console.log(
          "response.data :: FormInfo",
          response.data.CategorizedCartItems
        );

        const allFormNos: formNoData[] = [];

        response.data.CategorizedCartItems.map(
          (CategorizedCartItem: Category) => {
            CategorizedCartItem.subcategories.map((subcategory) => {
              subcategory.services.map((service) => {
                service.items.map((item) => {
                  // console.log("item.formNo..", item.formNo);
                  allFormNos.push({ formNod: item.formNo, check: false });
                });
              });
            });
          }
        );

        setFormNos(allFormNos);
        if(allFormNos && allFormNos.length > 1){
          // setActiveAccordion(allFormNos[0].formNod)
        }
        handleCheckAllData(false);
        setFormInfoData(response.data.CategorizedCartItems);
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  const getData = async () => {
    const UUID = localStorage.getItem("uuid");
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/cartinfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        console.log("response.data ::CartItems", response.data.CartItems);
        // setCartData(response.data.CartItems.Cart);
        const quantityArray: { serviceid: number; Quantity: number }[] = [];
        let total = 0;
        response.data.CartItems.Cart.map((item: CartItems) => {
          total = item.TotalAmount + total;
        });
        let ItemInCart = 0;
        response.data.CartItems.Cart.map((item: CartItems) => {
          ItemInCart = item.Quantity + ItemInCart;
        });
        let oldTotalAmount = 0;
        response.data.CartItems.Cart.map((item: CartItems) => {
          if (
            item.oldTotalAmount == null ||
            item.oldTotalAmount == undefined ||
            item.oldTotalAmount === 0 ||
            item.oldTotalAmount < item.TotalAmount
          ) {
            oldTotalAmount = item.TotalAmount + oldTotalAmount;
          } else {
            oldTotalAmount = item.oldTotalAmount + oldTotalAmount;
          }
        });

        response.data.CartItems.Cart.forEach((item: CartItem) => {
          // Check if the serviceid already exists in quantityArray
          const existingItem = quantityArray.find(
            (qItem) => qItem.serviceid === item.serviceid
          );

          if (existingItem) {
            // If it exists, add the Quantity
            existingItem.Quantity += item.Quantity;
          } else {
            // If not, create a new object and add to quantityArray
            quantityArray.push({
              serviceid: item.serviceid,
              Quantity: item.Quantity,
            });
          }
        });

        // console.log(quantityArray);
        console.log(
          "cartitemmm ::: response data : quantityArray",
          quantityArray
        );
        console.log(
          "cartitemmm ::: response data : totalItemInCart",
          ItemInCart
        );
        // setTotalItemInCart(ItemInCart);
        // setTotalPrice(total);
        // setOldTotalAmount(oldTotalAmount);
        setTotalQuantity(quantityArray);
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  useEffect(() => {
    import("./cardItem.css");
    const loca = location.pathname;
    if (loca) {
      setCurrentLocation(loca);
    }
    getData();
    getFormInfo();
  }, []);

  useEffect(() => {
    if (toggle) {
      getData();
      getFormInfo();
      // handleRefresh()
    }
    dispatch(setFalse());
  }, [toggle]);

  // const handleMinusQuantity = async (
  //   serviceid: number,
  //   priceRecordId: number
  // ) => {
  //   sessionStorage.setItem("serviceid", JSON.stringify(serviceid));

  //   cartData?.forEach((item) => {
  //     if (item.PriceRecordId === priceRecordId) {
  //       addDataToDataBase(item.PriceRecordId, item.serviceid, "minus");
  //     }
  //   });
  //   // sessionStorage.setItem("direction",JSON.stringify({ id: priceRecordId, direction: "down", key: Date.now() }))
  //   // setDirection({ id: priceRecordId, direction: "down", key: Date.now() });
  // };

  // const handlePlusQuantity = (serviceid: number, priceRecordId: number) => {
  //   sessionStorage.setItem("serviceid", JSON.stringify(serviceid));

  //   cartData?.forEach((item) => {
  //     if (item.PriceRecordId === priceRecordId) {
  //       addDataToDataBase(item.PriceRecordId, item.serviceid, "plus");
  //     }
  //   });
  //   // setDirection({ id: priceRecordId, direction: "up", key: Date.now() });
  // };

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      console.log("&& currentLocation === /cart", currentLocation);
      if (data) {
        dispatch(setTrue());
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const openModel = (serviceid: number) => {
    sessionStorage.setItem("serviceid", JSON.stringify(serviceid));
    dispatch(openAddModelData());
  };

  const handlePlusAndMinusFunction = (
    numberOfOptions: number,
    serviceid: number,
    PriceRecordId: number,
    name: string
  ) => {
    console.log(
      "handlePlusAndMinusFunction",
      numberOfOptions,
      serviceid,
      PriceRecordId
    );

    if (numberOfOptions > 1) {
      sessionStorage.setItem("serviceid", JSON.stringify(serviceid));
      dispatch(openAddModelData());
    } else {
      if (name === "add") {
        addDataToDataBase(PriceRecordId, serviceid, "plus");
      } else {
        addDataToDataBase(PriceRecordId, serviceid, "minus");
      }
    }
    // handleRefresh()
  };

  const handleSubmit = (
    data: FormDataa,
    priceRecordId: number,
    serviceId: number,
    personIndex: number,
    formId: string
  ) => {
    console.log("Form submitted:", data, priceRecordId, serviceId, personIndex);
    
    handleNext( formId);
    const allMatch = formNos?.every((item) => item.check === true);

    if (allMatch) {
      handleCheckAllData(true);
      setActiveAccordion("")
      // alert("data saved You can move to next step");
    } 
    // else {
    //   alert("data saved");
    // }

  };

  const handleSelect = (index: string) => {
    if(activeAccordion === index){
      setActiveAccordion("")
    }else{
    setActiveAccordion(index);
    }
  };

  const handleNext = (formId: string) => {
    const index = formNos.findIndex((item) => item.formNod === formId);

    if (index !== -1) {
      // Update the check property of the found object
      formNos[index].check = true;
      // console.log(`Updated formNos at index ${index}:`, formNos[index]);
    } 

    const nextId = index+1

    if (nextId < formNos.length) {
      const nextForm = formNos[nextId];
      setActiveAccordion(nextForm.formNod)
      // You can now use nextForm here
    } else {
      console.log("No more forms available.");
    }

  };
  // const handleNext = (index: number, formId: string) => {
  //   console.log(index, formId);

  //   // Find the index of the current formId
  //   const currentIndex = formNos.findIndex((item) => item.formNod === formId);

  //   if (currentIndex !== -1) {
  //     // If a match is found, update the item at the currentIndex
  //     formNos[currentIndex].check = true;
  //   }

  //   // Calculate the next index
  //   const nextIndex = currentIndex + 1;

  //   // Check if the next index is within the array bounds
  //   if (nextIndex < formNos.length) {
  //     const nextId = formNos[nextIndex].formNod;
  //     console.log("Next ID:", nextId);

  //     // Close the current section
  //     const currentCollapseElement = document.getElementById(
  //       `collapse-${formId}`
  //     );
  //     if (currentCollapseElement) {
  //       const currentCollapseInstance =
  //         Collapse.getInstance(currentCollapseElement) ||
  //         new Collapse(currentCollapseElement, { toggle: false });
  //       currentCollapseInstance.hide();
  //     }

  //     // Open the next section
  //     const nextCollapseElement = document.getElementById(`collapse-${nextId}`);
  //     if (nextCollapseElement) {
  //       const nextCollapseInstance =
  //         Collapse.getInstance(nextCollapseElement) ||
  //         new Collapse(nextCollapseElement, { toggle: false });
  //       nextCollapseInstance.show();
  //     }

  //     return nextId;
  //   } else {
  //     console.log("No next ID, reached the end of the array.");
  //     return null;
  //   }
  // };

  const handleDelete = async (Formid: string) => {
    const UUID = localStorage.getItem("uuid");
    console.log("handleDelelte", Formid);
    // /api/openRoute/deleteForm?FormNo=1&UUID=9b3bb970-3855-4c13-b883-feaa63a58a71
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/deleteForm?FormNo=${Formid}&UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Form deleted successfully") {
        console.log("delete :: formdata", response);
        getFormInfo();
        // getData()
        dispatch(setTrue());
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  return (
    <>
      <div className="card add-cart-each border-bottom-0 p-2">
        <div className="p-0">
          <div className="border-0">
            {formInfoData?.map((CategorizedCartItems, index: number) => (
              <div key={index}>
                <span className="cart-cat-badge font-13 ff-medium text-primary d-block">
                  {CategorizedCartItems.category}
                </span>
                <div className="border-0 border-bottom border-style-dashed pb-2">
                  {CategorizedCartItems.subcategories?.map((subcategory) =>
                    subcategory.services?.map((service, index: number) => (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center gap-3 gap-md-1"
                          key={index}
                        >
                          <div className="d-flex flex-column justify-content-start">
                            <div>
                              <span className="font-13 ff-semibold col-12">
                                {service?.serviceName}
                              </span>
                              <div className="font-12 ff-medium">
                                <p className="mb-0">
                                  AED {service?.totalNewTotalAmount.toFixed(2)}{" "}
                                  &nbsp;
                                  <span className="ff-normal font-10 text-black-50">
                                    <s>
                                      {service?.totalOldTotalAmount.toFixed(2)}
                                    </s>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center add-cart-btn-main">
                            <button
                              className="bt-mi"
                              id="decrement"
                              disabled
                              onClick={() =>
                                handlePlusAndMinusFunction(
                                  service.numberOfOptions,
                                  service.serviceid,
                                  service.items[0].PriceRecordId,
                                  "minus"
                                )
                              }
                            >
                              -
                            </button>
                            <span
                              className="mx-2 cursor-pointer"
                              id="quantity"
                              onClick={() => openModel(service?.serviceid)}
                            >
                              {totalQuantity?.find(
                                (item) => item.serviceid === service.serviceid
                              )?.Quantity ?? 0}
                            </span>
                            <button
                              className="bt-plu"
                              id="increment"
                              onClick={() =>
                                handlePlusAndMinusFunction(
                                  service.numberOfOptions,
                                  service.serviceid,
                                  service.items[0].PriceRecordId,
                                  "add"
                                )
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div
                          className="accordion my-3 cart-aco-main"
                          id={`accordion-${index}`}
                        >
                          {service?.items.map((servic, personIndex) => (
                            <React.Fragment key={personIndex}>
                              {/* <div className="d-flex align-items-start gap-2"> */}
                              {/* <div
                                className="accordion-item border mb-2 flex-grow-1"
                                // key={servic.formNo}
                              >
                                <h2
                                  className="accordion-header border-0"
                                  id={`heading-${servic.formNo}`}
                                >
                                  <div>
                                    <button
                                      className="accordion-button collapsed border-0 font-14 ff-medium p-2 align-items-center d-flex "
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse-${servic.formNo}`}
                                      aria-expanded="false"
                                      aria-controls={`collapse-${servic.formNo}`}
                                    >
                                      <div>
                                        Application {personIndex + 1} (
                                        {servic.pricetype})
                                      </div>
                                    </button>
                                  </div>

                                </h2>
                                <div
                                  id={`collapse-${servic.formNo}`}
                                  className="accordion-collapse collapse my-2 border-top "
                                  aria-labelledby={`heading-${servic.formNo}`}
                                  data-bs-parent={`#accordion-${servic.formNo}`}
                                >
                                  <div className="accordion-body px-2 ms-4 py-2">
                                    
                                    <DynamicForm
                                      fields={servic?.formFields}
                                      onSubmit={handleSubmit}
                                      priceRecordId={servic.PriceId}
                                      serviceId={servic.serviceid}
                                      formId={servic.formNo}
                                      UUiD={UUiD}
                                      personIndex={personIndex}
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <div className="d-flex align-items-start gap-2">
                                <Accordion className="mb-2 flex-grow-1"
                                activeKey={activeAccordion}
                                >
                                  <Accordion.Item
                                    // eventKey={servic.formNo}
                                    eventKey={servic.formNo}
                                    className="accordion-item border flex-grow-1"
                                  >
                                    <Accordion.Header 
                                    className="accordion-header border-0 font-14 ff-medium align-items-center d-flex"
                                    onClick={() => handleSelect(servic.formNo)}
                                    >
                                      Application {personIndex + 1} (
                                      {servic.pricetype})
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-collapse my-2 border-top accordion-body px-2 ms-4 py-2">
                                      <DynamicForm
                                        fields={servic?.formFields}
                                        onSubmit={handleSubmit}
                                        priceRecordId={servic.PriceId}
                                        serviceId={servic.serviceid}
                                        formId={servic.formNo}
                                        UUiD={UUiD}
                                        personIndex={personIndex}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>

                                <div
                                  className="d-flex justify-content-end"
                                  onClick={() => handleDelete(servic.formNo)}
                                >
                                  <i className="fas fa-trash font-12"></i>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </>
                    ))
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {addModalOpen && <Model />}
    </>
  );
};

export default AddDocument;
