import { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSelectAddressModel,
  openAddAnotherAddress,
  openAddressFromMap,
} from "../../store/slices/modalSlice";
// import { MapPin } from "lucide-react";
import { useLoadScript } from "@react-google-maps/api";
const baseUrl = import.meta.env.VITE_API_URL;
const googleMapsApiKey = import.meta.env.VITE_Google_Maps_Api_Key;

interface Address {
  RecordId: number;
  userName: string;
  session: string | null;
  address_type: string | null;
  address_detail: string | null;
  address: string | null;
  status: string | null;
  createdon: string | null;
  modifiedon: string;
  addresNickname: string;
  streetName: string;
  building: string;
  floor: string;
  flatNo: string;
  landmark: string;
  primaryAddress: number;
}

const libraries: "places"[] = ["places"];

const SelectAddressModel = () => {
  const dispatch = useDispatch();
  const [billingData, setBillingData] = useState<Address[]>([]);
  const showSelectAddress = useSelector(
    (state: { modal: { selectAddressModel: boolean } }) =>
      state.modal.selectAddressModel
  );

  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const handleClick = (id: number) => {
    setSelectedOption(id);
  };

  useEffect(() => {
    const localStorageData = sessionStorage.getItem("userName");
    if (localStorageData) {
      // setUserName(localStorageData);
    }
    // console.log("check",localStorageData)

    fetchAddressFromApi();
  }, []);

  const closeSelectAddress = () => {
    sessionStorage.removeItem("address");
    dispatch(closeSelectAddressModel());
  };

  const openSelectAddress = () => {
    console.log("openAddAnotherAddress");
    dispatch(closeSelectAddressModel());
    dispatch(openAddAnotherAddress());
    // dispatch(openAddressFromMap());
  };

  const handleSelectedAddress = () => {
    // Assume `billingData` is an array of objects
    // and `selectedOption` holds the RecordId you want to match.

    billingData.forEach((item) => {
      if (item.RecordId === selectedOption) {
        sessionStorage.setItem("address", JSON.stringify(item));
        sessionStorage.setItem("billingRecordID", item.RecordId.toString());
        dispatch(closeSelectAddressModel());
      }
    });
  };

  const fetchAddressFromApi = async () => {
    // const localStorageData = sessionStorage.getItem("userName");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");

    if (login_info) {
      // const login_infoo = JSON.parse(login_info);
      try {
        const postData = {
          // userName: localStorageData ?? login_infoo.userName,
          userName: "junaid5050",
        };

        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const response = await fetch(`${baseUrl}/api/user/userAddressShow`, {
          // Replace with your API endpoint
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });

        const data = await response.json();
        console.log("fetchAddressFromApi", data.mainAddressList.billingAddress);
        setBillingData(data.mainAddressList.billingAddress);
      } catch (error) {
        sessionStorage.removeItem("billing");
        console.error("Error fetching order data:", error);
      }
    }
  };

  const handleDelete = async (val: string, id: number) => {
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    if (login_info) {
      try {
        if (!baseUrl) {
          throw new Error(
            "Base URL is not defined in the environment variables"
          );
        }

        const response = await fetch(
          `${baseUrl}/api/user/userAddressDelete/?id=${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("AddressPage :: delete", response);

        if (val === "Billing") {
          setBillingData((prev) => prev.filter((add) => add.RecordId !== id));
        }
      } catch (error) {
        console.log("handleDelete error :: ", error);
        // setIsLoading(false);
        // setIsError(true);
      }
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });

  const handleOpenEditModal = (val: string, id: number) => {
    // const combinedArray = billingData.concat(shippingData);

    const filteredData = billingData.filter((item) => item.RecordId === id);
    console.log("selectedPlace edit", filteredData);

    // console.log ("AddressPage :: filter data ",filteredData)

    if (filteredData.length > 0) {
      localStorage.setItem("filteredData", JSON.stringify(filteredData[0])); // Assuming you want to store the first match
    }

    if (val === "Edit") {
      sessionStorage.setItem("mode", "Edit");
      dispatch(openAddressFromMap());
      dispatch(closeSelectAddressModel());
    }
  };

  if (loadError) {
    return <div className="text-red-500">Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-screen">
        {/* <MapPin className="w-8 h-8 text-blue-500 animate-bounce" /> */}
      </div>
    );
  }

  return (
    <Modal
      show={showSelectAddress}
      onHide={closeSelectAddress}
      centered
      scrollable
      className="mb-fix slide-from-bottom"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title className="h4 text-dark">Saved addresses</Modal.Title> */}
      </Modal.Header>

      <Modal.Body className="modal-dialog-scrollable rounded-top">
        <p className="font-17 ff-semibold text-black">Saved addresses</p>
        <div
          className="address-container d-flex flex-wrap align-items-center gap-2 pt-2 pb-2 mb-3 border-bottom"
          onClick={openSelectAddress}
        >
          <div className="address-icon-container">
            <div className="address-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="#6E42E5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 13v7.25h2V13h7.25v-2H13V3.75h-2V11H3.75v2H11z"
                  fill="#6E42E5"
                />
              </svg>
            </div>
          </div>
          <div className="address-text-container">
            <p className="address-text mb-0 font-13 ff-bold text-new cursor-pointer">
              Add another address
            </p>
          </div>
        </div>
        {billingData.length > 0 ? (
          <>
            {billingData.map((val) => (
              <div
                className="d-flex align-items-start justify-content-start gap-3 p-2"
                key={val.RecordId}
              >
                <Form.Check
                  type="radio"
                  id="option1"
                  name="address"
                  className="me-2"
                  onClick={() => handleClick(val.RecordId)}
                />
                <div className="cursor-pointer">
                  <p className="font-16 fw-semibold text-black mb-0">
                    {val.addresNickname}
                  </p>
                  <p className="font-12 fw-medium">
                    {val.flatNo} , {val.streetName}
                  </p>
                </div>
                <Dropdown align="end" className="ms-auto">
                  <Dropdown.Toggle variant="link" className="action-bt p-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#0F0F0F"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#0F0F0F"
                        fillRule="evenodd"
                        d="M8 5a1 1 0 110-2 1 1 0 010 2zM7 8a1 1 0 102 0 1 1 0 00-2 0zm0 4a1 1 0 102 0 1 1 0 00-2 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleOpenEditModal("Edit", val.RecordId);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDelete("Billing", val.RecordId)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="error-location text-center">
              <svg
                width="128"
                height="96"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 20h20v44H10V20z" fill="#FFD47F"></path>
                <path d="M22 8h20L30 20H10L22 8z" fill="#9E4D10"></path>
                <path d="M30 20L42 8l12 12v44H30V20z" fill="#FFE0A3"></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 28h-4v10h4V28zm0 14h-4v10h4V42zm4-14h4v10h-4V28zm4 14h-4v10h4V42z"
                  fill="#fff"
                ></path>
                <path
                  d="M54.411 28.006a13.998 13.998 0 00-8.222 2.39 14.047 14.047 0 00-5.302 6.738 14.081 14.081 0 00-.405 8.57c.76 2.83 2.384 5.35 4.644 7.21a30.672 30.672 0 016.19 6.622 23.79 23.79 0 012.182 4.141.543.543 0 00.79.235.542.542 0 00.2-.235 23.156 23.156 0 012.244-4.079 29.861 29.861 0 016.082-6.576 13.954 13.954 0 003.873-4.951 13.985 13.985 0 001.311-6.154 14.625 14.625 0 00-4.145-9.554 14.563 14.563 0 00-9.442-4.357z"
                  fill="#DC2804"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M54 40.586l-4.293-4.293-1.414 1.414L52.586 42l-4.294 4.294 1.415 1.414L54 43.414l4.293 4.293 1.414-1.414L55.414 42l4.293-4.293-1.414-1.414L54 40.586z"
                  fill="#fff"
                ></path>
              </svg>

              <p className="my-3 font-16 ff-semibold">No Address Found</p>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSelectedAddress}
          className="w-100 fw-semibold py-3"
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectAddressModel;
