import { servicesOffereed } from "../../../types/help";

interface Props {
  servicesOffered: servicesOffereed
}
const ServicesOffered: React.FC<Props> = ({ servicesOffered }) => {
  return (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: servicesOffered?.heading ?? "",
        }}
      ></h1>
      {servicesOffered?.categories?.map((category) => (
        <>
          <ul key={category?.id}>
            <li>
              {category?.id}.&nbsp;&nbsp;&nbsp;
              <b
                dangerouslySetInnerHTML={{
                  __html: category?.heading ?? "",
                }}
              >
              </b>
            </li>
          </ul>
          {category?.content?.map((content) => (
            <>
              <ul key={content?.id} style={{listStyleType:"circle", marginLeft:"2.5rem"}} >
                <li>{content?.heading}</li>
              </ul>
            </>
          ))}
        </>
      ))}
    </>
  );
};

export default ServicesOffered;
