import React, { useEffect, useRef } from 'react';

interface OtpInputProps {
  value: string;
  onChange: (value: string) => void;
  length?: number;
}

export const OtpInput: React.FC<OtpInputProps> = ({ value, onChange, length = 4 }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Handle pasting
    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const pastedData = e.clipboardData?.getData('text');
      if (!pastedData) return;

      // Only take the first {length} characters
      const sanitizedData = pastedData.replace(/\D/g, '').slice(0, length);
      onChange(sanitizedData);
      
      // Fill in the inputs
      sanitizedData.split('').forEach((char, idx) => {
        if (inputRefs.current[idx]) {
          inputRefs.current[idx]!.value = char;
        }
      });

      // Focus the next empty input or the last input
      const nextEmptyIndex = sanitizedData.length;
      if (nextEmptyIndex < length && inputRefs.current[nextEmptyIndex]) {
        inputRefs.current[nextEmptyIndex]?.focus();
      }
    };

    // Add paste event listener to each input
    inputRefs.current.forEach(input => {
      input?.addEventListener('paste', handlePaste);
    });

    return () => {
      inputRefs.current.forEach(input => {
        input?.removeEventListener('paste', handlePaste);
      });
    };
  }, [length, onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value;
    if (!/^\d*$/.test(newValue)) return; // Only allow digits

    const newOtp = value.split('');
    newOtp[index] = newValue.slice(-1);
    const updatedOtp = newOtp.join('');
    onChange(updatedOtp);

    // Auto-focus next input
    if (newValue && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="d-flex gap-2">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          ref={el => inputRefs.current[index] = el}
          type="text"
          maxLength={1}
          style={{
            width: "3rem", // w-12
            height: "3rem", // h-12
            textAlign: "center", // text-center
            fontSize: "1rem", // text-2xl
            // fontWeight: "600", // font-semibold
            border: "1px solid #ced4da", // border (Bootstrap default)
            borderRadius: "0.375rem", // rounded-lg
            outline: "none", // focus:outline-none
          }}
        //   className="w-12 h-12 text-center text-2xl font-semibold border rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={value[index] || ''}
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          autoComplete="off"
          inputMode="numeric"
        />
      ))}
    </div>
  );
};