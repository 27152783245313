import { Section10, Section9 } from "../../../types/department";

interface Props {
  section9: Section9 | undefined;
  section10?: Section10; // You can make section3 optional if necessary
}

const WhyChooseUs: React.FC<Props> = ({ section9, section10 }) => {
  return (
    <section className="why-choose-us py-5 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center mb-5">
            <div className="hd-style-2 golden">
              <h2
                dangerouslySetInnerHTML={{
                  __html: section9?.heading ?? " ",
                }}
              ></h2>
            </div>
            <p
              className="lead "
              dangerouslySetInnerHTML={{
                __html: section9?.content ?? " ",
              }}
            ></p>
          </div>
          {/* Card 1 */}
          <div className="col-md-5 mb-4  wow animate__zoomIn">
            <img src={section10?.image} alt="" className="img-fluid" />
          </div>
          {/* Card 2 */}
          <div className="col-md-5 mb-4">
            <div className="why-card p-4 wow animate__fadeInRight">
              <h3
                className="why-title"
                dangerouslySetInnerHTML={{
                  __html: section10?.heading ?? " ",
                }}
              ></h3>
              <p
                className="why-text"
                dangerouslySetInnerHTML={{
                  __html: section10?.content ?? " ",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
