import { useSwiper } from "swiper/react";
import img1 from "../../assets/partners/aroow-1.png";
import { useEffect } from "react";
const SwiperNavButtons = () => {
  const swiper = useSwiper();

  useEffect(() => {
    import("./SwiperNavButton.css");
  }, []);

  return (
    <div className="swiper-nav-btns d-flex flex-row-reverse gap-0 py-3">
      <button onClick={() => swiper.slideNext()}>
        <img src={img1} alt="nex" className="nex " />
      </button>
      <button onClick={() => swiper.slidePrev()}>
        <img src={img1} alt="prev" className="prev" />
      </button>
    </div>
  );
};

export default SwiperNavButtons;
