
interface Props {
  bannerImg?: string;
}
const BannerCompnent: React.FC<Props> = ( bannerImg ) => {
  return (
    <>
      <div className="adeed-banner">
        <img alt="Mian Banner" src={bannerImg.bannerImg} />
      </div>
    </>
  );
};

export default BannerCompnent;
