import { useEffect } from "react";
import logo from "../../assets/blogImg/blog-logo.svg";

const Blog = () => {
  const categories = [
    "Amer",
    "Emirates ID",
    "Dubai Economy",
    "Dubai Health Authority",
    "Tajweeh",
    "Thahsheel",
    "Health Services",
    "Legal Translation",
    "Notary Services",
  ];

  const recentBlogs = [
    {
      imgSrc:
        "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg",
      title: "The Importance of Emirates ID in the...",
      description:
        "The Emirates ID is more than just a card  it is a vital identity document for all UAE residents. Issued by the Federal Authority for Identity ...",
      link: "/blogDetails",
      badge: "Latest",
    },
    {
      imgSrc:
        "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg",
      title: "The Benefits of Dubai Health Services",
      description:
        "The Emirates ID is more than just a card it is a vital identity document for all UAE residents ...",
      link: "/blogDetails",
      badge: "Trending",
    },
  ];

  const blogs = [
    {
      imgSrc:
        "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg",
      title: "The Importance of Emirates ID in the UAE",
      date: "August 20, 2022",
      description:
        "The Emirates ID is more than just a card it is a vital identity document for all UAE residents ...",
      link: "/blogDetails",
      badge: "Latest",
    },
    {
      imgSrc:
        "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg",
      title: "The Importance of Emirates ID in the UAE",
      date: "August 20, 2022",
      description:
        "The Emirates ID is more than just a card it is a vital identity document for all UAE residents ...",
      link: "/blogDetails",
      badge: "Latest",
    },
    {
      imgSrc:
        "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg",
      title: "The Importance of Emirates ID in the UAE",
      date: "August 20, 2022",
      description:
        "The Emirates ID is more than just a card it is a vital identity document for all UAE residents ...",
      link: "/blogDetails",
      badge: "Latest",
    },
  ];

  useEffect(() => {
    import("./blog.css");
  }, []);

  return (
    <>
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-md-6 mb-4 hd-style-2 my-auto">
              <h2>
                Blog <span>Updates</span>
              </h2>
              <p>
                Our know-how will help steer the patron’s way through the thick
                and thin of bureaucracy and official procedure.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-4 d-flex align-items-center justify-content-end d-none d-md-block">
              <img
                src={logo}
                alt="Blog Updates Image"
                className="img-fluid blog-logo"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row reverse-sm">
            <aside className="col-md-3 mb-4 ">
              <div className="d-none d-md-block">
                <h3 className="ff-semibold font-18 py-2">Browse By Category</h3>
                <ul className="category-list ">
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      className={`ff-medium font-14 cursor-pointer ${
                        category === "Emirates ID" ? "active" : ""
                      }`}
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="ff-semibold font-18 py-2">Recent Blogs</h3>
                {recentBlogs.map((blog, index) => (
                  <div className="blog-item-sub" key={index}>
                    <img
                      src={blog.imgSrc}
                      alt={blog.title}
                      className="recent-image"
                    />
                    <div className="blog-content-sub">
                      <h4>{blog.title}</h4>
                      <div className="blog-description checkout-ser-name">
                        {blog.description}
                      </div>
                      <a
                        href={blog.link}
                        className="btn-link ff-semibold font-12 mt-2 text-decoration-none w-100 text-end d-block"
                      >
                        View
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </aside>

            <div className="col-md-9">
              <div className="row">
                {blogs.map((blog, index) => (
                  <div className="col-md-4 mb-4" key={index}>
                    <div className="blog-item">
                      <img
                        src={blog.imgSrc}
                        className="img-fluid list-image"
                        alt={blog.title}
                      />
                      <div className="blog-content my-2">
                        {blog.badge && (
                          <span className="blog-badge px-3  rounded bg-2 mt-2 font-12 ff-semibold ">
                            {blog.badge}
                          </span>
                        )}
                        <div className="blog-title ff-semibold font-15 my-1">
                          {blog.title}
                        </div>
                        <div className="blog-date font-12 ff-semibold text-secondary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            className="me-2"
                          >
                            <path d="M19,4H17V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H20Zm0-9H4V7A1,1,0,0,1,5,6H7V7A1,1,0,0,0,9,7V6h6V7a1,1,0,0,0,2,0V6h2a1,1,0,0,1,1,1Z" />
                          </svg>
                          {blog.date}
                        </div>
                        <div className="blog-description font-14 text-secondary pt-1">
                          {blog.description}
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <a
                            href={blog.link}
                            className="btn-link ff-semibold font-14 text-decoration-none w-100 text-start d-block"
                          >
                            Read More
                          </a>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.5 2.25C14.7051 2.25 13.25 3.70507 13.25 5.5C13.25 5.69591 13.2673 5.88776 13.3006 6.07412L8.56991 9.38558C8.54587 9.4024 8.52312 9.42038 8.50168 9.43939C7.94993 9.00747 7.25503 8.75 6.5 8.75C4.70507 8.75 3.25 10.2051 3.25 12C3.25 13.7949 4.70507 15.25 6.5 15.25C7.25503 15.25 7.94993 14.9925 8.50168 14.5606C8.52312 14.5796 8.54587 14.5976 8.56991 14.6144L13.3006 17.9259C13.2673 18.1122 13.25 18.3041 13.25 18.5C13.25 20.2949 14.7051 21.75 16.5 21.75C18.2949 21.75 19.75 20.2949 19.75 18.5C19.75 16.7051 18.2949 15.25 16.5 15.25C15.4472 15.25 14.5113 15.7506 13.9174 16.5267L9.43806 13.3911C9.63809 12.9694 9.75 12.4978 9.75 12C9.75 11.5022 9.63809 11.0306 9.43806 10.6089L13.9174 7.4733C14.5113 8.24942 15.4472 8.75 16.5 8.75C18.2949 8.75 19.75 7.29493 19.75 5.5C19.75 3.70507 18.2949 2.25 16.5 2.25ZM14.75 5.5C14.75 4.5335 15.5335 3.75 16.5 3.75C17.4665 3.75 18.25 4.5335 18.25 5.5C18.25 6.4665 17.4665 7.25 16.5 7.25C15.5335 7.25 14.75 6.4665 14.75 5.5ZM6.5 10.25C5.5335 10.25 4.75 11.0335 4.75 12C4.75 12.9665 5.5335 13.75 6.5 13.75C7.4665 13.75 8.25 12.9665 8.25 12C8.25 11.0335 7.4665 10.25 6.5 10.25ZM16.5 16.75C15.5335 16.75 14.75 17.5335 14.75 18.5C14.75 19.4665 15.5335 20.25 16.5 20.25C17.4665 20.25 18.25 19.4665 18.25 18.5C18.25 17.5335 17.4665 16.75 16.5 16.75Z"
                                fill="#6e42e5"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
