import React from "react";
interface Props {
  data?: {
    heading: string;
    line: string;
    image: string;
  };
}
const Banner: React.FC<Props> = ({ data }) => {
  return (
    <>
      <section className="inner_banner position-relative">
        <span className="d-none d-md-block">
          <img src={data?.image} alt="" className="banner-img" />
        </span>
        <span className="d-block d-md-none">
          <img src={data?.image} alt="" className="banner-img" />
        </span>

        <div className="container h-100 d-flex align-items-center">
          <div className="row w-100">
            <div className="col-md-6">
              <div className="banner_content">
                <div className="hd-style-2 wow animate__fadeInLeft">
                  <h2>{data?.heading}</h2>
                </div>
                <p
                  className="font-20 ff-semibold text-white wow animate__fadeInLeft"
                  dangerouslySetInnerHTML={{
                    __html: data?.line ?? "",
                  }}
                ></p>
                {/* <button
                  className="btn btn-primary flow-btn py-2 wow animate__fadeInLeft"
                  onClick={() => {
                    const pkgSection = document.getElementById("pkg");
                    if (pkgSection) {
                      pkgSection.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  <span className="text-decoration-none  px-3 ff-semibold">
                    Apply Now
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
