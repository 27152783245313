import { useNavigate } from "react-router-dom";

interface Props {
  data?: {
    heading: string;
    subHeading: string;
    line: string;
    imageUrl: string;
    imageAlt: string;
  };
}

const OneStopDestination: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="bg-2">
        <div className="row">
          <div className="col-md-6">
            <img
              src={
                data?.imageUrl ??
                "https://goodhand.b-cdn.net/Assets/Footer_pages/Ahdeed/MarriageServices/New%20Project.png"
              }
              alt={data?.imageAlt ?? "no img"}
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 my-auto">
            <div className="p-3 p-md-5">
              <span
                className="font-14 ff-semibold pb-3 text-uppercase"
                dangerouslySetInnerHTML={{
                  __html: data?.subHeading ?? "",
                }}
              >
                {/* Goodhand Marriage Services */}
              </span>
              <div className="hd-style-2 golden wow animate__fadeIn">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.heading ?? "",
                  }}
                ></h2>
              </div>

              <p
                className="font-14  w-75"
                dangerouslySetInnerHTML={{
                  __html: data?.line ?? "",
                }}
              ></p>

              <div className="text-center d-md-flex align-items-center gap-3  d-none">
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </button>
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={() => navigate("/services/al-adheed")}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OneStopDestination;
