import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const AnnounceModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasSeenAnnouncement = sessionStorage.getItem("hasSeenAnnouncement");
    if (!hasSeenAnnouncement) {
      // setShowModal(true);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
    sessionStorage.setItem("hasSeenAnnouncement", "true");
  };

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={handleClose}
        className="announcement-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-cente bg-transparent p-0">
          <a
            href="/visa-packages-dubai"
            onClick={() =>
              sessionStorage.setItem("hasSeenAnnouncement", "true")
            }
          >
            {" "}
            <img
              src="https://goodhand.b-cdn.net/Assets/GoodHand_HomeBanner.jpg"
              alt="Announcement"
              className="img-fluid rounded"
            />
          </a>
        </Modal.Body>
        <Modal.Footer className="bg-transparent"></Modal.Footer>
      </Modal>
    </>
  );
};

export default AnnounceModal;
