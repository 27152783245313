import { useEffect } from "react";
interface Props {
  videoPath?: string ; 
}

const VideoSec: React.FC<Props> = ({ videoPath }) => {
  useEffect(() => {
    import("../../../css/amer.css");
  }, []);
  return (
    <>
      <div className="row justify-content-center amer-video m-10">
        <div className="col-12 col-md-12   col-lg-12 position-relative">
          <div className="video-container">
            <video
              width="100%"
              height="400"
              autoPlay
              loop
              muted
              playsInline
              controlsList="nodownload nofullscreen noremoteplayback"
            >
              <source src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSec;
