import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import Search from "./Search";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const PaymentNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  
  const handleBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    import("./paymentNavbar.css")
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // const handleScrollB = () => {
    //   if (window.scrollY > 20) {
    //     setIsHidden(true);
    //   } else {
    //     setIsHidden(false);
    //   }
    // };

    window.addEventListener("scroll", handleScroll);
    // window.addEventListener("scroll", handleScrollB);
    // window.addEventListener("resize", handleResize);

    return () => {
      // window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      // window.removeEventListener("scroll", handleScrollB);
    };
  }, []);
  return (
    <>
      <div
        className={`mobile-nav d-flex justify-content-between align-items-center small-header fixed-top bg-white p-2 ${
          isFixed ? "fixed" : ""
        }`}
      >
        <div className="d-flex align-items-center gap-3">
          <button className="back-icon" onClick={handleBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>

          <p className="font-16 ff-semibold mb-0 text-black ">
            <>{location.pathname.slice(1)}</>
          </p>
        </div>
      </div>
    </>
  );
};

export default PaymentNavbar;
