import { useDispatch } from "react-redux";
import bannerWithLogo from "../../../../assets/Legal/banner-withlogo.png";
import { openGetQuoteModel } from "../../../../store/slices/modalSlice";
import { BestLegalTransl } from "../../../../types/homeComponentTypes";

interface Props {
  data?: BestLegalTransl;
}

const QualityTranslationLanguageTeaching: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const handleGetAQuote = () => {
    dispatch(openGetQuoteModel());
  };
  return (
    <>
      <section className="bg-2">
        <div className="row">
          <div className="col-md-6">
            <img
              src={data?.image ?? bannerWithLogo}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 my-auto">
            <div className="p-md-5 p-3">
              <span
                className="font-14 ff-semibold pb-3 text-uppercase"
                dangerouslySetInnerHTML={{
                  __html: data?.heading ?? "",
                }}
              ></span>
              <div className="hd-style-2 golden wow animate__fadeIn">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.sub_heading ?? "",
                  }}
                ></h2>
              </div>

              <p
                className="font-14  w-100 w-md-75"
                dangerouslySetInnerHTML={{
                  __html: data?.description ?? "",
                }}
              >
                {/* {data?.description} */}
              </p>

              <div className="text-center d-md-flex d-none">
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={handleGetAQuote}
                >
                  Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QualityTranslationLanguageTeaching;
