import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-main.svg";
import { useDispatch, useSelector } from "react-redux";
import { setFooterRef } from "../../store/slices/footerSlice";
import MobileNav from "./FooterNav";
import "./footer.css"

import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL;

interface Department {
  RecordId: number;
  URL: string;
  title: string;
  text: string;
  JSON_URL: string;
  compId: string;
  Position: number;
  isFooterShow: boolean;
}

interface typeofVisa {
  RecordId: number;
  SlugUrl: string;
  JsonAddress: string;
  BannerImg: string;
  BannerImgAlt: string | null;
  BannerHeading: string;
  BannerSubText: string;
  PackageImg: string;
  PackageImgAlt: string | null;
  PackageHeading: string;
  PackageText: string;
  Position: number;
  isFooterShow: boolean;
  footerPosition: number;
  LinkText: string;
}

const Footer = () => {
  const URL = `${apiUrl}/api/openRoute/navFooter`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const footerRef = useRef<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [navFooterDataDepartment, setNavFooterDataDepartment] = useState<
    Department[]
  >([]);
  const [navFooterDataTypesOfVisa, setNavFooterDataTypesOfVisa] = useState<
    typeofVisa[]
  >([]);

  const isServicesRoute = location.pathname.startsWith("/services/");

  const { cartValue, cartValuePrice, discount } = useSelector(
    (state: {
      ID: {
        cartValue: string;
        cartValuePrice: number;
        insideId: string;
        footerOutsideId: string;
        discount: number;
      };
    }) => state.ID
  );

  const addModalOpen = useSelector(
    (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  );

  const fetchNavFooterData = async () => {
    try {
      const response = await axios.get(URL);
      setNavFooterDataDepartment(response.data.data.department); // Store data in state
      setNavFooterDataTypesOfVisa(response.data.data.typesOfVisa);
    } catch (err) {
      console.error("Error fetching nav footer data:", err);
    }
  };

  useEffect(() => {
    if (footerRef.current) {
      dispatch(setFooterRef(footerRef.current));
    }

    fetchNavFooterData();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  // };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <>
      <footer className="footer" ref={footerRef}>
        {/* <footer className="footer"> */}

        {/* <div className={`scroll-top-btn ${isVisible ? "d-block" : "d-none"} `}> */}
          <button onClick={scrollToTop} aria-label="Scroll to top"
          className={`scroll-top-btn ${isVisible ? "d-block" : "d-none"} `}
          >
             <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 244 512.89"
            height="17px"
          >
            <path
              fillRule="nonzero"
              d="M0 138.67 120.95 0 244 140.72l-23.95 20.94-83.14-95.08.41 446.31h-31.8l-.41-446.32-81.16 93.05z"
              fill="white"
            />
          </svg>
          </button>
          {/* <div className="divider"></div>
          <button onClick={scrollToBottom} aria-label="scroll to bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="24px"
              height="24px"
            >
              <path d="M12 22L3 13h6V4h6v9h6L12 22z" />
            </svg>
          </button>
        </div> */}
        <div className="container wow fadeIn">
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex align-items-center gap-4">
                <div
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  <img src={logo} alt="good logo" className="img-fluid" />
                </div>
                <span className="ps-4 d-block d-md-none">
                  <img
                    src="https://goodhand.b-cdn.net/Assets/Logo%20Gif.gif"
                    alt="Gif"
                    className="img-fluid"
                    style={{ height: "100px" }}
                  />
                </span>
              </div>
              <nav aria-label="Main Navigation">
                <ul className="mt-3">
                  <li>
                    <Link to="/about" title="About Us">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/termsandconditions"
                      title="Terms and Conditions is missing"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacyandpolicy"
                      title="Privacy Policy is missing"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/CancellationPolicy"
                      title="Refund Policy is missing"
                    >
                      Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus" title="Contact Us is missing">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="d-none d-md-block">
                <img
                  src="https://goodhand.b-cdn.net/Assets/Logo%20Gif.gif"
                  alt="gif"
                  className="img-fluid"
                  style={{ height: "100px" }}
                />
              </div>
            </div>

            <div className="col-md-3">
              <span className="ff-bold d-block mb-2 font-20">Department</span>
              <nav aria-label="Department Navigation">
                <ul className="main-menu">
                  {navFooterDataDepartment?.map((department, index) => (
                    <li
                      key={index}
                      className={`${department.isFooterShow ? "" : "d-none"}`}
                    >
                      <Link to={department?.URL} title={department?.title}>
                        {department?.title}
                      </Link>
                    </li>
                  ))}
                  {/* <li>
                    <Link
                      to={`legal-translation-services-in-dubai`}
                      title={"legal-translation-services-in-dubai"}
                    >
                      {"Legal Translation Services"}
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="col-md-3">
              <span className="ff-bold d-block mb-2 font-20">Types of Visa</span>
              <nav aria-label="Visa Types Navigation">
                <ul className="main-menu">
                  <li
                  className="d-none"
                  // className={`${visa.isFooterShow ? "" : "d-none"}`}
                  >
                    <Link to="/visa-packages-dubai" >Visa Packages</Link>
                  </li>
                  {navFooterDataTypesOfVisa.map((visa, index) => (
                    <>
                      <li
                        key={index}
                        className={`${visa.isFooterShow ? "" : "d-none"}`}
                      >
                        <Link to={visa.SlugUrl} title={visa.LinkText}>
                          {visa.LinkText}
                        </Link>
                      </li>
                    </>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="col-md-3">
              <span className="ff-bold d-block mb-2 font-20">Contact Us</span>
              <ul>
                <li>
                  <i className="fas fa-phone"></i>&nbsp;
                  <a
                    href="tel:+97142979997"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    : +971 42 97 9997
                  </a>
                </li>
                <li>
                  <i className="fab fa-whatsapp"></i>&nbsp;
                  <a
                    href="https://web.whatsapp.com/send?phone=97142979998"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    : +971 42 97 9998
                  </a>
                </li>
              </ul>

              <div className="">
                <p>
                  Ground Floor, Saraya Plaza
                  <br />
                  Al Muraqabat Deira
                  <br />
                  P.O Box 81748, Dubai
                </p>
                <div className="map">
                 <iframe
                   src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d225.51738957615!2d55.32732658898194!3d25.26122418829788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGood%20Hand!5e0!3m2!1sen!2sae!4v1738757840776!5m2!1sen!2sae"
                   width="100%" 
                   height="150" 
                   title="Goodhand map location"
                   style={{ border: "0" }}
                   loading="lazy"
                  ></iframe>
                </div>
              </div>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/goodhanduae/"
                  target="_blank"
                  aria-label="Visit GoodHand UAE on Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.youtube.com/@GoodHandGovernmentTransactionL"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit GoodHand UAE on youtube"
                >
                  <i className="fab fa-youtube"></i>
                </a>

                <a
                  href="https://www.instagram.com/good.hand__/"
                  target="_blank"
                  aria-label="Visit GoodHand UAE on instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/good-hand-government-transactional-llc"
                  target="_blank"
                  aria-label="Visit GoodHand UAE on linkedin"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center mb-5  flex-column">
            <span className="text-muted my-4 me-4">
              We accept payments online using Visa and MasterCard credit/debit
              card in AED
            </span>
            <div className="d-flex">
              <i
                className="fab fa-cc-visa mx-3"
                style={{
                  fontSize: "36px",
                  color: "#1A1F71",
                }}
              ></i>
              <svg
                width="50"
                height="35"
                viewBox="0 0 160 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1971_2831)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M148 1.00854e-06C140 1.76854e-06 55.3008 -1.60146e-06 8 1.00854e-06C4 1.22854e-06 0 4 0 8V88C-4.6e-07 96 4 100 12 100C56.6232 100 140 100 148 100C156 100 160 96 160 88V12C160 4 156 1.00854e-06 148 1.00854e-06Z"
                    fill="#265697"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M149.546 50.5C149.552 73.7378 130.92 92.5793 107.93 92.5854C84.9393 92.5911 66.2991 73.7591 66.293 50.5214V50.5C66.2869 27.2627 84.9191 8.42024 107.908 8.41406C130.899 8.40789 149.539 27.2408 149.546 50.4786V50.5Z"
                    fill="#DFAC16"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.8124 8.42502C28.9585 8.57175 10.4541 27.3653 10.4541 50.5C10.4541 73.725 29.1028 92.5745 52.0806 92.5745C62.8648 92.5745 72.6949 88.4205 80.0919 81.6119C80.0914 81.6119 80.0905 81.611 80.0886 81.61H80.0975C81.6122 80.2149 83.0248 78.7096 84.3234 77.1045H75.7951C74.6563 75.7141 73.6213 74.2654 72.6916 72.7705H87.4001C88.2946 71.3236 89.1045 69.8211 89.8233 68.265H70.2632C69.5966 66.8205 69.0178 65.3432 68.5236 63.8445H91.5648C92.9536 59.6506 93.7071 55.1636 93.7071 50.4995C93.7071 47.4073 93.3754 44.3924 92.7478 41.4895H67.3016C67.6169 39.9956 68.0148 38.5198 68.4893 37.0695H91.539C91.0236 35.5262 90.4251 34.0224 89.7444 32.5645H70.2609C70.9637 31.0469 71.76 29.5705 72.6493 28.145H87.347C86.3689 26.5699 85.2865 25.0651 84.1162 23.6395H75.9215C77.1885 22.1323 78.5834 20.713 80.0975 19.3895C72.7001 12.58 62.8672 8.42502 52.0806 8.42502C51.9909 8.42502 51.9021 8.42454 51.8124 8.42502Z"
                    fill="#BF3126"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M67.0502 61.2122L67.605 57.4043C67.302 57.4043 66.8562 57.5353 66.4629 57.5353C64.9201 57.5353 64.7501 56.7062 64.8482 56.0932L66.0946 48.3235H68.4394L69.0055 44.1115H66.7946L67.2447 41.4922H62.8126C62.7149 41.591 60.1963 56.2238 60.1963 58.0055C60.1963 60.6433 61.6616 61.8181 63.7278 61.7991C65.3458 61.7853 66.6058 61.3328 67.0502 61.2122Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.4541 53.9519C68.4541 60.2828 72.5883 61.7871 76.1104 61.7871C79.3614 61.7871 80.792 61.0525 80.792 61.0525L81.5728 56.7317C81.5728 56.7317 79.0997 57.8329 76.8663 57.8329C72.1073 57.8329 72.9407 54.2463 72.9407 54.2463H81.9477C81.9477 54.2463 82.5293 51.3435 82.5293 50.1606C82.5293 47.2089 81.0757 43.6123 76.2095 43.6123C71.753 43.6133 68.4541 48.4668 68.4541 53.9519ZM76.2264 47.6244C78.7277 47.6244 78.2663 50.4655 78.2663 50.6953H73.3456C73.3456 50.4019 73.8107 47.6244 76.2264 47.6244Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M104.292 61.21L105.085 56.3261C105.085 56.3261 102.911 57.4282 101.418 57.4282C98.2743 57.4282 97.0134 55.0017 97.0134 52.3947C97.0134 47.1062 99.7185 44.1967 102.73 44.1967C104.988 44.1967 106.801 45.4784 106.801 45.4784L107.524 40.7331C107.524 40.7331 104.836 39.6338 102.533 39.6338C97.416 39.6338 92.4385 44.1203 92.4385 52.5462C92.4385 58.1334 95.1262 61.8235 100.415 61.8235C101.911 61.824 104.292 61.21 104.292 61.21Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.6735 43.6816C39.6344 43.6816 37.3046 44.6689 37.3046 44.6689L36.6615 48.5281C36.6615 48.5281 38.5844 47.7384 41.491 47.7384C43.1414 47.7384 44.3488 47.9259 44.3488 49.2817C44.3488 50.1051 44.2008 50.409 44.2008 50.409C44.2008 50.409 42.899 50.2988 42.2963 50.2988C38.4632 50.2988 34.4365 51.9523 34.4365 56.9374C34.4365 60.8654 37.0782 61.7667 38.7155 61.7667C41.8424 61.7667 43.1903 59.7158 43.2622 59.7096L43.1161 61.4215H47.0187L48.7597 49.0841C48.7597 43.8502 44.2426 43.6816 42.6735 43.6816ZM43.623 53.7268C43.7075 54.4804 43.1555 58.0129 40.4871 58.0129C39.1106 58.0129 38.7526 56.9502 38.7526 56.3215C38.7526 55.0954 39.4117 53.6233 42.6594 53.6233C43.4148 53.6243 43.4957 53.7059 43.623 53.7268Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.9079 61.6772C53.9062 61.6772 59.6133 61.9337 59.6133 55.9813C59.6133 50.4164 54.3304 51.5157 54.3304 49.2801C54.3304 48.167 55.1916 47.8166 56.7654 47.8166C57.3902 47.8166 59.7942 48.0174 59.7942 48.0174L60.3532 44.0618C60.3532 44.0623 58.7973 43.71 56.2641 43.71C52.9854 43.71 49.6573 45.0329 49.6573 49.5593C49.6573 54.6883 55.2066 54.1735 55.2066 56.3341C55.2066 57.7763 53.6562 57.8936 52.4616 57.8936C50.394 57.8936 48.5322 57.1765 48.5256 57.2107L47.9346 61.1264C48.0417 61.1597 49.1899 61.6772 52.9079 61.6772Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M141.005 40.1321L140.049 46.1282C140.049 46.1282 138.381 43.799 135.769 43.799C131.708 43.799 128.323 48.748 128.323 54.4344C128.323 58.1051 130.129 61.7017 133.819 61.7017C136.474 61.7017 137.946 59.8308 137.946 59.8308L137.751 61.4287H142.062L145.448 40.125L141.005 40.1321ZM138.947 51.8232C138.947 54.1894 137.788 57.3501 135.385 57.3501C133.79 57.3501 133.042 55.9967 133.042 53.8727C133.042 50.3996 134.586 48.1079 136.533 48.1079C138.128 48.1074 138.947 49.2153 138.947 51.8232Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.6756 61.4474L21.3737 45.3639L21.7697 61.4474H24.8234L30.5197 45.3639L27.9964 61.4474H32.5338L36.0281 40.1152H29.0117L24.6421 53.2038L24.4147 40.1152H17.947L14.4023 61.4474H18.6756Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M86.9616 61.4765C88.2516 54.0611 88.4908 48.0399 91.5694 49.1421C92.1082 46.272 92.6283 45.1618 93.2165 43.9471C93.2165 43.9471 92.9407 43.8887 92.3619 43.8887C90.377 43.8887 88.9065 46.6296 88.9065 46.6296L89.3016 44.1123H85.1749L82.4102 61.4765H86.9616Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M114.519 43.6816C111.48 43.6816 109.15 44.6689 109.15 44.6689L108.507 48.5281C108.507 48.5281 110.43 47.7384 113.337 47.7384C114.987 47.7384 116.194 47.9259 116.194 49.2817C116.194 50.1051 116.047 50.409 116.047 50.409C116.047 50.409 114.745 50.2988 114.141 50.2988C110.309 50.2988 106.282 51.9523 106.282 56.9374C106.282 60.8654 108.923 61.7667 110.561 61.7667C113.688 61.7667 115.036 59.7158 115.107 59.7096L114.962 61.4215H118.864L120.605 49.0841C120.605 43.8502 116.088 43.6816 114.519 43.6816ZM115.47 53.7268C115.554 54.4804 115.002 58.0129 112.332 58.0129C110.956 58.0129 110.599 56.9502 110.599 56.3215C110.599 55.0954 111.258 53.6233 114.505 53.6233C115.261 53.6243 115.341 53.7059 115.47 53.7268Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M124.172 61.4765C125.463 54.0611 125.702 48.0399 128.779 49.1421C129.319 46.272 129.84 45.1618 130.427 43.9471C130.427 43.9471 130.152 43.8887 129.573 43.8887C127.588 43.8887 126.117 46.6296 126.117 46.6296L126.513 44.1123H122.386L119.621 61.4765H124.172Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1971_2831">
                    <rect width="160" height="100" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <i
                className="fab fa-cc-amex mx-3"
                style={{
                  fontSize: "36px",
                  color: "#2E77BC",
                }}
              ></i>
              <svg
                width="90px"
                height="100%"
                viewBox="0 0 141 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M122.239 16.873L113.948 48.5272L113.929 48.5989H120.386L128.707 16.873H122.239Z"
                  fill="#292929"
                ></path>
                <path
                  d="M19.643 34.8883C18.6747 35.3651 17.6091 35.6112 16.5297 35.6072C14.2043 35.6072 12.8847 35.2358 12.7413 33.3563V33.2297C12.7413 33.1551 12.7328 33.089 12.7328 33.0018V27.528L12.7413 26.8837V23.0248H12.7328V21.3831L12.7413 20.7332V17.008L6.97342 17.7691C10.8744 17.0136 13.1126 13.9272 13.1126 10.8505V8.95557H6.63157V17.8183L6.26581 17.921V34.334C6.47964 38.9455 9.5183 41.6859 14.504 41.6859C16.2667 41.6859 18.2066 41.285 19.6922 40.6111L19.726 40.597V34.8391L19.643 34.8883Z"
                  fill="#292929"
                ></path>
                <path
                  d="M20.6657 15.6799L2.47736 18.4837V23.0951L20.6657 20.2914V15.6799Z"
                  fill="#292929"
                ></path>
                <path
                  d="M20.6657 22.427L2.47736 25.2307V29.6368L20.6657 26.8317V22.427Z"
                  fill="#292929"
                ></path>
                <path
                  d="M41.0782 24.5485C40.8208 19.425 37.6189 16.3892 32.4026 16.3892C29.4033 16.3892 26.9316 17.5469 25.2533 19.7401C23.575 21.9333 22.6971 25.1394 22.6971 29.0362C22.6971 32.933 23.5806 36.1489 25.2533 38.3336C26.9259 40.5184 29.4033 41.6846 32.4026 41.6846C37.6189 41.6846 40.8208 38.6333 41.0782 33.483V41.2021H47.5495V16.9294L41.0782 17.9282V24.5485ZM41.4159 29.0418C41.4159 33.5801 39.0328 36.5119 35.3456 36.5119C31.5472 36.5119 29.2753 33.7194 29.2753 29.0418C29.2711 24.3375 31.5402 21.5239 35.3414 21.5239C37.1899 21.5239 38.7345 22.2442 39.8079 23.606C40.856 24.9368 41.4116 26.8149 41.4116 29.0376L41.4159 29.0418Z"
                  fill="#292929"
                ></path>
                <path
                  d="M66.4581 16.3905C61.2389 16.3905 58.0356 19.4277 57.7824 24.5569V9.86157L51.3013 10.8618V41.2034H57.7824V33.4815C58.0356 38.6388 61.2389 41.693 66.4581 41.693C72.5649 41.693 76.2113 36.9647 76.2113 29.0445C76.2113 21.1243 72.5649 16.3905 66.4581 16.3905ZM63.5151 36.5075C59.8265 36.5075 57.4448 33.5758 57.4448 29.0375C57.4448 26.8148 57.999 24.9367 59.0485 23.6031C60.1219 22.2413 61.6665 21.521 63.5151 21.521C67.3134 21.521 69.5854 24.3346 69.5854 29.0375C69.5854 33.7151 67.3162 36.5075 63.5151 36.5075Z"
                  fill="#292929"
                ></path>
                <path
                  d="M93.8595 16.3905C88.6403 16.3905 85.4385 19.4277 85.1838 24.5569V9.86157L78.7028 10.8618V41.2034H85.1838V33.4815C85.4385 38.6388 88.6403 41.693 93.8595 41.693C99.9663 41.693 103.613 36.9647 103.613 29.0445C103.613 21.1243 99.9663 16.3905 93.8595 16.3905ZM90.9165 36.5075C87.2293 36.5075 84.8462 33.5758 84.8462 29.0375C84.8462 26.8148 85.4005 24.9367 86.4499 23.6031C87.5233 22.2413 89.068 21.521 90.9165 21.521C94.7148 21.521 96.9868 24.3346 96.9868 29.0375C96.9868 33.7151 94.7176 36.5075 90.9165 36.5075Z"
                  fill="#292929"
                ></path>
                <path
                  d="M103.628 16.873H110.548L116.17 41.1584H109.966L103.628 16.873Z"
                  fill="#292929"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center d-none d-md-block text-center Copyright-main mt-5">
          © Copyright {new Date().getFullYear()} Goodhand Company. All rights reserved.
        </div>
      </footer>

      {!isServicesRoute ? (
        <MobileNav />
      ) : (
        !addModalOpen && (
          <>
            {isMobile && cartValue && (
              <>
                <div
                  className="mobile-section w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {discount > 0 && (
                    <div
                      className="w-100 mb-6 text-center"
                      style={{
                        backgroundColor: "rgb(7, 121, 76)",
                        border: "none",
                        color: "white",
                        padding: "10px 2px",
                      }}
                    >
                      <span className="ff-bold text-center font-12">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="white"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                            fill="white"
                          ></path>
                        </svg>
                        <span className="ms-1">
                          Congratulations! AED &nbsp;
                          {Number(discount).toFixed(2)} &nbsp; saved so far!{" "}
                        </span>
                      </span>
                    </div>
                  )}

                  <div className="w-100 gap-2 d-flex align-items-center mb-3 px-3">
                    {/* <div className="w-100 gap-2 d-flex align-items-center mb-3"> */}
                    <p className="item-count bg-2 p-2 mb-0 rounded ff-semibold">
                      {cartValue}
                    </p>
                    <p className="current-price flex-grow-1 mb-0 ff-bold">
                      <span>AED</span> {cartValuePrice?.toFixed(2)}
                    </p>
                    <button
                      className="done-button btn btn-primary mt-2 float-right flex-grow-1 ff-semibold"
                      onClick={() => navigate("/cart")}
                    >
                      View Cart
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )
      )}
    </>
  );
};

export default Footer;
