import React, { useState, useEffect, Suspense, useRef } from "react";
import "../../css/slick.css";
import "../../css/style.css";
import // faArrowLeft,
// faSearch,
// faTimes,
"@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { clearCart } from "../../store/slices/cartSlice";
import {
  setCartValue,
  setcartValuePrice,
  setDiscount,
  setFalse,
  setOldValueOfCartItems,
} from "../../store/slices/IdSlice";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CartItem, UserDataType } from "../../types/navbarTypes";
import PreLoader from "../preloader/PreLoader";
// import Search from "./navbarComponents/Search";
// const Model = React.lazy(() => import("../model/Model"));
const LoginModel = React.lazy(() => import("../LoginModel/LoginModel"));
const VerifyOtp = React.lazy(() => import("../otp/VerifyOtp"));
const Search = React.lazy(() => import("./navbarComponents/Search"));
const MobileNavbar = React.lazy(
  () => import("./navbarComponents/MobileNavbar")
);
const DesktopNavbar = React.lazy(
  () => import("./navbarComponents/DesktopNavbar")
);

const baseUrl = import.meta.env.VITE_API_URL;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [isSearchVisible, setSearchVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isActive, setIsActive] = useState(false);
  // const [search, setSearch] = useState<string>();
  // const [searchApiData, setSearchApiData] = useState<SearchResult[]>([]);
  // const [profileImage, setProfileImage] = useState<string>();
  const [totalItemInCart, setTotalItemInCart] = useState<number>();
  const [isHidden, setIsHidden] = useState(false);
  const [locationName, setLocationName] = useState<string>();

  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [text, setText] = useState("");
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [isFocused, setIsFocused] = useState(false);

  // const [isOpen, setIsOpen] = useState(true);
  // const dropdownRef = useRef<HTMLUListElement | null>(null);

  const fullAddress = "Ground Floor, Saraya Plaza - Al Rigga Rd - Dubai";
  const truncatedAddress = fullAddress;

  const [isSticky, setIsSticky] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const [initialOffsetTop, setInitialOffsetTop] = useState(0);
  // fullAddress.length > 24 ? fullAddress.slice(0, 23) + "..." : fullAddress;

  // const categories = [
  //   "Visit Visa",
  //   "Spouse Visa",
  //   "Golden Visa",
  //   "Family Visa",
  //   "Employment Visa",
  //   "Newborn Visa",
  // ];

  const [notifications, setNotifications] = useState([
    {
      title: "New Comment",
      message: "Someone commented on your post.",
      time: "2 mins ago",
    },
    {
      title: "New Follower",
      message: "You have a new follower.",
      time: "10 mins ago",
    },
    {
      title: "Message from John",
      message: "John sent you a message.",
      time: "1 hour ago",
    },
  ]);

  const isServicesRoute = location.pathname.startsWith("/services/");

  const hasNotification = notifications.length > 0;

  const isLoginOpen = useSelector(
    (state: { modal: { login: boolean } }) => state.modal.login
  );

  const toggle = useSelector(
    (store: { ID: { status: boolean } }) => store.ID.status
  );

  const addModalOpen = useSelector(
    (state: { modal: { addModelData: boolean } }) => state.modal.addModelData
  );

  const { ServiceName, cartValue } = useSelector(
    (state: {
      ID: {
        cartValue: number;
        cartValuePrice: number;
        insideId: string;
        footerOutsideId: string;
        ServiceName: string;
        oldTotalAmount: number;
      };
    }) => state.ID
  );

  const [data, setLoginData] = useState<UserDataType | null>(null);

  const login = useSelector(
    (state: { signin: { userData: UserDataType } }) => state.signin.userData
  );

  const showVerifyOtp = useSelector(
    (state: { modal: { verifyOtp: boolean } }) => state.modal.verifyOtp
  );

  useEffect(() => {
    import("./navbar.css");
    const uuid = localStorage.getItem("uuid");
    if (!uuid) {
      const newUuid = uuidv4(); // Generate new UUID
      localStorage.setItem("uuid", newUuid); // Save it to local storage
    }

    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    const handleScrollB = () => {
      if (window.scrollY > 20) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScrollB);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScrollB);
    };
  }, []);

  useEffect(() => {
    import("../mobileNav/MobileNav.css");
    const refreshToken = async () => {
      const refreshToken = localStorage.getItem("rt");
      if (refreshToken) {
        const refreshTokenParse = JSON.parse(refreshToken);

        const postData = {
          refreshToken: refreshTokenParse,
        };

        try {
          const response = await axios.post(
            `${baseUrl}/api/openRoute/refreshtoken`,
            postData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = response.data;
          localStorage.setItem("rt", JSON.stringify(data.refreshToken));
          sessionStorage.setItem("tk", data.accessToken);
        } catch (error) {
          console.error("There was a problem with the axios request:", error);
          // ClearEveryThing();
        }
      }
    };
    refreshToken(); // Initial call

    const minute = 10 * 1000 * 60;
    setInterval(refreshToken, minute);
  }, []);

  useEffect(() => {
    setNotifications([
      {
        title: "New Comment",
        message: "Someone commented on your post.",
        time: "2 mins ago",
      },
      {
        title: "New Follower",
        message: "You have a new follower.",
        time: "10 mins ago",
      },
      {
        title: "Message from John",
        message: "John sent you a message.",
        time: "1 hour ago",
      },
    ]);
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location]);

  useEffect(() => {
    const loginData = localStorage.getItem("Login_info");
    const loginStatus = localStorage.getItem("Login_info_status");

    if (loginData) {
      setLoginData(JSON.parse(loginData));
      setIsActive(!!loginStatus);
    } else {
      setIsActive(false);
    }
  }, [login]);

  useEffect(() => {
    getData();
    dispatch(setFalse());
  }, [toggle]);

  useEffect(() => {
    const loc = location.pathname.startsWith("/services/");
    const loca = location.pathname;
    if (!loc) {
      const modifiedPath = formatPath(loca);
      setLocationName(modifiedPath);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (search?.trim() === "") {
  //     setIsOpen(false);
  //   }
  // }, [search]);

  // useEffect(() => {
  //   if (isFocused) return; // Stop animation when focused

  //   const currentCategory = categories[currentIndex];

  //   const typingSpeed = 40;
  //   const deletingSpeed = 40;
  //   const pauseDuration = 1000; // Pause after a full text is displayed

  //   let timeout;

  //   if (isDeleting) {
  //     // Deleting characters
  //     timeout = setTimeout(() => {
  //       setText((prev) => prev.slice(0, -1));
  //       if (text === "") {
  //         setIsDeleting(false);
  //         setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
  //       }
  //     }, deletingSpeed);
  //   } else {
  //     // Typing characters
  //     timeout = setTimeout(() => {
  //       setText((prev) => currentCategory.slice(0, prev.length + 1));
  //       if (text === currentCategory) {
  //         // Pause before deleting
  //         setTimeout(() => setIsDeleting(true), pauseDuration);
  //       }
  //     }, typingSpeed);
  //   }

  //   return () => clearTimeout(timeout);
  // }, [text, isDeleting, currentIndex, categories, isFocused]);

  const formatPath = (path: string) => {
    return path
      .replace(/^\//, "") // Remove the starting slash
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  const getData = async () => {
    const UUID = localStorage.getItem("uuid");
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/cartinfoNew?UUID=${UUID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        // console.log("response in navbar", response.data.CartItems.Cart)
        let ItemInCart = 0;
        let totalAmountOfCartItem = 0;
        let oldTotalAmount = 0;

        response.data.CartItems.Cart.map((item: CartItem) => {
          ItemInCart = item.Quantity + ItemInCart;
        });

        response.data.CartItems.Cart.map((item: CartItem) => {
          totalAmountOfCartItem = item.TotalAmount + totalAmountOfCartItem;
        });

        response.data.CartItems.Cart.map((item: CartItem) => {
          if (
            item.oldTotalAmount == null ||
            item.oldTotalAmount == undefined ||
            item.oldTotalAmount === 0 ||
            item.oldTotalAmount < item.TotalAmount
          ) {
            oldTotalAmount = item.TotalAmount + oldTotalAmount;
          } else {
            oldTotalAmount = item.oldTotalAmount + oldTotalAmount;
          }
        });

        const discount = oldTotalAmount - totalAmountOfCartItem;
        dispatch(setCartValue({ ItemInCart }));
        dispatch(setcartValuePrice({ totalAmountOfCartItem }));
        dispatch(setOldValueOfCartItems({ oldTotalAmount }));
        dispatch(setDiscount({ discount }));
        setTotalItemInCart(ItemInCart);
      }
    } catch (error) {
      console.log("Error on CartItems", error);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("Login_info");
    localStorage.removeItem("Login_info_status");
    localStorage.removeItem("cartDataLocal");
    localStorage.removeItem("count");
    localStorage.removeItem("userName");
    sessionStorage.removeItem("userName");
    localStorage.removeItem("orderNumber");
    localStorage.removeItem("carts");
    localStorage.removeItem("rt");
    sessionStorage.removeItem("tk");
    sessionStorage.removeItem("billing");
    sessionStorage.removeItem("address");
    localStorage.removeItem("phoneNumber");
    dispatch(clearCart());
    setIsActive(false);
    navigate("/");
  };

  // const handleOpenLogin = () => {
  //   dispatch(openNewLogin());
  // };

  // const fetchSearchResults = (searchTerm: string) => {
  //   if (searchTerm.trim() === "") return; // Avoid calling the API if the search term is empty
  //   fetch(`${baseUrl}/api/openRoute/search?searchTerm=${searchTerm}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setSearchApiData(data.results); // Handle the API response data here
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "Error fetching search results ::: fetchSearchResults",
  //         error
  //       );
  //     });
  // };

  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newSearchValue = e.target.value;
  //   setSearch(newSearchValue); // Update the search state
  //   fetchSearchResults(newSearchValue); // Call the API with the updated search term

  //   if (newSearchValue.trim() !== "") {
  //     setIsOpen(true);
  //   }
  // };

  // const handleSuggestionClick = (
  //   insideId: string,
  //   outsideId: string,
  //   slug: string
  // ) => {
  //   dispatch(getID({ insideId, outsideId }));
  //   handleCloseSearch();
  //   if (location.pathname.startsWith("/services/")) {
  //     navigate(`/services/${slug}`, { state: { action: "toggleVisibility" } });
  //   } else {
  //     navigate(`/services/${slug}`, { state: { action: "toggleVisibility" } });
  //   }
  //   setSearch("");
  //   setIsOpen(false);
  // };

  // const handleSearchToggle = () => {
  //   setSearchVisible(true);
  // };

  // const handleCloseSearch = () => {
  //   setSearchVisible(false);
  // };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleBackButton = () => {
    // setSearch("");
    navigate("/");
  };

  // Calculate the element's initial offset from the top of the page
  useEffect(() => {
    if (searchRef.current) {
      const offsetTop = searchRef.current.offsetTop;
      setInitialOffsetTop(offsetTop);
    }
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= initialOffsetTop && !isSticky) {
      setIsSticky(true); // Make sticky when scrolling down past the initial position
    } else if (window.scrollY < initialOffsetTop && isSticky) {
      setIsSticky(false); // Reset when scrolling back above the initial position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, initialOffsetTop]);

  return (
    <Suspense fallback={<PreLoader/>}>
      {location.pathname !== "/adminpanel" && (
        <>
          {isMobile && isServicesRoute ? (
            <>
              {!addModalOpen && (
                <div
                  className={`mobile-nav d-flex justify-content-between align-items-center small-header ${
                    isFixed ? "fixed" : ""
                  }`}
                >
                  <div className="d-flex align-items-center gap-3">
                    <button className="back-icon" onClick={handleBackButton}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>

                    <p className="font-16 ff-semibold mb-0 text-black ">
                      <>{isHidden ? ServiceName : ""}</>
                    </p>
                  </div>

                  <Search page="service" />
                </div>
              )}
            </>
          ) : (
            <>
              {isMobile && location.pathname === "/" ? (
                <div style={{ marginBottom: "0px" }}>
                  <div
                    className="d-flex align-items jusdtify-content-space "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "30px 20px",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <p className="font-14 text-black ff-semibold mb-0">
                        Goodhand Government Transactions LLC
                      </p>
                      <i className="fa fa-map-marker-alt"></i>
                      <span className="font-12 mx-2">{truncatedAddress}</span>
                    </div>
                    <div>
                      <div className="navbar-badge-wrapper">
                        <div
                          className="badge-link"
                          onClick={() => {
                            navigate("/cart");
                          }}
                        >
                          <svg
                            width="19"
                            height="22"
                            viewBox="0 0 19 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.0208 19.6532L15.9739 7.58752C15.902 7.00627 15.3989 6.56876 14.8082 6.56876H12.9395V4.42186C12.9395 2.53121 11.4176 1 9.51445 1C7.62697 1 6.09254 2.53439 6.09254 4.42186V6.56876H4.22378C3.63315 6.56876 3.13316 7.00627 3.06129 7.57501L1.01131 19.6594C0.96446 19.9938 1.06447 20.3344 1.28633 20.5906C1.51136 20.85 1.83948 21 2.18319 21H16.8488C17.1926 21 17.5207 20.85 17.7457 20.5906C17.9707 20.3344 18.0707 19.9938 18.0208 19.6532ZM6.71761 4.42186C6.71761 2.88122 7.97073 1.62497 9.51449 1.62497C11.0676 1.62497 12.3145 2.87184 12.3145 4.42186V6.56876H6.71761V4.42186ZM17.2739 20.1813C17.1676 20.3032 17.0145 20.3751 16.8489 20.3751H2.18319C2.02069 20.3751 1.86445 20.3032 1.75819 20.1813C1.65192 20.0594 1.60819 19.9063 1.62695 19.7563L3.67697 7.66877C3.71132 7.40001 3.95199 7.19378 4.22387 7.19378H6.09263V8.95941C5.40825 9.10627 4.89575 9.7094 4.89575 10.4344C4.89575 11.2657 5.57387 11.9438 6.40825 11.9438C7.23949 11.9438 7.91449 11.2657 7.91449 10.4344C7.91449 9.70945 7.40199 9.10319 6.71761 8.95941V7.19378H12.3145V8.95941C11.6301 9.10627 11.1176 9.7094 11.1176 10.4344C11.1176 11.2657 11.7958 11.9438 12.6301 11.9438C13.4614 11.9438 14.1364 11.2657 14.1364 10.4344C14.1364 9.70945 13.6239 9.10319 12.9395 8.95941V7.19378H14.8082C15.0801 7.19378 15.3207 7.40001 15.3551 7.68127L17.4052 19.7469C17.427 19.9063 17.3801 20.0594 17.2739 20.1813Z"
                              fill="black"
                              stroke="black"
                              strokeWidth="1.3"
                            />
                          </svg>

                          {cartValue >= 1 && (
                            <span className="navbar-badge-count">
                              {cartValue}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  // className={`${isSticky ? "sticky-search" : ""}`}
                  >
                    <Search page="home" />
                  </div>
                </div>
              ) : (
                <>
                  {isMobile === true ? (
                    <>
                      <MobileNavbar
                        locationName={locationName}
                        isFixed={isFixed}
                        handleBackButton={handleBackButton}
                      />
                    </>
                  ) : (
                    <DesktopNavbar
                      isActive={isActive}
                      data={data}
                      totalItemInCart={totalItemInCart}
                      notifications={notifications}
                      hasNotification={hasNotification}
                      handleLogout={handleLogout}
                    />
                  )}
                </>
              )}
            </>
          )}
          {isMobile && location.pathname !== "/" && (
            <div className="header-height-fix"></div>
          )}
          {!isMobile && <div className="header-height-fix"></div>}
        </>
      )}

      {/* <Suspense fallback={<div>Loading...</div>}> */}
      {isLoginOpen && <LoginModel />}
      {showVerifyOtp && <VerifyOtp />}
      {/* </Suspense> */}
    </Suspense>
  );
};

export default Navbar;
