import React from "react";
import { Section2 } from "../../types/notary";
import { useNavigate } from "react-router-dom";

interface Props {
    section2?: Section2; 
}

const About: React.FC<Props> = ({ section2 }) => {
    const navigate = useNavigate();
    return (
        <section className="main-notary pt-1 py-md-5 ">
            <div className="container">
            <div className="row">
                <div className="col-md-7">
                <img
                    src={section2?.image}
                    alt=""
                    className="img-fluid"
                />
                </div>
                <div className="col-md-5">
                <div className="hd-style-2 ">
                    <h1 className="font-35 ff-bold"
                    dangerouslySetInnerHTML={{
                        __html: section2?.title ?? "",
                    }}
                    >
                    </h1>
                </div>
                <div>
                    {section2?.content.map((des, index) => (
                    <p className="mb-0"
                        key={index}
                        dangerouslySetInnerHTML={{
                        __html: des ?? " ",
                        }}
                    >
                    </p>
                    ))}
                </div>

                <div className="text-center d-flex col-12 ">
                    <button onClick={() => navigate("/services/notary-services")} className="btn btn-primary   font-14 ff-semibold  mt-2  bt--cart">
                    Book an appointment
                    </button>
                </div>
                </div>
            </div>
            </div>
        </section>
    );
};

export default About;
