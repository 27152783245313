import React, { useState } from "react";
import {
  A11y,
  Navigation,
  Pagination,
  EffectFade,
  EffectCoverflow,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButtons from "../../../components/sliderButtons/SwiperNavButton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

interface Section1 {
  BannerHeading: string;
  BannerSubText: string;
  BannerImg: string;
  SlugUrl: string;
  BannerImgAlt: string;
}

interface Props {
  section1?: Section1[];
}

const PackagesBanner: React.FC<Props> = ({ section1 }) => {
  const [activeDot, setActiveDot] = useState(0);

  const handleSlideChange = (swiper: any) => {
    const totalSlides = swiper.slides.length - 0; // Exclude duplicated slides in loop mode
    if (swiper.realIndex === 0) {
      setActiveDot(0); // First slide
    } else if (swiper.realIndex === totalSlides - 1) {
      setActiveDot(2); // Last slide
    } else {
      setActiveDot(1); // Middle slides
    }
  };

  return (
    <div className="packages-banner">
      <Swiper
        modules={[Navigation, Pagination, A11y, EffectFade, EffectCoverflow]}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000 }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        navigation
        onSlideChange={handleSlideChange}
        breakpoints={{
          320: { slidesPerView: 1 },
          480: { slidesPerView: 1 },
          768: { slidesPerView: 1 },
          1024: { slidesPerView: 1 },
        }}
      >
        {section1 && section1.length > 0 ? (
          section1.map((item, index) => (
            <SwiperSlide key={index} className="bg-transparent">
              <div className="banner-slide-content">
                <img
                  src={item.BannerImg}
                  alt={item.BannerImgAlt || item.BannerHeading}
                  className="banner-img"
                />
                <div className="container h-100 d-flex align-items-center">
                  <div className="row w-100">
                    <div className="col-md-6">
                      <div className="banner_content">
                        <h2 className="hd-style-2 animate__fadeInLeft">
                          {item.BannerHeading}
                        </h2>
                        <p
                          className="font-20 ff-semibold text-white animate__fadeInLeft"
                          dangerouslySetInnerHTML={{
                            __html: item.BannerSubText,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <p className="text-center">No data available</p>
        )}
        <SwiperNavButtons />
      </Swiper>
      <div className="custom-pagination">
        <span className={`dot ${activeDot === 0 ? "active" : ""}`}></span>
        <span className={`dot ${activeDot === 1 ? "active" : ""}`}></span>
        <span className={`dot ${activeDot === 2 ? "active" : ""}`}></span>
      </div>
    </div>
  );
};

export default PackagesBanner;
