import Select, { SingleValue } from "react-select";
import countryCodes from "../../datas/countrydata.json";
import { CountryOption, PhoneInputProps } from "../../types/home";

const countryOptions = countryCodes.map((country) => ({
  value: country.code,
  label: `${country.code} ${country.country}`,
}));

const PhoneInput = ({
  phoneNumber,
  countryCode,
  phoneInputRef,
  onPhoneNumberChange,
  onCountryCodeChange,
}: PhoneInputProps) => {
  const handleCountryChange = (
    selectedOption: SingleValue<CountryOption> | null
  ) => {
    if (selectedOption) {
      onCountryCodeChange(selectedOption.value);
    }
  };

  return (
    <div className="input-group mb-3">
      <Select
        className="input-group-text text-black-50 bg-white font-12 custom-select"
        options={countryOptions}
        classNamePrefix="custom-select"
        value={{
          value: countryCode,
          label: `${countryCode}`,
        }}
        onChange={handleCountryChange}
        isSearchable
        placeholder="Search country code"
      />
      <input
        ref={phoneInputRef}
        type="tel"
        inputMode="numeric"
        className="form-control py-3 font-12"
        placeholder="Enter mobile number"
        aria-label="Mobile Number"
        aria-describedby="basic-addon1"
        value={phoneNumber}
        onChange={(e) => {
          const input = e.target.value;
          if (/^\d*$/.test(input) && input.length <= 12) {
            onPhoneNumberChange(input);
          }
        }}
      />
    </div>
  );
};

export default PhoneInput;
