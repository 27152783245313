import { useState, useEffect } from "react";
import MetaHead from "../../components/metaHead/MetaHead";
import { SEOData } from "../../types/home";

const TermsAndConditions = () => {
  const [metaData, setMetaData] = useState<SEOData>();
  
  useEffect(() => {
    const seoMetaData = {
      "hidden":{
          "heading" : "",
          "description" : ""
      },
      "seo" : {
        "shortcut_icon": {
          "type": "image/x-icon",
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "apple_touch_icon": {
          "href": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
        },
        "title": "Goodhand Terms and Conditions",
        "Keywords": "",
        "description": "Goodhand Terms and Conditions",
        "canonical": "https://www.goodhand.ae/termsandconditions",
        "meta": [
          {
            "name": "description",
            "content": "Goodhand Terms and Conditions"
          },
          {
            "name": "url",
            "content": "https://www.goodhand.ae/termsandconditions"
          },
          {
            "name": "og:title",
            "content": "Goodhand Terms and Conditions"
          },
          {
            "name": "og:type",
            "content": "website"
          },
          {
            "name": "og:url",
            "content": "https://www.goodhand.ae/termsandconditions"
          },
          {
            "name": "og:image",
            "content": "https://goodhand.ideaintl.net/assets/logo-main-gFDCiCbr.svg"
          },
          {
            "name": "og:site_name",
            "content": "https://www.goodhand.ae"
          },
          {
            "name": "og:description",
            "content": "Goodhand Terms and Conditions"
          },
          {
            "name": "twitter:card",
            "content": "summary_large_image"
          },
          {
            "name": "twitter:title",
            "content": "Goodhand Terms and Conditions"
          },
          {
            "name": "twitter:description",
            "content": "Goodhand Terms and Conditions"
          },
          {
            "name": "twitter:image",
            "content": "https://goodhand.b-cdn.net/Assets/Good-Hand-02.png"
          },
          {
            "name": "twitter:site",
            "content": "@GoodHandUAE"
          }
        ],
        scripts: [],
        noscript: []
      }
    };
    if (seoMetaData) {
      setMetaData(seoMetaData?.seo);
    }
  }, []);

  return (
    <>
      {metaData && <MetaHead metaData={metaData} />}
      <section className="bg-2 py-5 terms-main border-bottom border-3">
        <div className="container py-5 px-3 px-md-5 bg-white rounded shadow-sm">
          <div
            className="terms-header p-3 text-white mb-3 rounded"
            style={{ background: "rgb(110, 66, 229)" }}
          >
            <div className="hd-style-2 text-center">
              <h2>
                Terms and <span className="text-white">Conditions</span>
              </h2>

            </div>
          </div>
          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">1. Acceptance of Terms</h5>
            <p>
              These Terms and Conditions (hereinafter referred to as <b>"Terms"</b>)
              govern the use of our website by registered users for online
              services (hereinafter referred to as <b>"Services"</b>). By registering
              yourself as a User on our website, you agree to be bound by these
              Terms.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">2. Overview</h5>
            <p>
              By using our website as a Registered User, you acknowledge that
              you have read, understood and agree to be bound by these Terms
              (including our Privacy Policy and Cancellation and Refund Policy)
              and to comply with all applicable Laws and Regulations. In the
              event that you do not accept these Terms, we kindly request you to
              refrain from using this website.
            </p>
            <p>
              Good Hand may, at any time, without notice to you, revise these
              Terms and any other information contained in this website by
              updating this posting. Good Hand reserves the right to make
              improvements or modifications to the services offered on this site
              at any time and without prior notice.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">3. General</h5>
            <p>
              Your failure to comply with the Terms and Conditions on this site
              will result in automatic termination of any rights granted to you
              without prior notice.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">4. Disclaimers</h5>
            <p>
              Information on this website may contain technical inaccuracies or
              typographical errors. Good Hand bears no responsibility (and
              expressly disclaims responsibility) for such technical
              inaccuracies or typographical errors. Good Hand makes no guarantee
              that this website will be uninterrupted.
            </p>
            <p>
              You acknowledge that downloading or obtaining information or
              services is a personal choice and you take this responsibility
              with an understanding of the potential risks involved. You will
              also be held responsible for any damages, including data loss or
              computer system issues, that you might encounter.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">5. Limitation of liability</h5>
            <p>
              In no event Good Hand will be liable to any party/individual for
              any direct, indirect, incidental, special, exemplary or
              consequential damages of any type whatsoever related to or arising
              from this website or any use of this website or of any site or
              resource linked to, reference, or access through this website, or
              for the use or downloading of any information, or services,
              including, without limitation, business interruption, lost savings
              or loss of data, even if Good Hand is expressly advised of the
              possibility of such damages. This exclusion and waiver of
              liability applies to all causes of action, whether based on
              contract, warranty, tort or any other legal theories.
            </p>
            <p>
              To the fullest extent permitted by law, Good Hand and its
              affiliates, officers, directors, employees and agents shall not be
              liable for any indirect, incidental, special, consequential,
              punitive damages or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data or other
              tangible/intangible losses.
            </p>
          </div>
          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">
              6. Documents and Services available on this website
            </h5>
            <p>
              In no event Good Hand shall be liable for any special, indirect or
              consequential damages or any damages whatsoever resulting from
              loss of data whether in an action of contract, negligence or other
              actions that involves torts, arising out of or in connection with
              the use of documents, provision of or failure to provide services
              or information available from the services.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">
              7. Member account, password, and security
            </h5>
            <p>
              If any of the services requires you to open an account, you must
              complete the registration process by providing us with current,
              complete and accurate information as prompted by the applicable
              registration form. You also will choose a password and a user
              name. You are entirely responsible for maintaining the
              confidentiality of your password and account. Furthermore, you are
              entirely responsible for any and all activities that occur under
              your account. You agree to notify Good Hand immediately of any
              unauthorized use of your account or any other breach of security.
              Good Hand will not be liable for any loss that you may incur as a
              result of someone else using your password or account, either with
              or without your knowledge. You may not use anyone else's account
              at any time, without the permission of the account holder.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">
              8. No unlawful or prohibited use
            </h5>
            <p>
              As a condition of your use of the Services, you will not use the
              Services for any purpose that is unlawful or prohibited by these
              Terms and Conditions. You may not use the Services in any manner
              that could damage, disable, overburden, or impair any Good Hand
              server or interfere with any other party's use and enjoyment of
              any services. You may not attempt to gain unauthorized access to
              any services, other accounts, computer systems or networks
              connected to any Good Hand server or to any of the services,
              through hacking, password mining or any other means.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">
              9. Data/Information provided to Good Hand or posted on the Good Hand
              website
            </h5>
            <p>
              Good Hand does not claim ownership of the data/ information you
              provide to Good Hand (including feedback and suggestions) or post,
              upload, input or submit. However, by posting, uploading, providing
              or submitting, you are granting Good Hand, its affiliated
              companies and necessary sub-licenses permission to use your data/
              information in connection with the operation of their businesses
              (including, without limitation, all Good Hand services).
            </p>
            <p>
              By posting a submission with data/ information you warrant and
              represent that you own or otherwise control all of the rights to
              your submission as described in these Terms including, without
              limitation, all the rights necessary for you to provide, post,
              upload, input or submit the data/ information.
            </p>
            <p>
              In addition to the representation set forth above, by posting a
              submission that contain images, photographs, pictures or that are
              otherwise graphical in whole or in part ("images"), you warrant
              and represent that you are the owner of such images, or that the
              owner of such images has granted you permission to use such images
              or any content and/or images contained in such images are
              consistent with the manner and purpose of your use and as
              otherwise permitted by these Terms.
            </p>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16">10. Prohibited Activities</h5>
            <p className="prohibited-intro">You agree not to:</p>
            <ul className="prohibited-list">
              <li>Use our Services for any illegal or unauthorized purpose.</li>
              <li>
                Engage in any activity that interferes with or disrupts our
                Services.
              </li>
              <li>
                Circumvent any security measures we use to protect our Services.
              </li>
              <li>
                Use our Services to transmit any harmful or malicious content.
              </li>
              <li>
                Violate any applicable Laws or Regulations in connection with
                your use of our Services.
              </li>
            </ul>
          </div>

          <div className="border-bottom pt-2">
            <h5 className="ff-semibold font-16 intellectual-title">
              11. Privacy Policy
            </h5>
            <p className="intellectual-content">
              Your use of our Services is also governed by our Privacy Policy,
              which is incorporated into these Terms by reference. Please refer
              our Privacy Policy to understand our practices regarding the
              collection and handling of your information.
            </p>
          </div>

          <div className="border-bottom pt-2 payment-refunds">
            <h5 className="ff-semibold font-16 payment-title">
              12. Payment and Refunds
            </h5>
            <ul className="payment-list gap-4 d-md-flex bg-2 p-3 ">
              <li className="payment-item">
                <h6 className="ff-semibold font-16 payment-subtitle">12.1 Fees</h6>
                <p className="payment-content">
                  Certain aspects of our Services may be subject to fees. You
                  agree to pay all applicable fees as described on our website
                  or through other communication channels.
                </p>
              </li>
              <li className="payment-item">
                <h6 className="ff-semibold font-16 payment-subtitle">
                  12.2 Payment Methods
                </h6>
                <p className="payment-content">
                  We accept various payment methods for your convenience. By
                  providing payment information, you represent and warrant that
                  you are authorized to use the designated payment method and
                  that you authorize us to charge your payment method for the
                  total amount.
                </p>
              </li>
              <li className="payment-item">
                <h6 className="ff-semibold font-16 payment-subtitle">
                  12.3 Cancellation and Refund Policy
                </h6>
                <p className="payment-content">
                  Refund requests are subject to our Cancellation & Refund
                  Policy, which is available on our website. We reserve the
                  right to refuse any refund request that does not comply with
                  our Policy.
                </p>
              </li>
            </ul>
          </div>

          <div className="border-bottom pt-2 limitation-liability">
            <h5 className="ff-semibold font-16 liability-title">
              13. Indemnification
            </h5>
            <p className="liability-content">
              You agree to indemnify, defend and not to hold Good Hand, its
              affiliates, officers, directors, employees and agents liable
              against any or all claims, liabilities, damages, losses, costs,
              expenses or fees that arise from your use of our Services or your
              violation of these Terms.
            </p>
          </div>

          <div className="border-bottom pt-2 termination">
            <h5 className="ff-semibold font-16 termination-title">
              14. Termination
            </h5>
            <p className="termination-content">
              We may terminate or suspend your access to our Services, without
              prior notice or liability, without assigning any reason, in the
              event it is revealed that the information or documents submitted
              are either misrepresented or forged. Upon termination, your right
              to use our Services will immediately cease.
            </p>
          </div>

          <div className="border-bottom pt-2 governing-law">
            <h5 className="ff-semibold font-16 governing-law-title">
              15. Governing Law
            </h5>
            <p className="governing-law-content">
              These Terms shall be governed and construed in accordance with the
              Laws of the United Arab Emirates.
            </p>
          </div>
          <div className="border-bottom pt-2 changes-to-terms">
            <h5 className="ff-semibold font-16 changes-title">
              16. Changes to Terms
            </h5>
            <p className="changes-content">
              We reserve the right to modify or replace these Terms in the
              future. The New Terms will be posted on our website. Your
              continued and previous use of our Services after any such change/s
              would amount to your acceptance of the New Terms.
            </p>
          </div>

          <div className="border-bottom pt-4 pb-4 contact-us">
            <h5 className="ff-semibold font-16 contact-title">17. Contact Us</h5>
            <p className="contact-content mb-3">
              If you have any questions about these Terms, please contact us at:
            </p>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-2 contact-item">
                <i className="fas fa-phone me-2 contact-icon"></i>
                <span className="contact-info">+971 42 97 9997</span>
              </div>
              <div className="d-flex align-items-center contact-item mb-2">
                <i className="fab fa-whatsapp me-2 contact-icon"></i>
                <span className="contact-info">+971 42 97 9998</span>
              </div>
              <div className="d-flex align-items-center  contact-item">
                <i className="fas fa-envelope me-2 contact-icon"></i>
                <span className="contact-info">
                  <a href="mailto:info@goodhand.ae">info@goodhand.ae</a>
                </span>
              </div>
            </div>
          </div>
          <p>Last updated: 18th of November 2024 </p>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
