import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeNewLogin, openVerifyOtp } from "../../store/slices/modalSlice";
// import countryCodes from "../../datas/countrydata.json";
// import Select, { SingleValue } from "react-select";
// import '../../css/login.css'
import axios from "axios";
import PhoneInput from "./PhoneInput";

const baseUrl = import.meta.env.VITE_API_URL;

const LoginModel = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [countryCode, setCountryCode] = useState("+971");

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [errors, setErrors] = useState<{
    phoneNumber?: string;
    confirmation?: string;
  }>({});

  const showLoginModel = useSelector(
    (state: { modal: { login: boolean } }) => state.modal.login
  );

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }

    const num = localStorage.getItem("phoneNumber");
    if (num) {
      const number = JSON.parse(num);
      const lastSevenDigits = number.slice(-9); // Gets the last 7 characters
      console.log("number", lastSevenDigits);
      setPhoneNumber(number[0].phoneNumber);
    }
  }, []);

  const closeAddAddress = () => {
    localStorage.removeItem("phoneNumber");
    dispatch(closeNewLogin());
  };

  const openVeriftyModel = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${baseUrl}/api/openRoute/loginWithNumber`,
          {
            userNumber: phoneNumber,
            UserCountryCode: countryCode,
          }
        );

        if (response.status === 200) {
          localStorage.setItem(
            "phoneNumber",
            JSON.stringify([{ countryCode, phoneNumber }])
          );
          dispatch(closeNewLogin());
          dispatch(openVerifyOtp());
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors: {
      // phoneNumber?: string;
      confirmation?: string;
    } = {};
    // const phoneRegex = /^[0-9]{9}$/; // Adjust based on phone number format

    if (!isConfirmed) {
      newErrors.confirmation =
        "Please agree to the processing of your data to proceed";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleCountryChange = (
  //   selectedOption: SingleValue<CountryOption> | null
  // ) => {
  //   if (selectedOption) {
  //     setCountryCode(selectedOption.value);
  //   }
  // };

  // const countryOptions = countryCodes.map((country) => ({
  //   value: country.code,
  //   label: `${country.code} ${country.country}`, // Display both country code and name
  // }));

  return (
    <>
      <Modal
        centered
        show={showLoginModel}
        onHide={closeAddAddress}
        className="mb-fix slide-from-bottom"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <p className="font-18 ff-semibold text-black">Login/Sign up</p>
          {/* <div className="input-group mb-3">
            <Select
              className="input-group-text text-black-50 bg-white font-12 custom-select "
              options={countryOptions}
              classNamePrefix="custom-select"
              value={{
                value: countryCode,
                label: `${countryCode} `,
              }}
              onChange={handleCountryChange}
              isSearchable={true}
              placeholder="Search country code"
            />
            <input
              ref={phoneInputRef}
              type="tel"
              inputMode="numeric"
              className="form-control py-3 font-12 "
              placeholder="Enter mobile number"
              aria-label="Mobile Number"
              aria-describedby="basic-addon1"
              value={phoneNumber}
              onChange={(e) => {
                const input = e.target.value;
                // Only set phoneNumber if the input consists of digits only
                if (/^\d*$/.test(input) && input.length <= 12) {
                  setPhoneNumber(input);
                }
              }}
            />
          </div> */}
          <div className="inut-group  mb-3">
          <PhoneInput
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            phoneInputRef={phoneInputRef}
            onPhoneNumberChange={setPhoneNumber}
            onCountryCodeChange={setCountryCode}
          />
          </div>
          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="confirmation"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
              <label
                className="form-check-label font-12"
                htmlFor="confirmation"
              >
                I consent to the processing of my personal information
              </label>
              {errors.confirmation && (
                <p className="text-danger">{errors.confirmation}</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary w-100 ff-semibold"
            onClick={openVeriftyModel}
            disabled={phoneNumber.length < 9 || !isConfirmed}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginModel;
