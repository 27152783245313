// import React from "react";

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../components/toast/Api";

import PdfIco from "../../assets/pdf-ico.png";
import BackButton from "../../components/backButton/Backbutton";
const baseUrl = import.meta.env.VITE_API_URL;

interface Order {
  RecordId: number; // Array of numbers, can have null
  orderNumber: string; // Array of strings, can have null
  applicationNumber: string; // Array of strings, can have null
  serviceId: number; // Service ID is a number
  serviceName: string; // Service Name is a string
  servicePriceId: number; // Service Price ID is a number
  serviceType: string; // Service Type is a string
  serviceAmount: number; // Service Amount is a number
  userName: string; // Array of strings, can have null
  Status: string; // Service Status is a number (could be an enum in some cases)
  createdOn: string; // Created On is a string (ISO date format)
  modifiedOn: string | null; // Modified On can be a string (ISO date format) or null
  ApplicantName: string | null; // Applicant Name can be null
  ApplicantPassport: string | null; // Applicant Passport can be null
  SponsorName: string | null; // Sponsor Name can be null
  SponsorPassport: string | null; // Sponsor Passport can be null
  DocumentsUploadPaths: string | null; // Documents Upload Paths can be null
  createdon: string | null; // createdon (lowercase) can be a string or null
  modifiedon: string | null; // modifiedon (lowercase) can be a string or null
}

interface FileData {
  url: string;
  type: "image" | "pdf" | "png";
}

interface UploadedFile {
  id: number;
  file: File;
  previewUrl?: string | null;
}

// interface GroupedFiles {
//   id: number;
//   files: File[];
// }

interface OrderStatus {
  RecordId: number;
  userName: string;
  orderNumber: string | null;
  applicationNumber: string;
  Createdby: string;
  Createdon: string;
  Modifiedby: string | null;
  Modifiedon: string | null;
  serviceStatus: number;
  comment: string;
  Status: string;
  classname: string;
}

const OrderDetail = () => {
  const { showInfo, showSuccess } = useData();
  const { slug } = useParams();
  const [orderData, setOrderData] = useState<Order>();
  const [applicationTrackingData, setApplicationTrackingData] = useState<
    OrderStatus[] | undefined
  >();
  const [applicantName, setApplicantName] = useState<string>();
  const [applicantPassportNumber, setApplicantPassportNumber] =
    useState<string>();
  const [sponsorName, setSponsorName] = useState<string>();
  const [sponsorPassportNumber, setSponsorPassportNumber] = useState<string>();
  const [files, setFiles] = useState<FileData[]>(); // To store object URLs for images and PDFs
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [applicationNumber, setApplicationNumber] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [recordId, setRecordID] = useState(0);

  const [fileCount, setFileCount] = useState<{ [key: number]: number }>({});
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const [errors, setErrors] = useState<{
    applicantName?: string;
    applicantPassportNumber?: string;
    sponsorName?: string;
    sponsorPassportNumber?: string;
    files?: string;
  }>({});

  const validateForm = () => {
    const newErrors: {
      applicantName?: string;
      applicantPassportNumber?: string;
      sponsorName?: string;
      sponsorPassportNumber?: string;
      files?: string;
    } = {};

    // Regex for applicantName: Only allows letters and spaces
    const nameRegex = /^[A-Za-z\s]+$/;

    // Regex for applicantPassportNumber: Allows numbers and alphanumeric characters
    const passportNumberRegex = /^[A-Za-z0-9]+$/;

    // Check if applicantName is provided and matches regex
    if (!applicantName || applicantName.trim() === "") {
      newErrors.applicantName = "Applicant name is required";
    } else if (!nameRegex.test(applicantName)) {
      newErrors.applicantName =
        "Applicant name must contain only letters and spaces";
    }

    // Check if applicantPassportNumber is provided and matches regex
    if (!applicantPassportNumber || applicantPassportNumber.trim() === "") {
      newErrors.applicantPassportNumber =
        "Applicant passport number is required";
    } else if (!passportNumberRegex.test(applicantPassportNumber)) {
      newErrors.applicantPassportNumber =
        "Applicant passport number must be alphanumeric";
    }

    // Check if sponsorName is provided
    if (!sponsorName || sponsorName.trim() === "") {
      newErrors.sponsorName = "Sponsor name is required";
    } else if (!nameRegex.test(sponsorName)) {
      newErrors.sponsorName =
        "Applicant name must contain only letters and spaces";
    }

    // Check if sponsorPassportNumber is provided and matches regex
    if (!sponsorPassportNumber || sponsorPassportNumber.trim() === "") {
      newErrors.sponsorPassportNumber = "Sponsor passport number is required";
    } else if (!passportNumberRegex.test(sponsorPassportNumber)) {
      newErrors.sponsorPassportNumber =
        "Sponsor passport number must be alphanumeric";
    }

    const filteredNames = uploadedFiles?.filter((item) => item.id === recordId);

    // // Check if at least one file is uploaded
    if (!filteredNames) {
      newErrors.files = "At least one file must be uploaded";
    }

    // Set errors and return validation result
    setErrors(newErrors);

    if (filteredNames?.length === 0) {
      return false;
    }

    return Object.keys(newErrors).length === 0;
  };

  const getApplicationTrackingData = async (
    applicationNumber: string,
    token: string | null
  ) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/user/applicationTracking?applicationNumber=${applicationNumber}`,
        // `${baseUrl}/api/user/applicationTracking?applicationNumber=40982`,
        {
          // params: { applicationNumber: applicationNumber },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      setApplicationTrackingData(data.Data.orders);
    } catch (error) {
      console.error(
        "Error fetching order data: getApplicationTrackingData",
        error
      );
    }
  };

  const getImagesData = async (
    scrShot: string[] | string, // Update the type to allow both array and string
    orderNumber: string,
    applicationNumber: string,
    token: string | null
  ) => {
    try {
      // Initialize arrays to hold file data and file names
      const fileBlobs: Blob[] = [];
      const extractedFileNames: string[] = [];

      // Check if scrShot is an array, if not, convert it to an array for uniform handling
      const scrShotArray = Array.isArray(scrShot) ? scrShot : [scrShot];

      // Loop over each screenshot in the array
      for (const screenshot of scrShotArray) {
        const response = await axios.get(
          `${baseUrl}/api/user/download/${orderNumber}/${applicationNumber}/${screenshot}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add any additional headers if needed
            },
            responseType: "blob", // Expect a binary file (image/pdf)
          }
        );

        // Extract filenames from content-disposition headers, if available
        const contentDisposition = response.headers["content-disposition"];
        let fileName = "downloaded_file"; // Default filename if not provided in headers
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (fileNameMatch) {
            fileName = fileNameMatch[1];
          }
        }

        // Add the file blob and name to the arrays
        fileBlobs.push(response.data);
        extractedFileNames.push(fileName);
      }

      // Create object URLs for each file and store them in state
      const objectUrls = fileBlobs.map((blob) => {
        const fileType = blob.type;
        return {
          url: URL.createObjectURL(blob),
          type: fileType.includes("image")
            ? "image"
            : fileType.includes("pdf")
            ? "pdf"
            : "png", // Default to "png" if type is unknown
        } as FileData;
      });

      // Set the file URLs and filenames
      setFiles(objectUrls);
      setFileNames(extractedFileNames);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const fetchOrderData = async (token: string | null, slug: string) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/user/ApplicationDetails?Recordid=${slug}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // Add any additional headers here, like authorization if needed
          },
        }
      ); // Replace with your API endpoint
      const data = await response.json();
      getApplicationTrackingData(data.Data.orders.applicationNumber, token);
      getImagesData(
        data.Data.orders.DocumentsUploadPaths,
        data.Data.orders.orderNumber,
        data.Data.orders.applicationNumber,
        token
      );
      setOrderData(data.Data.orders);
      setApplicantName(data.Data.orders.ApplicantName);
      setApplicantPassportNumber(data.Data.orders.ApplicantPassport);
      setSponsorName(data.Data.orders.SponsorName);
      setSponsorPassportNumber(data.Data.orders.SponsorPassport);
      setApplicationNumber(data.Data.orders.applicationNumber);
      setOrderNumber(data.Data.orders.orderNumber);
      setRecordID(data.Data.orders.RecordId);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    import("./OrderDetails.css");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");

    if (login_info) {
      // const login_infoo = JSON.parse(login_info);

      if (slug) {
        fetchOrderData(token, slug);
      }
    }
  }, []);

  const handleFiles = (files: FileList, productId: number) => {
    const validFiles: UploadedFile[] = [];

    Array.from(files).forEach((file) => {
      if (
        file.type === "application/pdf" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        const fileObject: UploadedFile = {
          id: productId,
          file: file,
          previewUrl: file.type.startsWith("image/")
            ? URL.createObjectURL(file)
            : null, // Generate preview URL if image
        };
        validFiles.push(fileObject);
        setFileCount((prev) => ({
          ...prev,
          [productId]: (prev[productId] || 0) + 1,
        }));
      } else {
        setErrors({
          files: `${file.name} is not a valid file type. Only .pdf, .png, and .jpg files are allowed.`,
        });
      }
    });

    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDeleteFile = (fileToDelete: UploadedFile) => {
    // Remove the file from the uploaded files list
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.file !== fileToDelete.file)
    );

    // Decrement the file count for the specific recordId (productId)
    setFileCount((prev) => ({
      ...prev,
      [fileToDelete.id]: Math.max((prev[fileToDelete.id] || 1) - 1, 0), // Ensure the count doesn't go below 0
    }));
  };

  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = ""; // You can specify a file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const token = sessionStorage.getItem("tk");
      const filteredNames = uploadedFiles.filter(
        (item) => item.id === recordId
      );

      const formData = new FormData();
      formData.append("orderNumber", orderNumber || "");
      formData.append("applicantName", applicantName || "");
      formData.append("applicantPassport", applicantPassportNumber || "");
      formData.append("sponsorName", sponsorName || "");
      formData.append("sponsorPassport", sponsorPassportNumber || "");
      formData.append("applicationNumber", applicationNumber || "");

      if (filteredNames.length > 0 && filteredNames) {
        filteredNames.forEach((item) => {
          formData.append("files", item.file); // Append each file
        });
      }

      const login_info = localStorage.getItem("Login_info");

      if (login_info) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await fetch(`${baseUrl}/api/user/applicationFill`, {
            method: "POST",
            headers: {
              //   "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: formData, // Use FormData object as the body
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }

          const responseData = await response.json();
          if (responseData.message) {
            showSuccess(responseData.message);
            setUploadedFiles([]);
            setFileCount({});
            if (slug) fetchOrderData(token, slug);
          }
        } catch (error) {
          console.error("Response ::: Error sending form data:", error);
        }
      }
    } else {
      showInfo("fill all fields");
    }
  };

  return (
    <>
      <BackButton />
      <div className="container my-2 my-md-5">
        <div className="row flex-column-reverse flex-sm-row">
          {/* Form Section */}
          <div className="col-md-8">
            <form className="form-main bg-2 p-4 border-radius-10">
              <div className="row ">
                <div className="sub-hd1 col-12 text-decoration-underline">
                  <p className="font-21">Applicant’s Details</p>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Applicant Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailPhone"
                      placeholder="Applicant name"
                      value={applicantName}
                      onChange={(e) => setApplicantName(e.target.value)}
                    />
                    {errors.applicantName && (
                      <p className="text-danger">{errors.applicantName}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="emailPhone" className="form-label">
                      Applicant Passport Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailPhone"
                      placeholder="Applicant Passport No"
                      value={applicantPassportNumber}
                      onChange={(e) =>
                        setApplicantPassportNumber(e.target.value)
                      }
                    />
                    {errors.applicantPassportNumber && (
                      <p className="text-danger">
                        {errors.applicantPassportNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sub-hd1 col-12 text-decoration-underline">
                  <p>Sponsor's Details</p>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="emailPhone" className="form-label">
                      Sponsor Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailPhone"
                      placeholder="Sponsor name"
                      value={sponsorName}
                      onChange={(e) => setSponsorName(e.target.value)}
                    />
                    {errors.sponsorName && (
                      <p className="text-danger">{errors.sponsorName}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="emailPhone" className="form-label">
                      Sponsor Passport Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailPhone"
                      placeholder="Sponsor's Passport No"
                      value={sponsorPassportNumber}
                      onChange={(e) => setSponsorPassportNumber(e.target.value)}
                    />
                    {errors.sponsorPassportNumber && (
                      <p className="text-danger">
                        {errors.sponsorPassportNumber}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="uploaded-documents my-5">
              <div className="hd-style-3 py-2">Uploaded Documents</div>
              <div className="row ">
                {files?.map((file, index) => (
                  <div className="col-md-3 col-6" key={index}>
                    <div className="card mb-3 document-card">
                      {file.type === "image" ? (
                        <img
                          src={file.url}
                          className="card-img-top"
                          alt={`Uploaded Document ${index + 1}`}
                          width={150}
                          height={120}
                        />
                      ) : file.type === "pdf" ? (
                        <iframe
                          src={file.url}
                          className="card-img-top"
                          title={`PDF Document ${index + 1}`}
                          width="100%"
                          height="120px"
                          style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        />
                      ) : (
                        <a href={file.url} download={fileNames[index]}>
                          Download {fileNames[index]}
                        </a>
                      )}
                      <div className="document-actions">
                        <button
                          className="btn btn-link p-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="View"
                          onClick={() => window.open(file.url, "_blank")}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        <button
                          className="btn btn-link p-0 text-danger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Download"
                          onClick={() => handleDownload(file.url)}
                        >
                          <i className="fa fa-download"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-main bg-2 p-4 border-radius-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="upload-button-container">
                    <label htmlFor="emailPhone" className="form-label">
                      Please upload all the new documents.
                    </label>
                    <div
                      id="drop-zone"
                      className="drop-zone"
                      onClick={() =>
                        document
                          .getElementById(`fileInput-${recordId}`)
                          ?.click()
                      }
                      onDrop={(e) => {
                        e.preventDefault();
                        handleFiles(e.dataTransfer.files, recordId);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      style={{
                        border: "2px dashed #385986",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ textAlign: "justify" }}
                      >
                        <svg
                          width="34"
                          height="33"
                          viewBox="0 0 34 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            d="M15.625 22V10.7938L12.05 14.3688L10.125 12.375L17 5.5L23.875 12.375L21.95 14.3688L18.375 10.7938V22H15.625ZM8.75 27.5C7.99375 27.5 7.34635 27.2307 6.80781 26.6922C6.26927 26.1536 6 25.5063 6 24.75V20.625H8.75V24.75H25.25V20.625H28V24.75C28 25.5063 27.7307 26.1536 27.1922 26.6922C26.6536 27.2307 26.0063 27.5 25.25 27.5H8.75Z"
                            fill="#385986"
                          />
                        </svg>
                        <p className="mx-2">
                          Drag and drop files here or click to upload
                        </p>
                      </div>
                      <input
                        id={`fileInput-${recordId}`}
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.files) {
                            handleFiles(e.target.files, recordId);
                          }
                        }}
                      />
                    </div>

                    {fileCount[recordId] && (
                      <div id="upload-status">
                        <span className="ff-semibold font-14">
                          {fileCount[recordId]} File(s) Uploaded
                        </span>
                      </div>
                    )}

                    {errors.files && (
                      <p className="text-danger">{errors.files}</p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="file-preview-container mt-4">
                    {uploadedFiles?.map((file, index) => (
                      <div key={index} className="file-preview">
                        <button
                          className="close-button"
                          onClick={() => handleDeleteFile(file)}
                        >
                          &times; {/* Close icon */}
                        </button>
                        {file.file.type === "application/pdf" ? (
                          <>
                            <div className="file-icon font-25 text-primary">
                              <img
                                src={PdfIco}
                                className="img-fluid"
                                alt="PDF Icon"
                              />
                            </div>
                            <span className="file-name">{file.file.name}</span>
                          </>
                        ) : (
                          <div className="image-preview">
                            {file?.previewUrl && (
                              <img
                                src={file?.previewUrl}
                                alt={file?.file.name}
                                className="img-fluid"
                              />
                            )}
                            <p className="file-name">{file.file.name}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-block text-center  ">
                  <button
                    className="btn btn-primary w-25 flow-btn"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightSignUP"
                    aria-controls="offcanvasRight"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="order-details  p-3 rounded">
              <h5 className="order-heading">Order Information</h5>
              <p className="order-info">
                <strong>Order Number:</strong> {orderData?.orderNumber}
              </p>
              <p className="order-info">
                <strong>Application Number:</strong>
                {orderData?.applicationNumber}
              </p>
              <p className="order-info">
                <strong>Application Status:</strong> {orderData?.Status}
              </p>
              <p className="order-info">
                <strong>Service Name:</strong> {orderData?.serviceName}
              </p>
              <p className="order-info">
                <strong>Service Amount:</strong> {orderData?.serviceAmount}
              </p>
              <p className="order-info">
                <strong>Service Type:</strong> {orderData?.serviceType}
              </p>
            </div>

            {applicationTrackingData && applicationTrackingData.length > 0 && (
              <div className="col-md-12 order-details  p-3 rounded">
                <div className="hd-style-3 py-2">
                  Application No:{" "}
                  {applicationTrackingData?.[0]?.applicationNumber}
                </div>
                <div className="serv-link-text py-2 link-text">
                  Track your application status
                </div>

                <div className="tracking-timeline">
                  {applicationTrackingData?.map((item, index) => (
                    <div className="tracking-item" key={index}>
                      <div className="tracking-icon">
                        <i className={item.classname}></i>
                      </div>
                      <div>
                        <div className="tracking-date-time">
                          {/* {item.Createdon} */}
                          {new Date(item.Createdon).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true, // Set to false for 24-hour format
                          })}
                        </div>
                        <div className="tracking-location">{item.Status}</div>
                        <p className="tracking-status">{item.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
