import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeGetQuoteModel } from "../../store/slices/modalSlice";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import countryCodes from "../../datas/countrydata.json";
import ReCAPTCHA from "react-google-recaptcha";
import { CountryOption, LegalCategoryList } from "../../types/home";

const baseUrl = import.meta.env.VITE_API_URL;

const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const GetQuoteModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("+971");
  // const phoneInputRef = useRef<HTMLInputElement>(null);
  const [LegalCategoryList, setLegalCategoryList] =
    useState<LegalCategoryList[]>();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // const [captcha, setCaptcha] = useState()

  const countryOptions = countryCodes.map((country) => ({
    value: country.code,
    label: `${country.code} ${country.country}`,
  }));

  const handleCountryChange = (
    selectedOption: SingleValue<CountryOption> | null
  ) => {
    if (selectedOption) {
      setCountryCode(selectedOption.value);
    }
  };

  const [errors, setErrors] = useState<{
    selectedCategory?: string;
    email?: string;
    phoneNumber?: string;
    confirmation?: string;
    selectedFile?: string;
  }>({});

  const showGetQuoteModel = useSelector(
    (state: { modal: { getQuoteModel: boolean } }) => state.modal.getQuoteModel
  );

  useEffect(() => {
    // if (phoneInputRef.current) {
    //   phoneInputRef.current.focus();
    // }
    getDataFromApi();
  }, []);

  const closeGetQuoteModal = () => {
    dispatch(closeGetQuoteModel());
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/openRoute/LegalCategoryList`
      );
      if (response.data) {
        setLegalCategoryList(response.data.Data);
        return true; // Indicate success
      }
      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching API data:", error);
      return false; // Indicate failure
    }
  };

  // // const /api/openRoute/LegalServiceList/?id=L-POA
  // const LegalServiceListApiCall = () => {

  // }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // console.log("event.target.value",event.target.value)
    setSelectedCategory(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const file = event.target.files[0]; // Get the first file from the input
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file); // Update the state with the new file
    }
  };

  const validateForm = () => {
    const newErrors: {
      selectedCategory?: string;
      email?: string;
      phoneNumber?: string;
      selectedFile?: string;
      // confirmPassword?: string;
      confirmation?: string;
    } = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(?:\d{9}|\d{12})$/; // Adjust based on phone number format

    if (!selectedCategory) {
      newErrors.selectedCategory = "Select a Catergory";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Email is invalid";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone Number is invalid";
    }
    if (!selectedFile) {
      newErrors.selectedFile = "Select a File";
    }

    if (!isConfirmed) {
      newErrors.confirmation = "checked the captcha";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const number = countryCode + phoneNumber;
    if (validateForm()) {
      // Create a new FormData object
      const formData = new FormData();

      // Append data to FormData
      formData.append("serviceName", selectedCategory);
      formData.append("email", email);
      formData.append("phoneNumber", number);
      if (selectedFile) {
        formData.append("files", selectedFile); // Key "file" can be adjusted based on the API's requirements
      }

      try {
        // Send the FormData to the server
        const response = await axios.post(
          `${baseUrl}/api/tmp/getQuote`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data) {
          navigate("/thank-you");
        }
      } catch (error) {
        // Handle errors
        console.error("Error submitting data:", error);
        alert("Failed to submit data. Please try again.");
      }
    }
  };

  // const handleVerify = async (token: string | null) => {
  //   const secret = '6Lei7K0qAAAAABLCleSHt-WVyijjSroD6FZKOLeO'
  //   // console.log("token")
  //   const response = await axios.post(
  //     `https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`,
  //   );

  //   // const response = await fetch(`https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`,
  //   //   {
  //   //     method: "POST",
  //   //   }
  //   // )

  //   // // return response.data.success;
  //   // const data = await response.json()
  //   // if(data){
  //   //   console.log(data)
  //   // }
  //   if(response){
  //     console.log("response.data.success",response.data.success)
  //     alert("yes")
  //     setIsConfirmed(true);
  //   }else{
  //     alert("noe")
  //     console.log("response.data.success",response)
  //   }
  // };

  const handleVerify = async (token: string | null) => {
    const secret = "6Ldw_q0qAAAAAHmlH3ji2GmhOdmb9M0FJMwzA8-K";
    setIsConfirmed(true);
    try {
      const response = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`
      );

      if (response.data.success) {
        console.log("Verification Successful:", response.data);
      } else {
        console.error("Verification Failed:", response.data);
      }
    } catch (error) {
      console.error("Error during reCAPTCHA verification:", error);
    }
  };

  return (
    <>
      <Modal
        centered
        show={showGetQuoteModel}
        onHide={closeGetQuoteModal}
        className="mb-fix slide-from-bottom modal-xl shadow-none lega-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 rounded">
          <div className="container">
            <div className="row">
              <div className="col-md-5 p-0 d-none d-md-block">
                <img
                  src="https://goodhand.b-cdn.net/Assets/Footer_pages/tasheel/steps-01.png"
                  alt=""
                  className="img-fluid legal-modal-form d-none d-md-block"
                />
              </div>
              <div className="col-md-7 my-auto px-3 px-md-5 py-4 py-md-0">
                <p className="font-25 text-uppercase ff-bold text-black">
                  Are You Looking For Legal Translation in Dubai ?{" "}
                </p>
                <p className="mb-0 font-14">
                  <span className="ff-semibold">Good hand</span> Is Here To
                  Solve Your Problem. Get A FREE Quotation! Upload Document and
                  get the latest Price
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="d-block py-2">
                          <label
                            htmlFor="phone-number"
                            className="ff-normal font-13"
                          >
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <div
                            className="d-flex align-items-center border rounded px-2"
                            style={{
                              height: "50px",
                              backgroundColor: "#fff",
                            }}
                          >
                            {/* Select Dropdown for Country Code */}
                            <div style={{ flex: "0 0 32%", maxWidth: "40%" }}>
                              <Select
                                className="font-12 ff-semibold"
                                options={countryOptions}
                                classNamePrefix="custom-select"
                                value={{
                                  value: countryCode,
                                  label: countryCode,
                                }}
                                onChange={handleCountryChange}
                                isSearchable={true}
                                placeholder="Code"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none",
                                    boxShadow: "none",
                                    minHeight: "48px",
                                  }),
                                  container: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0 8px",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "4px",
                                  }),
                                }}
                              />
                            </div>

                            {/* Phone Number Input */}
                            <div style={{ flex: "1 1 70%" }}>
                              <input
                                type="tel"
                                inputMode="numeric"
                                className="border-0 w-100 font-12 ff-semibold ps-2"
                                placeholder="Enter mobile number"
                                aria-label="Mobile Number"
                                aria-describedby="basic-addon1"
                                value={phoneNumber}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Allow only numeric values up to 12 digits
                                  if (
                                    /^\d*$/.test(input) &&
                                    input.length <= 12
                                  ) {
                                    setPhoneNumber(input);
                                  }
                                }}
                                style={{
                                  outline: "none",
                                  height: "100%",
                                  background: "transparent",
                                }}
                              />
                            </div>
                          </div>
                          {errors.phoneNumber && (
                            <div className="error-text w-100 font-10 text-danger text-end">
                              {errors.phoneNumber}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-block py-2">
                          <label htmlFor="email" className="ff-normal font-13">
                            E Mail <span className="text-danger">*</span>
                          </label>
                          <input
                            style={{
                              height: "50px",
                            }}
                            id="email"
                            type="email"
                            className="form-control font-12 ff-semibold"
                            placeholder="email@gmail.com"
                            name={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          {errors.email && (
                            <div className="error-text w-100 font-10 text-danger text-end">
                              {errors.email}
                            </div>
                          )}
                          {/* <p className="text-danger"></p> */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-block py-2">
                          <span className="ff-normal font-13">
                            Select services{" "}
                            <span className="text-danger">*</span>
                          </span>
                          <select
                            style={{
                              height: "50px",
                            }}
                            className="form-control font-12 ff-semibold d-block custom-select bg-white"
                            value={selectedCategory}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select a Category
                            </option>
                            {LegalCategoryList?.map((service) => (
                              <option
                                key={service.sub2ServiceName}
                                value={service.sub2ServiceName}
                              >
                                {service.sub2ServiceName}
                              </option>
                            ))}
                          </select>
                          {errors.selectedCategory && (
                            <div className="error-text w-100 font-10 text-danger text-end">
                              {errors.selectedCategory}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-block py-2">
                          <label
                            htmlFor="upload-emirates-id"
                            className="ff-normal font-13"
                          >
                            Upload Document{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <label
                            htmlFor="upload-emirates-id"
                            style={{
                              height: "50px",
                            }}
                            className="btn btn-link d-flex align-items-center text-decoration-none font-12 ff-semibold justify-content-between gap-2 w-100 bg-white border"
                          >
                            {selectedFile ? selectedFile.name : "Upload"}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#572ac8"
                              viewBox="0 0 24 24"
                            ></svg>
                            <input
                              id="upload-emirates-id"
                              type="file"
                              className="d-none"
                              accept=".jpg,.jpeg,.png,.pdf"
                              onChange={handleFileChange}
                            />
                          </label>
                          {errors.selectedFile && (
                            <div className="error-text w-100 font-10 text-danger text-end">
                              {errors.selectedFile}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="recaptcha-main">
                        {/* <div className="d-block py-2 d-flex align-items-center gap-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="confirmation"
                            checked={isConfirmed}
                            onChange={(e) => setIsConfirmed(e.target.checked)}
                          />
                          <label
                            htmlFor="confirmation"
                            className="ff-normal font-13"
                          >
                            I'm not Robot
                          </label>
                        </div> */}
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={handleVerify}
                          size="normal"
                        />
                        {errors.confirmation && (
                          <div className="error-text w-100 font-10 text-danger">
                            {errors.confirmation}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    // onClick={(e) => handleSubmitData(e)}
                  >
                    Get Quote
                  </button>
                  {/* <LegalForm /> */}
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetQuoteModel;
