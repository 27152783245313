import { useEffect, useState } from "react";
import $ from 'jquery';
import logo from "../../assets/logo-main.svg";
import "./preLoader.css"

const PreLoader = () => {
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 3000) {
          clearInterval(progressInterval);
          $("#preloader").fadeOut("fast", function () {
            $("#root").fadeIn("fast");
          });
          return 3000;
        }
        return prev + 10;
      });
    }, );
  }, []);
  return (
    <>
      <div id="preloader" className="preloader">
        <div>
          <img id="logo" className="logo" src={logo} alt="Logo" />
          <div id="progress-bar" className="progress-bar">
            <div
              id="progress-bar-fill"
              className="progress-bar-fill"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreLoader;
