import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import BackButton from "../../components/backButton/Backbutton";
import PreLoader from "../../components/preloader/PreLoader";
import BottomLeftSidebar from "../../components/bottomLeftSidebar/BottomLeftSidebar";
import SideCard from "../../components/thirdsideCard/SideCard";
import axios from "axios";

interface OrderData {
  RecordId: number;
  orderNumber: string;
  serivceid: number;
  serviceName: string;
  serviceType: string;
  serviceAmount: number;
  createdon: string;
  modifiedon: string;
  userName: string;
  Status: string;
  applicationNumber: string;
  ApplicantName: string;
  ApplicantPassport: string;
}

interface Orders {
  RecordId: number;
  orderNumber: string;
  NoofApplications: number;
  paymentMode: string | null;
  createdon: string;
  orderStatus: string;
  ordertotal: number;
}

const baseUrl = import.meta.env.VITE_API_URL;

const OrderList = () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [count, setCount] = useState<number>();
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState<Orders[]>();

  const fetchOrderData = async (
    token: string | null,
    login_infoo_userName: string
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);

      const postData = {
        userName: login_infoo_userName,
        orderNumber: slug,
      };

      const response = await axios.post(
        `${baseUrl}/api/user/orderListingShow`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      setOrderData(data.mainOrdersList.orders);
      setCount(data.mainOrdersList.count);
      setOrder(data.mainOrdersList.order);

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    import("./orderlist.css")
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");

    if (login_info) {
      const login_infoo = JSON.parse(login_info);

      if (slug) {
        fetchOrderData(token, login_infoo.userName);
      }
    }
  }, []);

  const fetchData = async () => {
    if (searchTerm.trim() !== "") {
      const token = sessionStorage.getItem("tk");
      if (token) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.get(
            `${baseUrl}/api/user/searchOrderListing`,
            {
              params: {
                orderNumber: slug,
                applicationNumber: searchTerm,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const result = response.data;
          setOrderData(result.order);
        } catch (error) {
          console.log("handleSearch error :: ", error);
        }
      } else {
        console.log("No Token :: ");
      }
    } else {
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      if (login_info) {
        const login_infoo = JSON.parse(login_info);
        fetchOrderData(token, login_infoo.userName);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <BackButton />
          <section className="order-flow-main py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8 bg-2 p-2 p-md-5">
                  <div className="search-filter col-12 mb-3 border-bottom">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <div className="font-16 ff-semibold text-primary">
                        No. of Orders :{""}
                        <span className="text-black ms-1">{count}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-wrap">
                        <span className="ff-semibold font-16 me-3 text-primary">
                          Search:
                        </span>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control custom-search-input"
                            placeholder="Enter Order Number"
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                          <i className="fas fa-search custom-search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {order?.map((item) => (
                    <>
                      <div className="d-flex justify-content-between align-items-center py-2 flex-wrap">
                        <div className="text-black font-16 fw-bold">
                          Order No:
                          <span>{item?.orderNumber}</span>
                        </div>
                        <div>
                          <p className="text-black font-16 fw-bold mb-0">
                            <i className="fas fa-calendar-alt text-primary"></i>
                            {/* Font Awesome calendar icon */}
                            <span className="ms-2">
                              {new Date(item?.createdon).toLocaleString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div>
                          <p className="text-black font-16 fw-bold ">
                            Order Total:
                            <span>{item.ordertotal?.toLocaleString()} AED</span>
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                  {orderData && orderData.length > 0 ? (
                    orderData.map((data, index) => (
                      <div className="custom-card my-2">
                        <div className="card-body p-4">
                          <p className="font-16 ff-semibold mb-0">
                            {index + 1}
                          </p>
                          <div className="row align-items-center">
                            <div className="col-md-8 mb-4 mb-md-0">
                              <div className="d-flex align-items-center mb-3">
                                <i className="fas fa-box text-primary custom-icon"></i>

                                <h5 className="fw-bold font-16 text-dark mb-0">
                                  Application Number:
                                  <span className="text-primary mx-1">
                                    {data.applicationNumber}
                                  </span>
                                </h5>
                              </div>
                              <p className="mb-2 custom-text">
                                <div className="sub-hd">
                                  <p className="font-14 ff-semibold text-black">
                                    {data.serviceName}
                                  </p>
                                </div>
                              </p>
                              <p className="mb-2 custom-text">
                                <div className="sub-hd">
                                  <p className="font-14 ff-semibold text-black">
                                    {data.ApplicantName}
                                  </p>
                                </div>
                              </p>
                              <p className="mb-2 custom-text">
                                <div className="sub-hd">
                                  <p className="font-14 ff-semibold text-black">
                                    {data.ApplicantPassport}
                                  </p>
                                </div>
                              </p>
                            </div>

                            <div className="col-md-4">
                              <div>
                                <p>
                                  <span className="sr-prize">
                                    AED {data?.serviceAmount.toLocaleString()}
                                  </span>
                                </p>
                              </div>
                              {data.serviceType && (
                                <div className="d-flex align-items-center gap-2 flex-wrap">
                                  <p className="d-flex align-items-center gap-2">
                                    <span className="in-badge">
                                      {data.serviceType}
                                    </span>
                                  </p>
                                </div>
                              )}
                              <p className="mb-2 custom-text">
                                <span
                                  className={`font-12 p-1 px-2 border-radius-10 rounded text-bg-info mx-1 ${
                                    data.Status === "PENDING"
                                      ? "text-bg-info"
                                      : "text-bg-success"
                                  }`}
                                >
                                  {data.Status}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div>
                            <Link
                              className="btn-link text-decoration-none ff-semibold font-14"
                              to={`/applicationdetail/${data.RecordId}`}
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                        <div className="custom-accent-bar"></div>
                      </div>
                    ))
                  ) : (
                    <div
                      id="empty-billing-address"
                      className="text-center mt-3"
                    >
                      {/* Add SVG and Button */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80px"
                        height="80px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
                          stroke="#d6d6d6"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <ellipse
                          cx="15"
                          cy="10.5"
                          rx="1"
                          ry="1.5"
                          fill="#d6d6d6"
                        />
                        <ellipse
                          cx="9"
                          cy="10.5"
                          rx="1"
                          ry="1.5"
                          fill="#d6d6d6"
                        />
                        <path
                          d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                          stroke="#d6d6d6"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <div className="font-18 text-center font-bold mb-3">
                        You don't have an Order
                        <br />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 d-none  d-lg-block ">
                  <div className="sticky-card cart ">
                    <SideCard />
                    <BottomLeftSidebar />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default OrderList;
