type Props = {
  news?: string ;
};

const Marquee: React.FC<Props> = ({ news }) => {
  
  return (
    <>
      <section className="">
        <div className="marquebanner bg-2">
          <div className="container">
            <div className="row">
              <div className="marquee ">
                <div
                  className="marquee-content ff-semibold"
                  dangerouslySetInnerHTML={{
                    __html: news ?? "",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marquee;
