// features/footer/footerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  footerRef: null,
};

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setFooterRef: (state, action) => {
      state.footerRef = action.payload;
    },
  },
});

export const { setFooterRef } = footerSlice.actions;
export default footerSlice.reducer;
