import { useEffect } from "react";
import { Section1 } from "../../../types/home";

interface Props {
  data?: Section1;
}

const LegalDetailBanner: React.FC<Props> = ({ data }) => {
  useEffect(() => {
    import("./LegalDetailBanner.css");
  }, []);

  return (
    <div className="legal-detail-banner d-none d-md-block">
      <img src={data?.image} alt={data?.heading} />
      <div className="banner-text">
        <h1
          dangerouslySetInnerHTML={{
            __html: data?.heading ?? "",
          }}
        ></h1>
      </div>
    </div>
  );
};

export default LegalDetailBanner;
