import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeLanguadesModel } from "../../store/slices/modalSlice";
// import flag from "../../assets/flag.png";

const LanguageModel = () => {
  const dispatch = useDispatch();

  const languagePairs = [
    {
      language1: "English",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/1.svg",
    },
    {
      language1: "Tagalog",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/2.svg",
    },
    {
      language1: "French",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/3.svg",
    },
    {
      language1: "Russian",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/4.svg",
    },
    {
      language1: "Chinese",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/5.svg",
    },
    {
      language1: "Spanish",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/6.svg",
    },
    {
      language1: "Persian",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/7.svg",
    },
    {
      language1: "Turkish",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/8.svg",
    },
    {
      language1: "German",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/9.svg",
    },
    {
      language1: "Portuguese",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/10.svg",
    },
    {
      language1: "Korean",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/11.svg",
    },
    {
      language1: "Japanese",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/12.svg",
    },
    {
      language1: "Amharic",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/13.svg",
    },
    {
      language1: "Kazakh",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/14.svg",
    },
    {
      language1: "Tamazight",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/15.svg",
    },
    {
      language1: "Thai",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/16.svg",
    },
    {
      language1: "Hindi",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/17.svg",
    },
    {
      language1: "Urdu",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/21.svg",
    },
    {
      language1: "Malayalam",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/17.svg",
    },
    {
      language1: "Bengali",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/18.svg",
    },
    {
      language1: "Nepali",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/19.svg",
    },
    {
      language1: "Sinhala",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/20.svg",
    },
    {
      language1: "Tamil",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/17.svg",
    },
    {
      language1: "Telugu",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/17.svg",
    },
    {
      language1: "Pashto",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/21.svg",
    },
    {
      language1: "Hebrew",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/22.svg",
    },
    {
      language1: "Polish",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/23.svg",
    },
    {
      language1: "Hungarian",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/24.svg",
    },
    {
      language1: "Albanian",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/25.svg",
    },
    {
      language1: "Burmese",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/26.svg",
    },
    {
      language1: "Latin",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/27.svg",
    },
    {
      language1: "Swedish",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/28.svg",
    },
    {
      language1: "Croatian",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/29.svg",
    },
    {
      language1: "Danish",
      language2: "Arabic",
      flag: "https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/inside_Pages/flags/30.svg",
    },
  ];

  const showGetQuoteModel = useSelector(
    (state: { modal: { languadesModel: boolean } }) =>
      state.modal.languadesModel
  );

  const closeGetQuoteModal = () => {
    dispatch(closeLanguadesModel());
  };

  return (
    <>
      <Modal
        centered
        show={showGetQuoteModel}
        onHide={closeGetQuoteModal}
        className="mb-fix slide-from-bottom modal-sm shadow-none lega-modal "
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className=" rounded">
          <div className="container">
            <div className="row">
              <p className="ff-bold text-black font-18 mb-0">
                Translation Services in 252+ Languages
              </p>
              <p className="ff-medium font-10 text-black text-justify">
                We specialize in providing accurate and culturally appropriate
                translations for over 252 languages, including Arabic to any
                language and vice versa. Below is a sample of some of the
                languages we offer:
              </p>
              <div className="languages-list">
                {languagePairs?.map((item) => (
                  <div
                    className="col-12 col-md-12 mb-4 border-bottom pb-2 "
                    key={item.language1}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start ">
                        <img
                          src={item.flag}
                          alt=""
                          height={20}
                          className="d-block"
                        />
                      </div>
                      <p className="mb-0 text-start col-3 mb-1 font-12 ff-semibold text-black">
                        {item.language1}
                      </p>
                      <div className="d-flex justify-content-center col-3">
                        <img
                          src="https://goodhand.b-cdn.net/Assets/Footer_pages/Legalservices/left-right_12326129.svg"
                          alt=""
                          height={20}
                          className="d-block"
                        />
                      </div>
                      <p className="mb-0 text-end col-3 mb-1 font-12 ff-semibold text-black">
                        {item.language2}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-center d-md-flex d-block d-md-none">
                <button className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart">
                  Explore all Languages
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageModel;
