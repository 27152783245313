import { useDispatch } from "react-redux";
// import Translation from "../../../assets/Legal/translation-about.png";
import { openGetQuoteModel } from "../../../store/slices/modalSlice";

interface Content {
  id: number;
  description: string;
}

interface Props {
  section1?: {
    heading: string;
    picUrl: string;
    content: Content[];
  };
}

const Section2: React.FC<Props> = ({ section1 }) => {
  const dispatch = useDispatch();

  const handleGetAQuote = () => {
    dispatch(openGetQuoteModel());
  };
  return (
    <>
      <section className="pk-about-sec py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-auto wow animate__zoomIn">
              <img
                // src={Translation}
                src={section1?.picUrl}
                alt="Immigration Services"
                className="img-fluid pk-ab-image d-none d-md-block border border-0"
              />
            </div>

            <div className="col-md-7 my-auto mt-2 mt-md-4 wow animate__fadeInRight">
              <div className="hd-style-2 ">
                <h1
                  className="font-35 ff-bold"
                  dangerouslySetInnerHTML={{
                    __html: section1?.heading ?? "",
                  }}
                ></h1>
              </div>

              <div className="description">
                {section1?.content?.map((item) => (
                  <p key={item.id}>{item.description}</p>
                ))}
              </div>

              <div className="text-center d-flex col-12 ">
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={handleGetAQuote}
                >
                  Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
