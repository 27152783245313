import React from "react";
import { Section7 } from "../../../types/department";
import { CounterProps } from "../../../types/home";

interface Props {
  section7?: Section7;
}

const Counter: React.FC<CounterProps> = ({ end, label, icon }) => {
  const [count, setCount] = React.useState(0); // Start from 0 for animation
  const [isVisible, setIsVisible] = React.useState(false); // Track visibility
  const counterRef = React.useRef<HTMLDivElement | null>(null);

  // Intersection Observer to trigger count animation when the component is visible
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Handle the counting effect when visible
  React.useEffect(() => {
    if (isVisible) {
      let currentCount = 0;
      const step = Math.ceil(end / 50); // Define how much to increment by
      const interval = setInterval(() => {
        currentCount += step;
        if (currentCount >= end) {
          setCount(end);
          clearInterval(interval);
        } else {
          setCount(currentCount);
        }
      }, 30);

      return () => clearInterval(interval);
    }
  }, [isVisible, end]);

  const formatCount = (value: number) => {
    if (value >= 1000000) return (value / 1000000).toFixed(0) + "M+";
    if (value >= 1000) return (value / 1000).toFixed(0) + "K+";
    return value + "+";
  };

  return (
    <div className="counter-item" ref={counterRef}>
      <div className="icon-container">
        <i className={`fas ${icon} counter-icon`}></i>
      </div>
      <span className="counter-number">{formatCount(count)}</span>
      <div className="counter-label">{label}</div>
      <div className="counter-overlay"></div>
    </div>
  );
};

const ExpertCounter: React.FC<Props> = ({ section7 }) => {
  return (
    <div className="counter-container container mt-5">
      <div className="hd-style-2 text-start w-100 wow animate__fadeInLeft">
        <h2
          dangerouslySetInnerHTML={{
            __html: section7?.heading ?? " ",
          }}
        ></h2>
      </div>
      <div className="row justify-content-center">
        {section7?.box?.map((item, index) => (
          <div className="col-md-4 col-4 wow animate__zoomIn" key={index}>
            <Counter
              end={item.numberCount}
              label={item.label}
              icon={item.icon}
            />
          </div>
        ))}
      </div>
      <p className="description pt-3 text-center"></p>
    </div>
  );
};

export default ExpertCounter;
