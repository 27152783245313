 import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Provider } from "react-redux";
import store from "./store/store.ts";
// import { ToastContainer } from "react-toastify";
// import { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
// import './index.css'

ReactDOM.createRoot(document.getElementById("root")!).render(
 <React.StrictMode>
      <HelmetProvider>
  <Provider store={store}>
    <App />
    {/* <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    /> */}
    {/* <Toaster
  position="bottom-left"
  reverseOrder={false}
/> */}
  </Provider>
  </HelmetProvider>
  </React.StrictMode> 
);


