// import React from "react";
import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButtons from "../sliderButtons/SwiperNavButton";
import { useDispatch } from "react-redux";
import { CarouselItem } from "../../types/homeComponentTypes";
import { getID } from "../../store/slices/IdSlice";

interface Props {
  items?: CarouselItem[];
}

const LegalTranslationCarousel: React.FC<Props> = ({ items }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleOpenAddModal = (serviceId: number) => {
    // sessionStorage.setItem("serviceid", JSON.stringify(serviceId ?? 0));
    // dispatch(openAddModelData());
    const insideId = serviceId;
    const outsideId = 42;
    // addDataToDataBase(109,167,"plus")
    dispatch(getID({ insideId, outsideId }));
    // navigate("/services/legal-translation");
  };
  return (
    <div className="carousel-container legal-cr">
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={5}
        loop={true}
        autoplay={{
          delay: 1500, // Duration for each slide (in milliseconds)
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        breakpoints={{
          320: { slidesPerView: 2, spaceBetween: 20 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 5, spaceBetween: 30 },
          // 1024: { slidesPerView: 6, spaceBetween: 30 },
        }}
      >
        {items?.map((item) => (
          <SwiperSlide key={item.serviceid}>
            <div className="card py-3 px-3 text-center shadow-sm rounded border border-0 legal-serv-card ">
              <img
                src={item?.imageSrc}
                alt={item?.serviceName}
                className="img-fluid cat-svg-each mb-3"
              />
              <div className="card-body p-0">
                <h5
                  className="card-title font-14 ff-semibold text-black"
                  onClick={() => handleOpenAddModal(item.serviceid)}
                >
                  {item?.serviceName}
                </h5>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {items && items?.length > 5 && <SwiperNavButtons />}
      </Swiper>
    </div>
  );
};

export default LegalTranslationCarousel;
