// import React from "react";
// import abc from '../../assets/mia.mp4'
import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButtons from "../sliderButtons/SwiperNavButton";


interface Employee {
  RecordId: number;
  LinkSlug: string | null;
  ImgLocation: string;
  ImgAlt: string;
  name: string;
  profession: string;
  logo: string;
}

type Props = {
  employee?: Employee[];
};
const SwiperSlider: React.FC<Props> = ({ employee }) => {
  return (
    <div>
      <div className="col-12 px-5">
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={90}
          loop={true}
          // autoplay={{
          //   // Set autoplay configuration
          //   delay: 2500, // Duration for each slide (in milliseconds)
          //   disableOnInteraction: false, // Continue autoplay after user interaction
          // }}
          slidesPerView={7}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            380: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          }}
        >
          {employee?.map((slide, index) => (
            <SwiperSlide key={index} className="bg-transparent">
              <div className="SwiperSliderItem">
                <img
                  loading="lazy"
                  src={slide.ImgLocation}
                  className="aboutimg img-fluid"
                  alt={slide.ImgAlt + slide.name}
                />
                <div className="expert-logo">
                  <img
                    // src="https://goodhand.b-cdn.net/Assets/Web_Goodhand_imgs/Home_Page_Icons/fed.svg"
                    src={slide.logo}
                    alt={slide.logo + slide.name}
                  />
                </div>
                <div className="ex-cap">
                  <h5>{slide.name}</h5>
                  <p>{slide.profession}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <SwiperNavButtons />
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperSlider;
