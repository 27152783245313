import { useDispatch } from "react-redux";
import { BestLegalTransl } from "../../../../types/homeComponentTypes";
import { openGetQuoteModel } from "../../../../store/slices/modalSlice";

interface Props {
  data?: BestLegalTransl;
}

const BestLegalTranslation: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const handleOpenModel = () => {
    dispatch(openGetQuoteModel());
  };
  return (
    <>
      <section className="pk-about-sec py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-auto wow animate__zoomIn">
              <img
                src={data?.image}
                alt="Immigration Services"
                className="img-fluid  d-none d-md-block border border-0"
              />
            </div>

            <div className="col-md-7 my-auto mt-2 mt-md-4 wow animate__fadeInRight">
              <div className="hd-style-2 ">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.heading ?? "",
                  }}
                ></h2>
              </div>
              <div className="description font-14">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.description ?? "",
                  }}
                >
                  {/* {data?.description} */}
                </p>
              </div>

              <div className="text-center d-flex col-12 ">
                <button
                  className="btn btn-primary   font-14 ff-semibold  mt-3  bt--cart"
                  onClick={handleOpenModel}
                >
                  Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BestLegalTranslation;
