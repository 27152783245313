import Banner from "../../../components/typeofvisaComponents/typeofvisaBanner/Banner";
import WhoWeAre from "../../../components/typeofvisaComponents/typeofvisaWhoweare/WhoWeAre";
import Faq from "../../../components/amirComponents/amirFaq/Faq";
import RequiredDocuments from "../../../components/typeofvisaComponents/typeofvisaRequireddocuments/RequiredDocuments";
import Eligibility from "../../../components/typeofvisaComponents/typeofvisaEligibility/Eligibility";
import VisaProcess from "../../../components/typeofvisaComponents/typeofvisaVisaprocess/VisaProcess";
import { useEffect, useState } from "react";
import PreLoader from "../../../components/preloader/PreLoader";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import Department from "../../Footerpages/department/Department";
import MetaHead from "../../../components/metaHead/MetaHead";
import Clients from "../../../components/client/Clients";
import { SEOData } from "../../../types/home";
import {
  Allservice,
  GoldenVisaData,
  HomeData,
  PackageData,
} from "../../../types/typeofvisa";
import ServiceInDubaiSlider from "../../alAdheed/marriageServices/components/ServiceInDubaiSlider";

const apiUrl = import.meta.env.VITE_API_URL;

const SpouseVisa = () => {
  const navigate = useNavigate();
  const { visaType } = useParams();
  const URL = `${apiUrl}/api/openRoute/footerLinks?url=${visaType}`;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<GoldenVisaData>();
  const [apiData, setApiData] = useState<PackageData[]>();
  const [metaData, setMetaData] = useState<SEOData>();
  const [checkDepartment, setCheckDepartment] = useState(false);
  const [departmentData, setDepartmentData] = useState<HomeData>();
  const [allService, setAllService] = useState<Allservice[]>([]);

  const getDataFromJson = async (jsonUrl: string) => {
    try {
      const response = await fetch(jsonUrl);
      const data = await response.json();

      if (data) {
        console.log("typeofvise ",data.seo)
        setMetaData(data.seo);
        setData(data);
        return true; // Indicate success
      }

      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromApi = async () => {
    try {
      const response = await axios.get(URL);

      if (response && response.data) {
        if (response.data.HomeData.isDepartment === true) {
          setDepartmentData(response.data.HomeData);
          setCheckDepartment(true);

          const jsonSuccess = await getDataFromJson(
            response.data.HomeData.JSON_URl
          );

          return jsonSuccess;
        } else {
          setApiData(response.data.HomeData.packages);
          setAllService(response?.data.HomeData.Allservice);

          setCheckDepartment(false);
          const jsonSuccess = await getDataFromJson(
            response.data.HomeData.mainPackage[0].JsonAddress
          );
          return jsonSuccess;
        }
      }

      return false; // Return false if data is not available
    } catch (error) {
      console.error("Axios error:", error);
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error("Error 404: Redirecting to NotFound page.");
          navigate("/notFound"); // Redirect to NotFound page
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false; // Indicate overall failure if both API calls fail
    }
  };

  useEffect(() => {
    import("../../../pages/alAdheed/marriageServices/marriageServices.css");
    import("../../../pages/alAdheed/aladheed.css");
    import("./typesofvisa.css");
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch data from the main API
        const apiDataSuccess = await getDataFromApi();

        if (!apiDataSuccess) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [visaType]);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          {metaData && <MetaHead metaData={metaData} />}
          <div className="types-of-visa">
          

            {!checkDepartment ? (
              <div className="types-sec">
                <Banner data={data?.section1} />
                <WhoWeAre section2={data?.section2} />

                <Eligibility section3={data?.section3} />
                {/* who can apply */}
                {apiData && apiData?.length !== 0 && (
                  <RequiredDocuments data={apiData} section4={data?.section4} />
                )}

                <VisaProcess section5={data?.section5} />

                <ServiceInDubaiSlider data={allService} />
                {/* <AllserviceSlider allService={allService} /> */}

                <Faq FaqData={data?.section7} />

                {data?.["clients"] && (
                  <Clients
                    section9={data?.clients} //ye section dala ha
                  />
                )}
              </div>
            ) : (
              <>
                <Department departmentData={departmentData} />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SpouseVisa;
