import React, { useState, useEffect, useRef } from "react";
// Create and import a CSS file for styling
import axios from "axios";
const baseUrl = import.meta.env.VITE_API_URL;

interface Coupon {
  code: string;
  creation_date: string;
  discount_type: "percentage" | "fixed"; // Assuming "percentage" or "fixed" as possible types
  discount_value: number;
  end_date: string;
  heading: string;
  id: number;
  img: string; // SVG data or URL string for the image
  imgAlt: string;
  is_active: boolean;
  max_usage: number;
  no_of_usage: number;
  start_date: string;
}

const Coupons: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false); // State for the second modal
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [couponsData, setCouponsData] = useState<Coupon[]>();

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openTermsModal = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen]);

  const getCouponData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/openRoute/coupons`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setCouponsData(response.data.activeCoupons);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    import("./Coupons.css");
    getCouponData();
  }, []);

  // Close modal on pressing "Escape" key
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      closeModal();
      closeTermsModal(); // Close both modals on escape
    }
  };

  return (
    <>
      {couponsData && couponsData?.length >= 1 && (
        <div className="d-flex gap-2  align-items-center Cart-offer-sec   my-3 p-4">
          <div
            className="wow animate__heartBeat bg-2 rounded"
            data-wow-duration="6s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="animated-svg"
              viewBox="0 0 24 24"
              fill="#6e42e5"
            >
              <path
                d="M8 16L16 8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
                stroke="#385986"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>

          <div className="">
            <p className="fw-semibold font-13 text-black mb-0">
              Coupons and offers
            </p>
            <p className="mb-0 font-12">Save AED 30 on this order</p>
          </div>

          <div
            className="flex-grow-1 text-align-end d-flex align-items-center justify-content-end"
            onKeyDown={handleKeyDown}
          >
            <button
              type="button"
              className="btn bg-none d-flex align-items-center justify-content-between font-13"
              aria-label="1 offer"
              // onClick={openModal}
            >
              <span>{couponsData?.length} offer</span>
              <i
                className="fas fa-chevron-right ms-2"
                style={{ color: "#6E42E5" }}
              ></i>{" "}
              {/* Font Awesome icon */}
            </button>
            {isModalOpen && (
              <div
                className="modal d-block"
                role="dialog"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={closeModal}
                      ></button>
                    </div>
                    <div className="modal-body Coupon-modal">
                      <p className="modal-title ff-semibold font-18 text-black mb-3">
                        Coupons & Offers
                      </p>
                      <div className="position-relative ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Coupon Code"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          ref={inputRef}
                        />
                        <span
                          className="position-absolute"
                          style={{
                            color: inputValue ? "rgb(110, 66, 229)" : "grey",
                            pointerEvents: "none",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Apply
                        </span>
                      </div>

                      <div>
                        {couponsData?.map((offer, index) => (
                          <div
                            key={index}
                            className="mt-3 p-3 border rounded-3"
                          >
                            <div className="d-flex align-items-center gap-1 gap-md-3 flex-wrap">
                              <div className="flex-shrink-0">
                                <img
                                  // src={offer.img}
                                  src="https://www.goodhand.ae/assets/logo-main-gFDCiCbr.svg"
                                  alt={offer.imgAlt}
                                  className="img-fluid w-75"
                                />
                              </div>
                              <div className="ms-3">
                                <p className="mb-0 ff-semibold font-13">
                                  {offer.heading}
                                </p>
                                <p className="mb-0 text-muted font-13">
                                  Use: {offer.code}
                                </p>
                                <p className="mb-0 text-success font-13">
                                  Save AED 53 on this order
                                </p>
                                <a
                                  className="btn-link ff-semibold font-14"
                                  onClick={openTermsModal} // Open the second modal
                                  rel="nofollow"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    color: "rgb(110, 66, 229)",
                                  }}
                                >
                                  View T&C
                                </a>
                              </div>
                              <div className="flex-grow-1 text-end">
                                <a
                                  className="btn-link ff-semibold font-14"
                                  data-bs-toggle="modal"
                                  rel="nofollow"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    marginLeft: "1.3rem",
                                    color: "rgb(110, 66, 229)",
                                  }}
                                >
                                  Apply
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isTermsModalOpen && (
              <div
                className="modal d-block"
                role="dialog"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={closeTermsModal}
                      ></button>
                    </div>
                    <div className="modal-body cp-modal-2  bg-white p-4">
                      <p className="modal-title ff-semibold font-18 text-black">
                        Terms And Conditions
                      </p>
                      <ul className="font-14 m-4">
                        <li>Lorem ipsum dolor sit amet.</li>
                        <li>
                          Sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua.
                        </li>
                        <li>Ut enim ad minim veniam, quis nostrud</li>
                      </ul>

                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={closeTermsModal}
                      >
                        Okay! got it
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Coupons;
