import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface OfferService {
  Name: string; // The name or title of the visa service
  RecordId: number; // Unique identifier for the visa record
  amount: number; // Amount or cost associated with the service
  imgAlt: string | null; // Alternative text for the image, can be null
  imgUrl: string; // URL for the image
  options: string; // Comma-separated string of option IDs
  slugURL: string; // Slug for the URL path
}

const baseUrl = import.meta.env.VITE_API_URL;

const BottomLeftSidebar = () => {
  const [offer, setOffer] = useState<OfferService>();

  const fetchSideCardData = async () =>
    // token: string | null,
    // login_infoo_userName: string
    {
      try {
        // setIsLoading(true);
        // setIsError(false);
        // const postData = {
        //   userName: login_infoo_userName,
        // };

        const response = await fetch(`${baseUrl}/api/openRoute/specialOffers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify(postData),
        });
        const data = await response.json();
        // setOrderData(data.mainOrdersList.orders);
        // setCount(data.mainOrdersList.count);
        if (data) {
          setOffer(data.offer);
          // setIsLoading(false);
        }
      } catch (error) {
        // setIsError(true);
        // setIsLoading(false);
        console.error("Error fetching order data:", error);
      }
    };

  useEffect(() => {
    const login_info = localStorage.getItem("Login_info");
    // const token = sessionStorage.getItem("tk");
    if (login_info) {
      //   const login_infoo = JSON.parse(login_info);
      //   fetchOrderData(token, login_infoo.userName);
      fetchSideCardData();
    }
  }, []);

  return (
    <>
      <div className="inneroffer card mb-3">
        <div className="card-body">
          <h5 className="card-title">Today's Offers</h5>
          <div className="offer-card position-relative bg-primary">
            <div className="badge-2">
              <span>Offer</span>
            </div>
            <div className="offerText text-white ">
              <h4 className="offer-name">{offer?.Name}</h4>
              <p className="offer-p text-white">
                <span>{offer?.amount.toFixed(2)}</span> AED
              </p>

              <Link
                to={`/${offer?.slugURL}`}
                className="tourist-card-btn text-decoration-none"
              >
                View More
              </Link>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <img
                loading="lazy"
                // src="https://goodhand.ideaintl.net/assets/investor-Do6qmAGb.png"
                src={offer?.imgUrl}
                className="img-fluid"
                alt={offer?.imgAlt ?? offer?.Name}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomLeftSidebar;
