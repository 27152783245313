import React from "react";
import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
interface SliderItem {
  RecordId: number;
  LinkSlug: string | null;
  ImgLocation: string;
  MImgLocation: string;
  ImgAlt: string;
}

interface Props {
  sliderData: SliderItem[];
}

const GoldenVisaSlider: React.FC<Props> = ({ sliderData }) => {
  return (
    <section className="golden-visa-section">
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {sliderData.map((item) => (
          <SwiperSlide key={item.RecordId}>
            <Link to={`${item.LinkSlug}`}>
              <img
                src={item.ImgLocation}
                className="w-100 d-none d-md-block"
                alt={item.ImgAlt}
              />
              <img
                src={item.MImgLocation}
                className="w-100 d-block d-md-none"
                alt={item.ImgAlt}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default GoldenVisaSlider;
