import React from "react";
import { Section5 } from "../../types/notary";
import { useNavigate } from "react-router-dom";

interface Props {
    section5?: Section5;
}
const Expertise: React.FC<Props> = ({ section5 }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/contactus");
  };
  return (
    <section className="notary-expertise py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-8 my-auto">
            <div className="hd-style-2 ">
              <h4 className="font-35 ff-bold text-white mb-4"
                dangerouslySetInnerHTML={{
                  __html: section5?.heading ?? "",
                }}>
              </h4>
            </div>
            <p className="font-12 text-white w-50 mb-4"
              dangerouslySetInnerHTML={{
                __html: section5?.line ?? "",
              }}>
            </p>
            <div className="text-center d-flex col-12 ">
              <button onClick={() => handleNavigate()} className="btn btn-primary   font-14 ff-semibold  mt-2  bt--cart">
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              {section5?.box?.map((item, index) => (
                <div
                  className="d-flex align-items-center justify-content-start gap-3 mb-4"
                  key={index}
                >
                  <div className="bg-primary p-2 rounded">
                    <img
                      src={item.icon}
                      alt={`${item.title} Icon`}
                      className="notary-exp"
                    />
                  </div>
                  <div className="text-white">
                    <p className="font-14 ff-semibold mb-0 text-white">
                      {item.title}
                    </p>
                    <p className="font-12 text-white">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;