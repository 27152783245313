import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/backButton/Backbutton";
import PreLoader from "../../components/preloader/PreLoader";
import BottomLeftSidebar from "../../components/bottomLeftSidebar/BottomLeftSidebar";
import SideCard from "../../components/thirdsideCard/SideCard";
import axios from "axios";
import { logoutFun } from "../../config/auth";
import { useDispatch } from "react-redux";

interface OrderData {
  RecordId: number;
  userName: string;
  orderNumber: string;
  paymentstatus: number;
  ordertotal: number;
  createdon: string;
  modifiedon: string;
  orderStatus: string;
  InvoiceRef: string;
  paymentMode: string;
  NoofApplications: number;
}

const baseUrl = import.meta.env.VITE_API_URL;

const OrderFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term
  const [count, setCount] = useState<number>();

  const fetchOrderData = async (
    token: string | null,
    login_infoo_userName: string
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);

      const postData = {
        userName: login_infoo_userName,
      };

      const response = await axios.post(
        `${baseUrl}/api/user/orderShow`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      setOrderData(data.mainOrdersList.orders);
      setCount(data.mainOrdersList.count);

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      // console.error('Error fetching order data:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // If token is expired or invalid
          if (error.response.status === 401 || error.response.status === 407) {
            console.error("Token expired. Logging out...");
            // logout(); // Call the logout function to handle session end
          }
        } else if (error.code === "ERR_NETWORK") {
          console.error("Network error:", error);
          logoutFun(dispatch, navigate);
        }
      } else {
        console.error("Error fetching order data:", error);
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    import("./orderflow.css");
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");
    if (login_info) {
      const login_infoo = JSON.parse(login_info);
      fetchOrderData(token, login_infoo.userName);
    }
  }, []);

  const fetchData = async () => {
    if (searchTerm.trim() !== "") {
      const token = sessionStorage.getItem("tk");
      if (token) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.get(`${baseUrl}/api/user/searchOrder`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              orderNumber: searchTerm,
            },
          });

          setOrderData(response.data.order.orders);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response) {
              // If token is expired or invalid
              if (
                error.response.status === 401 ||
                error.response.status === 407
              ) {
                console.error("Token expired. Logging out...");
                // logout(); // Call the logout function to handle session end
              }
            } else if (error.code === "ERR_NETWORK") {
              console.error("Network error:", error);
              logoutFun(dispatch, navigate);
            }
          } else {
            console.error("Error fetching order data:", error);
            setIsError(true);
          }
        }
      } else {
        console.log("No Token found");
      }
    } else {
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      if (login_info && token) {
        const login_infoo = JSON.parse(login_info);
        fetchOrderData(token, login_infoo.userName);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <BackButton />
          <section className="order-flow-main py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8 bg-2 p-md-5 p-2">
                  <div className="search-filter col-12 mb-3 border-bottom">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap  ">
                      <div className="font-16 ff-semibold text-primary">
                        No. of Orders :
                        <span className="text-black ms-1"> {count}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="ff-semibold font-16 me-3 text-primary">
                          Search:
                        </span>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control custom-search-input"
                            placeholder="Enter Order Number"
                            value={searchTerm} // Bind input to searchTerm state
                            onChange={handleSearch}
                          />
                          <i className="fas fa-search custom-search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="listing">
                    {orderData && orderData.length > 0 ? (
                      orderData.map((data, index) => (
                        <div className="custom-card my-2" key={data.RecordId}>
                          <div className="card-body p-4">
                            <div className="row align-items-center">
                              <div className="col-md-6 mb-4 mb-md-0">
                                <p className="font-16 ff-semibold">
                                  {index + 1}
                                </p>
                                <div className="d-flex align-items-center mb-3">
                                  <i className="fas fa-box text-primary custom-icon"></i>
                                  <h5 className="fw-bold font-18 text-dark mb-0">
                                    Order Number:
                                    <span className="text-primary mx-1">
                                      {data.orderNumber}
                                    </span>
                                  </h5>
                                </div>
                                <p className="mb-2 custom-text">
                                  <span className="pe-1">Status:</span>
                                  <span
                                    className={`font-12 p-1 px-2 border-radius-10 rounded ${
                                      data.orderStatus === "open"
                                        ? "text-bg-info"
                                        : "text-bg-success"
                                    } mx-1`}
                                  >
                                    {data.orderStatus}
                                  </span>
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p className="mb-2 custom-text">
                                  No. of Applications:
                                  <span className="fw-semibold text-dark mx-1">
                                    {data.NoofApplications}
                                  </span>
                                </p>
                                <p className="mb-2 custom-text">
                                  Total :
                                  <span className="fw-semibold text-dark mx-1">
                                    {data.ordertotal}
                                  </span>
                                </p>
                                <p className="mb-0 custom-text">
                                  Date:
                                  <span className="fw-semibold text-dark mx-1">
                                    {new Date(data.createdon).toLocaleString(
                                      "en-US",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div>
                              <a
                                className="btn-link"
                                onClick={() =>
                                  navigate(`/orderlist/${data.orderNumber}`)
                                }
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                          <div className="custom-accent-bar"></div>
                        </div>
                      ))
                    ) : (
                      <div
                        id="empty-billing-address"
                        className="text-center mt-3"
                      >
                        {/* Add SVG and Button */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="80px"
                          height="80px"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
                            stroke="#d6d6d6"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <ellipse
                            cx="15"
                            cy="10.5"
                            rx="1"
                            ry="1.5"
                            fill="#d6d6d6"
                          />
                          <ellipse
                            cx="9"
                            cy="10.5"
                            rx="1"
                            ry="1.5"
                            fill="#d6d6d6"
                          />
                          <path
                            d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                            stroke="#d6d6d6"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                        <div className="font-18 text-center font-bold mb-3">
                          You don't have an Order
                          <br />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                  <div className="sticky-card cart ">
                    <SideCard />
                    <BottomLeftSidebar />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default OrderFlow;
