import LegalDetailBanner from "../../../components/LegalTranslation/LegalDetails/LegalDetailBanner";

// import "./LegalTranslationDetail.css";
import Faq from "../../../components/amirComponents/amirFaq/Faq";
import Clients from "../../../components/client/Clients";
import BestLegalTranslation from "./components/BestLegalTranslation";
import ProfessionalLanguagesTranslation from "./components/ProfessionalLanguagesTranslation";
import QualityTranslationLanguageTeaching from "./components/QualityTranslationLanguageTeaching";
import VideoSection from "./components/VideoSection";
import { useEffect, useState } from "react";
import PreLoader from "../../../components/preloader/PreLoader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Section5 from "../components/Section5";
import GetQuoteModel from "../../../components/getquotemodel/GetQuoteModel";
import { useSelector } from "react-redux";
import LanguageModel from "../../../components/languageModel/LanguageModel";
import MetaHead from "../../../components/metaHead/MetaHead";
import { redirection } from "../../../types/marriageSevices";
import ServiceInDubaiSlider from "../../alAdheed/marriageServices/components/ServiceInDubaiSlider";
const apiUrl = import.meta.env.VITE_API_URL;

const LegalTranslationDetail = () => {
  // const URL = `${apiUrl}/api/openRoute/home${location.pathname}`;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();
  const [serviceList, setServiceList] = useState();
  const [redirection, setRedirection] = useState<redirection>();
  const [allService, setAllService] = useState<[]>([]);

  const showGetQuoteModel = useSelector(
    (state: { modal: { getQuoteModel: boolean } }) => state.modal.getQuoteModel
  );

  const showLanguageModel = useSelector(
    (state: { modal: { languadesModel: boolean } }) =>
      state.modal.languadesModel
  );

  const getDataFromJson = async (jsonUrl: string) => {
    try {
      const response = await fetch(jsonUrl);
      const data = await response.json();

      if (data) {
        setMetaData(data.seo);
        setData(data);
        
        // setMetaData(data.)

        return true; // Indicate success
      }

      return false; // Return false if data is not available
    } catch (error) {
      console.error("Error fetching JSON data:", error);
      return false; // Indicate failure
    }
  };

  const getDataFromApi = async () => {
    const path = location.pathname.slice(1);
    try {
      const response = await axios.get(
        `${apiUrl}/api/openRoute/LegalInsideJson?URL=${path}`
      );

      if (response && response.data) {
        if (response.data) {
          const jsonSuccess = await getDataFromJson(response.data.data.jsonUrl);
          setServiceList(response.data.data.serviceList1);
          setRedirection(response.data.data.serviceList2)
          setAllService(response.data.data.Allservice);
          return jsonSuccess;
        }
      }

      return false; // Return false if data is not available
    } catch (error) {
      console.error("Axios error:", error);
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          console.error("Error 404: Redirecting to NotFound page.");
          navigate("/notFound"); // Redirect to NotFound page
        } else {
          console.error("Axios error:", error.message);
        }
      } else {
        console.error("Unknown error:", error);
      }
      return false; // Indicate overall failure if both API calls fail
    }
  };

  useEffect(() => {
    import("./../legalTranslation.css");
    import("./LegalTranslationDetail.css");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Fetch both JSON and API data
        const apiDataSuccess = await getDataFromApi();
        // const AllLegalServiceList = await getDataFromAllLegalServiceListApi();

        // If neither call succeeds, show an error
        if (!apiDataSuccess ) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
        console.error("Error during data fetching:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }
  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
          <MetaHead metaData={metaData} />
          <LegalDetailBanner data={data?.["bannerHeader"]} />

          <BestLegalTranslation data={data?.["section"]} />

          <Section5
            section3={data?.["section3"]}
            apiData={serviceList}
            redirection={redirection}
          />

          <ProfessionalLanguagesTranslation data={data?.["languages"]} />

          <QualityTranslationLanguageTeaching data={data?.["Expertise"]} />
          
          <section className="pt-5">
            <ServiceInDubaiSlider data={allService}  />
          </section>

          <VideoSection data={data?.["video"]} />

          <section className="types-of-visa">
            <Faq FaqData={data?.["FAQ"]} />
          </section>

          <Clients section9={data?.["clients"]} />

          {showGetQuoteModel && <GetQuoteModel />}
          {showLanguageModel && <LanguageModel />}
        </>
      )}
    </>
  );
};
export default LegalTranslationDetail;
