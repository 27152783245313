import { useParams } from "react-router-dom";
import img from "../../../assets/blogImg/side-nw.png";
const BlogDetail = () => {
  useParams(); // Get the blog ID from the URL

  // Mock blog data
  const blog = {
    title: "The Importance of Emirates ID in the UAE",
    date: "August 20, 2022",
    badge: "Latest",
    content: `
      The Emirates ID is more than just a card; it is a vital identity document 
      for all UAE residents. Issued by the Federal Authority for Identity, 
      Citizenship, Customs & Ports Security, this card plays a crucial role 
      in day-to-day transactions and accessing services in the UAE. 
      
      From banking to healthcare, having your Emirates ID is essential. 
      It serves as proof of identity, residency, and legal status in the country.
    `,
    image:
      "https://goodhand.b-cdn.net/Assets/Footer_pages/investor-visa/main_banner.jpg", // Replace with actual path
  };
  const recentBlogs = [
    {
      imgSrc: img,
      title: "The Importance of Emirates ID in the...",
      description:
        "The Emirates ID is more than just a card  it is a vital identity document for all UAE residents. Issued by the Federal Authority for Identity ...",
      link: "/blogDetails",
      badge: "Latest",
    },
    {
      imgSrc: img,
      title: "The Benefits of Dubai Health Services",
      description:
        "The Emirates ID is more than just a card it is a vital identity document for all UAE residents ...",
      link: "/blogDetails",
      badge: "Trending",
    },
  ];

  return (
    <section className="blog-detail pb-5">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-7 ">
            <div className="col-12">
              <h1 className="blog-title font-35 ff-semibold">{blog.title}</h1>
              <div className="blog-meta">
                <span className="blog-date">{blog.date}</span>
                {blog.badge && (
                  <span className="blog-badge px-3 rounded bg-2 font-12 mx-3">
                    {blog.badge}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 mt-4">
              <img
                src={blog.image}
                alt={blog.title}
                className="img-fluid blog-image"
              />
            </div>
            <div className="col-12 mt-4">
              <p className="blog-content font-14">{blog.content}</p>
              <p className="blog-content font-14">{blog.content}</p>
              <p className="blog-content font-14">{blog.content}</p>
            </div>
          </div>
          <div className="col-md-4 ">
            <aside className="mb-4 pt-4 py-md-3">
              {/* Contact Form */}
              <div className="contact-form mb-4 p-3 mb-4 mb-md-4 border rounded ">
                <h3 className="ff-semibold font-18 ">Leav a Reply</h3>
                <p className="font-14">
                  Your email address will not be published. Repuired fields are
                  marked*
                </p>
                <form>
                  <div className="mb-3 font-14">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control font-14"
                      id="name"
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                  <div className="mb-3 font-14">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control font-14"
                      id="email"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="mb-3 font-14">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control font-14"
                      id="message"
                      rows={3}
                      placeholder="Write your message"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Submit
                  </button>
                </form>
              </div>

              {/* Recent Blogs */}
              <div>
                <h3 className="ff-semibold font-18 py-2">Recent Blogs</h3>
                {recentBlogs.map((blog, index) => (
                  <div className="blog-item-sub" key={index}>
                    <img
                      src={blog.imgSrc}
                      alt={blog.title}
                      className="img-fluid"
                    />
                    <div className="blog-content-sub">
                      <h4>{blog.title}</h4>
                      <div className="blog-description checkout-ser-name">
                        {blog.description}
                      </div>
                      <a
                        href={blog.link}
                        className="btn-link ff-semibold font-12 mt-2 text-decoration-none w-100 text-end d-block"
                      >
                        View
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
