import axios from "axios";
import { useEffect, useState } from "react";
import ChatWithUs from "../chatWithus/ChatWithUs";
const baseUrl = import.meta.env.VITE_API_URL;

interface DiscountOffer {
  code: string; // "FIRSTBOOK30"
  creation_date: string; // ISO date format "2024-11-07T00:00:00.000Z"
  discount_type: string; // "percentage"
  discount_value: number; // 30
  end_date: string; // ISO date format "2024-12-01T00:00:00.000Z"
  heading: string; // "30% Off First Booking"
  id: number; // 1
  img: string; // SVG content as a string
  imgAlt: string; // "coupn"
  is_active: boolean; // true
  max_usage: number; // 100
  no_of_usage: number; // 1
  start_date: string; // ISO date format "2024-11-01T00:00:00.000Z"
}

interface Props {
  isMobile: boolean;
}

const SecondRightSideCard: React.FC<Props> = ({ isMobile }) => {
  const [showAll, setShowAll] = useState(false);
  const [offers, setOffers] = useState<DiscountOffer[]>();

  // const offers = [
  //   { id: 1, title: "Flat 10% off on each order", code: "HOMESALON10" },
  //   { id: 2, title: "30% CASHBACK on 1st booking", code: "30CB" },
  //   { id: 3, title: "Flat 10% off on each order", code: "HOMESALON10" },
  // ];

  const getCouponData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/openRoute/coupons`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setOffers(response.data.activeCoupons);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getCouponData();
  }, []);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    // <div className="card mb-3 add-cart-main">
    <>
      <ChatWithUs />

      {offers && offers.length >= 1 && (
        <div className={`card ${isMobile ? "mb-0 w-100 border-0 " : "mb-3"}`}>
          <div className={`${isMobile ? "" : " rounded"}`}>
            <div
              className={`card-body p-0 p-md-3  border-0 start  ${
                isMobile ? " d-flex flex-row gap-3 " : ""
              }`}
              style={{
                overflowY: isMobile ? "scroll" : "auto",
                // maxHeight: isMobile ? "300px" : "none", // Optional: limit the height for scroll to activate
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none",
              }}
            >
              {/* Display offers - only first offer by default, or all if showAll is true */}
              {offers
                ?.slice(
                  0,
                  isMobile ? offers.length : showAll ? offers.length : 1
                )
                .map((offer) => (
                  <div
                    className={`d-flex gap-2 gap-md-3 align-items-center justify-content-start col-7 col-md-12 border border-md-0 border-opacity-10 px-3 px-md-0 py-2 rounded`}
                    key={offer?.id}
                  >
                    <div className="d-block d-md-none">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          fill="#24A346"
                        ></path>
                      </svg>
                    </div>
                    <div className="d-none d-md-block bg-2 rounded">
                      <svg
                        height="32"
                        width="32"
                        xmlns="http://www.w3.org/2000/svg"
                        className="animated-svg"
                        viewBox="0 0 24 24"
                        fill="#6e42e5"
                      >
                        <path
                          d="M8 16L16 8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
                          stroke="#fff"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div className="d-flex flex-column align-self-center flex-grow-1">
                      <p className="ff-semibold font-12 text-black mb-0">
                        {offer?.heading}
                      </p>
                      <p className="font-12  mb-md-0">
                        Use code: {offer?.code}
                      </p>
                    </div>
                  </div>
                ))}

              {/* Toggle button to switch between showing all offers and only the first one */}

              {offers.length >= 2 && !isMobile && (
                <button
                  onClick={toggleShowAll}
                  className="btn btn-link font-12 p-0 pb-2 ff-semibold"
                  style={{ textDecoration: "none", color: "rgb(110, 66, 229)" }}
                >
                  {showAll ? "View Less Offers " : "View More Offers"}
                  {!showAll ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-up"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 10.5a.5.5 0 0 1-.354-.146L3.5 6.207a.5.5 0 1 1 .707-.707L8 9.293l3.793-3.793a.5.5 0 0 1 .707.707l-4.146 4.147A.5.5 0 0 1 8 10.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 5.5a.5.5 0 0 1 .354.146l4.146 4.147a.5.5 0 0 1-.707.707L8 6.707l-3.793 3.793a.5.5 0 0 1-.707-.707l4.146-4.147A.5.5 0 0 1 8 5.5z"
                      />
                    </svg>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SecondRightSideCard;
