import { WhyChooseGoodhand } from "../../../../types/marriageSevices";

interface Props {
  data?: WhyChooseGoodhand;
}

const WhyChooseGoodHandMarriageServices: React.FC<Props> = ({ data }) => {
  return (
    <>
      <section className="bg-2 py-3 py-md-5">
        <div className="container py-3 py-md-5">
          <div className="row">
            <div className="col-md-5">
              <div className="hd-style-2">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.heading ?? "",
                  }}
                ></h2>
              </div>
            </div>

            <div className="col-md-7 justify-content-end align-items-center"></div>
          </div>
        </div>
      </section>

      <section className="mt-5 my-md-5">
        <div className="container mt-4 above-minus">
          <div className="carousel-container">
            <div className="row">
              {data?.benefits.map((Benefit) => (
                <div key={Benefit.id} className="col-12 col-md-3  mb-4">
                  <div className="card flex-row flex-md-column align-items-center gap-md-0 gap-4  py-3 px-3 text-center shadow-sm rounded border border-0 legal-serv-card">
                    <img
                      src={Benefit.image}
                      alt={Benefit.title}
                      className="img-fluid cat-svg-each mb-0 mb-md-3 col-3 col-md-12 w-25 w-md-100"
                      style={{
                        height: "85px",

                        objectFit: "contain",
                      }}
                    />
                    <div className="card-body p-0">
                      <h5
                        className="card-title font-16 font-sm-14 ff-semibold text-start text-md-center"
                        dangerouslySetInnerHTML={{
                          __html: Benefit.title ?? "",
                        }}
                      ></h5>
                      <p
                        className="font-14 text-start text-md-center"
                        dangerouslySetInnerHTML={{
                          __html: Benefit.desc ?? "",
                        }}
                        style={{ minHeight: "64px" }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseGoodHandMarriageServices;
