import LegalForm from "./LegalForm";

interface Props {
  heroSection?: {
    heading?: string;
    picUrl?: string;
  };
}

const HeroSection: React.FC<Props> = ({ heroSection }) => {
  return (
    <>
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-5">
            <span
              className="font-22 ff-bold"
              dangerouslySetInnerHTML={{
                __html: heroSection?.heading ?? "",
              }}
            ></span>

            <div>
              <img
                src={heroSection?.picUrl}
                alt=""
                className="img-fluid d-none d-md-block"
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="doc-upload-sec">
              <LegalForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
