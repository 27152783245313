import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { clearCart } from "../store/slices/cartSlice";
import { logout } from "../store/slices/authSlice";

export const logoutFun = (dispatch: Dispatch, navigate: NavigateFunction) => {
  // Dispatch logout action and clear any relevant Redux state
  dispatch(logout());
  dispatch(clearCart());

  // Clear session and local storage
  localStorage.removeItem("Login_info");
  localStorage.removeItem("Login_info_status");
  localStorage.removeItem("cartDataLocal");
  localStorage.removeItem("count");
  localStorage.removeItem("userName");
  sessionStorage.removeItem("userName");
  localStorage.removeItem("orderNumber");
  localStorage.removeItem("carts");
  localStorage.removeItem("rt");
  sessionStorage.removeItem("tk");
  localStorage.removeItem("phoneNumber");

  // Navigate to the login page or any other page
  navigate("/");
};
