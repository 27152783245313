import React from "react";
import { Section4 } from "../../types/notary";

interface Props {
    section4?: Section4;
}

const Transformation: React.FC<Props> = ({ section4 }) => {
    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 my-auto">
                        <div className="hd-style-2 py-3 wow animate__fadeInRight animated">
                            <h4 className="font-35 ff-bold"
                                dangerouslySetInnerHTML={{
                                    __html: section4?.heading ?? "",
                                  }}>
                            </h4>
                            <p className="text-black font-12"
                                dangerouslySetInnerHTML={{
                                    __html: section4?.line ?? "",
                                  }}>
                            </p>
                        </div>

                        <div className="row">
                            {section4?.box?.map((boxSec4, index) => (
                                <div className="col-md-6 mb-4" key={index}>
                                    <div className="image-overlay-container1">
                                        <img
                                            src={boxSec4?.image}
                                            alt="Placeholder"
                                            className="img-fluid rounded"
                                        />
                                        <div className="overlay1">
                                            <p className="overlay-text1">{boxSec4.label}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-2 my-auto">
                        <img
                            src="https://goodhand.b-cdn.net/Assets/Footer_pages/Notaryservices/arrow5.svg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div className="col-md-5">
                        <img
                            src={section4?.image}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Transformation;