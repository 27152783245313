import React from "react";
import { Helmet } from "react-helmet-async";
import { SEOData } from "../../types/home";
interface MetaHeadProps {
  metaData?: SEOData;
}

const MetaHead: React.FC<MetaHeadProps> = ({ metaData }) => {
  if (!metaData) return null;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />

      {metaData.shortcut_icon && (
        <link
          rel="shortcut icon"
          type={metaData.shortcut_icon.type}
          href={metaData.shortcut_icon.href}
        />
      )}
      
      {/* <meta property="og:url" content={window.location.href} /> */}

      {metaData.apple_touch_icon && (
        <link
          rel="apple-touch-icon"
          type={metaData.apple_touch_icon.type}
          href={metaData.apple_touch_icon.href}
        />
      )}

      {metaData.canonical && <link rel="canonical" href={metaData.canonical} />}

      <base href="/" />

      {metaData.Keywords && (
        <meta name="keywords" content={metaData.Keywords} />
      )}

      {metaData.meta?.map((metaTag, index) => (
        <meta key={index} property={metaTag.name} content={metaTag.content} />
      ))}

      {metaData.scripts?.map((script, index) => (
        <script
          key={index}
          src={script.src}
          async={script.async}
          type={script.type}
        >
          {script.content}
        </script>
      ))}

      {metaData.noscript?.map((noscript, index) => (
        <noscript key={index}>{noscript.content}</noscript>
      ))}
    </Helmet>
  );
};

export default MetaHead;
