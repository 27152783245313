// import React from 'react'
// import { Helmet } from "react-helmet";
// import metaData from "../../utils/metatags.json";
import BannerServices from "../../../components/amirComponents/amirBannerServices/BannerServices";
import WhoWeAre from "../../../components/amirComponents/amirWhoWeAre/WhoWeAre";
// import TouristVisa from "../../../components/amirComponents/amirTouristVisa/TouristVisa";
import VideoSec from "../../../components/amirComponents/amirVideoSec/VideoSec";
import Faq from "../../../components/amirComponents/amirFaq/Faq";
import WhyChooseUs from "../../../components/amirComponents/amirWhyChooseUs/WhyChooseUs";
import { useEffect, useState } from "react";
import Experts from "../../../components/expert/Experts";
import ExpertCounter from "../../../components/amirComponents/amirCounter/ExpertCounter";
// import { amerImmigrationData } from "../../../datas/amerImmigration";
import PreLoader from "../../../components/preloader/PreLoader";
import Offers from "../../../components/Offers/Offers";
// import AllserviceSlider from "../../../components/AllServicesSlider/AllserviceSlider";
import ChannelPartaner from "./components/ChannelPartaner";
import Clients from "../../../components/client/Clients";
import Marquee from "../../../components/marquee/Marquee";
// import AllserviceSlider from "../../../components/AllServicesSlider/AllserviceSlider";
import {
  Allservice,
  EmployeeData,
  HomeData,
  OfferData,
  ServiceSubData,
} from "../../../types/typeofvisa";
import { AmerCenterData } from "../../../types/department";
import ServiceInDubaiSlider from "../../alAdheed/marriageServices/components/ServiceInDubaiSlider";

interface Props {
  departmentData?: HomeData;
}

const Department: React.FC<Props> = ({ departmentData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<AmerCenterData>();
  const [offers, setoffers] = useState<OfferData[]>([]);
  const [BannerService, setBannerServices] = useState<ServiceSubData[]>([]);
  const [employee, setEmployee] = useState<EmployeeData[]>([]);
  const [news, setNews] = useState<string>();
  const [isPackage, setIsPackage] = useState<boolean>(false);
  const [allService, setAllService] = useState<Allservice[]>([]);

  const getDataFromJson = async () => {
    if (departmentData)
      try {
        const response = await fetch(departmentData.JSON_URl);
        const data = await response.json();

        if (data) {
          console.log(
            "SpouseVisa aa",
            data,
            departmentData.JSON_URl,
            data?.clients
          );
          setData(data);
          return true; // Indicate success
        }
        return false; // Return false if data is not available
      } catch (error) {
        console.error("Error fetching JSON data:", error);
        return false; // Indicate failure
      }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      const check = await getDataFromJson();

      if (departmentData && check) {
        // Set state only if `departmentData` exists and `getDataFromJson` is successful
        setBannerServices(departmentData.serviceSubData);
        setEmployee(departmentData.employeeData);
        setNews(departmentData.news);
        setoffers(departmentData.offersData);
        setIsPackage(departmentData.isPackage);
        setAllService(departmentData?.Allservice);
      } else {
        console.error("Failed to fetch or set department data");
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [departmentData]);

  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }
  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <div>
          {/* <Helmet>
            <title>{metaData?.title}</title>

            <link
              rel="shortcut icon"
              type={metaData?.shortcut_icon.type}
              href={metaData?.shortcut_icon.href}
            />

            <link rel="canonical" href={metaData?.canonical} />

            <meta name="keybords" content={metaData?.Keywords} />

            {metaData?.meta.map((metaTag, index) => (
              <meta key={index} name={metaTag.name} content={metaTag.content} />
            ))}
          </Helmet> */}
          <BannerServices BannerService={BannerService} data={data?.section1} />
          <WhoWeAre section2={data?.section2} />

          {/* <TouristVisa section5={data?.section5} /> */}
          <Offers
            offers={offers}
            section8={data?.section5}
            isPackage={isPackage}
          />
          <div className="bg-white">
            <ChannelPartaner section3={data?.section3} />
          </div>
          {employee && employee.length > 0 && (
            <>
              <Experts employee={employee} />
            </>
          )}
          <Marquee news={news} />

          {data?.clients && (
            <Clients
              section9={data?.clients} //ye section dala ha
            />
          )}

          {/* {allService && allService.length > 0 && (
            <AllserviceSlider allService={allService} />
          )} */}

          <div className="bg-2 py-5">
            <ExpertCounter section7={data?.section7} />
          </div>

          {data?.section6.video && (
            <VideoSec videoPath={data?.section6.video} />
          )}
          <Faq FaqData={data?.section8} />
          <WhyChooseUs section9={data?.section9} section10={data?.section10} />

          {allService && allService.length > 0 && (
            <ServiceInDubaiSlider data={allService} />
          )}
        </div>
      )}
    </>
  );
};

export default Department;
