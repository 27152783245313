import AdminControls from "../adminComponents/AdminControl/AdminControl";
import Main from "../adminComponents/Main/Main";
// import Navbar from "../adminComponents/navbar/Navbar";
// import RightBar from "../adminComponents/RightBar.tsx/RightBar";

const Admin = () => {
  return (
    <>
      <div className="main-content">
        <Main />
        <AdminControls />
      </div>
      {/* <h1> juando </h1> */}
    </>
  );
};

export default Admin;
