
const AdminControls = () => {
  return (
    <div className="admin-controls">
      <h3>Admin</h3>
      <label htmlFor="status">Status</label>
      <select id="status">
        <option value="done">Done</option>
        <option value="in-progress">In Progress</option>
      </select>

      <label htmlFor="comments">Comments</label>
      <textarea
        id="comments"
        rows={4}
        placeholder="Add your comments here"
      ></textarea>
    </div>
  );
};

export default AdminControls;
