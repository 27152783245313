import { Videos } from "../../../types/homeComponentTypes";

const video = "https://goodhand.b-cdn.net/Assets/goodhand_homepage.mp4";
interface Props {
  section4?: Videos
}

const Section4: React.FC<Props> = ({ section4 }) => {
  return (
    <>
      <section className="stars">
        <div className="container">
          <div className="row justify-content-center video_sertion">
            <div className="col-12 text-center">
              {/* <h4 className="font-20 ff-semibold mb-5 ">
                " The ultimate guide to legal translation <br /> in the UAE: A
                seamless Step-by-step Process "
              </h4> */}
            </div>
            <div className="col-12 col-md-12 col-lg-12 py-2 position-relative">
              <div className="video-container">
                <video
                  width="100%"
                  height="240"
                  autoPlay
                  loop
                  muted
                  playsInline
                  controlsList="nodownload nofullscreen noremoteplayback"
                >
                  <source src={section4?.videoUrl ?? video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section4;
