import { useNavigate } from "react-router-dom";
import { comprehensiveSer } from "../../../../types/marriageSevices";
// import { Allservice } from "../../../../types/typeofvisa";
import { ServiceDetails } from "../../../../types/alhadeed";
import { useDispatch } from "react-redux";
import { getInsideID, setTrue } from "../../../../store/slices/IdSlice";
import axios from "axios";

const baseUrl = import.meta.env.VITE_API_URL;
interface Props {
  data?: comprehensiveSer;
  apidata?: ServiceDetails[];
}
const ComprehensiveService: React.FC<Props> = ({ data, apidata }) => {
  // console.log("ComprehensiveService", apidata);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addDataToDataBase = async (
    PriceRecordId: number,
    ServiceId: number,
    op: string,
    url: string
  ) => {
    const UUID = localStorage.getItem("uuid");
    // console.log("imppo",PriceRecordId,ServiceId,op)
    try {
      const postData = {
        ServiceId,
        Quantity: op === "plus" ? 1 : -1,
        PriceRecordId,
        UUID,
      };

      const response = await axios.post(
        `${baseUrl}/api/openRoute/addCart`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      if (data) {
        dispatch(setTrue());
        const insideId = ServiceId;
        // here we only required inside in and two function in the cart for that
        // getID
        // getInsideID({ insideId}));
        // use any one
        dispatch(getInsideID({ insideId }));
        navigate(`/${url}`);
      }
    } catch (error) {
      console.error("postData Error fetching user profile data:", error);
    }
  };

  const handleBookNow = (url: string, priceId: number, serviceId: number) => {
    addDataToDataBase(priceId, serviceId, "plus", url);
  };
  return (
    <>
      <section className="bg-2 py-5 py-md-5 ">
        <div className="container">
          <div className="row">
            <div className="hd-style-2 mb-5 ">
              <h2
                className="font-35 ff-bold"
                dangerouslySetInnerHTML={{
                  __html: data?.heading ?? "",
                }}
              ></h2>
            </div>

            {apidata?.map((service) => (
              <div className="col-md-3 mt-5" key={service.ServiceId}>
                <div className="personal-card bg-white p-md-3 p-2 rounded shadow-sm mt-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="margin-minus-50">
                      <img
                        src={service?.img}
                        alt={service?.imgAlt}
                        height={70}
                      />
                    </div>
                  </div>
                  <div>
                    <h3
                      className="font-14 ff-bold mb-1 text-black mt-2 serv-title"
                      dangerouslySetInnerHTML={{
                        __html: service?.serviceName ?? "",
                      }}
                    ></h3>
                    <p
                      className="font-12 text-black mb-1"
                      dangerouslySetInnerHTML={{
                        __html: service?.insidePageDescription ?? "",
                      }}
                    ></p>
                  </div>
                </div>
                <div className="text-center d-flex align-items-center margin-minus-20 justify-content-center col-12 ">
                  <button
                    className="btn btn-primary   font-12 ff-semibold margin-minus bt--cart h-50"
                    onClick={() =>
                      handleBookNow(
                        service?.URL,
                        service?.PriceId,
                        service?.ServiceId
                      )
                    }
                  >
                    Book Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ComprehensiveService;
