import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectAddressModel from "../../components/selectAddressModel/SelectAddressModel";
import "./cart.css";

import {
  openNewLogin,
  openSelectAddressModel,
} from "../../store/slices/modalSlice";
import AddAnotherAddressModel from "../../components/addAnotherAddress/AddAnotherAddress";
import AddDetailModel from "../../components/AddDetail/AddDetailModel";
import LoginModel from "../../components/LoginModel/LoginModel";
import { Link, useNavigate } from "react-router-dom";
import AddressFromMap from "../../components/selectAddressFromMapModel/AddressFromMap";
import Coupons from "../../components/coupons/Coupons";
import cartImg from "../../assets/cart.svg";
import AddDocument from "../../components/cardItem/AddDocument";
import { AddressRecord, UserDataType } from "../../types/cart";
// import InsideCartItem from "../../components/cardItem/InsideCartItem";
const baseUrl = import.meta.env.VITE_API_URL;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tooken, settoken] = useState<string | null>();
  const [address, setAddress] = useState<AddressRecord | null>();
  const [number, setNumber] = useState({ countryCode: "", phoneNumber: "" });

  const [paymentEnable, setPaymentEnable] = useState(false);

  const openSelectAddress = () => {
    dispatch(openSelectAddressModel());
  };

  const discount = useSelector(
    (store: { ID: { discount: number } }) => store.ID.discount
  );

  const totalAmount = useSelector(
    (store: { ID: { cartValuePrice: number } }) => store.ID.cartValuePrice
  );

  const oldTotalAmount = useSelector(
    (store: { ID: { oldValueOfCartItems: number } }) =>
      store.ID.oldValueOfCartItems
  );

  const showSelectAddress = useSelector(
    (state: { modal: { selectAddressModel: boolean } }) =>
      state.modal.selectAddressModel
  );

  const showAddAddress = useSelector(
    (state: { modal: { addAnotherAddress: boolean } }) =>
      state.modal.addAnotherAddress
  );

  const showDetailModel = useSelector(
    (state: { modal: { addDetailModel: boolean } }) =>
      state.modal.addDetailModel
  );

  const showLoginModel = useSelector(
    (state: { modal: { login: boolean } }) => state.modal.login
  );

  const login = useSelector(
    (state: { signin: { userData: UserDataType } }) => state.signin.userData
  );

  const showAddAddressFromMap = useSelector(
    (state: { modal: { addressFromMap: boolean } }) =>
      state.modal.addressFromMap
  );

  const handleData = () => {
    const localStorageData = localStorage.getItem("Login_info");
    if (localStorageData) {
      const billingRecordID = sessionStorage.getItem("billingRecordID");
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      const uuid = localStorage.getItem("uuid");

      if (login_info) {
        if (uuid) {
          console.log("cart servicepage :::: UUIDD 22", uuid);

          const fetchOrderData = async () => {
            try {
              const postData = {
                uuid,
              };

              if (!baseUrl) {
                throw new Error(
                  "Base URL is not defined in the environment variables"
                );
              }

              const URL = `${baseUrl}/api/user/createOrder`;

              const response = await fetch(URL, {
                // Replace with your API endpoint
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(postData),
              }); // Replace with your API endpoint
              const data = await response.json();
              localStorage.setItem(
                "orderNumber",
                JSON.stringify(data.orderNumber)
              );

              if (data.orderNumber) {
                const postData = {
                  billingRecordId: billingRecordID,
                  pickupAddressRecordId: "0",
                  orderNumber: data.orderNumber,
                };

                const URL = `${baseUrl}/api/user/userAddressSelection`;

                const response = await fetch(URL, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify(postData),
                });

                if (response) {
                  // Check if the response is successful
                  // if (addDetail === "true") {
                  navigate("/payment");
                  // } else {
                  //   navigate("/checkout");
                  // }
                }
              }
            } catch (error) {
              console.error("cart :: Error fetching order data:", error);
            }
          };

          fetchOrderData();
        }
      }
    }
  };

  const address1 = sessionStorage.getItem("address");

  useEffect(() => {
    if (address1) {
      const addre = JSON.parse(address1);
      setAddress(addre);
    } else {
      setAddress(null);
    }
  }, [address1]);

  // useEffect(() => {
  //   // Get all elements with IDs that start with "FormID_"
  //   const elements = Array.from(document.querySelectorAll("[id^='formID_']"));
  //   const ids = elements.map((element) => element.id);
  //   console.log("Form IDs:", ids);

  // }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("tk");
    settoken(token);
    const num = localStorage.getItem("phoneNumber");
    if (num) {
      const nm = JSON.parse(num);
      setNumber(nm[0]);
    }
  }, [login]);

  const [selectedTip, setSelectedTip] = useState<string | number | null>(null);
  const [customTip, setCustomTip] = useState("");
  const [tipValue, setTipValue] = useState< string | number>()

  // Create a reference for the custom input
  const customTipInputRef = useRef<HTMLInputElement | null>(null);

  const handleTipClick = (amount: string | number) => {

    if (amount === "custom") {
      setSelectedTip(amount);
      // Focus on the custom input field when "custom" is selected
      // customTipInputRef.current?.focus();
    } else {
      // Set the tip value directly for preset amounts and reset customTip
      setTipValue(Number(amount));
      // setCustomTip(0);
    }
  };

  // Handles updates to the custom tip input
  const handleCustomTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTip(e.target.value);

    // Mark 'custom' as the selected tip when editing the input
    if (e.target.value !== "") {
      setTipValue(Number(e.target.value));
    }
  };

  // Focus on the custom input when it's selected
  useEffect(() => {
    if (selectedTip === "custom" && customTipInputRef.current) {
      customTipInputRef.current.focus();
    }
  }, [selectedTip]);

  const handleLogin = () => {
    dispatch(openNewLogin());
  };

  const handleCheckAllData = (check: boolean) => {
    if (check) {
      setPaymentEnable(check);
    } else setPaymentEnable(check);
  };

  return (
    // <>
    //   {isLoading ? (
    //     <>
    //       <PreLoader />
    //     </>
    //   ) : (
    <>
      {totalAmount && totalAmount > 0 ? (
        <section className="cartPage-wrap py-0 py-md-5 my-4 my-md-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-5  mb-3 mb-md-0">
                {discount > 0 && (
                  <div className="Saved-section p-0 p-md-3 rounded text-center bg-2 mb-3 d-flex align-items-center gap-2 justify-content-md-center justify-content-between ">
                    <span className="font-12 ff-semibold text-dark">
                      You're saving total AED {discount.toFixed(2)} on this
                      order!
                    </span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 683 683"
                        fill="none"
                      >
                        <g>
                          <path
                            d="M572.668 169.998H532.668V309.998H632.668V229.998C632.668 196.861 605.805 169.998 572.668 169.998Z"
                            fill="#FF9801"
                          />
                          <path
                            d="M532.665 169.998H321.332V309.998H552.665L592.665 229.998C592.665 196.861 565.803 169.998 532.665 169.998Z"
                            fill="#FF4437"
                          />
                          <path
                            d="M592.668 253.426L532.668 219.999V672.666H572.668C605.805 672.666 632.668 645.802 632.668 612.666V309.999C632.668 283.877 615.969 261.665 592.668 253.426Z"
                            fill="#B6FA4C"
                          />
                          <path
                            d="M592.667 612.666V309.999C592.667 276.862 565.804 249.999 532.667 249.999H501.333L321.333 169.999H70C36.8627 169.999 10 196.862 10 229.999V612.666C10 645.666 37 672.666 70 672.666H532.667C565.804 672.666 592.667 645.803 592.667 612.666Z"
                            fill="#FFDC00"
                          />
                          <path
                            d="M572.667 209.999H70C58.9547 209.999 50 218.954 50 229.999C50 241.044 58.9547 249.999 70 249.999H141.333L321.333 229.999L501.333 249.999H572.667C579.681 249.999 586.409 251.214 592.667 253.426V229.999C592.667 218.954 583.712 209.999 572.667 209.999Z"
                            fill="#353A3D"
                          />
                          <path
                            d="M461.332 9.99804L381.332 129.998L461.332 249.998H501.332V9.99804H461.332Z"
                            fill="#92D652"
                          />
                          <path
                            d="M141.332 9.99804V249.998H181.332L461.332 109.998V9.99804H141.332Z"
                            fill="#AEED73"
                          />
                          <path
                            d="M241.332 49.998H401.332C401.332 83.1354 428.195 109.998 461.332 109.998V249.998H181.332V109.998C214.469 109.998 241.332 83.1354 241.332 49.998Z"
                            fill="#FFEEBC"
                          />
                          <path
                            d="M321.332 169.998C299.241 169.998 281.332 152.089 281.332 129.998C281.332 107.907 299.241 89.998 321.332 89.998C343.423 89.998 361.332 107.907 361.332 129.998C361.332 152.089 343.423 169.998 321.332 169.998Z"
                            fill="#92D652"
                          />
                          <path
                            d="M632.668 381.332H592.668V541.332H632.668C654.759 541.332 672.668 523.423 672.668 501.332V421.332C672.668 399.241 654.759 381.332 632.668 381.332Z"
                            fill="#B6FA4C"
                          />
                          <path
                            d="M632.665 501.332V421.332C632.665 399.241 614.756 381.332 592.665 381.332H501.332C457.149 381.332 421.332 417.149 421.332 461.332C421.332 505.515 457.149 541.332 501.332 541.332H592.665C614.756 541.332 632.665 523.423 632.665 501.332Z"
                            fill="#FF9801"
                          />
                          <path
                            d="M501.332 501.332C479.241 501.332 461.332 483.423 461.332 461.332C461.332 439.241 479.241 421.332 501.332 421.332C523.423 421.332 541.332 439.241 541.332 461.332C541.332 483.423 523.423 501.332 501.332 501.332Z"
                            fill="#EFF7F9"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}

                {tooken !== null ? (
                  <div className="address-card ">
                    <div className="d-flex align-items-center gap-3 border-bottom p-3">
                      <div className="cart-map-ico">
                        <i className="fa-solid fa-message"></i>
                      </div>

                      <div className="flex-grow-1">
                        <p className="mb-0 font-14 ff-semibold text-black">
                          Send booking details to
                        </p>
                        <p className="mb-0 font-14 ">
                          {number.countryCode + number.phoneNumber}
                        </p>
                      </div>
                    </div>

                    <div className={`d-flex align-items-center gap-3 p-3 border-bottom ${
                      address === null ? "flex-wrap" : ""
                    }`}
                    >
                      <div className="cart-map-ico col-3">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>

                      <div className="flex-grow-1">
                        <div>
                          <p className="mb-0 font-14 ff-semibold text-black">
                            Adress
                          </p>
                          {address && (
                            <p className="font-12" >
                              {/* Home - axis6, yty, ديرة - دبي - United Arab */}
                              {address?.streetName}
                            </p>
                          )}
                        </div>
                      </div>
                      {address !== null && (
                        <div className="d-flex justify-content-start align-items-center">
                          <a className="text-decoration-none">
                            <button
                              type="button"
                              className="btn-add position-relative my-2 px-3"
                              onClick={openSelectAddress}
                              style={{
                                width: "fit-content",
                              }}
                            >
                              Edit
                            </button>
                          </a>
                        </div>
                      )}
                      {address === null && (
                        <div className="text-center d-flex col-12 ">
                          <button
                            onClick={openSelectAddress}
                            className="btn btn-primary  w-100 font-14 ff-semibold py-3 mx-3 bt--cart"
                          >
                            Select Address
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Main Modal select address model */}
                    {showSelectAddress && <SelectAddressModel />}

                    {/* Nested Modal when no address then open new model */}
                    {showAddAddress && <AddAnotherAddressModel />}

                    <div className="d-flex align-items-center gap-3 border-bottom p-3 flex-wrap">
                      <div className="cart-map-ico">
                        <i className="fas fa-user-alt ml-2"></i>
                      </div>

                      <div className="flex-grow-1">
                        <p
                          className={`mb-0 font-14 ff-semibold ${
                            address === null ? "disabled" : "text-black"
                          }`}
                        >
                          Add Documents
                        </p>
                      </div>
                    </div>
                    {address !== null && (
                      <div className="p-4 border-bottom">
                        <div className="border-0">
                          <AddDocument
                            handleCheckAllData={handleCheckAllData}
                            AlltotalAmount={totalAmount}
                            AlloldTotalAmount={oldTotalAmount}
                            discount={discount}
                            //  totalQuantity={totalQuantity}
                            //  handleRefresh={handleRefresh}
                          />
                        </div>
                      </div>
                    )}

                    <div className="d-flex align-items-center gap-3 p-3 flex-wrap">
                      <div className="cart-map-ico">
                        <i className="fas fa-money-bill-wave ml-2"></i>
                      </div>

                      <div className="flex-grow-1">
                        <p
                          className={`mb-0 font-14 ff-semibold ${
                            !paymentEnable ? "disabled" : "text-black"
                          }`}
                          // className={`mb-0 font-14 ff-semibold text-black`}
                        >
                          Payment Method
                        </p>
                      </div>
                      {paymentEnable && (
                        <>
                          <label className="font-12 ff-medium text-dark">
                            <input type="checkbox" checked={true} readOnly />I
                            hereby consent to the retention of my data for
                            personal purposes.
                          </label>

                          <label className="font-12 ff-medium text-dark">
                            <input type="checkbox" checked={true} readOnly />I
                            agree to the terms and conditions, privacy policy,
                            and cancellation policy.
                          </label>

                          <div className="text-center d-flex col-12 flex-grow-1">
                            <button
                              className="btn btn-primary  ff-semibold w-100 font-14 py-3 mx-3 bt--cart"
                              disabled={!paymentEnable}
                              onClick={handleData}
                            >
                              {/* Pay AED {totalAmount.toFixed(2)} */}
                              Proceed to pay
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    {paymentEnable && (
                      <>
                        <div className="By_Proceding Saved-section p-2 rounded-bottom text-center bg-2">
                          <span className="font-12 ff-medium text-dark">
                            By Proceding, you agree to our &nbsp;
                            <Link
                              to="/termsandconditions"
                              target="blank"
                              className="text-black ff-semibold font-12 "
                            >
                              T&C
                            </Link>
                            , &nbsp;
                            <Link
                              to="/privacyandpolicy"
                              target="blank"
                              className="text-black ff-semibold font-12 "
                            >
                              Privacy
                            </Link>
                            &nbsp;and&nbsp;
                            <Link
                              to="/CancellationPolicy"
                              target="blank"
                              className="text-black ff-semibold font-12 "
                            >
                              Cancellation Policy
                            </Link>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="cart-content p-4">
                    <h2 className="font-14 ff-semibold"> Account </h2>
                    <p className="font-12 text-black">
                      To book the service, please login or signup
                    </p>
                    <button
                      className="btn btn-primary  w-100 font-14 ff-semibold py-3 me-3 bt--cart"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
              {/* <CartRightSide /> */}
              <div className="col-md-5  position-relative " >
                {/* <InsideCartItem
                  handleCheckAllData={handleCheckAllData}
                  AlltotalAmount={totalAmount}
                  AlloldTotalAmount={oldTotalAmount}
                  discount={discount}
                /> */}
                <Coupons />
                {showDetailModel && <AddDetailModel />}
                {showLoginModel && <LoginModel />}
                {showAddAddressFromMap && <AddressFromMap />}

                <div className="payment-summary-container p-4 mb-2">
                  <div className="payment-summary-header">
                    <p className="payment-summary-title">Payment summary</p>
                  </div>
                  {oldTotalAmount > totalAmount && (
                    <div className="payment-summary-item">
                      <div className="d-flex justify-content-between">
                        <p className="payment-summary-label">Actual Price</p>
                        <p className="payment-summary-value discount">
                          <s className="ff-medium">
                            AED {oldTotalAmount.toFixed(2)}
                          </s>
                        </p>
                      </div>
                    </div>
                  )}

                  {totalAmount > 0 && (
                    <div className="payment-summary-item">
                      <div className="d-flex justify-content-between">
                        <p className="payment-summary-label">Item total </p>
                        <p className="payment-summary-value">
                          AED {totalAmount.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  )}

                  {discount > 0 && (
                    <div className="payment-summary-item">
                      <div className="d-flex justify-content-between">
                        <p className="payment-summary-label">Discount</p>
                        <p className="payment-summary-value discount">
                          AED {discount.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  )}

                  {Number(tipValue) >= 5 && (

                    <div className="payment-summary-item">
                    <div className="d-flex justify-content-between">
                      <p className="payment-summary-label">Tip</p>
                      <p className="payment-summary-value discount">
                        AED {tipValue}
                      </p>
                    </div>
                  </div>
                  )}

                  {totalAmount > 0 && (
                    <div className="payment-summary-item total border-bottom">
                      <div className="d-flex justify-content-between">
                        <p className="payment-summary-label">
                          <b>Grand total</b>
                        </p>
                        <p className="payment-summary-value">
                        {Number(tipValue) >= 5  ? (
                          <b>AED {(totalAmount + (Number(tipValue) || 0)).toFixed(2)}</b>
                        ) : (
                          <b>AED {totalAmount.toFixed(2)}</b>
                        )}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="d-flex align-items-center justify-content-center gap-2 p-2 bg-2 rounded my-4 ff-normal Saved-section2">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 16"
                      fill="#095F3C"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 7.929L8.972 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.392L1.627 7.568a.998.998 0 00.274.904L8.428 15a1.002 1.002 0 001.414 0L15.5 9.343a.998.998 0 000-1.414zm-9.75-1.93a.75.75 0 110-1.5.75.75 0 010 1.5z"
                        fill="#095F3C"
                      ></path>
                    </svg>
                    <span
                      className="font-11 ff-normal"
                      style={{ fontWeight: "500" }}
                    >
                      Yay! You have saved AED {discount.toFixed(2)} on final
                      bill
                    </span>
                  </div>

                  <div>
                    <div className="font-14 ff-semibold mt-2 mb-3">
                      Add a tip to thank the Professional
                    </div>
                    <div className="tip-selector d-flex justify-content-start gap-3">
                      {/* Preset Tip Options */}
                      {[10, 15, 20].map((tip) => (
                        <div
                          key={tip}
                          className={`tip-option d-flex align-items-center justify-content-center flex-row ff-medium ${
                            selectedTip === tip ? "selected" : ""
                          }`}
                          onClick={() => handleTipClick(tip)}
                          // tabIndex="0"
                        >
                          <p className="tip-currency ">AED </p>
                          <p className="tip-amount ms-1 ">{tip}</p>
                        </div>
                      ))}

                      {/* Custom Tip Option */}
                      <div
                        className={`tip-option ${
                          selectedTip === "custom" ? "selected" : ""
                        }`}
                        onClick={() => handleTipClick("custom")}
                        // tabIndex="0"
                      >
                        {selectedTip === "custom" ? (
                          <div className="d-flex align-items-center justify-content-start gap-1 ff-medium">
                            <span className="font-13">AED </span>
                            <input
                              ref={customTipInputRef} // Attach the ref here
                              type="number"
                              value={customTip}
                              onChange={handleCustomTipChange}
                              className="custom-tip-input bg-none border-0"
                            />
                          </div>
                        ) : (
                          <p className="tip-custom-label ff-medium">Custom</p>
                        )}
                      </div>
                    </div>
                    {selectedTip === "custom" && Number(customTip) < 5 && Number(customTip) != 0 && (
                      <div className="error-text font-10 text-danger text-start mt-1 ff-medium">
                        Minimum Amount AED 5
                      </div>
                    )}

                    <div className="font-11 ff-medium mt-3 text-black-50">
                      100% of the tip goes to the professional.
                    </div>
                  </div>
                </div>

                <div className="cart-total-bt d-none">
                        <div className="d-flex justify-content-between align-items-center p-3">
                          <p className="ff-medium font-14 text-dark mb-0">
                            Amount to pay
                          </p>
                          <h4 className="ff-semibold  font-16 text-dark mb-0">
                          {Number(tipValue) >= 5  ? (
                          <b>AED {(totalAmount + (Number(tipValue) || 0)).toFixed(2)}</b>
                        ) : (
                          <b>AED {totalAmount.toFixed(2)}</b>
                        )}
                          </h4>
                        </div>
                      </div>
                {/* <div className="payment-summary-container  "                >
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <p className="ff-medium font-14 text-dark mb-0">
                      Amount to pay
                    </p>
                    <h4 className="ff-semibold  font-16 text-dark mb-0">
                      {Number(tipValue) >= 5  ? (
                          <b>AED {(totalAmount + (Number(tipValue) || 0)).toFixed(2)}</b>
                        ) : (
                          <b>AED {totalAmount.toFixed(2)}</b>
                        )}
                    </h4>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="container d-flex justify-content-center flex-column py-5">
          <img
            loading="lazy"
            src={cartImg}
            alt="No img"
            className="d-block mx-auto my-3"
            style={{ height: "50px" }}
          />
          <p className="text-black font-16 ff-semibold text-center">
            Hey, it feels so empty here.
          </p>
          <p className="text-black font-11 ff-semibold text-center">
            Lets add some services
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/services/immigration" className="text-decoration-none">
              <button
                type="button"
                className="btn-add cart-empty position-relative my-2 px-3"
                style={{ width: "fit-content" }}
              >
                Explore services
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
    //   )}
    // </>
  );
};

export default Cart;
