import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PreLoader from "../preloader/PreLoader";
import { ServiceDetails, Orders } from "../../types/booking.ts";
// import { ServiceDetails } from "../../types/alhadeed";

const baseUrl = import.meta.env.VITE_API_URL;

const BookingDetail = () => {
  const repeatCount = [1, 2, 3, 4, 5, 6];
  const { slug } = useParams();
  // const slug  = "ORD-GH-0000020";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState<ServiceDetails[]>([]);
  const [count, setCount] = useState<number>();
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState<Orders[]>();
  console.log("order",order)
  console.log("count",count)
  console.log("orderData",orderData)

  const fetchOrderData = async (
    token: string | null,
    login_infoo_userName: string
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      // const slg = "ORD-GH-0000020"
      const postData = {
        userName: login_infoo_userName,
        orderNumber: slug,
        // orderNumber: "ORD-GH-0000020",
      };

      const response = await axios.post(
        `${baseUrl}/api/user/orderListingShow`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      // console.log("response.data",response.data)
      setOrderData(data.mainOrdersList.orders);
      setCount(data.mainOrdersList.count);
      setOrder(data.mainOrdersList.order);

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    // import("./orderlist.css")
    const login_info = localStorage.getItem("Login_info");
    const token = sessionStorage.getItem("tk");

    if (login_info) {
      const login_infoo = JSON.parse(login_info);

      if (slug) {
        fetchOrderData(token, login_infoo.userName);
      }
    }
  }, []);

  
  const fetchData = async () => {
    if (searchTerm.trim() !== "") {
      const token = sessionStorage.getItem("tk");
      if (token) {
        try {
          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.get(
            `${baseUrl}/api/user/searchOrderListing`,
            {
              params: {
                orderNumber: slug,
                applicationNumber: searchTerm,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const result = response.data;
          setOrderData(result.order);
        } catch (error) {
          console.log("handleSearch error :: ", error);
        }
      } else {
        console.log("No Token :: ");
      }
    } else {
      const login_info = localStorage.getItem("Login_info");
      const token = sessionStorage.getItem("tk");
      if (login_info) {
        const login_infoo = JSON.parse(login_info);
        fetchOrderData(token, login_infoo.userName);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };



  if (isError) {
    return (
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        Something Went Wrong
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <>
      <div className="section bg-2" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center justify-content-between pb-4 bg-white mt-5">
                {order?.map((item) => (<>
                  <div className="p-2">
                    <div>
                      <span className=" font-14 ff-normal mb-0 text-black">
                        Order No:
                      </span>
                      <span className="font-14 ff-semibold mb-0 text-black"
                      
                      >
                        {item.orderNumber}
                      </span>
                    </div>
                    <div>
                      <span className=" font-14 ff-normal mb-0 text-black">
                        No. of Orders :
                      </span>
                      <span className="font-14 ff-semibold mb-0 text-black">
                        {count}
                      </span>
                    </div>
                    <div>
                      <span className=" font-14 ff-normal mb-0 text-black">
                        Order Total:
                      </span>
                      <span className="font-14 ff-semibold mb-0 text-black">
                      {item.ordertotal} AED
                      </span>
                    </div>
                  </div>
                  <p className="font-14 ff-medium mb-0 text-black">
                    <span className="font-14 ff-medium mb-0 text-black">
                      <i
                        className="fa fa-calendar-alt mx-2"
                        aria-hidden="true"
                      ></i>
                      {/* {item.orderNumber} */}
                      {new Date(item?.createdon).toLocaleString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                    </span>
                  </p>
                  </>))}
                </div>

                <div className="col-md-5 my-3 p-0">
                  <div className="bg-white rounded shadow-sm   p-4">
                    <div>
                      {orderData.map((item, index) => (
                        <div
                          className={`d-flex align-items-center gap-4 py-3 cursor-pointer order-card ${
                            index !== repeatCount.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={item.orderNumber}
                        >
                          <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <p className="font-14 ff-bold text-black mb-0">
                              Application Number : {item.applicationNumber}
                              </p>
                              <p className="bg-primary text-white font-10 mb-0 px-3 rounded-pill badge-hover">
                              {item.Status}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <p className="font-12 text-muted mb-0">
                                Service Name
                              </p>
                              <p className="font-12 text-black mb-0 text-end">
                              {item.serviceName}
                                <span>({item.serviceType})</span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <p className="font-12 text-muted mb-0">Total:</p>
                              <p className="font-12 text-black mb-0 text-end">
                                AED {item.serviceAmount}
                              </p>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center">
                              <p className="font-12 text-muted mb-0">Date:</p>
                              <p className="font-12 text-black mb-0 text-end">
                                December {item.date ?? "missing"}
                              </p>
                            </div> */}
                          </div>
                          <div className="text-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="12px"
                              height="12px"
                              viewBox="-5.5 0 26 26"
                              version="1.1"
                            >
                              <title>chevron-right</title>
                              <desc>Created with Sketch Beta.</desc>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Icon-Set-Filled"
                                  transform="translate(-474.000000, -1196.000000)"
                                  fill="#626161"
                                >
                                  <path
                                    d="M488.404,1207.36 L477.637,1197.6 C476.806,1196.76 475.459,1196.76 474.629,1197.6 C473.798,1198.43 473.798,1199.77 474.629,1200.6 L483.885,1209 L474.629,1217.4 C473.798,1218.23 473.798,1219.57 474.629,1220.4 C475.459,1221.24 476.806,1221.24 477.637,1220.4 L488.404,1210.64 C488.854,1210.19 489.052,1209.59 489.015,1209 C489.052,1208.41 488.854,1207.81 488.404,1207.36"
                                    id="chevron-right"
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 my-3 pe-0">
                  <div className="bg-white rounded shadow-sm p-4 mt-3">
                    <div className="row">
                      <h5 className="font-16 ff-bold border-bottom pb-2">
                        Applicant’s Details
                      </h5>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="applicantName"
                            className="form-label font-14 ff-medium"
                          >
                            Applicant Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="applicantName"
                            placeholder="Applicant name"
                            value=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="applicantPassport"
                            className="form-label font-14 ff-medium"
                          >
                            Applicant Passport Number
                          </label>
                          {/* <input
                            type="text"
                            className="form-control"
                            id="applicantPassport"
                            placeholder="Applicant Passport No"
                            value=""
                            onClick={(e)=>handleSearch(e)}
                          /> */}
                           <input
                            type="text"
                            className="form-control custom-search-input"
                            placeholder="Enter Order Number"
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>

                      <h5 className="font-16 ff-bold border-bottom pb-2">
                        Sponsor's Details
                      </h5>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="sponsorName"
                            className="form-label font-14 ff-medium"
                          >
                            Sponsor Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sponsorName"
                            placeholder="Sponsor name"
                            value=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="sponsorPassport"
                            className="form-label font-14 ff-medium"
                          >
                            Sponsor Passport Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sponsorPassport"
                            placeholder="Sponsor's Passport No"
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-4 mt-3">
                    <h5 className="font-16 ff-bold border-bottom pb-2">
                      Applicant’s Details
                    </h5>
                    <div className="tracking-timeline">
                      <div className="tracking-item">
                        <div className="tracking-icon">
                          <i className="fas fa-check"></i>
                        </div>
                        <div>
                          <div className="tracking-date-time">
                            10.11.2016 - 01:15
                          </div>
                          <div className="font-14 ff-semibold">Xiamen</div>
                          <p className="tracking-status">
                            The shipment has been successfully delivered.
                          </p>
                        </div>
                      </div>
                      <div className="tracking-item">
                        <div className="tracking-icon">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div>
                          <div className="tracking-date-time">
                            13.11.2016 - 12:38
                          </div>
                          <div className="font-14 ff-semibold">Beijing</div>
                          <p className="tracking-status">
                            The shipment is ready to be picked up.
                          </p>
                        </div>
                      </div>
                      <div className="tracking-item">
                        <div className="tracking-icon">
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div>
                          <div className="tracking-date-time">
                            14.11.2016 - 03:24
                          </div>
                          <div className="font-14 ff-semibold">Beijing</div>
                          <p className="tracking-status">
                            The shipment has been processed in location.
                          </p>
                        </div>
                      </div>
                      <div className="tracking-item">
                        <div className="tracking-icon">
                          <i className="fa-regular fa-hourglass-half"></i>
                        </div>
                        <div>
                          <div className="tracking-date-time">
                            17.11.2016 - 10:19
                          </div>
                          <div className="font-14 ff-semibold">Tianzjin</div>
                          <p className="tracking-status">
                            The shipment has been processed in location.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
    </>
  );
};

export default BookingDetail;
