// import Steps1 from "../../../assets/spouse-visa/step1.svg";
// import Steps2 from "../../../assets/spouse-visa/steps2.svg";
// import Steps3 from "../../../assets/spouse-visa/steps3.svg";
// import Steps4 from "../../../assets/spouse-visa/steps4.svg";

interface Box {
  icon: string; // or number, based on your actual data
  heading: string;
  line: string;
}

interface Section5 {
  heading: string;
  box: Box[];
}

interface Props {
  section5?: Section5; // You can make section3 optional if necessary
}

const VisaProcess: React.FC<Props> = ({ section5 }) => {
  return (
    <>
      <section className="steps bg-2 py-5">
        <div className="container">
          <div className="row">
            <div className="hd-style-2 golden py-3 py-md-5">
              <h2
                dangerouslySetInnerHTML={{
                  __html: section5?.heading ?? " ",
                }}
              ></h2>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {section5?.box.map((boxItem, index) => (
                <div className="col-md-3 col-sm-6 text-center " key={index}>
                  <div className="d-flex d-md-block align-items-center justify-content-center steps-full gap-3 pb-3 pb-md-0 ">
                    <img
                      src={boxItem.icon}
                      alt={boxItem.heading}
                      className="img-fluid d-block mx-auto wow animate__zoomIn"
                      data-wow-delay={`${index * 0.2}s`}
                      key={index}
                    />
                    <div>
                      <h5
                        className="card-title my-3 text-start text-md-center"
                        dangerouslySetInnerHTML={{
                          __html: boxItem?.heading ?? " ",
                        }}
                      ></h5>
                      <p
                        className="card-text text-start text-md-center pb-3 pb-md-0"
                        dangerouslySetInnerHTML={{
                          __html: boxItem?.line ?? " ",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisaProcess;
