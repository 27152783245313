import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import { useDispatch } from "react-redux";
// import { closeNewLogin, openVerifyOtp } from "../../store/slices/modalSlice";
import countryCodes from "../../datas/countrydata.json";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import BackButton from "../../components/backButton/Backbutton";

const baseUrl = import.meta.env.VITE_API_URL;

interface CountryOption {
  value: string;
  label: string;
}

const Logout: React.FC = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [countryCode, setCountryCode] = useState("+971");

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [errors, setErrors] = useState<{
    phoneNumber?: string;
    confirmation?: string;
  }>({});

  useEffect(() => {
    import("./logout.css");
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }

    const num = localStorage.getItem("phoneNumber");
    if (num) {
      const number = JSON.parse(num);
      console.log("number", number);
      console.log("number", typeof number);
      const lastSevenDigits = number.slice(-9); // Gets the last 7 characters
      console.log("number", lastSevenDigits);
      setPhoneNumber(number[0].phoneNumber);
    }
  }, []);


  const openVeriftyModel = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${baseUrl}/api/openRoute/loginWithNumber`,
          {
            userNumber: phoneNumber,
            UserCountryCode: countryCode,
          }
        );

        if (response.status === 200) {
          localStorage.setItem(
            "phoneNumber",
            JSON.stringify([{ countryCode, phoneNumber }])
          );
          navigate("/otp");
          // dispatch(closeNewLogin());
          // dispatch(openVerifyOtp());
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors: {
      // phoneNumber?: string;
      confirmation?: string;
    } = {};
    // const phoneRegex = /^[0-9]{9}$/; // Adjust based on phone number format

    if (!isConfirmed) {
      newErrors.confirmation =
        "Please agree to the processing of your data to proceed";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCountryChange = (
    selectedOption: SingleValue<CountryOption> | null
  ) => {
    if (selectedOption) {
      setCountryCode(selectedOption.value);
    }
  };

  const countryOptions = countryCodes.map((country) => ({
    value: country.code,
    label: `${country.code} ${country.country}`, // Display both country code and name
  }));

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <div className="bg-2 min-vh-100 py-md-0">
        <div className="container">
          <div className="row justify-content-center help-wrap">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
                borderBottom: "1px solid gray",
                marginBottom: "5px",
              }}
            >
              <div onClick={goBack}>
                {/* <div className="col-12"> */}
                {/* <BackButton />  */}
                <i className="fas fa-arrow-left"></i>
                {/* </div> */}
              </div>
              <div>
                <span className="ff-semibold text-black">
                  Login to continue
                </span>
              </div>
              <div>
                <h3 className="ff-semibold text-black"></h3>
              </div>
            </div>
            <div className="input-group mb-3">
              <Select
                className="input-group-text text-black-50 bg-white font-12 custom-select "
                options={countryOptions}
                classNamePrefix="custom-select"
                value={{
                  value: countryCode,
                  label: `${countryCode} `,
                }}
                onChange={handleCountryChange}
                isSearchable={true}
                placeholder="Search country code"
              />
              <input
                ref={phoneInputRef}
                type="tel"
                inputMode="numeric"
                className="form-control py-3 font-12 "
                placeholder="Enter mobile number"
                aria-label="Mobile Number"
                aria-describedby="basic-addon1"
                value={phoneNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  // Only set phoneNumber if the input consists of digits only
                  if (/^\d*$/.test(input) && input.length <= 12) {
                    setPhoneNumber(input);
                  }
                }}
              />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="confirmation"
                  checked={isConfirmed}
                  onChange={(e) => setIsConfirmed(e.target.checked)}
                />
                <label
                  className="form-check-label font-12"
                  htmlFor="confirmation"
                >
                  I consent to the processing of my personal information
                </label>
                {errors.confirmation && (
                  <p className="text-danger">{errors.confirmation}</p>
                )}
              </div>
            </div>

            <div>
              <Button
                className="btn btn-primary w-100 ff-semibold"
                onClick={openVeriftyModel}
                disabled={phoneNumber.length < 9 || !isConfirmed}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
