import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    import("./Backbutton.css");
  }, []);

  return (
    <div className="container p-0  d-md-block">
      <div className="row justify-content-center">
        <div className="col-md-5 bg-white py-3 border-bottom d-flex align-items-center ">
          <button onClick={goBack} className="back-button p-0">
            <i className="fas fa-arrow-left"></i>
          </button>
          {location.pathname === "/payment" && (
            <span className="font-16 ff-semibold mb-0 text-black"> {location.pathname.slice(1)}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackButton;
