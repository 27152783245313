import { WhyChooseU } from "../../../types/help";

interface Props {
  whychooseus: WhyChooseU
}

const WhyChooseUs: React.FC<Props> = ({ whychooseus }) => {
  return (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: whychooseus?.heading ?? "",
        }}
      ></h1>

      {whychooseus?.steps?.map((step) => (
        <ul key={step.id}>
          <li>
          {step.id}.&nbsp;&nbsp;&nbsp;<b>{step.heading}:</b>&nbsp;{step.step}
          </li>
        </ul>
      ))}
    </>
  );
};

export default WhyChooseUs;
