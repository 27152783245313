import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItems } from "../../types/cart";

interface CartState {
  items: CartItems[];
}

const initialState: CartState = {
  items: []
};

const separateCartDataSlice = createSlice({
  name: "separate",
  initialState,
  reducers: {
    addToCartData: (state, action: PayloadAction<{ productId: string; quantity: number; productName: string; productPrice: number; priceType: string }>) => {
      const { productId, quantity, productName, productPrice, priceType } = action.payload;
      const indexProductId = state.items.findIndex(item => item.productId === productId);
      if (indexProductId >= 0) {
        state.items[indexProductId].quantity = quantity;
      } else {
        state.items.push({ productId, quantity, productName, productPrice, priceType });
      }
    },
    changeQuantity: (state, action: PayloadAction<{ productId: string; quantity: number }>) => {
      const { productId, quantity } = action.payload;
      const indexProductId = state.items.findIndex(item => item.productId === productId);
      if (indexProductId >= 0) {
        if (quantity > 0) {
          state.items[indexProductId].quantity = quantity;
        } else {
          state.items = state.items.filter(item => item.productId !== productId);
        }
      }
    }
  }
});

export const { addToCartData, changeQuantity } = separateCartDataSlice.actions;

export default separateCartDataSlice.reducer;
