import { CompanyInfo } from "../../../types/help";

interface Props {
  compinfo: CompanyInfo
}

const CompnayInfo: React.FC<Props> = ({ compinfo }) => {
  return (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: compinfo?.heading ?? "",
        }}
      ></h1>
      <p
        dangerouslySetInnerHTML={{
          __html: compinfo?.content ?? "",
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: compinfo?.line ?? "",
        }}
      ></p>
    </>
  );
};

export default CompnayInfo;
