import { useCallback, useEffect, useState } from "react";
import {
  closeAddressFromMap,
  openAddAnotherAddress,
  openSelectAddressModel,
} from "../../store/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Row, Col, Form, Container } from "react-bootstrap";
import axios from "axios";
import { useData } from "../toast/Api";
import { GoogleMap, Marker } from "@react-google-maps/api";

const baseUrl = import.meta.env.VITE_API_URL;

interface coordinate {
  lat: number;
  lng: number;
}
interface selectedPlaceData {
  address: string;
  coordinates: coordinate;
  name: string;
}

const AddressFromMap = () => {
  const {
    // loading,
    // showMorning,
    showSuccess,
    showError,
    // showInfo,
    // showWarn,
    // showCustom,
    // getSomeData
  } = useData();

  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>();
  const [recordId, setRecordId] = useState("");
  const [addresNickname, setAddresNickname] = useState("");
  // const [streetName, setStreetName] = useState("");
  const [buildingName, setbuildingName] = useState("");
  // const [floor, setfloor] = useState("");
  const [flat, setFlat] = useState("");
  const [landmark, setLandmark] = useState("");
  const [isPrimary, setIsPrimary] = useState(true);
  const [addressType, setAddressType] = useState("billing");
  // console.log("streetName", streetName);

  const [selectedAddress, setSelectedAddress] = useState<string | null>(
    "billing"
  );
  const [selectedPlaceData, setSelectedPlaceData] =
    useState<selectedPlaceData>();
  const [center, setCenter] = useState<coordinate>({
    lat: 37.7749,
    lng: -122.4194,
  });

  const showThisModel = useSelector(
    (state: { modal: { addressFromMap: boolean } }) =>
      state.modal.addressFromMap
  );

  useEffect(() => {
    const selectedPlace = sessionStorage.getItem("selectedPlace");
    if (selectedPlace) {
      const selectPlace = JSON.parse(selectedPlace);
      const newCenter = {
        lat: selectPlace.coordinates.lat,
        lng: selectPlace.coordinates.lng,
      };
      setCenter(newCenter);
      setSelectedPlaceData(selectPlace);
    }
    const tkn = sessionStorage.getItem("tk");
    setToken(tkn);



    const mode = sessionStorage.getItem("mode");

    if (mode === "Edit") {
      const editData = localStorage.getItem("filteredData");


      if (editData) {
        try {
          const parsedData = JSON.parse(editData);
          const {
            RecordId,
            addresNickname,
            address_type,
            building,
            landmark,
            streetName,
            // floor,
            flatNo,
            // userName,
            longitude,
            latitude
          } = parsedData;
          setAddresNickname(addresNickname);
          setbuildingName(building);
          setFlat(flatNo);
          setLandmark(landmark);
          setIsPrimary(true);
          setAddressType(address_type);
          setRecordId(RecordId);
          if (!selectedPlace) {
          const newCenter = {
            lat: latitude,
            lng: longitude,
          };
          setSelectedPlaceData(
            {address: streetName,
            coordinates: newCenter,
            name:streetName })
          setCenter(newCenter);
          }
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.log('No data found in localStorage for key "filteredData".');
      }
    }
  }, []);

  const handleSelectAddress = (address: string) => {
    if(address === "shipping"){
      setAddresNickname("Home")
    }
    setSelectedAddress(address);
  };

  const openSelectAddress = async () => {
    const mode = sessionStorage.getItem("mode");
    if (
      // streetName != "" &&
      buildingName != "" &&
      // floor != "" &&
      flat != "" &&
      landmark != ""
    ) {
      if (mode === "Edit") {
        try {
          const data = {
            id: recordId,
            // userName: localStorageData ?? login_infoo.userName,
            address: selectedAddress,
            address_type: addressType,
            addresNickname: addresNickname,
            streetName: selectedPlaceData?.address,
            building: buildingName,
            floor: "floor",
            flatNo: flat,
            landmark: landmark,
            primaryAddress: isPrimary,
            longitude: center.lng,
            latitude: center.lat,
          };

          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.put(
            `${baseUrl}/api/user/userAddressUpdate?id=${recordId}`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if(response){
          sessionStorage.setItem(
            "billing",
            JSON.stringify(Math.floor(Math.random() * 1000) + 1)
          );
          showSuccess(" Address Successfully Updated");
          sessionStorage.removeItem("mode");
          localStorage.removeItem("filteredData");
          sessionStorage.removeItem("selectedPlace")
          dispatch(closeAddressFromMap());
          dispatch(openSelectAddressModel());
        }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        try {
          const data = {
            address_type: addressType,
            addresNickname: addresNickname,
            streetName: selectedPlaceData?.address,
            building: buildingName,
            floor: flat,
            flatNo: flat,
            landmark: landmark,
            primaryAddress: isPrimary,
            longitude: center.lng,
            latitude: center.lat,
          };

          if (!baseUrl) {
            throw new Error(
              "Base URL is not defined in the environment variables"
            );
          }

          const response = await axios.post(
            `${baseUrl}/api/user/userAddressAdd`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if(response){
          sessionStorage.setItem(
            "billing",
            JSON.stringify(Math.floor(Math.random() * 1000) + 1)
          );

          showSuccess("Address Saved");
          sessionStorage.removeItem("selectedPlace")
          dispatch(closeAddressFromMap());
          dispatch(openSelectAddressModel());
        }
        } catch (error) {
          console.error("Error in addUserAddress:", error);
        }
      }
    } else {
      showError("pllz fill all the fields");
    }
  };

  const closeThisModel = () => {
    sessionStorage.removeItem("selectedPlace")
    sessionStorage.removeItem("mode");
    localStorage.removeItem("filteredData");
    dispatch(closeAddressFromMap());
  };

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const clickedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCenter(clickedLocation);

      // Reverse geocode the clicked location
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: clickedLocation }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          setSelectedPlaceData({
            name: results[0].address_components[0].long_name,
            address: cleanAddress(results[0].formatted_address),
            coordinates: clickedLocation,
          });
        }
      });
    }
  }, []);

  const cleanAddress = (address: string) => {
    const parts = address.split(" - ");
    return parts.filter((part) => !/^[A-Za-z0-9+]{2,}$/.test(part)).join(" - ");
  };

  const handleChange = () => {
    dispatch(closeAddressFromMap());
    dispatch(openAddAnotherAddress());
  };

  return (
    <>
      <Modal
        show={showThisModel}
        onHide={closeThisModel}
        centered
        size="lg"
        className="mb-fix slide-from-bottom"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 rounded">
          <Row>
            <Col md={7}>
              <div className="rounded-lg rounded-top overflow-hidden ">
                <GoogleMap
                  mapContainerClassName="map-container"
                  zoom={13}
                  center={center}
                  onClick={handleMapClick}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                >
                  {/* {selectedPlaceData && ( */}
                  <Marker
                    position={center}
                    animation={google.maps.Animation.DROP}
                  />
                  {/* )} */}
                </GoogleMap>
              </div>
            </Col>
            <Col md={5} className="ps-0 custom-modal-section">
              <div className="modal-content-wrapper">
                <div className="modal-content-scroll">
                  <div>
                    <div
                      className="d-flex justify-content-start align-items-center"
                      onClick={handleChange}
                    >
                      <button
                        type="button"
                        className="btn-add position-relative my-2 px-3"
                        style={{
                          width: "fit-content",
                        }}
                      >
                        Change
                      </button>
                    </div>

                    <div className="details-loc mt-2">
                      <p className="font-18 fw-semibold text-black mb-0">
                        {selectedPlaceData?.address}
                      </p>
                      {/* <p className="font-12">
                        {streetName} {landmark}
                      </p> */}
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="House/Flat Number*"
                        id="House"
                        className="font-12 py-3"
                        value={flat}
                        onChange={(e) => setFlat(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Building Name - Street Address*"
                        id="Building"
                        className="font-12 py-2"
                        value={buildingName}
                        onChange={(e) => setbuildingName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter Landmark (Optional)"
                        id="landmark"
                        className="font-12 py-3"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                      />
                    </Form.Group>

                    <Container className="p-0">
                      <p className="font-12 fw-medium">Save as</p>
                      <div className="d-flex align-items-center gap-3">
                        <div
                          onClick={() => handleSelectAddress("shipping")}
                          className={`mb-2 border rounded font-12 text-black p-2 ${
                            selectedAddress === "shipping"
                              ? "border-primary"
                              : "border-secondary"
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <p className="m-0">Home</p>
                        </div>
                        <div
                          onClick={() => handleSelectAddress("billing")}
                          className={`mb-2 border rounded font-12 text-black p-2 ${
                            selectedAddress === "billing"
                              ? "border-primary"
                              : "border-secondary"
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <p className="m-0">Other</p>
                        </div>
                      </div>
                    </Container>

                    {selectedAddress === "billing" && (
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Eg. John's home, Mom's home etc."
                          id="landmark"
                          className="font-12 py-3"
                          value={addresNickname}
                          onChange={(e) => setAddresNickname(e.target.value)}
                        />
                      </Form.Group>
                    )}
                  </div>
                </div>
                <Button className="save-button" onClick={openSelectAddress}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressFromMap;
