import React from "react";
import { Offer, Section8 } from "../../types/home";

const OfferSlider = React.lazy(() => import("./OfferSlider"));

interface Props {
  offers: Offer[];
  section8?: Section8;
  isPackage: boolean;
}

const Offers: React.FC<Props> = ({ offers, section8, isPackage }) => {
  return (
    <>
      <section className="offers-wrap py-5 bg-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={section8?.image}
                className="img-fluid Offers-Banner d-none d-md-block"
                alt="Offers Banner"
              />
            </div>
            <div className="col-md-6 col-12 my-auto">
              <div className="hd-style-2 py-3 wow animate__fadeInRight">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: section8?.heading ?? "",
                  }}
                ></h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section8?.line ?? "",
                  }}
                ></p>
              </div>
              <div className="slider-offer-wrap wow animate__zoomIn">
                <OfferSlider offerData={offers} isPackage={isPackage}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offers;
