import { useEffect } from "react";

const AdminList = () => {
  const data = [
    {
      "SR NO.": 1,
      orderNumber: "ORD-GH-000001",
      noOfApplications: 5,
      action: "View",
    },
    {
      "SR NO.": 2,
      orderNumber: "ORD-GH-000002",
      noOfApplications: 3,
      action: "View",
    },
    {
      "SR NO.": 3,
      orderNumber: "ORD-GH-000003",
      noOfApplications: 8,
      action: "View",
    },
    {
      "SR NO.": 4,
      orderNumber: "ORD-GH-000004",
      noOfApplications: 2,
      action: "View",
    },
    {
      "SR NO.": 5,
      orderNumber: "ORD-GH-000005",
      noOfApplications: 4,
      action: "View",
    },
  ];

  useEffect(() => {
    import("./admin.css");
  }, []);

  return (
    <div className="p-3 d-flex flex-column gap-2">
      <div className="topdiv">
        <div>
          <h1> order </h1>
        </div>
        <div className="right">
          <button className="addnewButton"> Add New </button>
          <button className="exportButton"> Export </button>
        </div>
      </div>
      <div className="middlediv">
        <div className="search-containe col-3">
          <span className="search-button">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
          <input type="text" placeholder="Search..." className="search-input" />
        </div>
      </div>

      <div>
        <table className="table">
          {/* Table Header */}
          <thead className="tableHead">
            <tr>
              <th>SR NO.</th>
              <th>Order Number</th>
              <th>No of Applications</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item["SR NO."]}</td>
                <td>{item.orderNumber}</td>
                <td>{item.noOfApplications}</td>
                <td>
                  <span className="cursor-pointer">
                    <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                    {item.action}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminList;
